export default [
  "canberracentre",
  "eastland",
  "robina",
  "westpoint",
  "woodgrove",
  "grandcentral",
  "castletowers",
  "castlemall",
  "coburnscentral",
  "hyperdome",
  "watergardens",
  "forestlake",
  "nerangmall",
  "pittwaterplace",
  "pacificwerribee",
  "pacificepping",
  "hinklercentral",
  "bathurstcity",
  "bigtop",
  "kipparing",
  "thevillage",
  "craigieburnjunction",
  "merrifieldcity",
  "domaincentral"
];

import React, { Component, Fragment } from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import Details from "../Details";
import defaultImg from "../../../assets/img/placeholders/gray.png";
import RiseAndFade from "../Animations/RiseAndFade";
import TagManager from "react-gtm-module";

class ContentTileList extends Component {
  constructor(props) {
    super(props);
  }

  textFieldToUppercase(textField) {
    let value = textField.value.toUpperCase();
    return { value };
  }

  categoryHandler(category) {
    this.props.categoryMainHandler(category);
  }

  handleClickBrauzBtn = id => {
    window.Brauz_open_book_a_stylist_dialog_with_selected_store(id);
  };

  render() {
    const {
      category,
      name,
      nameLink,
      location,
      locationLink,
      itemId,
      openTime,
      thumbnail,
      logo,
      cookTime,
      serves,
      difficulty,
      rating,
      price,
      acceptsOnlineBooking,
      bookingIcon,
      bookingLabel,
      bookingUrl,
      hasBooking,
      index,
      position,
      retailerName,
      isRetailerContent,
      isLogoAsThumbnail,
      thumbnailAltText,
      pageType,
      retailerId,

    } = this.props;

    const defaultImage = () => {
      if (thumbnail && thumbnail.value && thumbnail.value.src) {
        return (
          <div className="include_logo_padding">
            <Image media={{ ...thumbnail.value, src: defaultImg }}
              data-src={thumbnail.value.src}
              className="tile-image lazyload"
            />
          </div>
        );
      } else
        return (
          <Fragment>
            {isRetailerContent && isRetailerContent.value && (
              <div className="display_retailer_name">
                <Text field={retailerName} />
              </div>
            )}
            <div className="no-image" />
          </Fragment>
        );
    };

    const defaultLogo = () => {
      if (logo && logo.value && logo.value.src) {
        return (
          <div className="tile-logo-wrapper">
            <Image
              media={{ ...logo.value, src: defaultImg }}
              data-src={logo.value.src}
              className="tile-logo lazyload"
            />
          </div>
        );
      } else {
        return (
          <div className="no-logo">
            <Text field={name} />
          </div>
        );
      }
    };

    const ratingSection = () => {
      let ratings = [];
      let priceLevel = [];
      let ratingContainer = [];

      if (rating && rating.value) {
        const ratingValue = parseInt(rating.value);

        for (let i = 1; i <= 5; i++) {
          if (i <= ratingValue) {
            ratings.push(<div className="icon icon-star checked" key={i} />);
          } else {
            ratings.push(<div className="icon icon-star" key={i} />);
          }
        }
      }

      if (price && price.value) {
        const priceLevelValue = parseInt(price.value);
        if (priceLevelValue <= 1) {
          priceLevel.push(
            <i className="icon icon-dollar" key={`priceLevelId1`} />
          );
        } else if (priceLevelValue === 2 || priceLevelValue === 3) {
          for (let i = 0; i < 2; i++) {
            priceLevel.push(
              <i className="icon icon-dollar" key={`priceLevelId` + i} />
            );
          }
        } else if (priceLevelValue >= 4) {
          for (let i = 0; i < 3; i++) {
            priceLevel.push(
              <i className="icon icon-dollar" key={`priceLevelId` + i} />
            );
          }
        }
      } else {
        priceLevel = "";
      }

      ratingContainer =
        ratings.length || priceLevel ? (
          <div className="storerating">
            <div className="star">{ratings}</div>
            <div className="dollar">
              <div className="pricelevel">
                <span>{priceLevel}</span>
              </div>
            </div>
          </div>
        ) : (
          ""
        );

      return ratingContainer;
    };

    const iconsLayout = (
      <div className="icons">
        {ratingSection()}
        {location && location.value && (
          <Details
            iconValue="location mr-2"
            linkUrl={locationLink && locationLink.value}
            linkName={location}
            mapInfo={retailerId && retailerId.value ? retailerId.value : ""}
            size="24"
          />
        )}
        {openTime && openTime.value && (
          <Details iconValue="time mr-2" linkName={openTime} size="24" />
        )}
        {cookTime && cookTime.value && (
          <Details iconValue="timer" linkName={cookTime} size="24" />
        )}
        {serves && serves.value && (
          <Details iconValue="users" linkName={serves} size="24" />
        )}
        {difficulty && difficulty.value && (
          <Details iconValue="chef-2" linkName={difficulty} size="24" />
        )}
        {acceptsOnlineBooking && acceptsOnlineBooking.value && (
          <div className="details-wrapper">
            {bookingIcon && bookingIcon.value ? (
              <i
                className={`icon icon-${bookingIcon.value} mr-2`}
                style={{ fontSize: "24px" }}
              />
            ) :
              <i
                className="icon icon-calendar-2 mr-2"
                style={{ fontSize: "24px" }}
              />
            }
            <div className="text-block">
              {hasBooking && hasBooking.value ? (
                <button className="brauz" onClick={() => this.handleClickBrauzBtn(itemId.value)}>
                  <Text field={bookingLabel} />
                </button>
              ) : bookingUrl && bookingUrl.value ? (
                <a href={bookingUrl.value} target="_blank">
                  <Text field={bookingLabel} />
                </a>
              ) : (
                <Text field={bookingLabel} />
              )}
            </div>
          </div>
        )}
      </div>
    );

    return (
      <div 
          className="col-12 col-md-4 col-lg-3" 
          key={name.value + index}
          onClick={() =>
            TagManager.dataLayer({
              dataLayer: {
                event: "lifestyleClick",
                lifestyle_page_type: pageType.value,
                lifestyle_title: name.value
              },
              dataLayerName: "dataLayer",
            })
          }
      >
        <RiseAndFade position={position}>
          <div className="animation-range">
            <div
              className={`image-wrapper  ${isLogoAsThumbnail && isLogoAsThumbnail.value
                ? "display_retailer_logo"
                : ""
                }`}
            >
              <a
                href={nameLink && nameLink.value ? nameLink.value : ""}
                className="name"
              >
                {defaultImage()}
                {itemId && itemId.value && defaultLogo()}
              </a>
            </div>
            {pageType && pageType.value ? (
              <div className="title">
                <div className="page-type">
                  <div className="pagetype-rectangle"></div><Text field={pageType} />
                </div>
                <div className="name">
                  <a href={nameLink && nameLink.value ? nameLink.value : ""}>
                    <Text field={name} />
                  </a>
                </div>
              </div>
            ) :
              <div className="title">
                {category && category.value && (
                  <div className="category">
                    <a onClick={() => this.categoryHandler(category)}>
                      <Text field={this.textFieldToUppercase(category)} />
                    </a>
                  </div>
                )}
                <div className="name">
                  <a href={nameLink && nameLink.value ? nameLink.value : ""}>
                    <Text field={name} />
                  </a>
                </div>
                {iconsLayout}
              </div>
            }

          </div>
          {iconsLayout}
        </RiseAndFade>
      </div>
    );
  }
}

export default ContentTileList;

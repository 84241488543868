import React from "react";
import { Text, Image, Link } from "@sitecore-jss/sitecore-jss-react";
import defaultImg from "../../../../assets/img/placeholders/gray.png";

const FeaturePromotionsWhatsOn = props => {
  const { Title: title = null, EventList: eventList = null } =
    props.fields || {};

  const getDate = function(date) {
    let d = new Date(date);
    return d.getDate();
  };

  const getMonth = function(date) {
    const month = [];
    month[0] = "Jan";
    month[1] = "Feb";
    month[2] = "Mar";
    month[3] = "Apr";
    month[4] = "May";
    month[5] = "Jun";
    month[6] = "Jul";
    month[7] = "Aug";
    month[8] = "Sep";
    month[9] = "Oct";
    month[10] = "Nov";
    month[11] = "Dec";

    let d = new Date(date);
    return month[d.getMonth()];
  };
  if (eventList && eventList.length) {
    return (
      <div className="container">
        <div className="whatson-container">
          <div className="whatson-header-banner">
            <div className="whatson-header-title">
              <Link field={title} />
            </div>
            {/* <div className="whatson-header-sub">
            <Text field={subtext} />
          </div> */}
          </div>

          {eventList &&
            eventList.map((listItem, index) => {
              const {
                Thumbnail: thumbnail = null,
                StartDate: startDate = null,
                Name: name = null,
                Description: description = null,
                Location: location = null,
                ScheduleTime: scheduleTime = null
              } = listItem.fields || {};
              return (
                <div
                  key={`myId-${index}`}
                  className="whatson-content-container"
                >
                  <div className="row">
                    <div className="whatson-content">
                      <div className="col-auto">
                        <Image
                          media={{ ...thumbnail.value, src: defaultImg }}
                          data-src={thumbnail.value.src}
                          className="whatson-content-image lazyload"
                        />
                        <div className="whatson-content-date">
                          <span className="whatson-content-date-day">
                            {getDate(startDate.value)}
                          </span>
                          <br />
                          <span className="whatson-content-date-month">
                            {getMonth(startDate.value)}
                          </span>
                        </div>
                      </div>

                      <div className="col">
                        <div className="whatson-content-info-container">
                          <div className="whatson-content-info-title">
                            <span>
                              <Text field={name} />
                            </span>
                          </div>
                          <div className="whatson-content-info-description">
                            <span>
                              <Text field={description} />
                            </span>
                          </div>
                          <div className="whatson-content-info-items">
                            <i className="icon icon-location" />
                            <u>
                              <Text field={location} />
                            </u>
                          </div>
                          <div className="whatson-content-info-items">
                            <i className="icon icon-time" />
                            <Text field={scheduleTime} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  } else return null;
};
export default FeaturePromotionsWhatsOn;

import React from "react";
import { Text, Link } from "@sitecore-jss/sitecore-jss-react";

const NavMovies = props => {
  let { title, data } = props;
  if (props && title && title.url && data && data.length) {
    return (
      <div className="nav-movies-wrapper">
        <div className="nav-movies-title">
          {title.url ? (
            <React.Fragment>
              <a href={title.url}>
                <Text field={title.name} />
              </a>
              <i className="icon icon-arrow-right" />
            </React.Fragment>) :
            <Text field={title.name} />
          }
        </div>
        <div className="nav-movies-content-wrapper">
          {data &&
            data.length &&
            data.map((movies, index) => {
              const { MovieTitle = null, MovieUrl = null, Thumbnail = null } =
                movies || {};
              return (
                <div
                  key={`storeId-${index}`}
                  className="nav-movies-content-columns"
                >
                  <Link field={{ href: MovieUrl.value }}>
                    {Thumbnail.value ? (
                      <img
                        alt=""
                        src={Thumbnail.value}
                        data-src={Thumbnail.value}
                        className="nav-movies-content-image lazyload" />
                    ) : (
                        <div className="nav-movies-content-image">&nbsp;</div>
                      )}
                  </Link>
                  <div className="nav-movies-content-title">
                    <Link
                      field={{
                        href: MovieUrl.value,
                        text: MovieTitle.value
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  } else return null;
};

export default NavMovies;

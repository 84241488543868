import React from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import Badge from "../../../Common/Badge";
import Details from "../../../Common/Details";
import BaseComponent from "../../../Base/BaseComponent";

const FeatureFeaturedRetailerFeaturedRetailer = props => {
  const {
    StoreId: storeid = null,
    Header: header = null,
    RetailerName: retailerName = null,
    RetailerUrl: retailerUrl = null,
    Location: location = null,
    OpeningHours: openingHours = null,
    RetailerImage: retailerImage = null,
    Position: position = null,
    MapUrl: mapUrl = null,
    BadgeText: badgeText = null
  } = props.fields || {};

  let direction = "left";

  if (position && position.value) {
    if (position.value.toLowerCase().indexOf("right") !== -1) {
      direction = "right";
    }
  }

  if (retailerName && retailerName.value) {
    return (
      <BaseComponent margin="standard">
        <div className="featured-retailer-wrapper container">
          <div className="retailer-card">
            <div className="row no-gutters">
              <div
                className={`d-none ${
                  direction === "left" ? "d-lg-block col-lg-5" : ""
                  }`}
              ></div>
              <div className="retailer-image col-12 col-md-11 col-lg-7">
                <a
                  href={
                    retailerUrl && retailerUrl.value ? retailerUrl.value : null
                  }
                >
                  <div className="no-image">
                    {(retailerImage &&
                      retailerImage.value &&
                      retailerImage.value.src) &&
                      <Image
                        media={retailerImage.value}
                        data-src={retailerImage.value.src}
                        className="lazyload"
                      />
                    }
                  </div>
                </a>
                <div className="img-badge">
                  <Badge
                    type="list"
                    badgeText={
                      badgeText && badgeText.value ? badgeText.value : null
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row no-gutters">
              <div
                className={`d-none d-md-block col-1 ${
                  direction === "left" ? "bg-even" : "bg-odd"
                  }`}
              ></div>
              <div
                className={`retailer-details col-12 col-md-11 col-lg-4 ${
                  direction === "left" ? "" : "order-lg-last"
                  }
              `}
              >
                <div className="title">
                  <Text field={header} />
                </div>
                <div className="retailer-name">
                  <a
                    href={
                      retailerUrl && retailerUrl.value
                        ? retailerUrl.value
                        : null
                    }
                  >
                    {retailerName &&
                      retailerName.value &&
                      <span>
                        <Text field={retailerName} />
                      </span>
                    }
                  </a>
                </div>
                <Details
                  iconValue="location"
                  linkUrl={mapUrl && mapUrl.value ? mapUrl.value : null}
                  linkName={location}
                  size="24"
                  mapInfo={storeid && storeid.value ? storeid.value : null}
                />
                <Details iconValue="time" linkName={openingHours} size="24" />
              </div>
              <div className="d-none d-lg-block col-6 with-bg"></div>
              <div
                className={`d-none col-1 ${
                  direction === "left" ? "" : "d-lg-block bg-even"
                  }`}
              ></div>
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  } else return null;
};

export default FeatureFeaturedRetailerFeaturedRetailer;

import React from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";

const NavDiscoverStore = props => {
  return (
    <div className="discover-stores-wrapper">
      <div className="discover-stores-title">
        <Text field={props.title} />
      </div>

      <div className="discover-stores-content-wrapper">
        {props.data.map((store, index) => {
          const {
            StorefrontUrl = null,
            RetailerLogo = null,
            RetailerName = null
          } = store || {};

          return (
            <div
              key={`storeId-${index}`}
              className="discover-stores-content-columns"
            >
              <a href={(StorefrontUrl && StorefrontUrl.value ? StorefrontUrl.value : null)}>
                {RetailerLogo && RetailerLogo.value && RetailerLogo.value.src ?
                  <Image
                    media={{ ...RetailerLogo.value}}
                    data-src={RetailerLogo.value.src}
                    className="discover-stores-content-image lazyload"
                  />
                  : <div className="no-logo"><Text field={RetailerName} /></div>
                }
              </a>
              <div className="discover-stores-content-title">
                <a href={(StorefrontUrl && StorefrontUrl.value ? StorefrontUrl.value : null)}>
                  <Text field={RetailerName} />
                </a>
                <i className="icon icon-arrow-right" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NavDiscoverStore;

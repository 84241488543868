import React from "react";

const SpecialDetailsContainer = ({ image, details }) => {
    return <div className="subscribe-special-container">
        <div className="subscribe-image">{image}</div>
        <div className="subcribe-special-details">
            <div className="row no-gutters">
                <div className="col-12 col-md-7 col-xl-6 offset-md-4 offset-lg-5">
                    {details}
                </div>
            </div>
        </div>
    </div>
}

export default SpecialDetailsContainer
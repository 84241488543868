import React from "react";
import {
  Placeholder,
  VisitorIdentification
} from "@sitecore-jss/sitecore-jss-react";
import Helmet from "react-helmet";
import "lazysizes";
import TagManager from "react-gtm-module";
import centreList from "./centreList";
import gtmIdList from "./gtmIdList";
import { get } from "lodash";
import "./assets/themes/base.css";
import "animate.css/animate.min.css";
import objectFitImages from "object-fit-images";
import BackToTop from "./components/Common/BackToTop";

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isReady: false, url: "", domain: "" };
    this.activeCentre = get(this, "props.context.centreInfo.centre", "");

    this.gtmId = this.activeCentre ? "GTM-WGDKN7LC" : "GTM-XXXXXXX";

    let validCentre = centreList.find(centre => this.activeCentre === centre);
    if (process.env.REACT_APP_THEME) {
      this.centre = process.env.REACT_APP_THEME;
    } else {
      this.centre = "base";
    }

    if (validCentre) {
      this.centre = validCentre;
    }

    let centreStyle = import(`./assets/themes/${this.centre}.css`);

    //this.pageTitlevalue = get(this, "props.route.fields.PageTitle.value", "Page");
  }

  // loadTheme = async () => {
  //   let response = await import(`./assets/themes/${this.centre}.css`);
  //   if (response) {
  //     this.setState({ isReady: true });
  //   }
  // };

  componentDidMount() {
    const { templateName = "", displayName = "" } = this.props.route || {};
    const templateList = [
      "Category Landing Page",
      "Storefront Page",
      "Food Storefront Page",
      "Directory Page"
    ];
    const { route, context } = this.props;

    let faviconSrc = get(context, "centreInfo.faviconImage.value.src", "");
    if (document) {
      const pagetitle = get(route, "fields.PageTitle.value", "Page");
      const centrename = get(context, "centreInfo.displayname", "");

      if (faviconSrc) {
        document.getElementById("favicon").href = faviconSrc;
      }
      if (centrename) {
        if (pagetitle === centrename) {
          document.title = pagetitle;
        } else {
          document.title = pagetitle + " | " + centrename;
        }
      } else {
        document.title = pagetitle;
      }

      // document.querySelector('meta[property="og:url"]').setAttribute("content", window.location.href);
      // document.querySelector('meta[property="og:image"]').setAttribute("content", window.location.origin + get(route, "fields.MetaImage.value.src", ""));
      // document.querySelector('meta[name="twitter:url"]').setAttribute("content", window.location.href);
      // document.querySelector('meta[name="twitter:image"]').setAttribute("content", window.location.origin + get(route, "fields.MetaImage.value.src", ""));
      this.setState({
        url: window.location.href,
        domain: window.location.origin
      });

      // Canonical URL
      let canonicalSrc = get(route, "fields.CanonicalUrl.value", "");
      if (!canonicalSrc) {
        canonicalSrc = document.URL;
      }
      document.getElementById("canonical").href = canonicalSrc;
      objectFitImages();
    }

    TagManager.initialize({ gtmId: this.gtmId });
    TagManager.initialize({ gtmId: "GTM-5TKGJMX" });
    if (templateList.includes(templateName)) {
      let category = "";
      let subCategory = "";
      let name = "";
      if (templateName === "Category Landing Page") {
        category = displayName;
      } else {
        name = displayName;
        category = get(context, "categoryInfo.category", "");
        subCategory = get(context, "categoryInfo.subCategory", "");
      }
      TagManager.dataLayer({
        dataLayer: {
          retail: {
            category,
            "sub-category": subCategory,
            name
          }
        },
        dataLayerName: "dataLayer"
      });
    }
  }

  render() {
    // if (!this.state.isReady) {
    //   return null;
    // }
    const { route, context } = this.props;
    const { activeCentre } = this;
    const { url, domain } = this.state;
    let metaImage = "";
    let metaImageAlt = "";

    return (
      <React.Fragment>
        <Helmet>
          <title>
            {route.fields &&
            route.fields.PageTitle &&
            route.fields.PageTitle.value
              ? route.fields.PageTitle.value
              : "Page"}
          </title>

          {route.fields &&
            route.fields.MetaDescription &&
            route.fields.MetaDescription.value && (
              <meta
                name="description"
                content={route.fields.MetaDescription.value}
              />
            )}

          {route.fields &&
            route.fields.RobotsTag &&
            route.fields.RobotsTag.value && (
              <meta name="robots" content={route.fields.RobotsTag.value} />
            )}

          {context.centreInfo && context.centreInfo.displayname && (
            <meta name="centrename" content={context.centreInfo.displayname} />
          )}

          {context.centreInfo && context.centreInfo.displayname && (
            <meta name="og:site_name" content={context.centreInfo.displayname} />
          )}

          {route.fields
            ? route.fields.MetaImageUrl && route.fields.MetaImageUrl.value
              ? (metaImage = route.fields.MetaImageUrl.value) &&
                (metaImageAlt = route.fields.MetaImageAltText.value)
              : route.fields.MetaImage &&
                route.fields.MetaImage.value &&
                route.fields.MetaImage.value.src
              ? (metaImage = domain + route.fields.MetaImage.value.src) &&
                (metaImageAlt = route.fields.MetaImage.value.alt)
              : ""
            : ""}

          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content={get(route, "fields.PageTitle.value", "")}
          />
          <meta
            property="og:description"
            content={get(route, "fields.MetaDescription.value", "")}
          />
          <meta property="og:url" itemProp="url" content={url} />
          <meta property="og:image" content={metaImage} />
          <meta property="og:image:alt" content={metaImageAlt} />
          

          <meta
            name="twitter:title"
            content={get(route, "fields.PageTitle.value", "")}
          />
          <meta
            name="twitter:description"
            content={get(route, "fields.MetaDescription.value", "")}
          />
          <meta name="twitter:card" content="website" />
          <meta name="twitter:url" content={url} />
          <meta name="twitter:image" content={metaImage} />
          <meta name="twitter:image:alt" content={metaImageAlt} />

          <link href="https://d1p5cqqchvbqmy.cloudfront.net/web2/release/mappedin-web.css" rel="stylesheet" />
        </Helmet>
        <VisitorIdentification />

        <input
          type="hidden"
          value={route.itemId}
          data-target-db={route.databaseName}
          data-centre={this.centre}
          name="ContextItem"
        />
        <Placeholder
          name="jss-header"
          rendering={route}
          params={{ activeCentre }}
        />
        <Placeholder
          name="jss-body"
          rendering={route}
          params={{ pageName: route.templateName }}
        />
        <Placeholder
          name="jss-footer"
          rendering={route}
          params={{ pageName: route.name }}
        />

        <BackToTop />
      </React.Fragment>
    );
  }
}
export default Layout;

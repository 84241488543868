import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";

const BlogDetails = props => {
  const {
    Title = null,
    ShortDescription = null,
  } = props.fields || {};

  return (
    <BaseComponent>
      <div className="blogdetails-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="blogdetails-content">
                <h1 className={`blogtitle ${ShortDescription && ShortDescription.value ? "" : 'title-only'}`}>
                  <Text field={Title} />
                </h1>
                <div className="blogdescription">
                  <Text field={ShortDescription} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseComponent>
  );
};

export default BlogDetails;

import React, { Fragment } from "react";
import { Text, Image, Link } from "@sitecore-jss/sitecore-jss-react";
import ScrollAnimation from "react-animate-on-scroll";
import Badge from "../../../Common/Badge";
import BaseComponent from "../../../Base/BaseComponent";
import Details from "../../../Common/Details";
import defaultImg from "../../../../assets/img/placeholders/gray.png";
import RiseAndFade from "../../../Common/Animations/RiseAndFade";

const FeaturePromotionsCategoryWhatsOn = props => {
  const {
    Title: title = null,
    Subtext: subtext = null,
    Position: position = null,
    EventList: eventList = null,
    Links: links = null
  } = props.fields || {};

  let MaxCount = 0;
  if (props.params && props.params.MaxCount) {
    MaxCount = props.params.MaxCount;
  }
  let align = true;

  if (position && position.value && position.value.toLowerCase() === "left") {
    align = false;
  }
  if (eventList && eventList.length && eventList.length == MaxCount) {
    return (
      <BaseComponent margin="standard">
        <div className="categorywhatson-container">
          <div
            className={`categorywhatson-main-header-${
              align ? "right" : "left"
            }`}
          >
            <div className="container">
              <div className="row no-gutters">
                <div className={`d-none ${align ? "col-1 d-lg-block" : ""}`} />
                <div
                  className={`categorywhatson-header-container col-12 col-lg-11 header-text-${
                    align ? "right" : "left"
                  }`}
                >
                  <ScrollAnimation
                    animateIn="animate__animated animate__fadeIn"
                    animateOnce={true}
                  >
                    <div className="categorywhatson-header-title">
                      <Link field={title} />
                    </div>
                    <div className="categorywhatson-header-sub">
                      <Text field={subtext} />
                    </div>
                    {links && links.length && (
                      <div className="categorywhatson-header-buttons">
                        {links.map((item, index) => {
                          const {
                            LinkText: linkText = null,
                            LinkUrl: linkUrl = null
                          } = item.fields || {};
                          if (
                            linkText &&
                            linkText.value &&
                            linkUrl &&
                            linkUrl.value
                          ) {
                            return (
                              <a
                                href={linkUrl.value}
                                key={`myButtonId-${index}`}
                                className={`btn tertiary-btn ${
                                  index < 5
                                    ? index < 4
                                      ? index < 3
                                        ? ""
                                        : "d-none d-md-inline-block"
                                      : "d-none d-lg-inline-block"
                                    : "d-none"
                                }`}
                              >
                                {linkText.value}
                              </a>
                            );
                          }
                        })}
                      </div>
                    )}
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
          <div className="categorywhatson-tile-wrapper">
            <div className="container">
              <div className="row no-gutters">
                {eventList &&
                  eventList.length !== undefined &&
                  eventList.map((item, index) => {
                    const {
                      Thumbnail: thumbnail = null,
                      StartDate: startDate = null,
                      EndDate: endDate = null,
                      EventText: eventText = null,
                      PromotionName: name = null,
                      LocationLink: locationLink = null,
                      PromotionLocation: location = null,
                      ScheduleTime: scheduleTime = null,
                      PromotionLink: promotionLink = null,
                      PromotionType: promotionType = null,
                      StorefrontId: storeFrontId = null,
                      IsRetailerContent: isRetailerContent = null,
                      IsLogoAsThumbnail: isLogoAsThumbnail = null,
                      Retailer: retailerName = null,
                      HidePublishDate: HidePublishDate = null
                    } = item.fields || {};

                    let value = 0;
                    let showLocation = true;
                    if (index == 1) {
                      value = 6;
                    } else if (index == 2) {
                      value = 8;
                    }

                    if (
                      promotionType &&
                      promotionType.value &&
                      promotionType.value.toLowerCase().indexOf("article") !==
                        -1
                    ) {
                      showLocation = false;
                    }

                    return (
                      <div key={`myId-${index}`} className="col-10 col-md-4">
                        <RiseAndFade position={value}>
                          <div className="animation-range">
                            <div
                              className={`categorywhatson-content-img-wrapper ${
                                isLogoAsThumbnail && isLogoAsThumbnail.value
                                  ? "display_retailer_logo"
                                  : ""
                              }`}
                            >
                              {promotionLink && promotionLink.value ? (
                                <a href={promotionLink.value}>
                                  {thumbnail &&
                                  thumbnail.value &&
                                  thumbnail.value.src ? (
                                    <div className="include_logo_padding">
                                      <Image
                                        media={{
                                          ...thumbnail.value,
                                          src: defaultImg
                                        }}
                                        data-src={thumbnail.value.src}
                                        className="categorywhatson-content-image lazyload"
                                      />
                                    </div>
                                  ) : (
                                    <Fragment>
                                      {isRetailerContent &&
                                        isRetailerContent.value && (
                                          <div className="display_retailer_name">
                                            <Text field={retailerName} />
                                          </div>
                                        )}
                                      <img
                                        alt="default"
                                        src={defaultImg}
                                        className="categorywhatson-content-image"
                                      />
                                    </Fragment>
                                  )}
                                </a>
                              ) : thumbnail &&
                                thumbnail.value &&
                                thumbnail.value.src ? (
                                <Image
                                  media={{
                                    ...thumbnail.value,
                                    src: defaultImg
                                  }}
                                  data-src={thumbnail.value.src}
                                  className="categorywhatson-content-image lazyload"
                                />
                              ) : (
                                <img
                                  alt="default"
                                  src={defaultImg}
                                  className="categorywhatson-content-image"
                                />
                              )}
                              <div className="categorywhatson-img-badge">
                                <Badge
                                  type="list"
                                  startDate={startDate ? startDate.value : ""}
                                  endDate={endDate ? endDate.value : ""}
                                  badgeText={eventText ? eventText.value : ""}
                                />
                              </div>
                            </div>

                            {promotionType && promotionType.value && (
                              <div className="categorywhatson-details-type">
                                <Text field={promotionType} />
                              </div>
                            )}
                            <div className="categorywhatson-details-title">
                              {promotionLink && promotionLink.value ? (
                                <a href={promotionLink.value}>
                                  <Text field={name} />
                                </a>
                              ) : (
                                <Text field={name} />
                              )}
                            </div>
                          </div>
                          {location && location.value && showLocation && (
                            <Details
                              iconValue="location"
                              linkUrl={locationLink && locationLink.value}
                              linkName={location}
                              size="24"
                              mapInfo={
                                storeFrontId && storeFrontId.value
                                  ? storeFrontId.value
                                  : ""
                              }
                            />
                          )}
                          {HidePublishDate && !HidePublishDate.value && scheduleTime && scheduleTime.value && (
                            <Details
                              iconValue="time"
                              linkName={scheduleTime}
                              size="24"
                            />
                          )}
                        </RiseAndFade>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  } else return null;
};

export default FeaturePromotionsCategoryWhatsOn;

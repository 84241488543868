import React, { Component, createElement } from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import { parseFromString } from 'dom-parser';


class FeatureGenericContentObjectBlock extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
      //this.appendScript(); //Enable this to see Stackla Widgets locally. 
      setTimeout(() => {
        let updatedFilter = this.detectQueryStringParams();
        let stacklaId = this.getStacklaId()
        if(stacklaId && global.StacklaFluidWidget && global.StacklaFluidWidget.changeFilter) {
        global.StacklaFluidWidget.changeFilter(stacklaId, updatedFilter);
        }
      }, 100);
  }

  appendScript() {
    // Appends any <script> tags found to the Head
    const { Object: object } = this.props.fields;
    if (object && object.value) {
      let parser = new DOMParser();
      let scripts = parser
        .parseFromString(object.value, "text/html")
        .body.querySelectorAll("script");

      if (scripts && scripts.length) {
        for (let i = 0; i < scripts.length; i++) {
          let scriptElement = document.createElement('script');
          scriptElement.textContent = scripts[i].textContent;
          document.head.appendChild(scriptElement);
        }
      }
    }
  }

  detectQueryStringParams() {
    if(document) { 
      let urlParams = new URLSearchParams(document.location.search);
      let filter = urlParams.get("stackla");
      return filter ? filter : null
    } else {
      return null
    }
  }

  getStacklaId(){
    const { Object: object } = this.props.fields;
    if (object && object.value) {
      //Parse entire HTML
      let parsedHtml = parseFromString(`<span>${object.value}</div>`);
      // Check for Stackla specifically
      let widgetDiv = parsedHtml.getElementsByClassName("stackla-widget")[0];
        
      if (widgetDiv) {
          let stacklaId = widgetDiv.getAttribute('data-id');
          return stacklaId ? stacklaId : null;
        }
      } else {
        //If Stackla is not detected, simply pass through the object as we receive it 
        return null;
      }
    }

  render() {
    if (this.props.fields.Object && this.props.fields.Object.value) {
      return (
        <BaseComponent margin="standard">
          <div className={`object-block container ${this.props.fields.FixedHeight && this.props.fields.FixedHeight.value ? 'fixed-height' : ''}`}>
            <RichText field={this.props.fields.Object} />
          </div>
        </BaseComponent>
      );
    } else return null;
  }
}

export default FeatureGenericContentObjectBlock;

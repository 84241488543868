import React, { Component } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import axios from "axios";

class FeatureSubscribeUnsubscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTextarea: false,
      pageId: "",
      dbName: "",
      emailAddress: "",
      selectedOption: null,
      textArea: null,
      message: null,
      success: false,
      disabled: false
    };
  }

  onRadioButtonChange = event => {
    const value = event.target.value;
    this.setState({
      selectedOption: value,
      showTextarea: value.toLowerCase().indexOf("other") !== -1 ? true : false
    });
  };

  onTextAreaChange = event => {
    const value = event.target.value;
    this.setState({
      textArea: value
    });
  };

  componentDidMount() {
    let contextInfo = window.document.getElementsByName("ContextItem")[0];
    let pageId = contextInfo.value;
    let dbName = contextInfo.dataset.targetDb;
    this.setState({
      pageId,
      dbName,
      emailAddress: this.getUrlParameter("email")
    });
  }

  getUrlParameter = name => {
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  submitForm = async () => {
    const {
      pageId,
      dbName,
      selectedOption,
      textArea,
      emailAddress
    } = this.state;
    const { SuccessMessage } = this.props.fields;
    const url = `/api/sitecore/subscription/unsubscribe`;

    this.setState({ disabled: true });

    let response = await axios({
      method: "POST",
      url,
      data: {
        pageId,
        dbName,
        emailAddress,
        unsubscribeReason: selectedOption,
        textArea
      }
    });

    if (response && response.data) {
      if (response.data.IsSuccessful) {
        this.setState({
          message:
            SuccessMessage && SuccessMessage.value
              ? SuccessMessage.value
              : response.data.Message,
          success: true
        });
      }
    }
  };

  render() {
    const {
      UnsubscribeTitle,
      ReasonTitle,
      UnsubscribeReasonOptions,
      RichtextPlaceholder,
      UnsubscribeButtonLabel
    } = this.props.fields;
    let {
      showTextarea,
      selectedOption,
      message,
      success,
      disabled
    } = this.state;
    return (
      <BaseComponent margin="standard">
        <div className="unsubscribe-wrapper">
          {success && message ? (
            <div className="unsubscribe-subheading">
              <div className="unsubscribe-border">{message}</div>
            </div>
          ) : (
            <React.Fragment>
              <div className="unsubscribe-subheading">
                <Text field={UnsubscribeTitle} />
              </div>
              <div className="unsubscribe-form">
                <div className="unsubscribe-form-title">
                  <Text field={ReasonTitle} />
                </div>
                <div className="unsubscribe-form-list">
                  {UnsubscribeReasonOptions && UnsubscribeReasonOptions.length
                    ? UnsubscribeReasonOptions.map((item, index) => {
                        const value = item.fields.Text.value;
                        return (
                          <div
                            className="form-group"
                            key={`unsubscribe-option-${index}`}
                          >
                            <label>
                              <input
                                type="radio"
                                className=""
                                id={index}
                                name="unsubscribe-option"
                                value={value}
                                checked={selectedOption === value}
                                onChange={this.onRadioButtonChange}
                                disabled={disabled}
                              />
                              <span>{value}</span>
                            </label>
                          </div>
                        );
                      })
                    : ""}
                </div>
                {showTextarea && (
                  <div className="unsubscribe-form-input">
                    <textarea
                      placeholder={RichtextPlaceholder.value}
                      onChange={this.onTextAreaChange}
                      disabled={disabled}
                    />
                  </div>
                )}
                <div className="unsubscribe-form-button">
                  <button
                    className="unsubscribe-button btn primary-btn"
                    onClick={this.submitForm}
                    disabled={selectedOption ? false : true}
                  >
                    <Text field={UnsubscribeButtonLabel} />
                  </button>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </BaseComponent>
    );
  }
}

export default FeatureSubscribeUnsubscribe;

import React, { Component } from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import GetAncestorElement from "../../../Common/Helper/GetAncestorElement";

class FeatureGenericContentConsumerFAQBlock extends Component {
  constructor(props) {
    super(props);

  }

  componentDidMount() {
  }

  handleClickOption = (event) => {
    const element = event.target;
    const faqOptionListContainer = GetAncestorElement(element, "faq-block__group--content__item");
    if (faqOptionListContainer) {
      faqOptionListContainer.classList.toggle("is-active");
    }
  }

  handleClickCollapse = (event) => {
    const element = event.target;
    const faqContainer = GetAncestorElement(element, "faq-block");
    const faqOptionList = faqContainer.querySelectorAll(".faq-block__group--content__item");
    if (faqOptionList) {
      for (let i = 0; i < faqOptionList.length; i++) {
        let faqOption = faqOptionList[i];
        faqOption.classList.remove("is-active");
      }
    }
  }

  handleClickExpand = (event) => {
    const element = event.target;
    const faqContainer = GetAncestorElement(element, "faq-block");
    const faqOptionList = faqContainer.querySelectorAll(".faq-block__group--content__item");
    if (faqOptionList) {
      for (let i = 0; i < faqOptionList.length; i++) {
        let faqOption = faqOptionList[i];
        faqOption.classList.add("is-active");
      }
    }
  }

  renderFAQ = (faqList) => {
    let faqOptionLayout;
    if (faqList && faqList.length > 0) {
      faqOptionLayout =
        <div className="faq-block__group">
          <div className="faq-block__group--content">
            {faqList.map((item, index) => {
              const {
                Question = null,
                Answer = null
              } = item.fields;
              let faqContentLayout;
              if (Question && Question.value && Answer && Answer.value) {
                faqContentLayout =
                  <div className="faq-block__group--content__item" key={Question.value + index}>
                    <button className="faq-block__group--content__item--btn"
                      onClick={this.handleClickOption}>
                      <RichText field={Question} />
                    </button>
                    <div className="faq-block__group--content__item--description">
                      <RichText field={Answer} />
                    </div>
                  </div>
              }
              return faqContentLayout;
            })
            }
          </div>
        </div >
    }

    return faqOptionLayout;
  }
  render() {
    const {
      Title = null,
      FAQs = [],
    } = this.props.fields || {};
    return (
      <div className="faq-block font-regular">
        {FAQs &&
          FAQs.length > 0 &&
          <BaseComponent margin="standard">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="faq-block__title">
                    <Text field={Title} />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="faq-block__btn">
                    <button className="faq-block__btn--button"
                      onClick={this.handleClickCollapse}>
                      <i className="icon icon-collapse" />
                      <span>Collapse all</span>
                    </button>
                    <button className="faq-block__btn--button"
                      onClick={this.handleClickExpand}>
                      <i className="icon icon-expand-2" />
                      <span>Expand all</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="faq-block__group--wrapper">
                {this.renderFAQ(FAQs)}
              </div>
            </div>
          </BaseComponent>
        }
      </div>
    );
  }
}

export default FeatureGenericContentConsumerFAQBlock;

import React from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";

const NavCampaign = ({ title, campaigns }) => {
  if (campaigns && campaigns.length) {
    return (
      <div className="nav-campaign-wrapper">
        <div className="title">
          {title.url ? (
            <React.Fragment>
              <a href={title.url}>
                <Text field={title.name} />
              </a>
              <i className="icon icon-arrow-right" />
            </React.Fragment>
          ) : (
            <Text field={title.name} />
          )}
        </div>
        <div className="content row">
          <div className="col col-auto">
            <div className="spotlight-item">
              <a href={campaigns[0].CampaignUrl.value}>
                <Image
                  media={{ ...campaigns[0].Thumbnail.value }}
                  data-src={campaigns[0].Thumbnail.value.src}
                  className="spotlight-image lazyload"
                />
              </a>
              <div className="type">
                <Text field={campaigns[0].Type} />
              </div>
              <div className="spotlight-description">
                <a href={campaigns[0].CampaignUrl.value}>
                  <Text field={campaigns[0].CampaignIntro} />
                </a>
              </div>
            </div>
          </div>
          {campaigns.length === 4 ? (
            <div className="col">
              {campaigns.slice(1).map((campaign, index) => {
                const {
                  Thumbnail,
                  CampaignIntro,
                  Type,
                  CampaignUrl
                } = campaign;
                return (
                  <div
                    key={`navCampaignID-${index}`}
                    className="row campaign-item"
                  >
                    <div className="col col-auto">
                      <a href={CampaignUrl.value}>
                        <Image
                          media={{ ...Thumbnail.value }}
                          data-src={Thumbnail.value.src}
                          className="campaign-image lazyload"
                        />
                      </a>
                    </div>
                    <div className="col campaign-details">
                      <div className="type">
                        <Text field={Type} />
                      </div>
                      <div className="campaign-description">
                        <a href={CampaignUrl.value}>
                          <Text field={CampaignIntro} />
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  } else return null;
};
export default NavCampaign;

import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Text, Link } from "@sitecore-jss/sitecore-jss-react";

const GrayBanner = props => {
  const {
    StoreDirectoryLandingPage = "",
    StoreDirectoryCtaText = "",
    IsCampaignPage: isCampaignPage = null,
    Title = null,
    TitleLink: titleLink = null,
    CTAText: CtaText = null,
    CTATextLink = null,
    ComponentTitle = null
  } = props.fields || {};

  const title = Title || ComponentTitle || null;
  const CTAText = StoreDirectoryCtaText || CtaText || null;
  const CTALink = StoreDirectoryLandingPage || CTATextLink || null;

  const CTA = {
    href: CTALink ? CTALink.value : null,
    text: CTAText ? CTAText.value : null,
    anchor: "",
    linktype: "internal",
    class: "",
    title: CTAText ? CTAText.value : null,
    querystring: ""
  };

  const CTABlock = (
    <div className="graybanner-header-title">
      {CTALink && CTALink.value ? (
        <React.Fragment>
          <Link field={CTA} />
          <i className="icon icon-arrow-right" />
        </React.Fragment>
      ) : (
        <Text field={CTAText} />
      )}
    </div>
  );

  return (
    <div className="graybanner-wrapper">
      <div className="container">
        <div className="graybanner-header-wrapper offset-lg-1">
          <ScrollAnimation
            animateIn="animate__animated animate__fadeIn"
            animateOnce={true}
          >
            {isCampaignPage && isCampaignPage.value ? (
              <React.Fragment>
                <div
                  className="graybanner-header-description"
                  style={{ marginBottom: "7px" }}
                >
                  {titleLink && titleLink.value ? (
                    <a href={titleLink.value}>
                      <Text field={title} />
                    </a>
                  ) : (
                    <Text field={title} />
                  )}
                </div>
                {CTABlock}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {CTABlock}
                <div className="graybanner-header-description">
                  <Text field={title} />
                </div>
              </React.Fragment>
            )}
          </ScrollAnimation>
        </div>
      </div>
    </div>
  );
};

export default GrayBanner;

import React from "react";
import thumbnailPlaceholder from "../assets/img/placeholders/thumbnailPlaceholder.png";

export const imageHelper = (src, alt) => {
  let imgSrc = thumbnailPlaceholder;
  let imgAlt = "";

  if (src && src.value) {
    imgSrc = src.value;
  }

  if (alt && alt.value) {
    imgAlt = alt.value;
  }

  return <img src={imgSrc} alt={imgAlt} />;
};

import React from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import { get } from "lodash";
import ScrollAnimation from "react-animate-on-scroll";
import BaseComponent from "../../../Base/BaseComponent";
import defaultImg from "../../../../assets/img/placeholders/gray.png";
import RiseAndFade from "../../../Common/Animations/RiseAndFade";
import { valueHelper } from "../../../../helper/Animations";

class FeatureSocialInstagramGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHomepage: false
    };
  }

  componentDidMount() {
    if (window && window.location && window.location.pathname) {
      if (window.location.pathname === "/") {
        this.setState({ isHomepage: true });
      }
    }
  }
  render() {
    const {
      InstagramGalleryLink,
      InstagramGalleryLinkText,
      InstagramGalleryTitle,
      InstagramFeed
    } = this.props.fields;

    const { isHomepage } = this.state;

    let list;
    if (InstagramFeed.length >= 8) {
      list = InstagramFeed.slice(0, 8);
    } else if (InstagramFeed.length >= 4) {
      list = InstagramFeed.slice(0, 4);
    } else {
      return null;
    }

    return (
      <BaseComponent margin="standard">
        <div
          className={`socialinstagram-container ${
            isHomepage ? "instagram-homepage" : "instagram-storefront"
          }`}
        >
          <div className="socialinstagram-banner" />
          <div className="container">
            <div className="socialinstagram-content-wrapper">
              <div className="socialinstagram-header-wrapper">
                <ScrollAnimation
                  animateIn="animate__animated animate__fadeIn"
                  animateOnce={true}
                >
                  <div className="socialinstagram-header-title">
                    <Text field={InstagramGalleryLinkText} />
                  </div>

                  <div className="socialinstagram-header-description">
                    <a
                      href={get(InstagramGalleryLink, "value", "#")}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>
                        <Text field={InstagramGalleryTitle} />
                      </span>
                      <i className="icon icon-external-link" />
                    </a>
                  </div>
                </ScrollAnimation>
              </div>

              <div className="socialinstagram-tile-wrapper">
                <div className="row">
                  {list &&
                    list.map((item, index) => {
                      const {
                        ImageUrl,
                        ImageCaption,
                        InstagramLink,
                        InstagramLinkText
                      } = item.fields || {};
                      return (
                        <div
                          className="socialinstagram-tile-container"
                          key={`tileID-${index}`}
                        >
                          <RiseAndFade position={valueHelper(index, 4)}>
                            <div
                              key={`myId-${index}`}
                              className="socialinstagram-tile"
                            >
                              {get(ImageUrl, "value", false) ? (
                                <Image
                                  media={{ src: defaultImg }}
                                  data-src={ImageUrl.value}
                                  className="lazyload"
                                />
                              ) : (
                                <div className="no-logo">
                                  <Text field={ImageCaption} />
                                </div>
                              )}
                              <div className="socialinstagram-tile-details">
                                <div className="details-wrapper">
                                  <div className="caption">
                                    <Text
                                      field={{
                                        value: `${ImageCaption.value.substr(
                                          0,
                                          90
                                        )}...`
                                      }}
                                    />
                                  </div>
                                  <div className="link">
                                    <a
                                      href={get(InstagramLink, "value", "#")}
                                      target="_black"
                                      rel="noreferrer"
                                    >
                                      <Text field={InstagramLinkText} />
                                    </a>
                                    <i className="icon icon-external" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </RiseAndFade>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  }
}

export default FeatureSocialInstagramGallery;

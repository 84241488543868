import React from "react";
import DetailsContainer from "./DetailsContainer";
import SpecialDetailsContainer from "./SpecialDetailsContainer";
import Details from "./Details";
import Image from "./Image";
const SubscribeContainer = ({ isSpecial, subscribeProps, state, handleDetailsUpdate, subscribe, SubscribeImageUrl }) => {
    return (
        isSpecial ?
            <SpecialDetailsContainer
                image={
                    <Image
                        SubscribeImageUrl={SubscribeImageUrl}
                    />
                }
                details={
                    <Details
                        subscribeProps={subscribeProps}
                        state={state}
                        handleDetailsUpdate={handleDetailsUpdate}
                        subscribe={subscribe}
                    />
                }
            />
            :
            <DetailsContainer
                image={
                    <Image
                        SubscribeImageUrl={SubscribeImageUrl}
                    />
                }
                details={
                    <Details
                        subscribeProps={subscribeProps}
                        state={state}
                        handleDetailsUpdate={handleDetailsUpdate}
                        subscribe={subscribe}
                    />
                }
            />
    )
}

export default SubscribeContainer
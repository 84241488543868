import React from "react";

const HeroVideo = props => {
  const {
    data,
    hasControls = false,
    isMute = false,
    isLoop = false,
    isAutoplay = false
  } = props;

  return (
    <div className="video">
      <div className="content">
        <video
          controls={hasControls}
          autoPlay={isAutoplay}
          loop={isLoop}
          muted={isMute}
          playsInline={isAutoplay}
        >
          <source src={data} type="video/mp4" />
          <source src={data} type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};
export default HeroVideo;

import React from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import axios from "axios";
import { get } from "lodash";
import EmailPreference from "../../../Common/EmailPreference";
import SubscribeForm from "../../../Common/SubscribeForm/SubscribeForm";
import SubscribeContainer from "./SubscribeContainer";

class FeatureSubscribeSubscribe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        pageId: "",
        dbName: "",
        fname: "",
        lname: "",
        postcode: "",
        email: "",
        fields: [
          { name: "fname", error: false, message: "" },
          { name: "lname", error: false, message: "" },
          { name: "email", error: false, message: "" },
          { name: "postcode", error: false, message: "" },
        ]
      },
      emailPrefResp: {
        IsSuccessful: false,
        Message: "",
        ApiResponse: null
      },
      // email validation
      message: "",
      error: false,
      success: false,
      modalOpen: false,
    };
  }

  validateFormat = value => {
    let re = new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$");
    return re.test(String(value).toLowerCase());
  };

  componentDidMount() {
    let contextInfo = window.document.getElementsByName("ContextItem")[0];
    let pageId = contextInfo.value;
    let dbName = contextInfo.dataset.targetDb;

    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        pageId: pageId,
        dbName: dbName,
        email: "",
        fname: "",
        lname: "",
        postcode: "",
      }
    });
  }

  componentDidUpdate() {

    if (this.state.modalOpen) {
      const modalWrapper = document.querySelector('.modal-wrapper')
      if (modalWrapper) {
        setTimeout(() => { modalWrapper.classList.add('modal-active') }, 1000)
      }
    }
  }
  subscribe = async (e) => {
    const url = `/api/sitecore/subscription/getuser`;

    const { pageId, dbName, email: emailAddress } = this.state.formData

    const data = { pageId, dbName, emailAddress }

    if (this.state.formData.email !== "") {
      if (!this.validateFormat(this.state.formData.email)) { //validate email
        this.setState({
          ...this.state,
          message: "Please enter a valid email address",
          error: true,
          success: false,
          modalOpen: false
        });
      } else {
        let response = await axios({
          method: "POST",
          url,
          data
        });

        if (response && response.data) {

          let success, error, modalOpen = false
          let message = ''
          if (response.data.IsSuccessful) {
            success = true
            modalOpen = true
          }
          else {
            error = true
            message = response.data.Message
          }

          this.setState({
            ...this.state,
            message,
            error,
            success,
            modalOpen
          });
          document.getElementsByTagName("body")[0].classList.add("modal-open");
        }
      }
    } else { //fall back
      this.setState({
        ...this.state,
        message: "Please enter a valid email address",
        error: true,
        success: false
      });
    }
  }

  handleEmailChange = e => {
    let message = "";
    let error = false;

    if (this.state.error && !this.validateFormat(e.target.value)) {
      message = "Please enter a valid email address";
      error = true;
    }

    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        email: e.target.value
      },
      message: message,
      error: error
    });
  };

  handleDetailsUpdate = obj => {
    this.setState({ ...this.state, ...obj, formData: { ...this.state.formData, ...obj.formData } })
  }

  handlePreferencesResponse = (success, data, submitted) => {
    if (data.ApiResponse === 2 || data.ApiResponse === 5) { //duplicate email
      let fieldArrIndex = data.ApiResponse === 5 ? 3 : 2
      let fieldArr = this.state.formData.fields
      fieldArr[fieldArrIndex].error = true
      fieldArr[fieldArrIndex].message = data.Message
      this.setState({
        ...this.state,
        success,
        submitted,
        emailPrefResp: { ...data },
        formData: { ...this.state.formData, fields: fieldArr }
      });
    } else {
      this.setState({
        formData: { ...this.state.formData, email: "", fname: "", lname: "", postcode: "" },
        success,
        submitted,
        emailPrefResp:
          { ...data },
      })
      if (success) { //close modal after 1500ms
        const modalWrapper = document.querySelector('.modal-wrapper')
        setTimeout(() => { modalWrapper.classList.remove('modal-active') }, 1000)
        setTimeout(() => {
          this.setState({ modalOpen: false })
          document.getElementsByTagName("body")[0].classList.remove("modal-open");
        }, 1500)
      }
    }
  }

  handleOnClose = e => {
    this.setState({
      success: false,
      modalOpen: false,
      formData: {
        ...this.state.formData,
        fname: "",
        lname: "",
        postcode: "",
        fields: [
          { name: "fname", error: false, message: "" },
          { name: "lname", error: false, message: "" },
          { name: "email", error: false, message: "" },
          { name: "postcode", error: false, message: "" },
        ]
      }
    });
    document.getElementsByTagName("body")[0].classList.remove("modal-open");
  };

  updateFieldsState = obj => {
    this.setState({ ...this.state, formData: { ...this.state.formData, ...obj } })
  }
  render() {
    let dataSource = get(this, "props.rendering.dataSource", null);
    let isSpecial = false;
    if (!dataSource) {
      return "";
    }
    const {
      SubscribeTitle = null,
      SubscribeDescription = null,
      SubscribeButton = null,
      EmailPlaceholder = null,
      SubscribeImageUrl = null,
      EmailPreferenceModalTitle = null,
      EmailPreferenceModalDescription = null,
      EmailPreferenceModalButton = null,
      Interests = null,
      FirstNameLabel = null,
      LastNameLabel = null,
      EmailLabel = null,
      PostcodeLabel = null,
      GenderGroupLabel = null,
      GenderOptions = null,
      Special = null
    } = this.props.fields || {};
    const { pageId, dbName, email, fname, lname, postcode, fields } = this.state.formData || {};

    const { message, error, modalOpen } = this.state || {};

    const labels = {
      FirstNameLabel,
      LastNameLabel,
      EmailLabel,
      PostcodeLabel
    }
    const fieldStates = { fname, lname, email, postcode }

    return (
      <BaseComponent margin="standard">
        <div className="d-block d-md-none pt-4" />
        <div
          className={`subscribe-wrapper ${isSpecial ? "subscribe-special-wrapper" : ""
            }`}
        >
          <div className="container">
            {/* Subscribe parent */}
            <SubscribeContainer
              isSpecial={Special && Special.value}
              subscribeProps={{ SubscribeButton, SubscribeTitle, SubscribeDescription, EmailPlaceholder }}
              state={{ message, error, isSpecial, email }}
              handleDetailsUpdate={(obj) => this.handleDetailsUpdate(obj)}
              subscribe={this.subscribe}
              SubscribeImageUrl={SubscribeImageUrl}
            />
            {/* Modal */}
            {modalOpen && (
              <div className="modal-wrapper">
                <div className="container">
                  <i className="icon icon-close" onClick={this.handleOnClose} />
                  {fields.length &&
                    <div className="modal-subscribe-form">
                      <div className="modal-subscribe-form__header">

                        <Text field={EmailPreferenceModalTitle} />
                      </div>
                      <div className="modal-subscribe-form-group-container">
                        <SubscribeForm fields={fields} fieldsStates={fieldStates} setFields={this.updateFieldsState} fieldsLabels={labels} />

                      </div>
                    </div>}
                  <EmailPreference
                    PageID={pageId}
                    DBName={dbName}
                    EmailAddress={email}
                    FirstName={fname}
                    LastName={lname}
                    Postcode={postcode}
                    Description={EmailPreferenceModalDescription}
                    ButtonName={
                      EmailPreferenceModalButton &&
                        EmailPreferenceModalButton.value
                        ? EmailPreferenceModalButton.value
                        : "Done"
                    }
                    Interests={Interests}
                    GenderDescription={GenderGroupLabel}
                    GenderOptions={GenderOptions}
                    RadioGroupName={"Subscribe-Gender"}
                    IsModal={true}
                    EmailPreferenceResponse={(success, data, submitted) => {
                      this.handlePreferencesResponse(success, data, submitted)
                    }}
                    Container={'subscribe-wrapper'}
                    Endpoint={'signup-subscribe'}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </BaseComponent>
    );
  }
}
export default FeatureSubscribeSubscribe;
import React, { useEffect, useState } from 'react';
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import Badge from "../../../Common/Badge";
import HeroVideo from "../../../Common/Video";
import defaultImg from "../../../../assets/img/placeholders/transparent.png";
import BaseComponent from "../../../Base/BaseComponent";
import Details from "../../../Common/Details";
import Flickity from "react-flickity-component";

const FeatureGenericContentHomepageHero = props => {
    const {
        Interval: Interval = null,
        FeaturedHero: FeaturedHero = null,
    } = props.fields || {};

    const [flkty, setFlkty] = useState(null)

    useEffect(() => {
        if (flkty) {
            flkty.on('pointerUp', () => {
                flkty.player.play()
            })
        }
    }, [flkty])

    const flickityOptions = {
        autoPlay: Interval && Interval.value ? (Interval.value * 1000) : 5000,
        imagesLoaded: true,
        pageDots: false,
        prevNextButtons: FeaturedHero.length > 1,
        wrapAround: true,
        draggable: false,
        selectedAttraction: 0.01,
        adaptiveHeight: true,
        accessibility: false,
        pauseAutoPlayOnHover: false,
        cellAlign: 'center',
        initialIndex: 0,
    };

    return (
        <div className="generic-homepagehero" style={{ overflowX: 'hidden', overflowY: 'hidden' }}>
            {FeaturedHero && FeaturedHero.length > 0 && (
                <Flickity className="carousel" options={flickityOptions} flickityRef={ref => setFlkty(ref)} reloadOnUpdate static>
                    {FeaturedHero.map((item, index) => {
                        const {
                            StartDate: startDate = null,
                            EndDate: endDate = null,
                            HeroImage: heroImage = null,
                            TargetUrl: targetUrl = null,
                            HeroVideo: heroVideo = null,
                            Title: title = null,
                            PromotionType: promotionType = null,
                            PromotionCategoryTag: PromotionCategoryTag = null,
                            PromotionName: PromotionName = null,
                            PromotionSummary: PromotionSummary = null,
                            PromotionLink: PromotionLink = null,
                            Location: Location = null,
                            LocationLink: LocationLink = null,
                            ScheduleTime: ScheduleTime = null,
                            HidePublishDate: HidePublishDate = null,
                            CTAButtonText: CTAButtonText = null,
                        } = item.fields || {};

                        const getTitle = () => {
                            if (title && title.value) {
                                return (
                                    <div className="row">
                                        <div className="col-12 col-lg-9 col-xl-8">
                                            <div className="genericcontent-herodescription-wrapper">
                                                {promotionType &&
                                                    promotionType.value &&
                                                    <div className="promotions-type">
                                                        {targetUrl && targetUrl.value ? (
                                                            <a href={targetUrl.value}>
                                                                <Text field={promotionType} />
                                                            </a>
                                                        ) : (
                                                            <Text field={promotionType} />
                                                        )}
                                                    </div>
                                                }
                                                <div className="promotions-name">
                                                    {targetUrl && targetUrl.value ? (
                                                        <a href={targetUrl.value}>
                                                            <Text field={title} />
                                                        </a>
                                                    ) : (
                                                        <Text field={title} />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            } else return null;
                        };

                        const getBadge = () => {
                            if (startDate && startDate.value) {
                                return (
                                    <div className="genericcontent-hero-badge">
                                        <Badge
                                            type="hero"
                                            startDate={startDate.value}
                                            endDate={endDate && endDate.value ? endDate.value : ""}
                                        />
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        };

                        const getBannerDescription = () => {
                            return (
                                <div className="container">
                                    <BaseComponent margin="standard">
                                        <div className="two-column-layout two-column-layout--left">
                                            <div className="genericcontent-herodescription-wrapper">
                                                {PromotionSummary && PromotionSummary.value && (
                                                    <div className='promotions-wrapper'>
                                                        <div className="col-12 col-lg-9 col-xl-8 promotions-summary">
                                                            <RichText className='promotions-summary-text'
                                                                field={PromotionSummary} />
                                                            {Location && Location.value && (
                                                                <Details
                                                                    iconValue="location"
                                                                    linkUrl={LocationLink && LocationLink.value}
                                                                    linkName={Location}
                                                                    size="24"
                                                                />
                                                            )}
                                                            {HidePublishDate && !HidePublishDate.value && ScheduleTime && ScheduleTime.value && (
                                                                <Details iconValue="time" linkName={ScheduleTime} size="24" />
                                                            )}
                                                        </div>
                                                        {CTAButtonText && CTAButtonText.value && (
                                                            <a className='btn primary-btn banner-btn' href={targetUrl.value}><Text field={CTAButtonText} /></a>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </BaseComponent>
                                </div>
                            )
                        }

                        if (heroVideo && heroVideo.value) {
                            return (
                                <div key={"homepage-hero" + index} className={`genericcontent-hero ${promotionType && promotionType.value ? "has-promotion" : ""}`}>
                                    <div className="container">
                                        <div className="animation-range">
                                            <div className="genericcontent-hero-container">
                                                <div className="hero-wrapper">
                                                    <HeroVideo data={heroVideo.value}
                                                        hasControls={false}
                                                        isMute={true}
                                                        isLoop={true}
                                                        isAutoplay={true}
                                                    />
                                                </div>
                                            </div>
                                            {getTitle()}
                                        </div>
                                    </div>
                                    {getBannerDescription()}
                                </div>
                            );
                        } else if (heroImage && heroImage.value && heroImage.value.src) {
                            return (
                                <div key={"homepage-hero" + index} className={`genericcontent-hero ${promotionType && promotionType.value ? "has-promotion" : ""}`}>
                                    <div className="container">
                                        <div className="animation-range">
                                            <div className="genericcontent-hero-container">
                                                <div className="hero-wrapper">
                                                    {targetUrl && targetUrl.value ? (
                                                        <a href={targetUrl.value}>
                                                            <img
                                                                src={heroImage && heroImage.value && heroImage.value.src ? heroImage.value.src : defaultImg}
                                                                className="carousel-cell"
                                                                key={`carousel${index}`}
                                                                alt={heroImage.value && heroImage.value.alt ? heroImage.value.alt : "carousel_image"}
                                                            />
                                                        </a>
                                                    ) : (
                                                        <img
                                                            src={heroImage && heroImage.value && heroImage.value.src ? heroImage.value.src : defaultImg}
                                                            className="carousel-cell"
                                                            key={`carousel${index}`}
                                                            alt={heroImage.value && heroImage.value.alt ? heroImage.value.alt : "carousel_image"}
                                                        />
                                                    )}
                                                </div>
                                                {getBadge()}
                                            </div>
                                            {getTitle()}
                                        </div>
                                    </div>
                                    {getBannerDescription()}
                                </div>
                            );
                        } else {
                            return (
                                <div key={"homepage-hero" + index} className="genericcontent-hero-empty">
                                    {getBannerDescription()}
                                </div>
                            );
                        }
                    })}
                </Flickity>
            )}
        </div>)
};

export default FeatureGenericContentHomepageHero;

import React, { Component } from "react";
import SiteSearch from "../SiteSearch";
import SearchBar from "../SearchBar";

class FeatureSearchSearchModal extends Component {
  render() {
    if (this.props.isSimple) {
      return <SearchBar fields={this.props.fields} />;
    } else {
      return <SiteSearch fields={this.props.fields} />;
    }
  }
}

export default FeatureSearchSearchModal;

import React from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import defaultImg from "../../../../assets/img/placeholders/gray.png";

const FeatureGenericContentGeneralNote = props => {
  const { NoteList = null } = props.fields || {};

  if (NoteList && NoteList.length) {
    return (
      <div className="genericcontent-generalnote-container">
        <div className="content-wrapper">
          {NoteList.map((item, index) => {
            const {
              Icon,
              IconUrl,
              IconAltText,
              Title,
              Description
            } = item.fields;
            return (
              <div key={`myId-${index}`} className="content-item">
                <div className="item-heading">
                  <div className="heading-icon">
                    <Image
                      media={{
                        ...(IconUrl.value || Icon.value.src),
                        src: defaultImg,
                        alt:
                          IconAltText && IconAltText.value
                            ? IconAltText.value
                            : ""
                      }}
                      data-src={IconUrl.value || Icon.value.src}
                      className="img-fluid lazyload"
                    />
                  </div>
                  <div className="heading-text">
                    <Text field={Title} />
                  </div>
                </div>
                <div className="item-description">
                  <Text field={Description} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else return null;
};
export default FeatureGenericContentGeneralNote;

import React from "react";
import ContentLoader from "react-content-loader";

const SearchSkeleton = props => {
  const { opacity = 1 } = props;
  return (
    <ContentLoader
      style={{ width: "100%", opacity: opacity }}
      height={56}
      title=""
      backgroundColor="#f7f7f7"
      foregroundColor="#ececec"
    >
      <rect x="70" y="22" rx="5" ry="5" width="380" height="14" />
      <rect x="20" y="10" rx="4" ry="4" width="40" height="40" />
    </ContentLoader>
  );
};

export default SearchSkeleton;

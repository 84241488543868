import React from "react";
import { Image, Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import Badge from "../../../Common/Badge";
import BaseComponent from "../../../Base/BaseComponent";
import Details from "../../../Common/Details";
import defaultImg from "../../../../assets/img/placeholders/gray.png";

const FeaturePromotionsHeroPromotion = props => {
  const { PromotionHero: PromotionHero = null, PageHero: PageHero = null } =
    props.fields || {};

  if (PromotionHero && PromotionHero.fields) {
    const {
      HeroImage: HeroImage = null,
      StartDate: StartDate = null,
      EndDate: EndDate = null,
      TargetUrl: TargetUrl = null,
      Location: Location = null,
      LocationLink: LocationLink = null,
      PromotionDate: PromotionDate = null,
      PromotionText: PromotionText = null
    } = PromotionHero.fields || {};
    if (HeroImage && HeroImage.value && HeroImage.value.src) {
      return (
        <BaseComponent margin="standard">
          <div className="genericcontent-heropromotion">
            <div className="container">
              <Image
                media={{ ...HeroImage.value, src: defaultImg }}
                data-src={HeroImage.value.src}
                className="lazyload category-header-hero-img"
              />
              {StartDate && StartDate.value && (
                <div className="promotion-header-content">
                  <Badge
                    type="hero"
                    startDate={StartDate.value}
                    endDate={EndDate && EndDate.value ? EndDate.value : ""}
                  />
                </div>
              )}
              <div className="promotion-info">
                <div className="promotion-text">
                  {TargetUrl && TargetUrl.value ? (
                    <a href={TargetUrl.value}>
                      <Text field={PromotionText} />
                    </a>
                  ) : (
                      <Text field={PromotionText} />
                    )}
                </div>
                {Location && Location.value && (
                  <Details
                    iconValue="location"
                    linkUrl={
                      LocationLink && LocationLink.value
                        ? LocationLink.value
                        : ""
                    }
                    linkName={Location}
                    size="24"
                  />
                )}
                {PromotionDate && PromotionDate.value && (
                  <Details
                    iconValue="time"
                    linkName={PromotionDate}
                    size="24"
                  />
                )}
              </div>
            </div>
          </div>
        </BaseComponent>
      );
    } else return null;
  } else if (PageHero && PageHero.fields) {
    const {
      HeroImage = null,
      HeroDescription = null,
      HeroSubcategoryDetails = null
    } = PageHero.fields || {};
    if (HeroImage && HeroImage.value && HeroImage.value.src) {
      return (
        <BaseComponent margin="standard">
          <div className="genericcontent-heropromotion">
            <div className="container">
              <Image
                media={{ ...HeroImage.value, src: defaultImg }}
                data-src={HeroImage.value.src}
                className="lazyload category-header-hero-img"
              />
              <div className="promotion-info col-12 col-md-8">
                <div className="generic-text">
                  <Text field={HeroDescription} />
                </div>
              </div>
              {HeroSubcategoryDetails && HeroSubcategoryDetails.value && (
                <div className="generic-details col-12 col-md-9">
                  <input
                    type="checkbox"
                    className="read-more-state"
                    id="post-2"
                  />
                  <div className="read-more-wrap">
                    <RichText field={HeroSubcategoryDetails} />
                  </div>
                  <label htmlFor="post-2" className="read-more-trigger"></label>
                </div>
              )}
            </div>
          </div>
        </BaseComponent>
      );
    }
    else if (HeroDescription && HeroDescription.value || HeroSubcategoryDetails && HeroSubcategoryDetails.value) {
      return (
        <BaseComponent margin="standard">
          <div className="genericcontent-heropromotion no-image">
            <div className="container">
              {HeroDescription &&
                HeroDescription.value &&
                <div className="promotion-info col-12 col-md-8">
                  <div className="generic-text">
                    <Text field={HeroDescription} />
                  </div>
                </div>
              }

              {HeroSubcategoryDetails && HeroSubcategoryDetails.value && (
                <div className="generic-details col-12 col-md-9">
                  <input
                    type="checkbox"
                    className="read-more-state"
                    id="post-2"
                  />
                  <div className="read-more-wrap">
                    <RichText field={HeroSubcategoryDetails} />
                  </div>
                  <label htmlFor="post-2" className="read-more-trigger"></label>
                </div>
              )}
            </div>
          </div>
        </BaseComponent>
      );
    }
    else return null;
  } else return null;
};

export default FeaturePromotionsHeroPromotion;

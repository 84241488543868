export const renameElements = (
  list,
  defaultName,
  defaultFilter,
  defaultActive
) => {
  let temp = [];
  list.forEach(el =>
    temp.push({
      name: el.fields[defaultName].value,
      filter: el.fields[defaultFilter].value,
      active: defaultActive ? el.fields[defaultActive].value : null
    })
  );
  return temp;
};

import React from "react";

const DetailsContainer = ({ image, details }) => {
    return <div className="subscribe-container">
        <div className="row">
            <div className="col-12 col-md-auto order-12 order-md-1 subscribe-content-wrapper">
                {details}
            </div>
            <div className="col-12 col-md order-1 order-md-12 subscribe-image-wrapper">
                {image}
            </div>
        </div>
    </div>
}

export default DetailsContainer
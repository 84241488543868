import React from "react";
import { Text, Image, Link } from "@sitecore-jss/sitecore-jss-react";
import ScrollAnimation from "react-animate-on-scroll";
import Badge from "../../../Common/Badge";
import BaseComponent from "../../../Base/BaseComponent";
import Details from "../../../Common/Details";
import defaultImg from "../../../../assets/img/placeholders/gray.png";
import RiseAndFade from "../../../Common/Animations/RiseAndFade";

const FeaturePromotionsSimilarPromotions = props => {
  const {
    Title: title = null,
    Subtext: subtext = null,
    EventList: eventList = null
  } = props.fields || {};

  let list;

  if (eventList && eventList.length && eventList.length > 2) {
    list = (
      <BaseComponent margin="standard">
        <div className="similarpromotions-wrapper">
          <div className="similarpromotions-banner">
            <div className="container">
              <div className="similarpromotions-header">
                <ScrollAnimation
                  animateIn="animate__animated animate__fadeIn"
                  animateOnce={true}
                >
                  <div className="title">
                    <Link field={title} />
                    <i className="icon icon-arrow-right" />
                  </div>
                  <div className="subtitle">
                    <Text field={subtext} />
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
          <div className="similarpromotions-tile-wrapper">
            <div className="container">
              <div className="row no-gutters">
                {eventList.map((listItem, index) => {
                  const {
                    PromotionLink: promotionLink = null,
                    Thumbnail: thumbnail = null,
                    StartDate: startDate = null,
                    EndDate: endDate = null,
                    EventText: eventText = null,
                    PromotionType: promotionType = null,
                    PromotionName: promotionName = null,
                    Name: name = null,
                    LocationLink: locationLink = null,
                    PromotionLocation: location = null,
                    ScheduleTime: scheduleTime = null,
                    IsRetailerContent: isRetailerContent = null,
                    IsLogoAsThumbnail: isLogoAsThumbnail = null,
                    Retailer: retailerName = null,
                    HidePublishDate: HidePublishDate = null,
                    StorefrontId: storefrontId = null
                  } = listItem.fields || {};
                  
                  let value = 0;
                  if (index == 1) {
                    value = 6;
                  } else if (index == 2) {
                    value = 8;
                  }

                  return (
                    <div key={`myId-${index}`} className="col-10 col-md-4">
                      <RiseAndFade position={value}>
                        <div className="animation-range">
                          <div
                            className={`content-img-wrapper ${
                              isLogoAsThumbnail && isLogoAsThumbnail.value
                                ? "display_retailer_logo"
                                : ""
                            }`}
                          >
                            <a href={promotionLink.value}>
                              {thumbnail &&
                                thumbnail.value &&
                                !thumbnail.value.src &&
                                isRetailerContent &&
                                isRetailerContent.value && (
                                  <div className="display_retailer_name">
                                    <Text field={retailerName} />
                                  </div>
                                )}
                              <div className="include_logo_padding">
                                <Image
                                  media={{
                                    ...thumbnail.value,
                                    src: defaultImg
                                  }}
                                  data-src={thumbnail.value.src}
                                  className="content-img lazyload"
                                />
                              </div>
                            </a>
                            <div className="img-badge">
                              <Badge
                                type="list"
                                startDate={startDate ? startDate.value : null}
                                endDate={endDate ? endDate.value : ""}
                                badgeText={eventText ? eventText.value : ""}
                              />
                            </div>
                          </div>
                          <div className="details-type">
                            <Text field={promotionType} />
                          </div>
                          <div className="details-title">
                            <a href={promotionLink.value}>
                              <Text
                                field={promotionName ? promotionName : name}
                              />
                            </a>
                          </div>
                        </div>

                        {location && location.value && (
                          <Details
                            iconValue="location"
                            linkUrl={locationLink && locationLink.value}
                            mapInfo={storefrontId && storefrontId.value}
                            linkName={location}
                            size="24"
                          />
                        )}
                        {HidePublishDate && !HidePublishDate.value && scheduleTime && scheduleTime.value && (
                          <Details
                            iconValue="time"
                            linkName={scheduleTime}
                            size="24"
                          />
                        )}
                      </RiseAndFade>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  } else {
    list = "";
  }

  return list;
};
export default FeaturePromotionsSimilarPromotions;

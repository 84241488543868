import React, { Component } from "react";

class ContactUsInputFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validInput: false,
      touched: false,
      hasValue: false
    };
    this.inputField = React.createRef();
  }

  validateField = () => {
    const {
      FormatValidation = null,
      Type = null,
      IsRequired = null
    } = this.props.info || {};
    const {
      updateFieldValue = null
    } = this.props || {};
    let expression = FormatValidation && FormatValidation.value ? FormatValidation.value : "";
    let re = new RegExp(expression);
    let inputValue;
    if (IsRequired.value && !this.inputField.current.value) {
      this.setState({ validInput: false, touched: true });
      inputValue = "";
    } else if (!re.test(String(this.inputField.current.value))) {
      if (this.inputField.current.value) {
        this.setState({ validInput: false, touched: true });
      } else {
        this.setState({ validInput: true, touched: false });
      }
      inputValue = "";
    } else {
      this.setState({ validInput: true, touched: false });
      inputValue = this.inputField.current.value;
    }
    updateFieldValue(this.inputField.current.id, inputValue, Type.value, IsRequired.value, this.state.validInput);

    if (this.inputField.current.value) {
      this.setState({ hasValue: true });
    } else {
      this.setState({ hasValue: false });
    }
  };

  render() {
    const {
      Name = null,
      Type = null,
      IsRequired = null,
      Placeholder = null,
      RequiredValidationMessage = null,
    } = this.props.info || {};

    return (
      <div className="row">
        <div className="col col-md-12">
        <div className={`form-group ${(Name.value).toLowerCase() === 'phone number' ? "phone-number" : ""}`}>
            {Type.value.toLowerCase() === 'multi-line field' ?
            <textarea
              id={Name.value}
              className={`${(!this.state.validInput && this.state.touched) || (this.props.submitted && !this.state.validInput && IsRequired.value) ? "form-control-error" : ""}`}
              required={IsRequired.value ? true : false}
              ref={this.inputField}
              onChange={this.validateField}
              onBlur={this.validateField}
            /> :
            <input
              type="text"
              id={Name.value}
              className={`form-control form-control-lg ${(!this.state.validInput && this.state.touched) || (this.props.submitted && !this.state.validInput && IsRequired.value) ? "form-control-error" : ""}`}
              required={IsRequired.value ? true : false}
              ref={this.inputField}
              onChange={this.validateField}
              onBlur={this.validateField}
            />}
            <label
              className={
                `${(IsRequired.value ? "form-control-placeholder" : "form-field-not-required")}
                ${(this.state.hasValue ? "form-field-hasValue" : "")}
                ${(!this.state.validInput && this.state.touched) || (this.props.submitted && !this.state.validInput && IsRequired.value) ? "form-text-error" : ""}
                `
              }
              htmlFor={Name.value}
              >
              {Type.value.toLowerCase() === 'multi-line field' && this.state.hasValue ?
              "" : (Placeholder.value)}
            </label>
            {
              ((!this.state.validInput && this.state.touched) ||
                (this.props.submitted && !this.state.validInput && IsRequired.value)) && (
                <span>
                  <i className="icon icon-danger contactus-danger-icon">!</i>
                  <label className="form-control-validation">{RequiredValidationMessage.value}</label>
                </span>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUsInputFields;

import React, { Component } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import CategoryResults from "../CategoryResults";
import StoreResults from "../StoreResults";
import WhatsOnResults from "../WhatsOnResults";
import TagManager from "react-gtm-module";
import axios from "axios";
import _ from "lodash";
import LazyLoading from "../../../Common/LazyLoading";

class SiteSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storeDirectoryLabel: "",
      categoriesLabel: "",
      whatsOnLabel: "",
      pagesLabel: "",
      noSearchResult: "",
      SearchHelpText: props.fields.SearchHelpText || {},
      SearchAdditionalText: props.fields.SearchAdditionalText || {},
      CentreId: props.fields.CentreId || {},
      storeItems: [],
      categoryItems: [],
      whatsOnItems: [],
      pageItems: [],
      showLoader: false
    };
    this.searchTerm = React.createRef();
    this.handleSearch = _.debounce(this.handleSearch, 1000);
  }

  handleSearch = () => {
    let url = "";
    let base = "";
    let keyword = "";
    let centreId = "";

    if (this.state.CentreId && this.state.CentreId.value) {
      centreId = this.state.CentreId.value.replace(/{|},|}/g, "");

      //populate keyword
      if (
        this.searchTerm.current.value &&
        /\S/.test(this.searchTerm.current.value)
      ) {
        // base = this.searchTerm.current.value.replace(/\s/g, ",");
        base = this.searchTerm.current.value;
        keyword = base.replace(/&/g, "%26");
        TagManager.dataLayer({
          dataLayer: {
            event: "performedSearch",
            searchTerm: this.searchTerm.current.value
          },
          dataLayerName: "dataLayer"
        });
      } else {
        this.setState({
          noSearchResult: "",
          storeItems: [],
          categoryItems: [],
          whatsOnItems: [],
          pageItems: []
        });
        return null;
      }
      this.setState({
        showLoader: true
      });
      url = `/api/sitecore/search/search?keyword=${keyword}&centreId=${centreId}`;

      axios({
        url,
        method: "GET"
      })
        .then(responseRaw => {
          let response = responseRaw.data ? responseRaw.data : null;
          if (response) {
            if (this.searchTerm.current.value.length === 0) {
              this.setState({
                storeItems: [],
                categoryItems: [],
                whatsOnItems: [],
                pageItems: []
              });
            } else {
              if (response.RetailerList) {
                this.setState({
                  storeItems: response.RetailerList,
                  storeDirectoryLabel: response.StoreDirectoryLabel
                });
              }
              if (response.Categories) {
                this.setState({
                  categoryItems: response.Categories,
                  categoriesLabel: response.CategoriesLabel
                });
              }
              if (response.WhatsOn) {
                this.setState({
                  whatsOnItems: response.WhatsOn,
                  whatsOnLabel: response.WhatsOnLabel
                });
              }
              if (response.Pages) {
                this.setState({
                  pageItems: response.Pages,
                  pagesLabel: response.PagesLabel
                });
              }
              if (
                response.RetailerList.length === 0 &&
                response.Categories.length === 0 &&
                response.WhatsOn.length === 0 &&
                response.Pages.length === 0
              ) {
                this.setState({ noSearchResult: response.NoSearchResult });
              } else {
                this.setState({ noSearchResult: "" });
              }
            }
          }
          this.setState({
            showLoader: false
          });
        })

        .catch(error => {
          // just handle error
        });
    } else return null;
  };

  showRetailerList = () => {
    var {
      storeItems: storeItems = null,
      storeDirectoryLabel: storeDirectoryLabel = null
    } = this.state || {};
    if (storeItems.length > 0) {
      return (
        <div className="col col-12 col-sm-6">
          <StoreResults title={storeDirectoryLabel} data={storeItems} />
        </div>
      );
    } else {
      return null;
    }
  };

  showCatgeoryList = () => {
    var {
      categoryItems: categoryItems = null,
      whatsOnItems: whatsOnItems = null,
      pageItems: pageItems = null,
      categoriesLabel: categoriesLabel = null
    } = this.state || {};
    if (categoryItems.length > 0) {
      if (whatsOnItems.length > 0 || pageItems.length > 0) {
        return (
          <React.Fragment>
            <CategoryResults title={categoriesLabel} data={categoryItems} />
            <hr />
          </React.Fragment>
        );
      } else {
        return <CategoryResults title={categoriesLabel} data={categoryItems} />;
      }
    } else {
      return null;
    }
  };

  showPromotionList = () => {
    var {
      whatsOnLabel: whatsOnLabel = null,
      whatsOnItems: whatsOnItems = null,
      pageItems: pageItems = null
    } = this.state || {};
    if (whatsOnItems.length > 0) {
      if (pageItems.length > 0) {
        return (
          <React.Fragment>
            <WhatsOnResults title={whatsOnLabel} data={whatsOnItems} />
            <hr />
          </React.Fragment>
        );
      } else {
        return <WhatsOnResults title={whatsOnLabel} data={whatsOnItems} />;
      }
    } else {
      return null;
    }
  };

  showPageList = () => {
    var { pageItems: pageItems = null, pagesLabel: pagesLabel = null } =
      this.state || {};
    if (pageItems.length > 0) {
      return <CategoryResults title={pagesLabel} data={pageItems} />;
    } else {
      return null;
    }
  };

  showNoResults = () => {
    if (this.state.noSearchResult)
      return (
        <div className="no-results">
          <Text field={this.state.noSearchResult} />
        </div>
      );
  };

  render() {
    const { showLoader, SearchAdditionalText } = this.state;
    return (
      <div className="search-wrapper container">
        <div className="row no-gutters">
          <div className="col search-searchbar align-items-center">
            <i className="icon icon-outlined-search" />
            <input
              className="form-control rounded-0"
              autoFocus={true}
              type="text"
              ref={this.searchTerm}
              placeholder={this.state.SearchHelpText.value}
              onChange={this.handleSearch}
            />
          </div>
        </div>
        {SearchAdditionalText && SearchAdditionalText.value ? (
          <div className="additional-hint">
            <Text field={SearchAdditionalText} />
          </div>
        ) : null}
        {!showLoader && (
          <div className="results-wrapper">
            {this.showNoResults()}
            <div className="row">
              {this.showRetailerList()}
              <div className="col">
                {this.showCatgeoryList()}
                {this.showPromotionList()}
                {this.showPageList()}
              </div>
            </div>
          </div>
        )}
        {showLoader && <LazyLoading />}
      </div>
    );
  }
}

export default SiteSearch;

import React from "react";
import { Text, Link } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";

const FeatureGenericContentTermsAndConditions = props => {
  const { Copy: copy = null, Link: link = null } = props.fields || {};

  if (copy && copy.value && link && link.value && link.value.href) {
    return (
      <BaseComponent margin="standard">
        <div className="tandc-container">
          <div className="tandc-text">
            <Text field={copy} />
          </div>
          <Link field={link} className="btn primary-btn btn-padding" />
        </div>
      </BaseComponent>
    );
  } else return null;
};

export default FeatureGenericContentTermsAndConditions;

import React from "react";
import Checkbox from "../Checkbox";
const Interest = ({ interests, handleCheckboxClick }) => {
    return (


        interests.map((listItem, index) => {
            const {
                Id: id = null,
                Name: name = null,
                Selected: selected = null,
            } = listItem.fields || {};
            if (index > 5) {
                return false;
            }
            return (
                <div className="col-12 col-md-4" key={`${name.value}-${id.value}-${index}`}>
                    <Checkbox
                        id={id.value}
                        name={name.value}
                        checked={selected && selected.value ? selected.value : false}
                        handleCheckboxClick={handleCheckboxClick}
                    />
                </div>
            );
        })

    )
}

export default Interest
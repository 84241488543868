import React from "react";
import defaultImg from "../../../../assets/img/placeholders/gray.png";

const Image = ({ SubscribeImageUrl }) => {

    if (SubscribeImageUrl && SubscribeImageUrl.value) return (
        <img
            alt="subscribe"
            src={defaultImg}
            data-src={SubscribeImageUrl.value}
            className="lazyload"
        />
    )
    return ""
}

export default Image;
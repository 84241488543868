import React, { Component } from "react";
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import BaseComponent from "../../../Base/BaseComponent";
import defaultImg from "../../../../assets/img/placeholders/gray.png";

class FeatureRecipeRecipeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showIngredients: true,
      showMethods: false
    };
  }

  componentDidMount() {
    const {
      Ingredients,
      LinkedStoreList,
      Methods,
    } = this.props.fields || {};

    if ((Ingredients && Ingredients.length > 0) || (LinkedStoreList && LinkedStoreList.length > 0)) {
      this.setState({
        showIngredients: true,
        showMethods: false
      })
    } else {
      if (Methods && Methods.legnth > 0) {
        this.setState({
          showIngredients: false,
          showMethods: true
        })
      }
    }
  }

  handleClickIngredient = () => {
    this.setState({
      showIngredients: true,
      showMethods: false
    })
  }
  handleClickMethod = () => {
    this.setState({
      showIngredients: false,
      showMethods: true
    })
  }

  renderPrep = () => {
    const {
      PrepTime,
      CookTime,
      Serves,
      Difficulty,
    } = this.props.fields || {};
    let prepLayout;

    if ((PrepTime && PrepTime.value) || (CookTime && CookTime.value)
      || (Serves && Serves.value) || (Difficulty && Difficulty.value)) {
      prepLayout =
        <div className="recipe-details__prep">
          {PrepTime &&
            PrepTime.value &&
            <div className="recipe-details__prep--item">
              <div className="recipe-details__prep--item-header">Prep time</div>
              <div className="recipe-details__prep--item-description">
                <Text field={PrepTime} />
              </div>
            </div>
          }
          {CookTime &&
            CookTime.value &&
            <div className="recipe-details__prep--item">
              <div className="recipe-details__prep--item-header">Cook time</div>
              <div className="recipe-details__prep--item-description">
                <Text field={CookTime} />
              </div>
            </div>
          }
          {Serves &&
            Serves.value &&
            <div className="recipe-details__prep--item">
              <div className="recipe-details__prep--item-header">Serves</div>
              <div className="recipe-details__prep--item-description">
                <Text field={Serves} />
              </div>
            </div>
          }
          {Difficulty &&
            Difficulty.value &&
            <div className="recipe-details__prep--item">
              <div className="recipe-details__prep--item-header">Difficulty</div>
              <div className="recipe-details__prep--item-description">
                <Text field={Difficulty} />
              </div>
            </div>
          }
        </div>
    }
    return prepLayout;
  }

  renderIngredients = (showIngredients) => {
    const {
      Ingredients,
      LinkedStoresHeader,
      LinkedStoreList,
    } = this.props.fields || {};

    let ingredientsLayout;
    if ((Ingredients && Ingredients.length > 0) || (LinkedStoreList && LinkedStoreList.length > 0)) {
      ingredientsLayout =
        <div className={`recipe-details__ingredient ${showIngredients ? "is-active" : ""}`}>
          <div className="recipe-details__ingredient--header">Ingredients</div>
          {Ingredients &&
            Ingredients.length > 0 &&
            <div className="recipe-details__ingredient--item-wrapper">
              {Ingredients.map((item, index) => {
                const {
                  Header,
                  Ingredients
                } = item.fields;
                return (
                  <div key={Header.value + index}
                    className="recipe-details__ingredient--item">
                    <div className="recipe-details__ingredient--item-title">
                      <Text field={Header} />
                    </div>
                    <div className="recipe-details__ingredient--item-description">
                      <RichText field={Ingredients} />
                    </div>
                  </div>
                )
              })
              }
            </div>
          }
          {LinkedStoreList &&
            LinkedStoreList.length > 0 &&
            <div className="recipe-details__stores">
              {LinkedStoresHeader &&
                LinkedStoresHeader.value &&
                <div className="recipe-details__stores--title">
                  <Text field={LinkedStoresHeader} />
                </div>
              }
              <div className="recipe-details__stores--wrapper">
                {LinkedStoreList.map((item, index) => {
                  const {
                    Name,
                    Logo,
                    StoreLink
                  } = item.fields;

                  let logoLayout;
                  logoLayout =
                    <div className="recipe-details__stores--item">
                      <div className="recipe-details__stores--item-image">
                        <Image
                          media={{
                            ...Logo.value,
                            src: defaultImg
                          }}
                          data-src={Logo.value.src}
                          className="lazyload"
                        />
                      </div>
                      <div className="recipe-details__stores--item-name">
                        <Text field={Name} />
                      </div>
                    </div>

                  return (
                    <div key={Name.value + index}
                      className="recipe-details__stores--content">
                      <div className="animation-range">
                        {StoreLink && StoreLink.value ?
                          <a href={StoreLink.value}>
                            {logoLayout}
                          </a>
                          :
                          logoLayout
                        }
                      </div>
                    </div>
                  )
                })
                }
              </div>
            </div>
          }
        </div>
    }
    return ingredientsLayout
  }

  renderMethod = (showMethods) => {
    const {
      Methods,
    } = this.props.fields || {};
    let methodLayout;
    if (Methods && Methods.length > 0) {
      methodLayout =
        <div className={`recipe-details__method ${showMethods ? "is-active" : ""}`}>
          <div className="recipe-details__method--header">Method</div>
          <div className="recipe-details__method--item-wrapper">
            {Methods.map((item, index) => {
              const {
                StepNumber,
                StepDescription,
                Image: image = null,
              } = item.fields;
              return (
                <div key={StepNumber.value + index}
                  className="recipe-details__method--item">
                  <div className="recipe-details__method--number">
                    <Text field={StepNumber} />
                  </div>
                  <div className="recipe-details__method--description">
                    <RichText field={StepDescription} />
                  </div>
                  {image &&
                    image.value &&
                    image.value.src &&
                    <div className="recipe-details__method--image-wrapper">
                      <div className="recipe-details__method--image">
                        <Image
                          media={{
                            ...image.value,
                            src: defaultImg
                          }}
                          data-src={image.value.src}
                          className="recipe-details__method--image-img lazyload"
                        />
                      </div>
                    </div>
                  }
                </div>
              )
            })
            }
          </div>
        </div>
    }
    return methodLayout;
  }

  renderButtons = (showIngredients, showMethods) => {
    const {
      Ingredients,
      LinkedStoreList,
      Methods,
    } = this.props.fields || {};

    let btnLayout;
    if ((Ingredients && Ingredients.length > 0) || (LinkedStoreList && LinkedStoreList.length > 0)
      || (Methods && Methods.legnth > 0)) {
      btnLayout =
        <div className="recipe-details__button">
          <button onClick={this.handleClickIngredient}
            className={`recipe-details__button--btn ${showIngredients ? "is-active" : ""}`}>
            Ingredients
        </button>
          <button onClick={this.handleClickMethod}
            className={`recipe-details__button--btn ${showMethods ? "is-active" : ""}`}>
            Method
        </button>
        </div>
    }
    return btnLayout;
  }
  render() {
    const {
      RecipeTitle,
      RecipeDescription,
      Notes
    } = this.props.fields || {};

    const {
      showIngredients,
      showMethods
    } = this.state;
    return (
      <div className="recipe-details font-regular">
        <BaseComponent margin="standard">
          <div className="container">
            {RecipeTitle &&
              RecipeTitle.value &&
              <h1 className="recipe-details__title">
                <Text field={RecipeTitle} />
              </h1>
            }
            {this.renderPrep()}
            {RecipeDescription &&
              RecipeDescription.value &&
              <div className="recipe-details__description">
                <RichText field={RecipeDescription} />
              </div>
            }
            {this.renderButtons(showIngredients, showMethods)}
            {this.renderIngredients(showIngredients)}
            {this.renderMethod(showMethods)}
            {Notes &&
              Notes.value &&
              <div className="recipe-details__notes">
                <div className="recipe-details__notes--header">Notes</div>
                <div className="recipe-details__notes--description">
                  <RichText field={Notes} />
                </div>
              </div>
            }
          </div>
        </BaseComponent>
      </div>
    )
  }
};

export default FeatureRecipeRecipeDetails;

export const valueHelper = (index, columns, tabletColumns) => {
  let value = 0;
  let checker;

  if (tabletColumns) {
    // Mobile breakpoint
    if (window && window.outerWidth && window.outerWidth < 768) {
      value = 6;
    }
    // Tablet breakpoint
    else if (
      window &&
      window.outerWidth &&
      window.outerWidth <= 768 &&
      window.outerWidth < 992 &&
      tabletColumns
    ) {
      checker = index % tabletColumns;
    }

    // Desktop breakpoint
    else {
      checker = index % columns;
    }

    // Default checker
    if (checker == 1) {
      value = 6;
    } else if (checker == 2) {
      value = 8;
    } else if (checker == 3) {
      value = 10;
    } else if (checker == 4) {
      value = 12;
    }
  } else {
    checker = index % columns;

    if (checker == 1) {
      value = 6;
    } else if (checker == 2) {
      value = 8;
    } else if (checker == 3) {
      value = 10;
    } else if (checker == 4) {
      value = 12;
    }
  }

  return value;
};

import React from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";

const NavWhatsOn = ({ title, spotlight, promotions }) => {
  if (spotlight || promotions.length === 3) {
    return (
      <div className="nav-whatson-wrapper">
        <div className="title">
          {title.url ? (
            <React.Fragment>
              <a href={title.url}>
                <Text field={title.name} />
              </a>
              <i className="icon icon-arrow-right" />
            </React.Fragment>
          ) : (
            <Text field={title.name} />
          )}
        </div>
        <div className="content row">
          {/* ------------------- SPOTLIGHT ------------------- */}
          {spotlight && (
            <div className="col col-auto">
              <div className="spotlight-item">
                <a href={spotlight.ArticleUrl.value}>
                  <div
                    className={
                      spotlight.IsLogoAsThumbnail &&
                        spotlight.IsLogoAsThumbnail.value
                        ? "display_retailer_logo"
                        : ""
                    }
                  >
                    {spotlight.Thumbnail &&
                      spotlight.Thumbnail.value &&
                      !spotlight.Thumbnail.value.src &&
                      spotlight.IsRetailerContent &&
                      spotlight.IsRetailerContent.value && (
                        <div className="display_retailer_name">
                          <Text field={spotlight.Retailer} />
                        </div>
                      )}
                    <div className="include_logo_padding">
                      <Image
                        media={{ ...spotlight.Thumbnail.value }}
                        data-src={spotlight.Thumbnail.value.src}
                        className="spotlight-image lazyload"
                      />
                    </div>
                  </div>
                </a>
                <div className="type">
                  <Text field={spotlight.PromotionType} />
                </div>
                <div className="spotlight-description">
                  <a href={spotlight.ArticleUrl.value}>
                    <Text field={spotlight.ArticleName} />
                  </a>
                </div>
              </div>
            </div>
          )}
          {/* ------------------- WHATS ON ------------------- */}
          {promotions && promotions.length && promotions.length === 3 && (
            <div className="col">
              {promotions.map((promotion, index) => (
                <div key={`navWhatsOnID-${index}`} className="row whatson-item">
                  <div className="col col-auto">
                    <a href={promotion.PromotionUrl.value}>
                      <div
                        className={
                          promotion.IsLogoAsThumbnail &&
                            promotion.IsLogoAsThumbnail.value
                            ? "display_retailer_logo"
                            : ""
                        }
                      >
                        {promotion.Thumbnail &&
                          promotion.Thumbnail.value &&
                          !promotion.Thumbnail.value.src &&
                          promotion.IsRetailerContent &&
                          promotion.IsRetailerContent.value && (
                            <div className="display_retailer_name">
                              <Text field={promotion.Retailer} />
                            </div>
                          )}
                        <div className="include_logo_padding">
                          <Image
                            media={{ ...promotion.Thumbnail.value }}
                            data-src={promotion.Thumbnail.value.src}
                            className="whatson-image lazyload"
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col whatson-details">
                    <div className="type">
                      <Text field={promotion.PromotionType} />
                    </div>
                    <div className="whatson-description">
                      <a href={promotion.PromotionUrl.value}>
                        <Text field={promotion.PromotionName} />
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
  else return null;
};
export default NavWhatsOn;

import React from "react";
import { Text, Link } from "@sitecore-jss/sitecore-jss-react";
import Details from "../../../Common/Details";

const FeatureParkingCarPark = props => {
  const {
    OnlineGroupLabel = null,
    OnlineCarParkList = null,
    OfflineGroupLabel = null,
    OfflineCarParkList = null,
    CarParkList = null,
  } = props.fields || {};

  const renderCarpark = (carParkList, isOnlineCarPark) => {
    let carParkListLayout;
    if (carParkList && carParkList.length > 0) {
      carParkListLayout = carParkList.map((item, index) => {
        const {
          IconColour: iconColour = null,
          IconCharacters: IconCharacters = null,
          ParkType: parkType = null,
          BestPark: bestPark = null,
          AvailableParking: availableParking = null,
          AvailableParkingCount: availableParkingCount = null,
          StoreNames: storeNames = null,
          Precincts: precincts = null,
          MapDirectionLabel: mapDirectionLabel = null,
          MapDirectionLink: mapDirectionLink = null,
          DisabilityParking: disabilityParking = null,
          PramParking: pramParking = null,
          ElectricCarCharging: electricCarCharging = null
        } = item.fields || item || {};

        const parkColour = (iconColour && iconColour.value ? iconColour.value : "");
        const parkText = (IconCharacters && IconCharacters.value ? IconCharacters.value : "P");

        let parkingGroup;
        if (parkType && parkType.value) {
          parkingGroup =
            <div key={`myId-${index}`} className="parking-carpark__wrapper">
              <div className="parking-carpark__content">
                <div className="parking-carpark__content--icon" style={{ borderColor: parkColour, backgroundColor: parkColour }}>
                  <div className="parking-carpark__content--icon-border">
                    <div className="parking-carpark__content--icon-text">{parkText}</div>
                  </div>
                </div>

                <div className="parking-carpark__content--available is-mobile">
                  <div className="parking-carpark__content--available-count">
                    <Text field={availableParkingCount} />
                  </div>
                  <div className="parking-carpark__content--available-text">
                    <Text field={availableParking} />
                  </div>
                </div>

                <div className="parking-carpark__content--details">
                  <div className="row">
                    <div className="parking-carpark__content--title col-12 col-md-10">
                      <div className="parking-carpark__content--header">
                        <Text field={parkType} />
                      </div>
                      {((storeNames && storeNames.length > 0) ||
                        (precincts && precincts.value)) &&
                        <div className="parking-carpark__content--sub">
                          <span className="parking-carpark__content--sub-bestpark">
                            <Text field={bestPark} />
                          </span>
                          {storeNames.map((store, storeIndex) => {
                            const { StoreDetails: storeDetails = null } =
                              store.fields || {};
                            let storeData;
                            if (store && store.value) {
                              storeData = store
                            } else {
                              storeData = storeDetails
                            }
                            return (
                              <span key={`storeId-${storeIndex}`}>
                                {storeIndex > 0 ? ", " : ""}
                                <Link
                                  field={storeData}
                                  className="parking-carpark__content--sub-item"
                                />
                              </span>
                            );
                          })}
                          {precincts &&
                            precincts.value &&
                            <span className="parking-carpark__content--sub-precinct">
                              {storeNames && storeNames.length > 0 ? ", " : ""}
                              <Text field={precincts} />
                            </span>
                          }
                        </div>
                      }
                    </div>
                    <div className="parking-carpark__content--available is-desktop col-12 col-md-2">
                      <div className="parking-carpark__content--available-count">
                        {availableParkingCount && availableParkingCount.value ?
                          <Text field={availableParkingCount} />
                          : isOnlineCarPark ? "" : "0"
                        }
                      </div>
                      <div className="parking-carpark__content--available-text">
                        {availableParking && availableParking.value ?
                          <Text field={availableParking} />
                          : isOnlineCarPark ? "" : "Available"
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {mapDirectionLabel &&
                mapDirectionLabel.value &&
                <div className="parking-carpark__location">
                  <Details
                    iconValue="location"
                    linkUrl={mapDirectionLink && mapDirectionLink.value}
                    linkName={mapDirectionLabel}
                    size="24"
                  />
                </div>
              }

              {((disabilityParking && disabilityParking.value) ||
                (pramParking && pramParking.value) ||
                (electricCarCharging && electricCarCharging.value)) && (
                  <div className="parking-carpark__others">
                    {disabilityParking &&
                      disabilityParking.value &&
                      <div className="parking-carpark__others--item">
                        <i className="icon icon-wheelchair" />
                        <span className="parking-carpark__others--item-text">Disabled Parking Available</span>
                      </div>
                    }
                    {pramParking &&
                      pramParking.value &&
                      <div className="parking-carpark__others--item">
                        <i className="icon icon-pram" />
                        <span className="parking-carpark__others--item-text">Pram Parking Available</span>
                      </div>
                    }
                    {electricCarCharging &&
                      electricCarCharging.value &&
                      <div className="parking-carpark__others--item">
                        <i className="icon icon-valet" />
                        <span className="parking-carpark__others--item-text">Electric Car Charging</span>
                      </div>
                    }
                  </div>
                )}
            </div>
        }

        return parkingGroup;
      })
    }

    return carParkListLayout;
  }

  if ((OnlineCarParkList && OnlineCarParkList.length > 0) ||
    (OfflineCarParkList && OfflineCarParkList.length > 0) ||
    (CarParkList && CarParkList.length > 0)) {
    return (
      <div className="parking-carpark font-regular">
        {OnlineCarParkList &&
          OnlineCarParkList.length > 0 &&
          <div className="parking-carpark__live">
            {OnlineGroupLabel &&
              OnlineGroupLabel.value &&
              <div className="parking-carpark__live--header">
                <span className="parking-carpark__live--label">
                  <Text field={OnlineGroupLabel} />
                </span>
                <span className="parking-carpark__live--indicator">LIVE</span>
              </div>
            }
            {renderCarpark(OnlineCarParkList, true)}
          </div>
        }
        {OfflineCarParkList &&
          OfflineCarParkList.length > 0 &&
          <div className={`parking-carpark__other ${OnlineCarParkList && OnlineCarParkList.length > 0 ? "" : "no-live-parking"}`}>
            {OfflineGroupLabel &&
              OfflineGroupLabel.value &&
              <div className="parking-carpark__other--header">
                <span className="parking-carpark__other--label">
                  <Text field={OfflineGroupLabel} />
                </span>
              </div>
            }
            <div className="parking-carpark__other--list">
              {renderCarpark(OfflineCarParkList)}
            </div>
          </div>
        }

        {CarParkList &&
          CarParkList.length > 0 &&
          renderCarpark(CarParkList)
        }
      </div>
    );
  } else return null;
};
export default FeatureParkingCarPark;

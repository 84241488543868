import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const CategoryResults = ({ title, data }) => {
  return (
    <div className="search-list">
      <div className="title">
        <Text field={title} />
      </div>
      <div className="content-list">
        <ul>
          {data.map((item, index) => {
            try {
              const {
                CategoryName: CategoryName = null,
                PageTitle: PageTitle = null,
                Link: Link = null
              } = item || {};
              return (
                <li key={`myId-${index}`}>
                  <a href={Link.value}>
                    <Text
                      field={CategoryName === null ? PageTitle : CategoryName}
                    />
                  </a>
                </li>
              );
            } catch (error) {
              //Catch error
            }
          })}
        </ul>
      </div>
    </div>
  );
};
export default CategoryResults;

import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Text, Link } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import defaultImg from "../../../../assets/img/placeholders/gray.png";
import RiseAndFade from "../../../Common/Animations/RiseAndFade";

const FeatureCinemaNowShowing = props => {
  const {
    title = null,
    cinemaName = null,
    cinemaUrl = null,
    movieList = null
  } = props.fields || {};
  if (movieList && movieList.length) {
    return (
      <BaseComponent margin="standard">
        <div className="now-showing-wrapper">
          <div className="now-showing-banner">
            <div className="container">
              <div className="now-showing-content-wrapper col-11">
                <div className="now-showing-header-wrapper">
                  <ScrollAnimation
                    animateIn="animate__animated animate__fadeIn"
                    animateOnce={true}
                  >
                    {cinemaName && cinemaName.value && (
                      <div className="now-showing-name">
                        <Link
                          field={{
                            href:
                              cinemaUrl && cinemaUrl.value
                                ? cinemaUrl.value
                                : ""
                          }}
                        >
                          <span>{cinemaName.value}</span>
                          <i className="icon icon-arrow-right" />
                        </Link>
                      </div>
                    )}
                    <div className="now-showing-title">
                      <Text field={title} />
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
          <div className="now-showing-tiles-wrapper">
            <div className="container">
              <div className="row no-gutters">
                {movieList &&
                  movieList.map((listItem, index) => {
                    const {
                      thumbnail = null,
                      title = null,
                      movieUrl = null,
                      runtime = null,
                      movieRating = null,
                      genres = null,
                      sessions = null
                    } = listItem.fields || {};
                    let value = 0;
                    if (index == 1) {
                      value = 6;
                    } else if (index == 2) {
                      value = 8;
                    } else if (index == 3) {
                      value = 10;
                    }
                    return (
                      <div
                        className="col-12 col-md-3"
                        key={"showingId" + index}
                      >
                        <RiseAndFade position={value}>
                          <div className="animation-range">
                            <div className="image-content">
                              {thumbnail && thumbnail.value && (
                                <Link
                                  field={{
                                    href: movieUrl.value
                                  }}
                                >
                                  <img
                                    alt=""
                                    src={defaultImg}
                                    data-src={thumbnail.value}
                                    className="lazyload"
                                  />
                                </Link>
                              )}
                            </div>

                            <div className="now-showing-tiles-details-title">
                              <Link
                                field={{
                                  href: movieUrl.value
                                }}
                              >
                                <Text field={title} />
                              </Link>
                            </div>
                          </div>
                          <div className="now-showing-tiles-details">
                            <div className="now-showing-tiles-details-genre-wrapper">
                              <span className="genre-text">
                                <Text field={movieRating} />
                                <span className="vertical-line"> | </span>
                              </span>
                              <span className="genre-text">
                                <Text field={runtime} />
                                <span className="vertical-line"> | </span>
                              </span>
                              {genres.map((item, i) => {
                                const { genre } = item.fields || {};
                                return (
                                  <span
                                    className="genre-text"
                                    key={
                                      genre && genre.value
                                        ? genre.value + "number" + index
                                        : "randomGenre" + index
                                    }
                                  >
                                    <Text field={genre} />
                                    {i + 1 !== genres.length && ", "}
                                  </span>
                                );
                              })}
                            </div>
                            <div className="now-showing-tiles-details-session-wrapper">
                              {sessions.map((item, i) => {
                                const { session } = item.fields || {};
                                return (
                                  <span
                                    className="session-text"
                                    key={
                                      session && session.value
                                        ? session.value + "number" + index
                                        : "randomSession" + index
                                    }
                                  >
                                    <Text field={session} />
                                    {i + 1 !== sessions.length && ", "}
                                  </span>
                                );
                              })}
                            </div>
                          </div>
                        </RiseAndFade>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  } else return null;
};

export default FeatureCinemaNowShowing;

import React from "react";

const LazyLoading = () => {
    return (
        <div className="loader-wrapper">
            <div className="loader"></div>
        </div>
    )
};

export default LazyLoading;
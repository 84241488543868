import React, { Component } from "react";
import { Text, Image, RichText } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import defaultImg from "../../../../assets/img/placeholders/gray.png";

class FeatureCraftCraftDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMaterials: true,
      showInstructions: false,
      isMobile: false
    };
  }

  componentDidMount() {
    const { Materials, LinkedStoreList, Instructions } =
      this.props.fields || {};

    if (window.matchMedia("only screen and (max-width: 768px)").matches) {
      this.setState({
        isMobile: true
      });
    }

    if (
      (Materials && Materials.length > 0) ||
      (LinkedStoreList && LinkedStoreList.length > 0)
    ) {
      this.setState({
        showMaterials: true,
        showInstructions: false
      });
    } else {
      if (Instructions && Instructions.legnth > 0) {
        this.setState({
          showMaterials: false,
          showInstructions: true
        });
      }
    }
  }

  handleClickMaterial = () => {
    this.setState({
      showMaterials: true,
      showInstructions: false
    });
  };
  handleClickInstruction = () => {
    this.setState({
      showMaterials: false,
      showInstructions: true
    });
  };

  renderPrep = () => {
    const { PrepTime, ActiveTime, EstimatedCost, Difficulty } =
      this.props.fields || {};
    let prepLayout;

    if (
      (PrepTime && PrepTime.value) ||
      (ActiveTime && ActiveTime.value) ||
      (EstimatedCost && EstimatedCost.value) ||
      (Difficulty && Difficulty.value)
    ) {
      prepLayout = (
        <div className="craft-details__prep">
          {PrepTime && PrepTime.value && (
            <div className="craft-details__prep--item">
              <div className="craft-details__prep--item-header">Prep time</div>
              <div className="craft-details__prep--item-description">
                <Text field={PrepTime} />
              </div>
            </div>
          )}
          {ActiveTime && ActiveTime.value && (
            <div className="craft-details__prep--item">
              <div className="craft-details__prep--item-header">
                Active time
              </div>
              <div className="craft-details__prep--item-description">
                <Text field={ActiveTime} />
              </div>
            </div>
          )}
          {EstimatedCost && EstimatedCost.value && (
            <div className="craft-details__prep--item">
              <div className="craft-details__prep--item-header">
                {`${this.state.isMobile ? "Est. Cost" : "Estimated Cost"}`}
              </div>
              <div className="craft-details__prep--item-description">
                <Text field={EstimatedCost} />
              </div>
            </div>
          )}
          {Difficulty && Difficulty.value && (
            <div className="craft-details__prep--item">
              <div className="craft-details__prep--item-header">Difficulty</div>
              <div className="craft-details__prep--item-description">
                <Text field={Difficulty} />
              </div>
            </div>
          )}
        </div>
      );
    }
    return prepLayout;
  };

  renderMaterials = showMaterials => {
    const { MaterialsBucket, Materials, LinkedStoresHeader, LinkedStoreList } =
      this.props.fields || {};

    let materialLayout;
    if (
      (Materials && Materials.length > 0) ||
      (LinkedStoreList && LinkedStoreList.length > 0)
    ) {
      materialLayout = (
        <div
          className={`craft-details__material ${
            showMaterials ? "is-active" : ""
          }`}
        >
          <div className="craft-details__material--header">{`${
            MaterialsBucket.fields.Title.value
              ? MaterialsBucket.fields.Title.value
              : "MATERIALS"
          }`}</div>
          {Materials && Materials.length > 0 && (
            <div className="craft-details__material--item-wrapper">
              {Materials.map((item, index) => {
                const { Header, Materials } = item.fields;
                return (
                  <div
                    key={Header.value + index}
                    className="craft-details__material--item"
                  >
                    <div className="craft-details__material--item-title">
                      <Text field={Header} />
                    </div>
                    <div className="craft-details__material--item-description">
                      <RichText field={Materials} />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {LinkedStoreList && LinkedStoreList.length > 0 && (
            <div className="craft-details__stores">
              {LinkedStoresHeader && LinkedStoresHeader.value && (
                <div className="craft-details__stores--title">
                  <Text field={LinkedStoresHeader} />
                </div>
              )}
              <div className="craft-details__stores--wrapper">
                {LinkedStoreList.map((item, index) => {
                  const { Name, Logo, StoreLink } = item.fields;

                  let logoLayout;
                  logoLayout = (
                    <div className="craft-details__stores--item">
                      <div className="craft-details__stores--item-image">
                        <Image
                          media={{
                            ...Logo.value,
                            src: defaultImg
                          }}
                          data-src={Logo.value.src}
                          className="lazyload"
                        />
                      </div>
                      <div className="craft-details__stores--item-name">
                        <Text field={Name} />
                      </div>
                    </div>
                  );

                  return (
                    <div
                      key={Name.value + index}
                      className="craft-details__stores--content"
                    >
                      <div className="animation-range">
                        {StoreLink && StoreLink.value ? (
                          <a href={StoreLink.value}>{logoLayout}</a>
                        ) : (
                          logoLayout
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      );
    }
    return materialLayout;
  };

  renderInstruction = showInstructions => {
    const { InstructionsBucket, Instructions } = this.props.fields || {};
    let instructionLayout;
    if (Instructions && Instructions.length > 0) {
      instructionLayout = (
        <div
          className={`craft-details__instruction ${
            showInstructions ? "is-active" : ""
          }`}
        >
          <div className="craft-details__instruction--header">{`${
            InstructionsBucket.fields.Title.value
              ? InstructionsBucket.fields.Title.value
              : "INSTRUCTIONS"
          }`}</div>
          <div className="craft-details__instruction--item-wrapper">
            {Instructions.map((item, index) => {
              const {
                StepNumber,
                StepDescription,
                Image: image = null
              } = item.fields;
              return (
                <div
                  key={StepNumber.value + index}
                  className="craft-details__instruction--item"
                >
                  <div className="craft-details__instruction--number">
                    <Text field={StepNumber} />
                  </div>
                  <div className="craft-details__instruction--description">
                    <RichText field={StepDescription} />
                  </div>
                  {image && image.value && image.value.src && (
                    <div className="craft-details__instruction--image-wrapper">
                      <div className="craft-details__instruction--image">
                        <Image
                          media={{
                            ...image.value,
                            src: defaultImg
                          }}
                          data-src={image.value.src}
                          className="craft-details__instruction--image-img lazyload"
                        />
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    return instructionLayout;
  };

  renderButtons = (showMaterials, showInstructions) => {
    const {
      MaterialsBucket,
      Materials,
      LinkedStoreList,
      InstructionsBucket,
      Instructions
    } = this.props.fields || {};

    let btnLayout;
    if (
      (Materials && Materials.length > 0) ||
      (LinkedStoreList && LinkedStoreList.length > 0) ||
      (Instructions && Instructions.legnth > 0)
    ) {
      btnLayout = (
        <div className="craft-details__button">
          <button
            onClick={this.handleClickMaterial}
            className={`craft-details__button--btn ${
              showMaterials ? "is-active" : ""
            }`}
          >
            {`${
              MaterialsBucket.fields.Title.value
                ? MaterialsBucket.fields.Title.value
                : "MATERIALS"
            }`}
          </button>
          <button
            onClick={this.handleClickInstruction}
            className={`craft-details__button--btn ${
              showInstructions ? "is-active" : ""
            }`}
          >
            {`${
              InstructionsBucket.fields.Title.value
                ? InstructionsBucket.fields.Title.value
                : "INSTRUCTIONS"
            }`}
          </button>
        </div>
      );
    }
    return btnLayout;
  };
  render() {
    const { CraftTitle, CraftDescription, Notes } = this.props.fields || {};

    const { showMaterials, showInstructions } = this.state;
    return (
      <div className="craft-details font-regular">
        <BaseComponent margin="standard">
          <div className="container">
            {CraftTitle && CraftTitle.value && (
              <h1 className="craft-details__title">
                <Text field={CraftTitle} />
              </h1>
            )}
            {this.renderPrep()}
            {CraftDescription && CraftDescription.value && (
              <div className="craft-details__description">
                <RichText field={CraftDescription} />
              </div>
            )}
            {this.renderButtons(showMaterials, showInstructions)}
            {this.renderMaterials(showMaterials)}
            {this.renderInstruction(showInstructions)}
            {Notes && Notes.value && (
              <div className="craft-details__notes">
                <div className="craft-details__notes--header">Notes</div>
                <div className="craft-details__notes--description">
                  <RichText field={Notes} />
                </div>
              </div>
            )}
          </div>
        </BaseComponent>
      </div>
    );
  }
}

export default FeatureCraftCraftDetails;

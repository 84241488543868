import React from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";

const FeatureParkingValetParking = props => {
  const {
    ValetRateDescription: valetRateDescription = null,
    OpeningHoursLabel: openingHoursLabel = null,
    ValetServices: valetServices = null
  } = props.fields || {};
  const getClass = val => {
    return val ? "darken" : "normal";
  };
  return (
    <div className="parking-valetparking-container">
      <div className="content-wrapper">
        <div className="valet-header">
          <RichText field={valetRateDescription} />
        </div>
        <div className="valet-opening-header">
          <Text field={openingHoursLabel} />
        </div>
        {valetServices && valetServices.length && valetServices.length > 0
         && valetServices[0] && valetServices[0].fields && valetServices[0].fields.Schedule
         && valetServices[0].fields.Schedule.fields && (
          <div className="valet-list-wrapper">
            <div className="valet-list">
              <input type="checkbox" id="toggle1" className="toggle" />
              <label className="title" htmlFor="toggle1">
                <Text field={valetServices[0].fields.Schedule.fields.day} />{" "}
                <Text field={valetServices[0].fields.Schedule.fields.time} />
              </label>
              <div className="content">
                <table className="valet-table normal">
                  <tbody>
                    {valetServices.slice(1).map((item, index) => {
                      const {
                        day: day = null,
                        time: time = null,
                        holiday: holiday = null
                      } = (item && item.fields && item.fields.Schedule && item.fields.Schedule.fields) 
                        ? item.fields.Schedule.fields : {};
                      return (
                        <tr
                          key={`trID-${index}`}
                          className={getClass(holiday.value)}
                        >
                          <td>
                            <Text field={day} />
                          </td>
                          <td>
                            <Text field={time} />
                          </td>
                          <td>
                            {holiday && holiday.value && (
                              <div className="btnevent">
                                <Text field={holiday} />
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <div className="valet-table mobile">
                  {valetServices.slice(1).map((item, index) => {
                    const {
                      day: day = null,
                      time: time = null,
                      holiday: holiday = null
                    } = (item && item.fields && item.fields.Schedule && item.fields.Schedule.fields) 
                      ? item.fields.Schedule.fields : {};
                    return (
                      <div className="table-item-wrapper" key={`trID-${index}`}>
                        <div
                          className={`table-item ${getClass(holiday.value)}`}
                        >
                          <div className="item">
                            <Text field={day} />
                          </div>
                          <div className="item">
                            <Text field={time} />
                          </div>
                          {holiday && holiday.value && (
                            <div className="item">
                              <div className="btnevent">
                                <Text field={holiday} />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeatureParkingValetParking;

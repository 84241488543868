import React from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import defaultImg from "../../../../assets/img/placeholders/gray.png";
import RiseAndFade from "../../../Common/Animations/RiseAndFade";
import GrayBanner from "../../../Common/GrayBanner";
import { valueHelper } from "../../../../helper/Animations";

const FeatureStorefrontCategoryDiscoverStores = props => {
  const { RetailerList: retailerList = null } = props.fields || {};

  if (retailerList && retailerList.length) {
    return (
      <BaseComponent margin="standard">
        <div className="categorydiscover-wrapper">
          <GrayBanner fields={props.fields} />
          <div className="categorydiscover-tile-wrapper">
            <div className="container">
              <div className="row">
                {retailerList &&
                  retailerList.map((listItem, index) => {
                    const {
                      RetailerPageHref = null,
                      Logo = null,
                      RetailerName = null
                    } = listItem.fields || {};

                    return (
                      <div className="col-8 col-md-2" key={`tileID-${index}`}>
                        <RiseAndFade position={valueHelper(index, 5)}>
                          <div className="animation-range">
                            <div
                              key={`myId-${index}`}
                              className="categorydiscover-tile"
                            >
                              <a
                                href={
                                  RetailerPageHref && RetailerPageHref.value
                                    ? RetailerPageHref.value
                                    : ""
                                }
                              >
                                <div className="categorydiscover-image">
                                  {Logo && Logo.value && Logo.value.src ? (
                                    <Image
                                      media={{ ...Logo.value, src: defaultImg }}
                                      data-src={Logo.value.src}
                                      className="lazyload"
                                    />
                                  ) : (
                                    <div className="no-logo">
                                      <Text field={RetailerName} />
                                    </div>
                                  )}
                                </div>
                              </a>
                            </div>
                            <div className="categorydiscover-tile-title">
                              <a
                                href={
                                  RetailerPageHref && RetailerPageHref.value
                                    ? RetailerPageHref.value
                                    : ""
                                }
                              >
                                <Text field={RetailerName} />
                              </a>
                            </div>
                          </div>
                        </RiseAndFade>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  } else {
    return "";
  }
};
export default FeatureStorefrontCategoryDiscoverStores;

import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const FeatureOpeningHoursOpeningHoursHeader = props => {
  const {
    openingHoursHeadline: openingHoursHeadline = null,
    openingState: openingState = null
  } = props.fields;
  return (
    <div className="openinghours-header">
      <div className="container">
        <div className="content-wrapper">
          <div className="content-header-wrapper">
            {openingHoursHeadline.value && (
              <div className="header-title">
                <Text field={openingHoursHeadline} />
              </div>
            )}
            {openingState.value && (
              <div className="header-sub">
                <Text field={openingState} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureOpeningHoursOpeningHoursHeader;

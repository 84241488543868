import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import GrayBanner from "../../../Common/GrayBanner";
import RiseAndFade from "../../../Common/Animations/RiseAndFade";
import { valueHelper } from "../../../../helper/Animations";

const FeatureCategoryRelatedSubcategory = props => {
  const { RelatedSubcategories = null } = props.fields || {};

  if (RelatedSubcategories && RelatedSubcategories.length) {
    return (
      <BaseComponent margin="standard">
        <div className="relatedsubcategory-wrapper">
          <GrayBanner fields={props.fields} />
          <div className="relatedsubcategory-tile-wrapper">
            <div className="container">
              <div className="row">
                {RelatedSubcategories.map((item, index) => {
                  const { Icon = null, Label = null, PageUrl = null } =
                    item.fields || {};
                  if (
                    Icon &&
                    Icon.value &&
                    Label &&
                    Label.value &&
                    PageUrl &&
                    PageUrl.value
                  ) {
                    return (
                      <div className="col-8 col-md-2" key={`tileID-${index}`}>
                        <RiseAndFade position={valueHelper(index, 5)}>
                          <div
                            key={`myId-${index}`}
                            className="relatedsubcategory-tile"
                          >
                            <a href={PageUrl.value}>
                              <div className="relatedsubcategory-icon">
                                <i className={`icon icon-${Icon.value}`} />
                              </div>
                              <div className="relatedsubcategory-label">
                                <Text field={Label} />
                              </div>
                            </a>
                          </div>
                        </RiseAndFade>
                      </div>
                    );
                  } else return null;
                })}
              </div>
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  } else return null;
};

export default FeatureCategoryRelatedSubcategory;

import React, { Fragment } from "react";
import defaultImg from "../../../../assets/img/placeholders/gray.png";
import { Text, Image, Link } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import Badge from "../../../Common/Badge";
import Details from "../../../Common/Details";
import ScrollAnimation from "react-animate-on-scroll";
import RiseAndFade from "../../../Common/Animations/RiseAndFade";
import { valueHelper } from "../../../../helper/Animations";

const FeaturePromotionsCampaignFeaturedWhatsOn = props => {
  const { Title = null, Subtitle = null, WhatsOnList = null} =
    props.fields || {};

  return (
    <div className="featuredWhatsOn font-regular">
      {WhatsOnList && WhatsOnList.length > 0 && (
        <BaseComponent margin="standard">
          <div className="featuredWhatsOn__header">
            <div className="container">
              <div className="featuredWhatsOn__header--container col-12 col-lg-11">
                <ScrollAnimation
                  animateIn="animate__animated animate__fadeIn"
                  animateOnce={true}
                >
                  {Title &&
                    Title.value &&
                    (Title.value.href || Title.value.text) && (
                      <div className="featuredWhatsOn__header--title">
                        {Title.value.href ? (
                          <Link field={Title} />
                        ) : (
                          Title.value.text
                        )}
                      </div>
                    )}
                  {Subtitle &&
                    Subtitle.value &&
                    (Subtitle.value.href || Subtitle.value.text) && (
                      <div className="featuredWhatsOn__header--subtitle">
                        {Subtitle.value.href ? (
                          <Link field={Subtitle} />
                        ) : (
                          Subtitle.value.text
                        )}
                      </div>
                    )}
                </ScrollAnimation>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="featuredWhatsOn__tile row">
              {WhatsOnList.map((item, index) => {
                const {
                  Name = null,
                  Type = null,
                  Location = null,
                  LocationLink = null,
                  StartDate = null,
                  EndDate = null,
                  ScheduleTime = null,
                  Thumbnail = null,
                  Link = null,
                  StorefrontId = null,
                  IsRetailerContent = null,
                  IsLogoAsThumbnail = null,
                  Retailer = null,
                  HidePublishDate = null
                } = item.fields || {};
                let thumbnailLayout;

                const badgeLayout = (
                  <div className="featuredWhatsOn__tile--image-badge">
                    <Badge
                      type="list"
                      startDate={
                        StartDate && StartDate.value ? StartDate.value : ""
                      }
                      endDate={EndDate && EndDate.value ? EndDate.value : ""}
                    />
                  </div>
                );

                thumbnailLayout = (
                  <div>
                    {Thumbnail && Thumbnail.value && Thumbnail.value.src ? (
                      <div className="include_logo_padding">
                        <Image
                          media={{
                            ...Thumbnail.value,
                            src: defaultImg
                          }}
                          data-src={Thumbnail.value.src}
                          className="featuredWhatsOn__tile--image-img lazyload"
                        />
                      </div>
                    ) : (
                      <Fragment>
                        {IsRetailerContent && IsRetailerContent.value && (
                          <div className="display_retailer_name">
                            <Text field={Retailer} />
                          </div>
                        )}
                        <img
                          alt="default"
                          src={defaultImg}
                          className="featuredWhatsOn__tile--image-img"
                        />
                      </Fragment>
                    )}
                    {badgeLayout}
                  </div>
                );

                return (
                  <div
                    key={`featuredWhatsOn-${index}`}
                    className="featuredWhatsOn__tile--column col-lg-4"
                  >
                    <RiseAndFade position={valueHelper(index, 3)}>
                      <div className="animation-range">
                        <div
                          className={`featuredWhatsOn__tile--image-wrapper ${
                            IsLogoAsThumbnail && IsLogoAsThumbnail.value
                              ? "display_retailer_logo"
                              : ""
                          }`}
                        >
                          {Link && Link.value ? (
                            <a href={Link.value}>{thumbnailLayout}</a>
                          ) : (
                            thumbnailLayout
                          )}
                        </div>
                        {Type && Type.value && (
                          <div className="featuredWhatsOn__tile--type">
                            <Text field={Type} />
                          </div>
                        )}
                        <div className="featuredWhatsOn__tile--title">
                          {Link && Link.value ? (
                            <a href={Link.value}>
                              <Text field={Name} />
                            </a>
                          ) : (
                            <Text field={Name} />
                          )}
                        </div>
                      </div>
                      <div className="featuredWhatsOn__tile--metadata">
                        {Location && Location.value && (
                          <Details
                            iconValue="location"
                            linkUrl={
                              LocationLink && LocationLink.value
                                ? LocationLink.value
                                : ""
                            }
                            linkName={Location}
                            size="24"
                            mapInfo={
                              StorefrontId && StorefrontId.value
                                ? StorefrontId.value
                                : ""
                            }
                          />
                        )}
                        {HidePublishDate && !HidePublishDate.value && ScheduleTime && ScheduleTime.value && (
                            <Details
                              iconValue="time"
                              linkName={ScheduleTime}
                              size="24"
                            />
                          )}
                      </div>
                    </RiseAndFade>
                  </div>
                );
              })}
            </div>
          </div>
        </BaseComponent>
      )}
    </div>
  );
};

export default FeaturePromotionsCampaignFeaturedWhatsOn;

import React from "react";
import {
  Text,
  Link,
  Image,
  Placeholder
} from "@sitecore-jss/sitecore-jss-react";
import Badge from "../../../Common/Badge";
import Details from "../../../Common/Details";
import defaultImg from "../../../../assets/img/placeholders/gray.png";

const FeaturePromotionsCategoryHeader = props => {
  const {
    Category: category = null,
    StoreDirectoryCta: storeDirectoryCta = null,
    Event: event = null
  } = props.fields || {};

  return (
    <div className="category-header-wrapper">
      <div className="container">
        <div className="d-flex justify-content-between align-items-end mb-4">
          <div className="category-header-title">
            <Text field={category} />
          </div>
          <div className="category-header-link">
            <i className="icon icon-stores" />
            <Link className="mx-2" field={storeDirectoryCta} />
            <i className="icon icon-arrow-right" />
          </div>
        </div>

        {event && (
          <div className="category-header-hero-wrapper">
            <Image
              media={{ ...event.fields.Image.value, src: defaultImg }}
              data-src={event.fields.Image.value.src}
              className="category-header-hero-img img-fluid lazyload"
            />
            <div className="category-header-content-badge">
              {event.fields.StartDate && event.fields.StartDate.value && (
                <Badge type="hero" startDate={event.fields.StartDate.value} />
              )}
            </div>
            <div className="category-header-content">
              <div className="row">
                <div className="col-12 col-md-8 col-lg-8 col-xl-8">
                  <div className="category-header-content-title">
                    <Text field={event.fields.Description} />
                  </div>

                  <div className="category-header-content-details">
                    <Details
                      iconValue="location"
                      linkName={event.fields.Location}
                      size="24"
                    />
                    <Details
                      iconValue="time"
                      linkName={event.fields.ScheduleTime}
                      size="24"
                    />
                  </div>
                </div>

                <div className="col-12 col-md-4 col-lg-4 col-xl-4">
                  <div className="quicklinks-wrapper">
                    <Placeholder
                      name="jss-category-header-right"
                      rendering={props.rendering}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default FeaturePromotionsCategoryHeader;

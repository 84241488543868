import React, { Component } from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import ContentTileList from "../../../Common/ContentTileList";
import defaultImg from "../../../../assets/img/placeholders/gray.png";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import LazyLoading from "../../../Common/LazyLoading";
import { valueHelper } from "../../../../helper/Animations";
import Subnav from "../../../Common/Subnav";

class FeatureLifestyleLifestyleListingFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LifestyleListData: {
                pageId: "",
                dbName: "",
                pageSize: 1,
                filter_contenttag: [],
            },
            LifestyleListing: [],
            currentPage: 1,
            totalCount: 0,
            showLoader: true,
            isScrolling: false,
            hasMoreItems: true
        };
    }

    componentDidMount() {
        let contextInfo = window.document.getElementsByName("ContextItem")[0];
        let pageId = contextInfo.value;
        let dbName = contextInfo.dataset.targetDb;
        let contentTagCategory = "";
        let contentTagCategoryQSName = "filter_tag";
        contentTagCategory = this.getUrlParameter(contentTagCategoryQSName);
        let contentTagCategoryList = [];
        if (contentTagCategory !== "") {
            contentTagCategoryList = contentTagCategory.split(",");
        }
        this.setState(
            {
                ...this.state,
                LifestyleListData: {
                    pageId: pageId,
                    dbName: dbName,
                    pageSize: 12,
                    filter_contenttag: contentTagCategoryList,
                }
            },
            () => this.getLifestyleList(1)
        );
    }

    getUrlParameter(querystringParam) {
        querystringParam = querystringParam
            .replace(/[\[]/, "\\[")
            .replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + querystringParam + "=([^&#]*)");
        var results =
            window && window.location && window.location.search
                ? regex.exec(window.location.search)
                : null;
        return results === null
            ? ""
            : decodeURIComponent(results[1].replace(/\+/g, " "));
    }

    getLifestyleList = async (pageNumber, isInfiniteScroll) => {
        const {
            pageId,
            dbName,
            pageSize,
            filter_contenttag,
        } = this.state.LifestyleListData;

        const data = {
            pageId,
            dbName,
            pageNumber,
            pageSize,
            filter_contenttag: filter_contenttag.toString(),
        };

        if (isInfiniteScroll) {
            this.setState({ isScrolling: true });
        } else {
            this.setState({ showLoader: true });
        }

        const url = "/api/sitecore/lifestyle/GetLifestyle";
        axios({
            url,
            method: "GET",
            params: data
        })
            .then(responseRaw => {
                let response = responseRaw.data ? responseRaw.data : null;
                if (response && response.Lifestyle) {
                    const lastPage = Math.ceil(response.TotalCount.value / 12);
                    const list =
                        pageNumber > 1
                            ? this.state.LifestyleListing.concat(response.Lifestyle)
                            : response.Lifestyle;
                    const totalCount =
                        response.TotalCount && response.TotalCount.value
                            ? response.TotalCount.value
                            : 0;
                    const page =
                        response.Page && response.Page.value ? response.Page.value : 1;

                    this.setState({
                        ...this.state,
                        LifestyleListing: list,
                        totalCount: totalCount,
                        currentPage: page,
                        hasMoreItems: page == lastPage ? false : true
                    });
                }
                this.setState({
                    showLoader: false,
                    isScrolling: false
                });
            })
            .catch(error => {
                // just handle error
                console.log(error);
                this.setState({
                    showLoader: false,
                    isScrolling: false
                });
            });
    };

    handleClickCategory = event => {
        const element = event.target;
        const elementId = element.id;
        const allCategories = element.parentNode.firstChild
        const elementContent = element.textContent.toLowerCase()

        let tagList = this.state.LifestyleListData.filter_contenttag;
        if (/is-active/.test(element.classList.value)) {
            tagList = tagList.filter(function (arrValue) {
                return elementContent.indexOf(arrValue) < 0; //return elementContent instead of ID
            });
        } else if (!elementId) {
            tagList.length = 0;
        } else tagList.push(elementContent);

        this.setState(
            {
                LifestyleListData: {
                    ...this.state.LifestyleListData,
                    filter_contenttag: tagList
                },
                currentPage: 1
            },
            () => this.getLifestyleList(1)
        );
        // remove is-active on 'All Categories' if elementId is true
        if (elementId) if (/is-active/.test(allCategories.classList.value)) allCategories.classList.toggle("is-active");
        element.classList.toggle("is-active");
    };

    loadLifestyleList() {
        this.getLifestyleList(this.state.currentPage + 1, true);
    }

    renderCategories(ContentTags) {
        const { filter_contenttag } = this.state.LifestyleListData;
        // Add 'All Categories' to Category Tag List
        let updatedContentTags = [{
            "fields": {
                "Name": {
                    "value": "All"
                },
                "Id": {
                    "value": ""
                }
            }
        }, ...ContentTags]
        return (
            <div className="lifestyle-listing__category">
                <div className="lifestyle-listing__category--label">Showing:</div>
                <ul className="lifestyle-listing__category--filter">
                    {updatedContentTags.map((item, index) => {
                        const { Name = null, Id = null } = item.fields;
                        let isActive = "";
                        if (filter_contenttag.length > 0) {
                            filter_contenttag.forEach(function (item, index) {
                                if (item == Name.value.toLowerCase()) {
                                    isActive = "is-active";
                                }
                            });
                        }
                        if (index < 1 && filter_contenttag.length < 1) isActive = "is-active"; //initially set 'All Categories' as active if no filters selected
                        return (
                            <li
                                key={`category-${Id.value}-${index}`}
                                className={`lifestyle-listing__category--filter-list ${isActive}`}
                                id={Id && Id.value ? Id.value : ""}
                                onClick={this.handleClickCategory}
                            >
                                <Text field={Name} />
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }

    renderLifestyles(LifestyleListing) {
        const { NoResultsText } = this.props.fields || {};
        let LifestyleLayout;
        if (LifestyleListing && LifestyleListing.length > 0) {
            LifestyleLayout = (
                <div className="content-tile-list-wrapper">
                    <div className="row">
                        {LifestyleListing.map((item, index) => {
                            const {
                                Title = null,
                                Thumbnail = null,
                                Link = null,
                                PageType = null
                            } = item;

                            return (
                                <ContentTileList
                                    index
                                    name={Title}
                                    nameLink={Link}
                                    thumbnail={Thumbnail}
                                    pageType={PageType} //new
                                    position={valueHelper(index, 4, 3)}
                                />
                            );
                        })}
                    </div>
                </div>
            );
        } else {
            LifestyleLayout = (
                <div className="lifestyle-listing__content--noresult">
                    {NoResultsText && NoResultsText.value ? (
                        <Text field={NoResultsText} />
                    ) : (
                        "No result. Please try again."
                    )}
                </div>
            );
        }
        return LifestyleLayout;
    }

    render() {
        const { ContentTags = [] } = this.props.fields || {};

        const { showLoader, isScrolling, LifestyleListing } = this.state;

        return (
            <div className="lifestyle-listing font-regular">
                <BaseComponent margin="standard">
                    {/* {Occasions && Occasions.length > 0 && this.renderMeals(Occasions)} */}

                    <div className="container">
                        {ContentTags && ContentTags.length > 0 && this.renderCategories(ContentTags)}
                        <InfiniteScroll
                            style={{ height: "auto", overflow: "auto" }}
                            dataLength={LifestyleListing.length}
                            next={this.loadLifestyleList.bind(this)}
                            hasMore={this.state.hasMoreItems}
                        >
                            <div className="lifestyle-listing__content--wrapper">
                                {!showLoader && this.renderLifestyles(LifestyleListing)}
                                {(showLoader || isScrolling) && <LazyLoading />}
                            </div>
                        </InfiniteScroll>
                    </div>
                </BaseComponent>
            </div>
        );
    }
}

export default FeatureLifestyleLifestyleListingFilter;

import React from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import defaultImg from "../../../../assets/img/placeholders/gray.png";

const FeatureOpeningHoursMajorRetailersAndRestaurantsOpeningHours = props => {
  let { FeaturedGroups: FeaturedGroups = null } = props.fields;
  return (
    <div className="openinghours-wrapper">
      {FeaturedGroups &&
        FeaturedGroups.length &&
        FeaturedGroups.map((item, index) => {
          let {
            Headline: Headline = null,
            RetailerList: RetailerList = null
          } = item.fields;
          if (
            Headline &&
            Headline.value &&
            Headline.value.length &&
            RetailerList &&
            RetailerList.length
          ) {
            return (
              <div
                className="openinghours-retailers"
                key={`openingHoursModuleId-${index}`}
              >
                <div className="openinghours-retailerlabel">
                  <Text field={Headline} />
                </div>
                {RetailerList &&
                  RetailerList.length &&
                  RetailerList.map((retailerItem, retailerIndex) => {
                    let {
                      RetailerName: RetailerName = null,
                      RetailerLogo: RetailerLogo = null,
                      Schedules: Schedules = null,
                      OpeningToday: OpeningToday = null
                    } = retailerItem.fields;

                    let retailerId = 0;
                    if (index < 1) {
                      retailerId = index + retailerIndex;
                    } else {
                      retailerId = index + retailerIndex + 3;
                    }
                    return (
                      <div
                        className="openinghours-option"
                        key={`retailerId-${retailerId}`}
                      >
                        <input
                          type="checkbox"
                          id={`majorRetailerId-${RetailerName.value}-${retailerId}`}
                          className="openinghours-toggle"
                        />
                        <label
                          className="openinghours-title"
                          htmlFor={`majorRetailerId-${RetailerName.value}-${retailerId}`}
                        >
                          <div className="row">
                            <div className="col col-auto">
                              <div className="openinghours-retailerimagecontainer">
                                <Image
                                  media={{
                                    ...RetailerLogo.value,
                                    src: defaultImg
                                  }}
                                  data-src={RetailerLogo.value.src}
                                  className="openinghours-retailerimage lazyload"
                                />
                              </div>
                            </div>
                            <div className="col col-md-6">
                              <div className="openinghours-retailername">
                                <Text field={RetailerName} />
                              </div>
                              <div className="openinghours-retailerinfo">
                                <Text field={OpeningToday} />
                              </div>
                            </div>
                          </div>
                        </label>
                        <div className="openinghours-content">
                          <div className="normal">
                            <table className="table">
                              <tbody>
                                {Schedules &&
                                  Schedules.length &&
                                  Schedules.slice(1).map(
                                    (scheduleItem, scheduleIndex) => {
                                      const {
                                        Day: day = null,
                                        Time: time = null,
                                        DineUntilTime: dine = null,
                                        Holiday: holiday = null
                                      } = scheduleItem.fields;
                                      return (
                                        <tr
                                          key={`schduleId-${scheduleIndex}`}
                                          className={
                                            holiday && holiday.value
                                              ? "openinghours-holiday"
                                              : ""
                                          }
                                        >
                                          <td>
                                            <Text field={day} />
                                          </td>
                                          <td>
                                            <Text field={time} />
                                            {dine && dine.value ? (
                                              <React.Fragment>
                                                <br /> <Text field={dine} />
                                              </React.Fragment>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                          <td>
                                            <div
                                              className={
                                                holiday && holiday.value
                                                  ? "openinghours-btnevent"
                                                  : ""
                                              }
                                            >
                                              <Text field={holiday} />
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </table>
                          </div>
                          <div className="mobile">
                            {Schedules &&
                              Schedules.length &&
                              Schedules.map((scheduleItem, scheduleIndex) => {
                                const {
                                  Day: day = null,
                                  Time: time = null,
                                  DineUntilTime: dine = null,
                                  Holiday: holiday = null
                                } = scheduleItem.fields;
                                return (
                                  <div
                                    key={`scheduleMobileId-${scheduleIndex}`}
                                    className={
                                      holiday && holiday.value
                                        ? "item openinghours-holiday"
                                        : "item"
                                    }
                                  >
                                    <div className="fontandweight">
                                      <Text field={day} />
                                    </div>
                                    <div>
                                      <Text field={time} />
                                      {dine && dine.value ? (
                                        <React.Fragment>
                                          <br /> <Text field={dine} />
                                        </React.Fragment>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div
                                      className={
                                        holiday && holiday.value
                                          ? "openinghours-btnevent"
                                          : ""
                                      }
                                    >
                                      <Text field={holiday} />
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            );
          } else return null;
        })}
    </div>
  );
};

export default FeatureOpeningHoursMajorRetailersAndRestaurantsOpeningHours;

import React, { Component } from "react";

class BaseComponent extends Component {
  setMargin = () => {
    if (this.props.margin === "standard") {
      this.margin = "standard-margin";
    } else if (this.props.margin === "custom") {
      this.margin = "custom-margin";
    } else if (this.props.margin === "campaign") {
      this.margin = "campaign-margin";
    }

    return this.margin;
  };

  render() {
    return (
      <div
        className={`base-component ${this.setMargin()} ${this.props.className}`}
      >
        {this.props.children}
      </div>
    );
  }
}

export default BaseComponent;

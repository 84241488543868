import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";

const FoundationLayoutTwoColumn7030 = props => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-9 col-xl-8 two-column-layout two-column-layout--left">
          <Placeholder
            name="jss-two-column-70-30-left"
            rendering={props.rendering}
            params={props.params}
          />
        </div>
        <div className="col-12 col-lg-3 col-xl-4 two-column-layout two-column-layout--right">
          <Placeholder
            name="jss-two-column-70-30-right"
            rendering={props.rendering}
          />
        </div>
      </div>
    </div>
  );
};

export default FoundationLayoutTwoColumn7030;

import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const FeatureHeaderGenericPageHeader = props => {
  const {
    HeaderButton = null,
    HeaderTitle = null,
    LeftCtaLink = null,
    RightCtaLink = null
  } = props.fields || {};
  const { Name: LeftCtaName = null, LinkTarget: LeftCtaLinkTarget = null } =
    LeftCtaLink.fields || {};
  const {
    Name: RightCtaName = null,
    LinkTarget: RightCtaLinkTarget = null,
    Icon: RightCtaIcon = null
  } = RightCtaLink.fields || {};
  return (
    <div className="generic-pageheader-container">
      <div className="container content-wrapper">
        <div className="header-wrapper">
          <div className={`header-left
          ${RightCtaName && RightCtaName.value && RightCtaLinkTarget && RightCtaLinkTarget.value ? "has-btn-link" : ""}
          `}>
            {LeftCtaName &&
              LeftCtaName.value &&
              LeftCtaLinkTarget &&
              LeftCtaLinkTarget.value && (
                <div className="link">
                  <i className="icon icon-arrow-left mr-2" />
                  <a href={LeftCtaLinkTarget.value}>
                    <div className="label">
                      <Text field={LeftCtaName} />
                    </div>
                  </a>
                </div>
              )}

            <div className="main">
              {HeaderTitle && HeaderTitle.value && (
                <h1 className="title">
                  <Text field={HeaderTitle} />
                </h1>
              )}
              {HeaderButton && HeaderButton.value && (
                <div className="sub">
                  <Text field={HeaderButton} />
                </div>
              )}
            </div>
          </div>
          {RightCtaName &&
            RightCtaName.value &&
            RightCtaLinkTarget &&
            RightCtaLinkTarget.value && (
              <div className="header-right">
                <div className="spacer" />
                <div className="btnlink">
                  <i className={`icon icon-${RightCtaIcon.value} main`} />
                  <div className="link">
                    <a href={RightCtaLinkTarget.value}>
                      <Text field={RightCtaName} />
                    </a>
                  </div>

                  <i className="icon icon-arrow-right" />
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default FeatureHeaderGenericPageHeader;

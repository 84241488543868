import React from "react";
import { Text, Image, RichText } from "@sitecore-jss/sitecore-jss-react";
import defaultImg from "../../../../assets/img/placeholders/gray.png";
import Details from "../../../Common/Details";

const FeatureGenericContentGeneralSection = props => {
  const { SectionList: SectionList = null } = props.fields;

  if (SectionList && SectionList.length) {
    return (
      <div className="genericcontent-generalsection-container">
        <div className="content-wrapper">
          {SectionList.map((item, index) => {
            let {
              Icon: Icon = null,
              Title: Title = null,
              Description: Description = null,
              DirectionLink: DirectionLink = null,
              DirectionLabel: DirectionLabel = null,
              DirectionIcon: DirectionIcon = null
            } = item.fields;
            return (
              <div key={`myId-${index}`} className="content-item">
                <div className="item-heading">
                  <div className="heading-icon">
                    <Image
                      media={{ ...Icon.value, src: defaultImg }}
                      data-src={Icon.value.src}
                      className="lazyload"
                    />
                  </div>
                  <div className="heading-text">
                    <Text field={Title} />
                  </div>
                </div>
                <RichText className="item-description" field={Description} />
                {DirectionLink &&
                  DirectionLink.value &&
                  DirectionLabel &&
                  DirectionLabel.value && (
                    <div className="link">
                      <Details
                        iconValue="location"
                        linkUrl={DirectionLink && DirectionLink.value}
                        linkName={DirectionLabel}
                        size="24"
                      />
                    </div>
                  )}
              </div>
            );
          })}
        </div>
      </div>
    );
  } else return null;
};
export default FeatureGenericContentGeneralSection;

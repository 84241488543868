import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const NavLinkList = ({ data }) => {
    let navLinkListLayout = "";

    if (data && data.length > 0) {
        navLinkListLayout =
            <div className="nav-link-list font-regular">
                {data.map((item, index) => {
                    const {
                        LinkName,
                        LinkUrl
                    } = item;
                    return (
                        <div key={"nav-link-list" + index}>
                            <a className="nav-link-list__link"
                                href={LinkUrl && LinkUrl.value ? LinkUrl.value : ""}>
                                <Text field={LinkName} />
                            </a>
                        </div>
                    )
                })}
            </div>
    }
    return navLinkListLayout;
};

export default NavLinkList;

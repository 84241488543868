import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const NavOpeningHours = ({ data }) => {
  return (
    <div className="nav-openinghours-wrapper">
      {data.OpeningHoursList.map((item, index) => (
        <div key={`openingID-${index}`} className="opening">
          <div className="day">
            <Text field={item.Day} />
          </div>
          <div className="time">
            <Text field={item.OpeningHours} />
          </div>
        </div>
      ))}

      {data.HolidayGroupList.length > 0 && (
        <div className="special">
          <div className="heading">
            <Text field={data.UpcomingHolidaysTitle} />
          </div>

          {data.HolidayGroupList.map((group, index) => (
            <div key={`holidayGroupID-${index}`} className="content">
              <div className="holiday">
                <Text field={group.HolidayGroupTitle} />
              </div>
              <ul>
                {group.HolidaysList.map((holiday, index) => (
                  <li key={`holidayID-${index}`}>
                    <Text field={holiday.Holiday} />
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default NavOpeningHours;

import React from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";

const FeatureGenericContentTextBlock = props => {
  const { Title, Description } = props.fields;
  if (Title && Title.value && Description && Description.value) {
    return (
      <BaseComponent margin="standard">
        <div className="container">
          <div className="campaign-text-block-wrapper">
            <div className="title">
              <Text field={Title} />
            </div>
            <div className="description">
              <RichText field={Description} />
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  } else return null;
};

export default FeatureGenericContentTextBlock;

import React from "react";
import NavList from "../NavList";
import NavDiscoverStore from "../NavDiscoverStore";
import NavWhatsOn from "../NavWhatsOn";
import NavRichTextList from "../NavRichTextList";
import NavBestParking from "../NavBestParking";
import NavOpeningHours from "../NavOpeningHours";
import NavMovies from "../NavMovies";
import NavPopularTimes from "../NavPopularTimes";
import NavCampaign from "../NavCampaign";
import NavRecipe from "../NavRecipe";
import NavCarParks from "../NavCarParks";
import NavLinkList from "../NavLinkList";

const NavComponent = ({ type, item, centre, size }) => {
  let component;
  switch (type) {
    case "NavList":
      component = (
        <NavList
          title={{
            name: item.ComponentTitle,
            url: item.TitleUrl ? item.TitleUrl.value : ""
          }}
          data={item.Categories}
        />
      );
      break;

    case "NavDiscoverStore":
      component = (
        <NavDiscoverStore title={item.ComponentTitle} data={item.Stores} />
      );
      break;

    case "NavWhatsOn":
      let spotlight = item.WithSpotlight ? item.Spotlight : null;

      if (item && item.Promotions && item.Promotions.length > 0) {
        component = (
          <NavWhatsOn
            title={{
              name: item.ComponentTitle,
              url: item.LandingPage ? item.LandingPage.value : ""
            }}
            spotlight={spotlight}
            promotions={item.Promotions}
          />
        );
      } else {
        component = "";
      }

      break;

    case "NavCampaigns":
      if (item && item.Campaigns && item.Campaigns.length) {
        component = (
          <NavCampaign
            title={{
              name: item.ComponentTitle,
              url: item.LandingPage ? item.LandingPage.value : ""
            }}
            campaigns={item.Campaigns}
          />
        );
      } else {
        component = "";
      }

      break;

    case "NavRichTextList":
      component = (
        <NavRichTextList
          title={item.ComponentTitle}
          richTextList={item.RichTextList}
        />
      );
      break;

    case "NavBestParking":
      component = (
        <NavBestParking
          title={{
            name: item.ComponentTitle,
            url: item.TitleUrl ? item.TitleUrl.value : ""
          }}
          centre={centre}
          retailers={item.Retailers}
        />
      );
      break;

    case "NavOpeningHours":
      component = <NavOpeningHours data={item} />;
      break;

    case "NavMovies":
      component = (
        <NavMovies
          title={{
            name: item.ComponentTitle,
            url: item.TitleUrl ? item.TitleUrl.value : ""
          }}
          data={item.MovieList}
        />
      );
      break;

    case "NavPopularTimes":
      component = <NavPopularTimes data={item} />;
      break;

    case "NavRecipes":
      component = (
        <NavRecipe
          title={{
            name: item.ComponentTitle,
            url: item.LandingPage ? item.LandingPage.value : ""
          }}
          recipes={item.Recipes}
          size={size}
        />
      );
      break;

    case "NavCarParks":
      component = <NavCarParks data={item} />;
      break;

    case "NavLinkList":
      component = <NavLinkList data={item} />;
      break;

    default:
      component = "";
      break;
  }

  return <React.Fragment>{component}</React.Fragment>;
};

export default NavComponent;

import React, { Component, Fragment } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import axios from "axios";
import BaseComponent from "../../../Base/BaseComponent";
import Details from "../../../Common/Details";
import LazyLoading from "../../../Common/LazyLoading";
import InfiniteScroll from "react-infinite-scroll-component";

class FeatureStoreDirectoryMiniStoreDirectory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageId: null,
      dbName: null,
      retailers: null,
      preview: null,
      noSelection: true,
      noResults: false,
      showContentLoader: false,
      currentPage: 1,
      totalCount: 0,
      showLoader: true,
      isScrolling: false,
      hasMoreItems: true
    };
  }

  componentDidMount() {
    let contextInfo = window.document.getElementsByName("ContextItem")[0];
    const {
      BrauzFormId = null
    } = this.props.fields || {};

    this.setState(
      {
        pageId: contextInfo.value,
        dbName: contextInfo.dataset.targetDb
      },
      () => this.getRetailerList(1)
    );
    if (window) {
      window.addEventListener("resize", this.handleScroll);
    }

    if (
      BrauzFormId &&
      BrauzFormId.value
    ) {
      const brauzPackageURL =
        "https://d3aq2u4yw77ivo.cloudfront.net/retailer-appointment/public/1.0/bookAStylist.prod.js";
      this.loadScript(brauzPackageURL, () => {
        window.Brauz.initializeBrauzBookAStylist(BrauzFormId.value);
      });
    }
  }

  loadScript(url = "", callback = () => {}) {
    if (url) {
      const script = document.createElement("script");
      script.setAttribute("type", "text/javascript");
      script.setAttribute("src", url);
      document.body.appendChild(script);
      script.onload = callback;
    }
  }

  handleScroll = () => {
    if (
      window &&
      window.outerWidth < 992 &&
      (this.state.showContentLoader || this.state.preview)
    ) {
      this.disableScroll();
    } else {
      this.enableScroll();
    }
  };
  handleClick = () => {
    if (window && window.outerWidth < 992) {
      this.disableScroll();
    }
  };

  enableScroll = () => {
    document.querySelector("html").style.overflowY = "auto";
  };
  disableScroll = () => {
    document.querySelector("html").style.overflowY = "hidden";
  };

  getRetailerList = async (pageNumber, isInfiniteScroll) => {
    const { pageId, dbName } = this.state;

    const params = {
      pageId,
      dbName,
      pageNumber,
      pageSize: 12
    };

    if (isInfiniteScroll) {
      this.setState({ isScrolling: true });
    }

    try {
      let response = await axios({
        method: "GET",
        url: `/api/sitecore/ministoredirectory/minisearch`,
        params
      });
      if (response && response.data && response.data.ResultList) {
        const { TotalCount, Page, ResultList } = response.data;
        const lastPage = Math.ceil(TotalCount.value / 12);
        const list =
          pageNumber > 1 ? this.state.retailers.concat(ResultList) : ResultList;
        const totalCount =
          TotalCount && TotalCount.value ? TotalCount.value : 0;
        const page = Page && Page.value ? Page.value : 1;

        this.setState({
          ...this.state,
          retailers: list,
          totalCount: totalCount,
          currentPage: page,
          hasMoreItems: page == lastPage ? false : true,
          isScrolling: false,
          showLoader: false
        });
      } else {
        this.setState({
          showLoader: false,
          noResults: true
        });
      }
    } catch (error) {
      // handle error
      console.log(error);
      this.setState({
        showLoader: false,
        noResults: true
      });
    }
  };

  loadRetailerList() {
    this.getRetailerList(this.state.currentPage + 1, true);
  }

  getRetailerContent = async itemId => {
    const { pageId, dbName } = this.state;

    const params = {
      pageId,
      dbName,
      itemId: (itemId && itemId.value) || null
    };
    this.setState({ noSelection: false, showContentLoader: true });
    try {
      let response = await axios({
        method: "GET",
        url: `api/sitecore/ministoredirectory/getstorecontent`,
        params
      });
      if (response && response.data) {
        this.setState({ preview: response.data, showContentLoader: false });
      }
    } catch (error) {
      // handle error
      this.setState({
        showContentLoader: false
      });
    }
  };

  renderRetailerList = (retailers, isScrolling) => {
    const { NoResultsText } = this.props.fields;
    if (retailers && retailers.length) {
      return (
        <div className="col-12 col-lg-5">
          {retailers.map((item, index) => {
            const {
              ItemId: itemId = null,
              OpeningTime: openingTime = null,
              RetailerName: retailerName = null,
              Logo: logo = null
            } = item;
            return (
              <div
                className="retailers-list"
                key={`SDItem${index}`}
                onClick={() => {
                  this.handleClick();
                  this.getRetailerContent(itemId);
                }}
              >
                {logo && logo.value && logo.value.src ? (
                  <div className="logo with-logo">
                    <img {...logo.value} className="lazyload" />
                  </div>
                ) : (
                  <div className="logo">
                    {this.getRetailerLogo(retailerName && retailerName.value)}
                  </div>
                )}
                <div className="info">
                  <div className="name">
                    <Text field={retailerName} />
                  </div>
                  <div className="hours">
                    <Text field={openingTime} />
                  </div>
                </div>
                <i className="icon icon-chevron-right" />
              </div>
            );
          })}
          {isScrolling && <LazyLoading />}
        </div>
      );
    } else {
      return (
        <div className="col-12">
          <div className="no-results">
            <Text field={NoResultsText} />
          </div>
        </div>
      );
    }
  };

  handleClickBrauzBtn = id => {
    window.Brauz_open_book_a_stylist_dialog_with_selected_store(id);
  };

  renderRetailerPreview = () => {
    const { preview, showContentLoader, noSelection, noResults } = this.state;
    const { NoSelectionText } = this.props.fields;
    if (!noSelection) {
      if (showContentLoader) {
        return (
          <Fragment>
            <div className="preview-blur" />
            <div className="retailer-preview-container col-lg-6 offset-lg-1">
              <div className="retailer-preview-loader">
                <LazyLoading />
              </div>
            </div>
          </Fragment>
        );
      } else {
        if (preview) {
          const {
            RetailerName = null,
            Logo = null,
            OpeningTime = null,
            OpeningHours = null,
            StoreLocation = null,
            StoreLocationLink = null,
            ItemId = null,
            PhoneNumber = null,
            ExternalLink = null,
            FacebookLink = null,
            InstagramLink = null,
            BookingIcon = null,
            BookingLabel = null,
            BookingUrl = null,
            HasBooking = null
          } = preview;

          return (
            <Fragment>
              <div className="preview-blur" />
              <div className="retailer-preview-container col-12 col-lg-6 offset-lg-1">
                <div className="retailer-content">
                  <div className="close-wrapper">
                    <i
                      className="icon icon-close d-lg-none"
                      onClick={() => {
                        this.enableScroll();
                        this.setState({ preview: null, noSelection: true });
                      }}
                    />
                  </div>
                  <div className="header">
                    <div className="name order-2 order-lg-1">
                      <Text field={RetailerName} />
                    </div>
                    {Logo && Logo.value && Logo.value.src ? (
                      <div className="logo order-1 order-lg-2">
                        <img {...Logo.value} className="lazyload" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {OpeningTime && OpeningTime.value ? (
                    <div className="opening-hours-wrapper">
                      <i className="icon icon-time" />
                      <div className="opening-time">
                        <Text field={OpeningTime} />
                      </div>
                    </div>
                  ) : OpeningHours && OpeningHours.length ? (
                    <div className="opening-hours-wrapper">
                      <i className="icon icon-time" />
                      <div className="opening-hours">
                        {OpeningHours.map((item, index) => {
                          const {
                            Day: day = null,
                            Time: primaryTime = null,
                            SecondaryTime: secondaryTime = null
                          } = item;
                          return (
                            <div
                              className="opening-hour-item"
                              key={`SDItemHour${index}`}
                            >
                              <div className="col-4 col-md-2 col-lg-3 day">
                                <Text field={day} />
                              </div>
                              <div className="col-6 col-md-3 col-lg-6 primary-time">
                                <Text field={primaryTime} />
                              </div>
                              {secondaryTime && secondaryTime.value && (
                                <div className="col-6 col-md-3 col-lg-6 secondary-time">
                                  <Text field={secondaryTime} />
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <Details
                    iconValue="location mr-2"
                    linkUrl={StoreLocationLink && StoreLocationLink.value}
                    linkName={StoreLocation}
                    size="24"
                    withViewOnMap={true}
                    mapInfo={ItemId && ItemId.value ? ItemId.value : ""}
                  />
                  {PhoneNumber && PhoneNumber.value && (
                    <Details
                      size="24"
                      iconValue="phone-call mr-2"
                      noLocation={true}
                      linkName={PhoneNumber}
                      linkUrl={`tel:${PhoneNumber.value}`}
                    />
                  )}
                  {ExternalLink && ExternalLink.value && (
                    <Details
                      size="24"
                      iconValue="globe mr-2"
                      noLocation={true}
                      linkName={ExternalLink}
                      linkUrl={ExternalLink.value}
                    />
                  )}
                  {FacebookLink && FacebookLink.value && (
                    <Details
                      size="24"
                      iconValue="facebook-2 mr-2"
                      noLocation={true}
                      linkName={FacebookLink}
                      linkUrl={FacebookLink.value}
                    />
                  )}
                  {InstagramLink && InstagramLink.value && (
                    <Details
                      size="24"
                      iconValue="instagram-2 mr-2"
                      noLocation={true}
                      linkName={this.getUsername(InstagramLink.value)}
                      linkUrl={InstagramLink.value}
                    />
                  )}
                  {HasBooking && HasBooking.value ? (
                    <div className="details-wrapper">
                      {BookingIcon && BookingIcon.value ? (
                          <i
                            className={`icon icon-${BookingIcon.value} mr-2`}
                            style={{ fontSize: "24px" }}
                          />
                          ) : (
                          <i
                            className="icon icon-calendar-2 mr-2"
                            style={{ fontSize: "24px" }}
                          />
                        )}
                          <button
                            onClick={() => this.handleClickBrauzBtn(ItemId.value)}
                          >
                            <Text field={BookingLabel} />
                          </button>
                    </div>
                   ) : BookingUrl && BookingUrl.value ? (
                    <div className="details-wrapper">
                      {BookingIcon && BookingIcon.value ? (
                          <i
                            className={`icon icon-${BookingIcon.value} mr-2`}
                            style={{ fontSize: "24px" }}
                          />
                          ) : 
                          <i
                            className="icon icon-calendar-2 mr-2"
                            style={{ fontSize: "24px" }}
                          />
                        }
                          <a href={BookingUrl.value} target="_blank">
                            <Text field={BookingLabel} />
                          </a>
                    </div>
                   ):""}
                </div>
              </div>
            </Fragment>
          );
        } else {
          return (
            <div className="retailer-preview-container col-12 col-lg-6 offset-lg-1">
              <div className="retailer-content">
                <div className="no-info">
                  Something went wrong. Please try again.
                </div>
              </div>
            </div>
          );
        }
      }
    } else {
      if (!noResults) {
        return (
          <div className="retailer-preview-container d-none d-lg-block col-lg-6 offset-lg-1">
            <div className="no-selection">
              <Text field={NoSelectionText} />
            </div>
          </div>
        );
      }
    }
  };

  getUsername = link => {
    const result = link.match(
      /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am)\/([A-Za-z0-9-_]+)/
    );
    if (result && result[1] && result[1] !== "explore") {
      return { value: "@" + result[1] };
    } else return null;
  };

  getRetailerLogo = retailerName => {
    const initials = retailerName
      .match(/\b(\w)/g)
      .join("")
      .slice(0, 2);

    return <div className="initials">{initials.toUpperCase()}</div>;
  };

  render() {
    const { showLoader, isScrolling, retailers } = this.state;
    return (
      <BaseComponent margin="standard">
        <div className="mini-storedirectory-container">
          <div className="container">
            <InfiniteScroll
              style={{ height: "auto", overflow: "auto" }}
              dataLength={retailers && retailers.length}
              next={this.loadRetailerList.bind(this)}
              hasMore={this.state.hasMoreItems}
              scrollThreshold={0.5}
            />
            {showLoader && <LazyLoading />}
            {!showLoader && (
              <div className="mini-storedirectory-wrapper row">
                {this.renderRetailerList(retailers, isScrolling)}

                {this.renderRetailerPreview()}
              </div>
            )}
          </div>
        </div>
      </BaseComponent >
    );
  }
}

export default FeatureStoreDirectoryMiniStoreDirectory;

import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import CarPark from "../../../../Feature/Parking/CarPark";

const NavCarParks = ({ data }) => {
    const {
        ComponentTitle = null,
        LiveParkingPage = null,
        LiveParkingLabel = null,
    } = data || {}
    return (
        <div className="nav-carpark font-regular">
            {ComponentTitle &&
                ComponentTitle.value &&
                <div className="nav-carpark__header">
                    <Text field={ComponentTitle} />
                </div>
            }
            <div className="nav-carpark__list">
                <CarPark fields={data} />
            </div>
            {LiveParkingPage &&
                LiveParkingPage.value &&
                <div className="nav-carpark__link">
                    <a href={LiveParkingPage.value}>
                        <span>
                            <Text field={LiveParkingLabel} />
                        </span>
                    </a>
                </div>
            }

        </div>
    );
};
export default NavCarParks;

import React, { Component } from "react";
import { renameElements } from "../../../helper/ArrayHelper";

function navigationResize() {
  const nav = document.getElementById("nav");
  const navItemMore = nav.querySelector("li.more");
  const navItems = Array.from(nav.querySelectorAll("li:not(.more)"));
  const overflowList = document.getElementById("overflow");

  let totalWidth = 0;
  navItems.forEach(item => {
    totalWidth += item.offsetWidth + 45;
  });
  let overflow = false;
  if (window.innerWidth > 991) { 
    if (totalWidth > nav.offsetWidth - navItemMore.offsetWidth) {
      overflow = true;
      for (let i = navItems.length - 1; i >= 0; i--) {
        const currentItem = navItems[i];
        if (totalWidth > nav.offsetWidth - navItemMore.offsetWidth) {
          totalWidth -= currentItem.offsetWidth;
          overflowList.appendChild(currentItem); 
          navItems.splice(i, 1); 
        }
      }
    }
  } else {
    while (overflowList.children.length > 0) {
      const overflowItem = overflowList.firstChild;
      nav.insertBefore(overflowItem, navItemMore); 
      navItems.push(overflowItem); 
    }
    overflow = false;
  }

  navItemMore.style.display = overflow ? "block" : "none";
}

class Subnav extends Component {
  componentDidMount() {
    navigationResize();
    window.addEventListener("resize", navigationResize);
  }

  renderAllTab = (selected, updateFilter, allTabQuery) => {
    return (
      <li
        name="listAll"
        className={`tab ${selected == "" || selected.toLowerCase() == "all" ? "selected" : ""
          }`}
        onClick={() => {
          updateFilter(allTabQuery);
        }}
      >
        <div>All</div>
      </li>
    );
  };

  redirectPage = link => {
    if (typeof window !== "undefined") {
      window.location.href = link.href;
    }
  };

  render() {
    const {
      selected,
      updateFilter,
      hasAllTab,
      allTabQuery,
      defaultName,
      defaultFilter,
      defaultActive,
      className
    } = this.props || {};
    let { tabs } = this.props || {};

    if (tabs && tabs.length) {
      tabs = renameElements(tabs, defaultName, defaultFilter, defaultActive);
      return (
        <div className={`header-sub-nav-wrapper ${className ? className : ""}`}>
          <div className="container">
            <ul id="nav">
              {hasAllTab &&
                this.renderAllTab(selected, updateFilter, allTabQuery)}

              {tabs.map((item, index) => {
                const { name = null, filter = null, active = null } =
                  item || {};
                return (
                  <li
                    key={`secondarynavID-` + index}
                    className={`${(selected &&
                      selected.toLowerCase() == filter.toLowerCase()) ||
                      (active && active === "true")
                      ? "selected"
                      : ""
                      }`}
                    onClick={() => {
                      hasAllTab
                        ? updateFilter(filter)
                        : this.redirectPage(filter);
                    }}
                  >
                    <div>{name}</div>
                  </li>
                );
              })}
              <li className="more">
                <div>More »</div>
                <ul id="overflow" />
              </li>
            </ul>
          </div>
        </div>
      );
    } else return null;
  }
}

export default Subnav;

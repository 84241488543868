import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const FeatureParkingParkingInformation = props => {
  const { Title: title = null, FreePark: freePark = null } = props.fields || {};
  if (title && title.value && freePark && freePark.value) {
    return (
      <div className="parking-information-container">
        <div className="container">
          <div className="content-wrapper">
            <div className="content-header-wrapper">
              <div className="header-title">
                <Text field={title} />
              </div>
              <div className="header-sub">
                <Text field={freePark} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default FeatureParkingParkingInformation;

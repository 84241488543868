import React, { Fragment } from "react";
import { Text, Link, Image, RichText } from "@sitecore-jss/sitecore-jss-react";
import defaultImg from "../../../../assets/img/placeholders/gray.png";

const WhatsOnResults = ({ title, data }) => {
  return (
    <div className="search-whatson">
      <div className="title">
        <Link field={title} />
        <i className="icon icon-arrow-right" />
      </div>
      <div className="row">
        {/* ------------------- WHATS ON ------------------- */}
        <div className="col col-auto">
          {data.map((item, index) => {
            const {
              PromotionLink: PromotionLink = null,
              Thumbnail: Thumbnail = null,
              PromotionType: PromotionType = null,
              PromotionDetails: PromotionDetails = null,
              IsRetailerContent = null,
              IsLogoAsThumbnail = null,
              Retailer
            } = item || {};
            return (
              <div
                key={`promID-${index}`}
                className="row no-gutters whatson-item"
              >
                <div
                  className={`col col-auto whatson-img ${
                    IsLogoAsThumbnail && IsLogoAsThumbnail.value
                      ? "display_retailer_logo"
                      : ""
                  }`}
                >
                  <a href={PromotionLink.value}>
                    {Thumbnail && Thumbnail.value && Thumbnail.value.src ? (
                      <div className="include_logo_padding">
                        <Image
                          media={{
                            ...Thumbnail.value,
                            src: Thumbnail.value.src
                          }}
                          data-src={Thumbnail.value.src}
                          className="whatson-image lazyload"
                        />
                      </div>
                    ) : (
                      <Fragment>
                        {IsRetailerContent && IsRetailerContent.value && (
                          <div className="display_retailer_name">
                            <Text field={Retailer} />
                          </div>
                        )}
                        <img src={defaultImg} className="whatson-image" />
                      </Fragment>
                    )}
                  </a>
                </div>
                <div className="col whatson-details">
                  <div className="type">
                    <Text field={PromotionType} />
                  </div>
                  <div className="whatson-description">
                    <a href={PromotionLink.value}>
                      <RichText field={PromotionDetails} />
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default WhatsOnResults;

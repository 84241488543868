import React, { Component } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

class FeatureCampaignCampaignTitle extends Component {
  componentDidMount() {
    const itbWrappers = document.querySelectorAll(".itb-wrapper");

    if (itbWrappers.length > 0) {
      itbWrappers[itbWrappers.length - 1].classList.add("standard-margin");
    }
  }

  render() {
    const { CampaignTitle } = this.props.fields;
    if (CampaignTitle && CampaignTitle.value) {
      return (
        <div className="campaign-title-wrapper">
          <div className="container">
            <h1 className="title">
              <Text field={CampaignTitle} />
            </h1>
          </div>
        </div>
      );
    } else return null;
  }
}

export default FeatureCampaignCampaignTitle;

import React from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";

const NavRecipe = ({ title, recipes, size }) => {
  if (recipes && recipes.length) {
    let colSize =
      size === "12"
        ? "3"
        : size === "9"
        ? "4"
        : size === "6"
        ? "6"
        : size === "3"
        ? "12"
        : "auto";
    let tileCount =
      size === "12"
        ? "4"
        : size === "9"
        ? "3"
        : size === "6"
        ? "2"
        : size === "3"
        ? "1"
        : "";
    return (
      <div className="nav-recipe-wrapper">
        <div className="title">
          {title.url ? (
            <React.Fragment>
              <a href={title.url}>
                <Text field={title.name} />
              </a>
              <i className="icon icon-arrow-right" />
            </React.Fragment>
          ) : (
            <Text field={title.name} />
          )}
        </div>
        <div className="content row">
          {recipes.map((item, index) => {
            const { Thumbnail, Type, RecipeUrl, RecipeName } = item;
            if (index < tileCount) {
              return (
                <div key={`navRecipeID-${index}`} className={`col-${colSize}`}>
                  <div className="recipe-item">
                    <a href={RecipeUrl.value}>
                      <Image
                        media={{ ...Thumbnail.value }}
                        data-src={Thumbnail.value.src}
                        className="recipe-image lazyload"
                      />
                    </a>
                    <div className="type">
                      <Text field={Type} />
                    </div>
                    <div className="recipe-description">
                      <a href={RecipeUrl.value}>
                        <Text field={RecipeName} />
                      </a>
                    </div>
                  </div>
                </div>
              );
            } else return null;
          })}
        </div>
      </div>
    );
  } else return null;
};
export default NavRecipe;

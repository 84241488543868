import React, { Component } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";

class FeatureGenericContentServiceQuickLinksMenu extends Component {
  render() {
    const {
      Title: title = null,
      BestParking: bestParking = null,
      AvailableParking: availableParking = null,
      OpeningToday: openingToday = null,
      OpeningHours: openingHours = null,
      PhoneNumber: phoneNumber = null,
      LocationLink: locationLink = null,
      BestParkingLink: bestParkingLink = null,
      Location: location = null
    } = this.props.fields || {};

    let phone =
      phoneNumber && phoneNumber.value
        ? phoneNumber.value.replace(/[&\/\s\#,+()$~%.'":*?<>{}]/g, "")
        : null;

    if (title && title.value) {
      return (
        <BaseComponent margin="standard">
          <div className="guest-services-desk-container">
            <div className="content-header">
              <Text field={title} />
            </div>
            <div className="content-list">
              <ul>
                {(openingToday &&
                  openingToday.value &&
                  openingHours &&
                  openingHours.length) &&
                  <li>
                    <div className="list-row">
                      <div className="list-icon">
                        <i className="icon icon-time" />
                      </div>
                      <div className="list-text">
                        <input
                          type="checkbox"
                          id="guest-services-openinghours"
                          className="opening-hours-toggle"
                        />
                        <label
                          className="opening-hours-title"
                          htmlFor="guest-services-openinghours"
                        >
                          <Text field={openingToday} />
                        </label>
                        <div className="opening-hours-content">
                          {openingHours.map((listItem, index) => {
                            const { Day: day = null, Time: time = null } =
                              listItem.fields || {};
                            if (
                              day &&
                              day.value &&
                              day.value.length &&
                              day.value.toLowerCase() !== "today"
                            ) {
                              return (
                                <div className="row" key={index}>
                                  <div className="col-5">
                                    <span>
                                      <Text field={day} />
                                    </span>
                                  </div>
                                  <div
                                    className="col-7"
                                    style={{
                                      paddingLeft: "0",
                                      paddingRight: "0"
                                    }}
                                  >
                                    <span>
                                      <Text field={time} />
                                    </span>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </li>
                }
                {(phone &&
                  phoneNumber
                  && phoneNumber.value) &&
                  <li>
                    <div className="list-row">
                      <div className="list-icon">
                        <i className="icon icon-phone" />
                      </div>
                      <div className="list-text">
                        <a href={`tel:` + phone}>{phoneNumber.value}</a>
                      </div>
                    </div>
                  </li>
                }
                {(location
                  && location.value) &&
                  <li>
                    <div className="list-row">
                      <div className="list-icon">
                        <i className="icon icon-centre-map" />
                      </div>
                      <div className="list-text">
                        {locationLink && locationLink.value ? (
                          <a href={locationLink.value}>
                            <Text field={location} />
                          </a>
                        ) : (
                            <Text field={location} />
                          )}
                      </div>
                    </div>
                  </li>
                }
                {(bestParking &&
                  bestParking.value &&
                  availableParking &&
                  availableParking.value) &&
                  <li>
                    <div className="list-row">
                      <div className="list-icon">
                        <i className="icon icon-parking" />
                      </div>
                      {bestParkingLink && bestParkingLink.value ? (
                        <div className="list-text">
                          <a href={bestParkingLink.value}>
                            <Text field={bestParking} />
                            <br />
                            <Text field={availableParking} />
                          </a>
                        </div>
                      ) : (
                          <div className="list-text">
                            <Text field={bestParking} />
                            <br />
                            <Text field={availableParking} />
                          </div>
                        )}
                    </div>
                  </li>
                }
              </ul>
            </div>
          </div>
        </BaseComponent>
      );
    } else return null;
  }
}

export default FeatureGenericContentServiceQuickLinksMenu;

import React from "react";
import { Text, Link, Image } from "@sitecore-jss/sitecore-jss-react";
import defaultImg from "../../../../assets/img/placeholders/gray.png";

const FeatureStoreDirectoryDirectoryHeader = props => {
  const {
    Title = null,
    CentreMapURL = null,
    HeroImage = null
  } = props.fields || {};

  return (
    <div className="container">
      <div className="directoryheader-wrapper">
        <div className="row">
          <div className="col-sm-6 directoryheader-storedirectorytext">
            <span>
              <Text field={Title} />
            </span>
          </div>
          <div className="col-md-6 mt-auto text-md-right directoryheader-centremaptext">
            <span>
              <i className="icon icon-centre-map" />
              <Link className="mx-2 mx-md-3" field={CentreMapURL} />
              <i className="icon icon-arrow-right directoryheader-arrow" />
            </span>
          </div>
        </div>
      </div>
      <div className="justify-content-center">
        <Image
          media={{ ...HeroImage.value, src: defaultImg }}
          data-src={HeroImage.value.src}
          className="directoryheader-image lazyload"
        />
      </div>
    </div>
  )
};

export default FeatureStoreDirectoryDirectoryHeader;

import { Form } from "@sitecore-jss/sitecore-jss-react-forms";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { sitecoreApiHost, sitecoreApiKey } from "../../../../temp/config";
import BaseComponent from "../../../Base/BaseComponent";
import {
  ConditionalFieldWrapper,
  CustomFetchForm,
  ErrorComponent,
  FieldErrorComponent,
  UpdateFieldModel
} from "./CustomizeForm";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";

class FoundationFormsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { fields, history, sitecoreContext } = this.props;
    let message = "";
    //console.log(sitecoreContext);
    if (sitecoreContext && sitecoreContext.formInfo) {
      const {
        formExpired,
        formExpiredMessage,
        formLimitReached,
        formLimitReachedMessage
      } = sitecoreContext.formInfo;
      if ((formExpired && formLimitReached) || formExpired) {
        message = formExpiredMessage;
      } else if (formLimitReached) {
        message = formLimitReachedMessage;
      }
    }
    return (
      <div className="custom-form-container">
        {fields && (
          <BaseComponent margin="standard">
            <div className="custom-form" id="js-custom-form">
              {message ? (
                <h2>{message}</h2>
              ) : (
                <Form
                  form={UpdateFieldModel(fields)}
                  sitecoreApiHost={sitecoreApiHost}
                  sitecoreApiKey={sitecoreApiKey}
                  onRedirect={url => history.push(url)}
                  fieldWrapperComponent={ConditionalFieldWrapper}
                  errorComponent={ErrorComponent}
                  fieldValidationErrorsComponent={FieldErrorComponent}
                  formFetcher={CustomFetchForm}
                />
              )}
            </div>
          </BaseComponent>
        )}
      </div>
    );
  }
}

export default withSitecoreContext()(withRouter(FoundationFormsForm));

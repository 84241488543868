import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const FeatureGettingHereGettingHereHeader = (props) => {
  const { Title } = props.fields;
  const headerTitle = Title ? Title : "Opening Hours";

  return (
    <div className="gettinghere-header fluid">
      <div className="container">
        <div className="row">
          <div className="col-auto gettinghere-label">
            <Text field={headerTitle} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureGettingHereGettingHereHeader;

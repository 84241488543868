import React from "react";
import { Text, Image, Link, RichText } from "@sitecore-jss/sitecore-jss-react";
import ScrollAnimation from "react-animate-on-scroll";
import BaseComponent from "../../../Base/BaseComponent";
import Details from "../../../Common/Details";
import defaultImg from "../../../../assets/img/placeholders/gray.png";
import Highlight from "../../../Common/Highlight";

const FeaturePromotionsCategorySpotlight = props => {
  const { Title: title = null, EventList: eventList = null } =
    props.fields || {};
  let MaxCount = 0;
  if (props.params && props.params.MaxCount) {
    MaxCount = props.params.MaxCount;
  }
  if (
    eventList &&
    eventList.length &&
    eventList.length > 0 &&
    eventList.length <= MaxCount
  ) {
    return (
      <BaseComponent margin="standard">
        <div className="container">
          <div className="categoryspotlight-wrapper">
            <div className="categoryspotlight-header-banner" />
            <div className="categoryspotlight-content-wrapper">
              <ScrollAnimation
                animateIn="animate__animated animate__fadeIn"
                animateOnce={true}
              >
                <div className="categoryspotlight-header-title">
                  <Link field={title} />
                  {/* <i className="icon icon-arrow-right" /> */}
                </div>
              </ScrollAnimation>
              {eventList.length &&
                eventList.map((item, index) => {
                  const {
                    Thumbnail: thumbnail = null,
                    PromotionName: name = null,
                    PromotionType: promotionType = null,
                    Summary: summary = null,
                    LocationLink: locationLink = null,
                    PromotionLocation: promotionLocation = null,
                    PromotionLink: promotionLink = null,
                    StorefrontId: storeFrontId = null,
                    Validity: validity = null,
                    OfferHighlight: offerHighlight = null
                  } = item.fields || {};
                  return (
                    <div
                      key={`myId-${index}`}
                      className="categoryspotlight-tile-wrapper"
                    >
                      <div className="categoryspotlight-tile">
                        <div className="animation-range">
                          <div className="categoryspotlight-tile-image-wrapper">
                            <div className="categoryspotlight-tile-image">
                              {promotionLink && promotionLink.value ? (
                                <a href={promotionLink.value}>
                                  {thumbnail &&
                                  thumbnail.value &&
                                  thumbnail.value.src ? (
                                    <Image
                                      media={{
                                        ...thumbnail.value,
                                        src: defaultImg
                                      }}
                                      data-src={thumbnail.value.src}
                                      className="lazyload"
                                    />
                                  ) : (
                                    <img src={defaultImg} alt="default" />
                                  )}
                                </a>
                              ) : thumbnail &&
                                thumbnail.value &&
                                thumbnail.value.src ? (
                                <Image
                                  media={{
                                    ...thumbnail.value,
                                    src: defaultImg
                                  }}
                                  data-src={thumbnail.value.src}
                                  className="lazyload"
                                />
                              ) : (
                                <img src={defaultImg} alt="default" />
                              )}
                              {offerHighlight && offerHighlight.value && (
                                <Highlight text={offerHighlight} />
                              )}
                            </div>
                          </div>
                          <div className="categoryspotlight-tile-info-title">
                            <span>
                              {promotionLink && promotionLink.value ? (
                                <a href={promotionLink.value}>
                                  <Text field={name} />
                                </a>
                              ) : (
                                <Text field={name} />
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="categoryspotlight-tile-info-wrapper">
                          {summary && summary.value && (
                            <div className="categoryspotlight-tile-info-description">
                              <span>
                                <RichText field={summary} />
                              </span>
                            </div>
                          )}
                          {validity &&
                            validity.value &&
                            promotionType &&
                            promotionType.value &&
                            promotionType.value !== "Article" && (
                              <Details
                                iconValue="time"
                                linkName={validity}
                                size="24"
                              />
                            )}
                          {promotionLocation && promotionLocation.value && (
                            <Details
                              iconValue="location"
                              linkUrl={locationLink && locationLink.value}
                              linkName={promotionLocation}
                              size="24"
                              mapInfo={
                                storeFrontId && storeFrontId.value
                                  ? storeFrontId.value
                                  : ""
                              }
                            />
                          )}
                          {validity &&
                            validity.value &&
                            promotionType &&
                            promotionType.value &&
                            promotionType.value === "Article" && (
                              <Details
                                iconValue="time"
                                linkName={validity}
                                size="24"
                              />
                            )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  } else return null;
};

export default FeaturePromotionsCategorySpotlight;

import React from "react";
import {
  Text,
  Image,
  Placeholder,
  Link,
  RichText
} from "@sitecore-jss/sitecore-jss-react";
import Details from "../../../Common/Details";
import defaultImg from "../../../../assets/img/placeholders/gray.png";

const FeaturePromotionsPromotionDetails = props => {
  const {
    PromotionCategory = null,
    PromotionName = null,
    Summary = null,
    LocationLink = null,
    PromotionLocation = null,
    ScheduleTime = null,
    Description = null,
    RetailerLogo = null,
    Duration = null,
    Capacity = null,
    Price = null,
    BookNow = null,
    AddToCalendarCta = null,
    StorefrontLink = null,
    StorefrontId = null
  } = props.fields || {};

  return (
    <div className="promotiondetails-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8">
            <div className="promotions-content">
              {PromotionCategory && PromotionCategory.value && (
                <div className="promotions-category">
                  <Text field={PromotionCategory} />
                </div>
              )}
              {PromotionName && PromotionName.value && (
                <h1 className="promotions-name">
                  <Text field={PromotionName} />
                </h1>
              )}
              {Summary && Summary.value && (
                <div className="promotions-summary">
                  <RichText field={Summary} />
                </div>
              )}

              {RetailerLogo && RetailerLogo.value && RetailerLogo.value.src && (
                <div className="event-logo-mobile">
                  <a href={StorefrontLink ? StorefrontLink.value : "#"}>
                    <Image
                      media={{ ...RetailerLogo.value, src: defaultImg }}
                      data-src={RetailerLogo.value.src}
                      className="lazyload"
                    />
                  </a>
                </div>
              )}


 {(PromotionLocation.value || Duration.value || ScheduleTime.value || Capacity.value) && (
  
              <div className="details">
                <div className="row">
                  {PromotionLocation && PromotionLocation.value && (
                    <div className="col-12 col-md-6">
                      <Details
                        iconValue="location"
                        linkUrl={LocationLink && LocationLink.value}
                        linkName={PromotionLocation}
                        size="24"
                        mapInfo={
                          StorefrontId && StorefrontId.value
                            ? StorefrontId.value
                            : ""
                        }
                      />
                    </div>
                  )}
                  {Duration && Duration.value ? (
                    <div className="col-12 col-md-6">
                      <Details iconValue="time" linkName={Duration} size="24" />
                    </div>
                  ) : (
                    <div className="d-none d-md-block col-md-6" />
                  )}
                  {ScheduleTime && ScheduleTime.value && (
                    <div className="col-12 col-md-6">
                      <Details
                        iconValue="calendar"
                        linkName={ScheduleTime}
                        size="24"
                      />
                    </div>
                  )}
                  {Capacity && Capacity.value && (
                    <div className="col-12 col-md-6">
                      <Details
                        iconValue="seats"
                        linkName={Capacity}
                        size="24"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
              {Description && Description.value && (Description.value != "<span></span>") && (
                <div className="promotions-description">
                  <RichText field={Description} />
                </div>
              )}

              {Price && Price.value && (
                <div className="price-wrapper">
                  <div className="label">Price</div>
                  <div className="val">
                    <Text field={{ value: `$${Price.value}` }} />
                  </div>
                </div>
              )}

          {BookNow && BookNow.value && (
              <div className="button-links">
                {BookNow && BookNow.value && BookNow.value.href && (
                  <div>
                    <Link field={BookNow} className="btn primary-btn" />
                  </div>
                )}

                {BookNow &&
                  BookNow.value &&
                  BookNow.value.href &&
                  AddToCalendarCta &&
                  AddToCalendarCta.value && (
                    <div>
                      <a
                        href={AddToCalendarCta.value}
                        className="btn secondary-btn"
                      >
                        Add to Calendar
                      </a>
                    </div>
                  )}
              </div>
              )}
            </div>
          </div>

          <div className="col-12 col-md-4">
            {RetailerLogo && RetailerLogo.value && RetailerLogo.value.src && (
              <div className="event-logo-wrapper">
                <a href={StorefrontLink ? StorefrontLink.value : "#"}>
                  <Image
                    media={{ ...RetailerLogo.value, src: defaultImg }}
                    data-src={RetailerLogo.value.src}
                    className="event-logo lazyload"
                  />
                </a>
              </div>
            )}
            <div className="quicklinks-wrapper">
              <Placeholder
                name="jss-quicklinks-right"
                rendering={props.rendering}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturePromotionsPromotionDetails;

import React from "react";
import { Text, Image, Link } from "@sitecore-jss/sitecore-jss-react";
import ScrollAnimation from "react-animate-on-scroll";
import Badge from "../../../Common/Badge";
import BaseComponent from "../../../Base/BaseComponent";
import defaultImg from "../../../../assets/img/placeholders/gray.png";
import Details from "../../../Common/Details";
import RiseAndFade from "../../../Common/Animations/RiseAndFade";

const FeaturePrecinctsFeaturedPrecinct = props => {
  const {
    Title: title = null,
    TitleLink: link = null,
    Subtitle: subtitle = null,
    PrecinctsList: precinctsList = null
  } = props.fields || {};

  const composeRatingSection = (ratingFromAPI, priceFromAPI) => {
    let ratingSection = [];
    let rating = [];
    let priceLevel = [];

    if (ratingFromAPI != 0) {
      const ratingValue = parseInt(ratingFromAPI);
      for (let i = 0; i < 5; i++) {
        if (i <= ratingValue) {
          rating.push(<span className="icon icon-star checked" key={i} />);
        } else {
          rating.push(<span className="icon icon-star" key={i} />);
        }
      }
    } else {
      rating = null;
    }

    if (priceFromAPI != 0) {
      const priceLevelValue = parseInt(priceFromAPI);

      for (let i = 0; i < priceLevelValue; i++) {
        priceLevel.push(<span className="icon icon-dollar blocked" key={i} />);
      }
    } else {
      priceLevel = null;
    }

    ratingSection = (
      <div className="tiles-details-rate">
        {rating && <div>{rating}</div>}
        {priceLevel && <div>{priceLevel}</div>}
      </div>
    );

    return ratingSection;
  };

  let list;

  if (precinctsList && precinctsList.length > 2) {
    list = (
      <BaseComponent margin="standard">
        <div className="featuredprecinct-wrapper">
          <div className="header-wrapper banner-left">
            <div className="container">
              <div className="row no-gutters">
                <div className="col-12 col-lg-11 header-bg header-text-left">
                  <ScrollAnimation
                    animateIn="animate__animated animate__fadeIn"
                    animateOnce={true}
                  >
                    {title && title.value && (
                      <div className="header-title">
                        {link && link.value && link.value.href ? (
                          <Link field={link}>
                            <Text field={title} />
                          </Link>
                        ) : (
                          <Text field={title} />
                        )}
                      </div>
                    )}
                    <div className="header-sub">
                      <Text field={subtitle} />
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
          <div className="tiles-wrapper">
            <div className="container">
              <div className="row no-gutters">
                {precinctsList &&
                  precinctsList.map((listItem, index) => {
                    let item, itemList;
                    itemList = listItem.fields;
                    for (const iterator in itemList) {
                      if (itemList[iterator] && itemList[iterator].fields) {
                        item = itemList[iterator].fields;
                      }
                    }
                    let value = 0;
                    if (index == 1) {
                      value = 6;
                    } else if (index == 2) {
                      value = 8;
                    }
                    return item ? (
                      <div key={`myId-${index}`} className="col-10 col-md-4">
                        <RiseAndFade position={value}>
                          <div className="animation-range">
                            <div className="content-img-wrapper">
                              <a
                                href={
                                  item.RetailerPageHref &&
                                  item.RetailerPageHref.value
                                    ? item.RetailerPageHref.value
                                    : item.PromotionLink &&
                                      item.PromotionLink.value
                                    ? item.PromotionLink.value
                                    : null
                                }
                              >
                                {(item.HeroImage &&
                                  item.HeroImage.value &&
                                  item.HeroImage.value.src) ||
                                (item.Thumbnail &&
                                  item.Thumbnail.value &&
                                  item.Thumbnail.value.src) ? (
                                  <Image
                                    media={
                                      item.HeroImage
                                        ? {
                                            ...item.HeroImage.value,
                                            src: defaultImg
                                          }
                                        : {
                                            ...item.Thumbnail.value,
                                            src: defaultImg
                                          }
                                    }
                                    data-src={
                                      item.HeroImage
                                        ? item.HeroImage.value.src
                                        : item.Thumbnail.value.src
                                    }
                                    className={`content-image ${
                                      item.HeroImage ? "content-image-logo" : ""
                                    } lazyload`}
                                  />
                                ) : (
                                  <div className="no-image" />
                                )}
                              </a>
                              <div className="img-badge">
                                <Badge
                                  type="list"
                                  startDate={
                                    item.StartDate ? item.StartDate.value : null
                                  }
                                  endDate={
                                    item.EndDate ? item.EndDate.value : ""
                                  }
                                  badgeText={
                                    item.EventText ? item.EventText.value : ""
                                  }
                                />
                              </div>
                              <div className="img-logo">
                                <div className="img-logo-bg">
                                  <Image
                                    media={
                                      item.Logo
                                        ? {
                                            ...item.Logo.value,
                                            src: defaultImg
                                          }
                                        : {
                                            ...item.Thumbnail.value,
                                            src: defaultImg
                                          }
                                    }
                                    data-src={
                                      item.Logo
                                        ? item.Logo.value.src
                                        : item.Thumbnail.value.src
                                    }
                                    className="lazyload"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="details-title">
                              <a
                                href={
                                  item.RetailerPageHref &&
                                  item.RetailerPageHref.value
                                    ? item.RetailerPageHref.value
                                    : item.PromotionLink &&
                                      item.PromotionLink.value
                                    ? item.PromotionLink.value
                                    : null
                                }
                              >
                                <Text
                                  field={
                                    item.RetailerName
                                      ? { value: item.RetailerName.value }
                                      : { value: item.Name.value }
                                  }
                                />
                              </a>
                            </div>
                          </div>
                          <div className="tiles-details-rate-wrapper">
                            {(item.Rating || item.PriceLevel) &&
                              composeRatingSection(
                                item.Rating ? item.Rating.value : 0,
                                item.PriceLevel ? item.PriceLevel.value : 0
                              )}
                          </div>
                          {item.StoreLocation && item.StoreLocation.value && (
                            <Details
                              iconValue="location"
                              linkUrl={
                                item.StoreLocationLink &&
                                item.StoreLocationLink.value
                              }
                              linkName={item.StoreLocation}
                              size="24"
                              mapInfo={
                                item.ItemId && item.ItemId.value
                                  ? item.ItemId.value
                                  : null
                              }
                            />
                          )}
                          {item.Location && item.Location.value && (
                            <Details
                              iconValue="location"
                              linkUrl={
                                item.LocationLink && item.LocationLink.value
                              }
                              linkName={item.Location}
                              size="24"
                              mapInfo={
                                item.LocationMapTargetId &&
                                item.LocationMapTargetId.value
                                  ? item.LocationMapTargetId.value
                                  : null
                              }
                            />
                          )}
                          {item.ScheduleTime && item.ScheduleTime.value && (
                            <Details
                              iconValue="time"
                              linkName={item.ScheduleTime}
                              size="24"
                            />
                          )}
                          {item.OpeningTime && item.OpeningTime.value && (
                            <Details
                              iconValue="time"
                              linkName={item.OpeningTime}
                              size="24"
                            />
                          )}
                        </RiseAndFade>
                      </div>
                    ) : (
                      ""
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  } else {
    list = "";
  }

  return list;
};

export default FeaturePrecinctsFeaturedPrecinct;

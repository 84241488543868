import React from "react";
import { Text, Image, RichText, Link } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import defaultImg from "../../../../assets/img/placeholders/gray.png";

const FeatureCentreServicesCentreServicesHighlight = props => {
  const {
    ServiceName = null,
    Description = null,
    CentreServiceLink = null,
    HeroImage = null,
    HeroImageUrl = null,
    HeroImageAltText = null
  } = props.fields || {};
  if (ServiceName && ServiceName.value) {
    if (HeroImage && HeroImage.value && HeroImageUrl && HeroImageUrl.value) {
      HeroImage.value.src = HeroImageUrl.value;
    }
    return (
      <BaseComponent margin="standard">
        <div className="categoryother">
          <div className="container">
            <div className="categoryother-wrapper">
              <div className="animation-range">
                <div className="categoryother-image-wrapper col-12 col-md-7">
                  <div className="categoryother-image">
                    <Image
                      media={{
                        ...HeroImage.value,
                        src: defaultImg,
                        alt:
                          HeroImageAltText && HeroImageAltText.value
                            ? HeroImageAltText.value
                            : ""
                      }}
                      data-src={HeroImage.value.src}
                      className="lazyload"
                    />
                  </div>
                </div>
                <div className="categoryother-details-link col-12 col-md-5 offset-md-7">
                  <Link field={CentreServiceLink} />
                  <i className="icon icon-arrow-right" />
                </div>
                <div className="categoryother-details-title col-12 col-md-5 offset-md-7">
                  <span>
                    <Text field={ServiceName} />
                  </span>
                </div>
              </div>
              <div className="categoryother-details">
                <div className="categoryother-details-wrapper col-12 col-md-5 offset-md-7">
                  <div className="categoryother-details-description">
                    <RichText field={Description} />
                  </div>
                  {CentreServiceLink && CentreServiceLink.value && (
                    <div className="categoryother-details-learn-more">
                      <Link
                        field={{
                          ...CentreServiceLink.value,
                          text: "Learn More"
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  } else return null;
};

export default FeatureCentreServicesCentreServicesHighlight;

import React, { Fragment, useEffect, useState } from "react";

const RadioButton = props => {
    const {
        data = null,
        onChange = null,
        RadioGroupName = null,
        setRelatedFieldsValues = null,
        relatedFieldsValues = null,
    } = props || {};

    // const [relVals, setRelVals] = useState({ ...relatedFieldsValues })

    useEffect(() => {
        let relatedVals = {}
        data.forEach((radioList) => {
            const {
                Id: id = null,
                HasRelatedField = null,
                RelatedFieldCurrentValue = { value: "" }
            } = radioList.fields || {};
            if (HasRelatedField && HasRelatedField.value) relatedVals[id.value] = RelatedFieldCurrentValue && RelatedFieldCurrentValue.value
        })

        setRelatedFieldsValues(relatedVals)

    }, [])
    const handleRadioClick = (event, isRelatedFieldRequired = false) => {
        const relatedFields = document.body.querySelectorAll('.related-field-input')
        // show/hide related field
        if (relatedFields.length) {
            relatedFields.forEach(field => {
                if (field.id === event.target.id + '-input') {
                    field.parentElement.classList.add('is-active')
                    field.classList.add('is-active')
                    if (isRelatedFieldRequired) field.classList.add('is-required')
                }
                else {
                    field.parentElement.classList.remove('is-active')
                    field.classList.remove('is-active')
                    if (isRelatedFieldRequired) field.classList.remove('is-required')
                    // tempFieldsValues[field.id] = ""
                }
            })
            // setRelVals(tempFieldsValues)
        }

        if (onChange) {
            if (relatedFieldsValues[event.target.id]) onChange(`${event.target.id}+${relatedFieldsValues[event.target.id]}`, event.target);
            else onChange(event.target.id, event.target);
        }
    }

    const handleInputChange = (event, isRelatedFieldRequired = false) => {
        const tempFieldsValues = { ...relatedFieldsValues }
        tempFieldsValues[event.target.id.split('-')[0]] = event.target.value
        setRelatedFieldsValues(tempFieldsValues)
        if (isRelatedFieldRequired) {
            if (validateInput(event.target.value)) {
                // remove box-error if there's any
                event.target.classList.remove('box-error')
                if (onChange) onChange(`${event.target.id}+${event.target.value}`, event.target);
            } else event.target.classList.add('box-error')
        } else {
            if (onChange) onChange(`${event.target.id}+${event.target.value}`, event.target);
        }

    }

    const validateInput = (value) => {
        let inputRegex = new RegExp(/^[a-zÀ-ÿ ,.'-]+$/i);
        if (value) return inputRegex.test(String(value).toLowerCase())
    };
    return (
        <div>
            {data &&
                data.map((radioList, index) => {
                    const {
                        Id: id = null,
                        Name: name = null,
                        Selected: selected = null,
                        HasRelatedField = null,
                        RelatedFieldLabel = null,
                        RelatedFieldRequired = null,
                    } = radioList.fields || {};

                    return (
                        <div className={`form-group form-radiobutton ${HasRelatedField && HasRelatedField.value ? 'has-related-field' : ''}`} key={`${name.value}-${name.id}-${index}-radiobutton`}>
                            <label>
                                <input
                                    type="radio"
                                    className={selected && selected.value === true ? "is-active" : ""}
                                    id={id.value}
                                    name={RadioGroupName}
                                    value={name.value}
                                    onClick={(e) => handleRadioClick(e, RelatedFieldRequired && RelatedFieldRequired.value)}
                                />
                                {name.value}
                            </label>
                            {HasRelatedField && HasRelatedField.value &&
                                <div className="form-group related-field">
                                    <input
                                        id={id.value + '-input'}
                                        type="text"
                                        className="form-control form-control-lg related-field-input"
                                        onChange={(e) => handleInputChange(e, RelatedFieldRequired && RelatedFieldRequired.value)}
                                        onBlur={(e) => handleInputChange(e, RelatedFieldRequired && RelatedFieldRequired.value)}
                                        required
                                        value={relatedFieldsValues[id.value]}
                                    >
                                    </input>
                                    <label
                                        className="form-control-placeholder"
                                        htmlFor={id.value + '-input'}
                                    >
                                        {RelatedFieldLabel && RelatedFieldLabel.value}
                                    </label>
                                </div>
                            }
                        </div>

                    );
                })}
        </div>
    )
};

export default RadioButton;
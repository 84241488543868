import React from "react";
import { Image, Link } from "@sitecore-jss/sitecore-jss-react";
import defaultImg from "../../../../assets/img/placeholders/gray.png";

const Header = props => {
  return (
    <div className="navbar-wrapper">
      <div className="container">
        <nav className="navbar navbar-expand-xl navbar-light">
          <div className="divider">
            <a className="navbar-brand" href="/">
              <Image
                media={{ ...props.fields.centreLogo.value, src: defaultImg }}
                data-src={props.fields.centreLogo.value.src}
                className="lazyload"
              />
            </a>
          </div>
          <div className="divider">
            <button className="navbar-collapse-search-btn pull-right">
              <i className="icon icon-outlined-search" />
            </button>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="icon icon-outlined-menu" />
            </button>
          </div>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item mx-3">
                <Link className="nav-link" field={props.fields.visitCta} />
              </li>
              <li className="nav-item mx-3">
                <Link className="nav-link" field={props.fields.shopCta} />
              </li>
              <li className="nav-item mx-3">
                <Link
                  className="nav-link"
                  field={props.fields.eatAndDrinkCta}
                />
              </li>
              <li className="nav-item mx-3">
                <Link
                  className="nav-link"
                  field={props.fields.entertainmentCta}
                />
              </li>
              <li className="nav-item ml-3">
                <a className="nav-link" href="#">
                  <i className="icon icon-outlined-search" />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;

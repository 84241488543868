import React from 'react';

const FeatureMapsGoogleMap = props => {
  const {
    MapUrl: mapUrl = null,
    DirectionUrl: directionUrl = null
  } = props.fields;
  
  return (
    <div className="map-container container">
      <iframe src={mapUrl.value} title="Google Map" />
      <a href={directionUrl.value} className="btn btn-primary btn-rounded" target="blank" >Get Directions</a>
    </div>
  )
};


export default FeatureMapsGoogleMap;

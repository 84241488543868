import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import PopularTimes from "../../../../Feature/Metrics/PopularTimes";

const NavPopularTimes = ({ data }) => {
    const {
        Day = null,
        OpeningHours = null,
    } = data.NavPopularTimes || {}
    return (
        <div className="nav-populartimes__wrapper font-regular">
            <div className="nav-populartimes__day">
                <Text field={Day} />
            </div>
            <div className="nav-populartimes__time">
                <Text field={OpeningHours} />
            </div>
            <div className="nav-populartimes__graph">
                <PopularTimes fields={data.NavPopularTimes} isNavigation={true} />
            </div>
        </div>
    );
};
export default NavPopularTimes;

import React from "react";
import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";

const FeatureCampaignCampaignDetails = props => {
  const { CampaignIntro, CampaignDescription } = props.fields;
  if (
    CampaignIntro &&
    CampaignIntro.value &&
    CampaignDescription &&
    CampaignDescription.value
  ) {
    return (
      <BaseComponent margin="campaign">
        <div className="campaign-details-wrapper container">
          <div className="intro col-md-8">
            <Text field={CampaignIntro} />
          </div>
          <div className="description col-md-9">
            <RichText field={CampaignDescription} />
          </div>
        </div>
      </BaseComponent>
    );
  } else return null;
};

export default FeatureCampaignCampaignDetails;

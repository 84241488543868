import React from "react";
import FeaturedLifestyleTiles from "../../../Common/Tiles/FeaturedLifestyle";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import ScrollAnimation from "react-animate-on-scroll";
import BaseComponent from "../../../Base/BaseComponent";

const FeatureLifestyleFeaturedLifestyle = props => {
  const { Title, TitleLink, Lifestyle } = props.fields;
  if (Title && Title.value && Lifestyle && Lifestyle.length > 2) {
    return (
      <BaseComponent margin="standard">
        <div className="featured-lifestyle-wrapper">
          <div className="container">
            <div className="featured-lifestyle-header-wrapper col-12 col-md-11">
              <ScrollAnimation
                animateIn="animate__animated animate__fadeIn"
                animateOnce={true}
              >
                <div className="featured-lifestyle-header-text">
                  {TitleLink && TitleLink.value && TitleLink.value.href ? (
                    <React.Fragment>
                      <a {...TitleLink.value}>
                        <Text field={Title} />
                      </a>
                      <i className="icon icon-chevron-right" />
                    </React.Fragment>
                  ) : (
                    <Text field={Title} />
                  )}
                </div>
              </ScrollAnimation>
            </div>
          </div>
          <FeaturedLifestyleTiles data={Lifestyle} />
        </div>
      </BaseComponent>
    );
  } else return null;
};

export default FeatureLifestyleFeaturedLifestyle;

import React from "react";
import { Text, Link } from "@sitecore-jss/sitecore-jss-react";

class FeatureGenericContentQuickLinksMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isArticle: false };
  }

  componentDidMount() {
    const isArticle = document.querySelector(".articledetails-wrapper, .blogdetails-wrapper");
    if (isArticle) {
      this.handleArticle();
      window.addEventListener("resize", this.handleArticle);
      this.setState({ isArticle: true });
    }
  }

  handleArticle = () => {
    const articleDetails = document.querySelector(".articledetails-wrapper, .blogdetails-wrapper");
    if (articleDetails) {
      const hasRetailerLogo = document.querySelector(
        ".articledetails-wrapper .article-logo"
      );
      let margin = "";

      const quickLinks = document.querySelector(".quicklinksmenu-container");

      if (hasRetailerLogo) {
        margin = -(articleDetails.offsetHeight + 74 - 196) + "px";
      } else {
        margin = -(articleDetails.offsetHeight + 74) + "px";
      }

      if (quickLinks) {
        quickLinks.style.marginTop = margin;
      }
    }
  };

  render() {
    let dataSource =
      this.props && this.props.rendering && this.props.rendering.dataSource
        ? this.props.rendering.dataSource
        : "";
    if (!dataSource) {
      return "";
    }

    const { Title: title = null, Links: links = null } =
      this.props.fields || {};

    let { isArticle } = this.state;
    if (links && links.length) {
      return (
        <div
          className={`quicklinksmenu-container font-regular ${isArticle ? `quicklinks-article` : ""
            }`}
        >
          <div className="content-header">
            <Text field={title} />
          </div>
          <div className="content-list">
            <ul>
              {links.map((item, index) => (
                <li key={`myId-${index}`}>
                  <Link field={item.fields.LinkTarget} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      );
    } else return null;
  }
}

export default FeatureGenericContentQuickLinksMenu;

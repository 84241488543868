import React from "react";
import { Text, Link, Image } from "@sitecore-jss/sitecore-jss-react";
import defaultImg from "../../../../assets/img/placeholders/gray.png";

const StoreResults = ({ title, data }) => {
  return (
    <div className="search-storedirectory">
      <div className="title">
        <Link field={title} />
        <i className="icon icon-arrow-right" />
      </div>
      <div className="content-list">
        <ul className="list">
          {data.map((item, index) => {
            const {
              RetailerLink: RetailerLink = null,
              Logo: Logo = null,
              RetailerName: RetailerName = null
            } = item || {};
            return (
              <li key={`myId-${index}`}>
                <div className="row align-items-center no-gutters">
                  <div className="col-auto defaultImg">
                    <a href={RetailerLink.value}>
                      {Logo && Logo.value ? (
                        <Image
                          media={{ ...Logo.value, src: Logo.value.src }}
                          data-src={Logo.value.src}
                          className="lazyload"
                        />
                      ) : (
                        <img src={defaultImg} />
                      )}
                    </a>
                  </div>
                  <div className="col">
                    <a href={RetailerLink.value}>
                      <Text field={RetailerName} />
                    </a>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default StoreResults;

import React, { Component, Fragment } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import SearchSkeleton from "../../../Common/Animations/SearchSkeleton";
import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";
import defaultImage from "../../../../assets/img/placeholders/gray.png";
import TagManager from "react-gtm-module";
import _ from "lodash";
import axios from "axios";

import {
  disableBodyScroll,
  enableBodyScroll
} from "../../../../helper/ScrollHelper";

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      centreId:
        props.fields && props.fields.CentreId && props.fields.CentreId.value
          ? props.fields.CentreId.value.replace(/{|},|}/g, "")
          : null,
      searchTerm: null,
      searchResults: null,
      noResults: false,
      noResultsMessage: "",
      recentSearches: null,
      showLoader: false,
      topSearches: null
    };
    this.getSearchResults = _.debounce(this.getSearchResults, 1000);
  }

  componentDidMount() {
    if (window && window.outerWidth >= 992) {
      window.addEventListener("resize", this.handleBlur);
    }
    this.getRecentSearch(this.state.centreId);
    this.getTopSearches();
  }

  getTopSearches = () => {
    let topSearches = [];
    if (
      this.props.fields &&
      this.props.fields.TopSearches &&
      this.props.fields.TopSearches.length
    ) {
      this.props.fields.TopSearches.map(el =>
        el.fields && el.fields.SearchTerm && el.fields.SearchTerm.value
          ? topSearches.push(el.fields.SearchTerm.value)
          : null
      );
    }
    this.setState({ topSearches });
  };

  handleScroll = () => {
    if (window && window.outerWidth < 992) {
      if (this.state.searchTerm) {
        disableBodyScroll();
      } else {
        enableBodyScroll();
      }
    }
  };

  handleBlur = () => {
    this.resetState();
    document.querySelector(".nav-item .icon-close") &&
      document.querySelector(".nav-item .icon-close").click();
  };

  resetState = () => {
    this.setState({
      searchTerm: "",
      searchResults: null,
      noResults: false,
      noResultsMessage: "",
      showLoader: false
    });
  };

  renderInfo = (title, section, icon, icon2) => {
    if (section && section.length) {
      const { centreId } = this.state;
      return (
        <div className="search-info-wrapper">
          <div className="section-container">
            <div className="section-title">{title}</div>
            {section.map((term, index) => {
              while (index < 3) {
                return (
                  <div
                    key={`recent-search-term-${index}`}
                    className="section-content"
                    onClick={e => {
                      if (!/clear/.test(e.target.id)) {
                        this.handleSearch(term);
                        this.handleFocus();
                      }
                    }}
                  >
                    <i className={`icon icon-${icon}`} />
                    <div className="term">{term}</div>
                    {icon2 && (
                      <i
                        id={`${title}-clear-${term}`}
                        className={`icon icon-${icon2}`}
                        onClick={() =>
                          this.setRecentSearch(
                            "remove",
                            centreId.split("-").pop(),
                            index
                          )
                        }
                      />
                    )}
                  </div>
                );
              }
            })}
          </div>
        </div>
      );
    } else return null;
  };

  renderResults = (results, show) => {
    return (
      <div className={`search-results-wrapper ${show ? "show-results" : ""}`}>
        {results && results.length ? (
          results.map((item, index) => {
            const {
              Name: name,
              Link: link,
              Type: type,
              Thumbnail: thumbnail,
              IsRetailerContent = null,
              IsLogoAsThumbnail = null,
              Retailer
            } = item;
            return (
              <a
                href={(link && link.value) || null}
                key={`search-result-${index}`}
              >
                <div className="search-result-row">
                  <div
                    className={`thumbnail ${
                      IsLogoAsThumbnail && IsLogoAsThumbnail.value
                        ? "display_retailer_logo"
                        : ""
                    }`}
                  >
                    <div className="include_logo_padding">
                      {thumbnail && thumbnail.value ? (
                        <img src={thumbnail.value} />
                      ) : (
                        <Fragment>
                          {/* {IsRetailerContent && IsRetailerContent.value && (
                            <div className="display_retailer_name">
                              <Text field={Retailer} />
                            </div>
                          )} */}
                          <img src={defaultImage} />
                        </Fragment>
                      )}
                    </div>
                  </div>
                  <div className="search-result-col">
                    <div className="name">
                      {this.renderSuggestion(name, this.state.searchTerm)}
                    </div>
                    <div className="type">
                      <Text field={type} />
                    </div>
                  </div>
                </div>
              </a>
            );
          })
        ) : (
          <div className="no-results">{this.state.noResultsMessage}</div>
        )}
      </div>
    );
  };

  renderContent = () => {
    const {
      recentSearches,
      topSearches,
      searchTerm,
      searchResults,
      noResults,
      showLoader
    } = this.state;

    if (searchTerm) {
      return (
        <React.Fragment>
          {this.renderResults(
            searchResults,
            (searchResults && searchResults.length) || noResults
          )}
          {showLoader && (
            <div className={`search-skeleton-wrapper`}>
              <SearchSkeleton />
              <SearchSkeleton />
              <SearchSkeleton opacity="0.8" />
              <SearchSkeleton opacity="0.6" />
              <SearchSkeleton opacity="0.4" />
              <SearchSkeleton opacity="0.2" />
            </div>
          )}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {this.renderInfo("Recent searches", recentSearches, "time", "close")}
          {this.renderInfo("Top searches", topSearches, "outlined-search")}
        </React.Fragment>
      );
    }
  };

  handleSearch = async keyword => {
    const term =
      keyword && /\S/.test(keyword) ? keyword.replace(/&/g, "%26") : null;
    const { centreId } = this.state;

    this.setState(
      {
        searchTerm: keyword,
        noResults: false,
        searchResults: null,
        showLoader: true
      },
      () => this.handleScroll()
    );

    if (term && centreId) {
      this.getSearchResults(term, centreId);
    }
  };

  /**
   * changes '%26' characters to /&/
   * @param {string} term 
   * @returns 
   */
  cleanRecentSearchTerm = (term) => {
    return term.replace("%26", "&")
  };

  getSearchResults = async (term, centreId) => {
    
    if (term.length > 2) {
      this.setRecentSearch("add", centreId, this.cleanRecentSearchTerm(term));
    }
    TagManager.dataLayer({
      dataLayer: {
        event: "performedSearch",
        searchTerm: term
      },
      dataLayerName: "dataLayer"
    });
    const url = `/api/sitecore/search/searchBar?keyword=${term}&centreId=${centreId}`;
    try {
      let response = await axios({
        method: "GET",
        url
      });
      if (response && response.data) {
        if (response.data.SearchResults && response.data.SearchResults.length) {
          this.setState({
            searchResults: response.data.SearchResults,
            noResults: false,
            noResultsMessage: "",
            showLoader: false
          });
        } else {
          this.setState({
            searchResults: [],
            noResults: true,
            noResultsMessage: "No results. Please try another keyword.",
            showLoader: false
          });
        }
      } else {
        this.setState({
          searchResults: [],
          noResults: true,
          noResultsMessage: "Something went wrong. Please try again.",
          showLoader: false
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        searchResults: [],
        noResults: true,
        noResultsMessage: "Something went wrong. Please try again.",
        showLoader: false
      });
    }
  };

  getRecentSearch = centreId => {
    const terms = localStorage.getItem(
      `RecentSearchFor${centreId.split("-").pop()}`
    );
    let recentSearches = [];
    if (terms) {
      if (terms.indexOf(",") !== -1) {
        terms.split(",").map(item => {
          recentSearches.push(item);
        });
      } else {
        recentSearches.push(terms);
      }
    }
    this.setState({ recentSearches });
  };

  setRecentSearch = (func, centreId, item) => {
    const { recentSearches } = this.state;
    let items = recentSearches;

    if (func === "add") {
      const termExists = items.findIndex(el => el === item);
      if (termExists > -1) {
        items.splice(termExists, 1);
      }
      if (items.length === 3) {
        items.pop();
      }
      items.unshift(item);
    } else {
      items.splice(item, 1);
    }
    this.setState({ recentSearches: items });
    localStorage.setItem(`RecentSearchFor${centreId.split("-").pop()}`, items);
  };

  handleKeyDown = e => {
    const code = e.charCode || e.keyCode;
    if (code === 27) {
      this.setState({ searchTerm: "" }, () => this.handleScroll());
    }
  };

  handleFocus = () => {
    if (document) {
      document.querySelector("#consumer-search-bar").focus();
    }
  };

  renderSuggestion = (suggestion, query) => {
    const name = suggestion.value;
    const matches = AutosuggestHighlightMatch(name, query);
    const parts = AutosuggestHighlightParse(name, matches);
    return parts.map((part, index) => {
      const className = part.highlight ? "highlight" : null;
      return (
        <span className={`name ${className}`} key={index}>
          {part.text}
        </span>
      );
    });
  };

  render() {
    const { searchTerm, searchResults, noResults } = this.state;
    return (
      <div className="search-bar-container" id="search-bar">
        <div className="searchbar">
          {searchTerm ? (
            (searchResults && searchResults.length) || noResults ? (
              <i className="icon icon-outlined-search" />
            ) : (
              <div className="mini-loader" />
            )
          ) : (
            <i className="icon icon-outlined-search" />
          )}
          <input
            autoFocus={true}
            id="consumer-search-bar"
            type="text"
            placeholder="Search for anything"
            onKeyDown={e => this.handleKeyDown(e)}
            value={searchTerm}
            onChange={e => this.handleSearch(e.target.value)}
          />
          <div className="search-cancel" onClick={() => this.handleBlur()}>
            Cancel
          </div>
        </div>
        {this.renderContent()}
      </div>
    );
  }
}

export default SearchBar;

import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const NavList = ({ title, data }) => {
  return (
    <div className="list-wrapper">
      <div className="title">
        {title.url ? (
          <React.Fragment>
            <a href={title.url}>
              <Text field={title.name} />
            </a>
            <i className="icon icon-arrow-right" />
          </React.Fragment>) :
          <Text field={title.name} />
        }
      </div>
      {data.length > 0 && (
        <div className="content-list">
          <ul className="list">
            {data.map((item, index) => {
              let linkUrlLayout;
              if (item.LinkUrl) {
                linkUrlLayout =
                  <li key={`myId-${index}`}>
                    <a href={item.LinkUrl.value}>
                      {item.LinkName.value}
                    </a>
                  </li>
              }
              return linkUrlLayout;
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
export default NavList;

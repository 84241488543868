import React from "react";
import { Image, Text, Link, RichText } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import defaultImg from "../../../../assets/img/placeholders/gray.png";
import Details from "../../../Common/Details";

const FeatureCentreServicesCentreServicesListInlineImage = props => {
  const {
    Logo: logo = null,
    Title: title = null,
    Description: description = null,
    CentreHighlights: centreHighlights = null
  } = props.fields || {};

  if (props && props.rendering && props.rendering.dataSource) {
    return (
      <BaseComponent margin="standard">
        <div className="kids-family-services-main-container">
          <div className="row">
            <div className="col-sm-12">
              <div className="kids-family-services-title-container">
                <Image
                  media={{ ...logo.value, src: defaultImg }}
                  data-src={logo.value.src}
                  className="kids-family-services-logo lazyload"
                />
                <div className="kids-family-services-title">
                  <Text field={title} />
                </div>
              </div>
              <div className="kids-family-services-desc-container">
                <div className="kids-family-services-desc">
                  <Text field={description} />
                </div>
              </div>
              <div>
                {centreHighlights &&
                  centreHighlights.map((listItem, index) => {
                    const {
                      Image: image = null,
                      Summary: summary = null,
                      Name: name = null,
                      MapLink: mapLink = null,
                      Location: location = null
                    } = listItem.fields || {};
                    return (
                      <div
                        key={`myId-${index}`}
                        className="kids-family-services-content"
                      >
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="kids-family-services-content-title">
                              <Text field={name} />
                            </div>
                            <div className="kids-family-services-content-summary">
                              <RichText field={summary} />
                            </div>
                          </div>
                        </div>

                        {mapLink &&
                        mapLink.value &&
                        (mapLink.value.text || mapLink.value.href) ? (
                          <div className="kids-family-services-content-link">
                            <Details
                              iconValue="location"
                              linkUrl={
                                mapLink && mapLink.value && mapLink.value.href
                              }
                              mapInfo={
                                location && location.value ?
                                encodeURI(location.value)
                                : ""
                              }
                              linkName={
                                mapLink && mapLink.value && mapLink.value.text
                                  ? { value: mapLink.value.text }
                                  : ""
                              }
                              size="24"
                            />
                          </div>
                        ) : null}

                        <div className="kids-family-services-content-image-wrapper">
                          {image && image.value && image.value.src ? (
                            <Image
                              media={{ ...image.value, src: defaultImg }}
                              data-src={image.value.src}
                              className={`lazyload kids-family-services-content-image ${
                                centreHighlights.length === index + 1
                                  ? "no-margin-bottom"
                                  : ""
                              }`}
                            />
                          ) : (
                            <div
                              className={`kids-family-services-content-no-image ${
                                centreHighlights.length === index + 1
                                  ? "no-margin-bottom"
                                  : ""
                              }`}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  } else return null;
};

export default FeatureCentreServicesCentreServicesListInlineImage;

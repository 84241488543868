import React, { Fragment } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const Details = props => {
  const {
    iconValue = null,
    linkUrl = null,
    linkName = null,
    size = 16,
    mapInfo = null,
    noLocation = false,
    withViewOnMap = false
  } = props;
  if (iconValue && linkName && linkName.value) {
    return (
      <div className="details-wrapper">
        <i
          className={`icon icon-${iconValue} mr-1 mr-md-2`}
          style={{ fontSize: size + "px" }}
        />
        {linkUrl && linkUrl.length && noLocation ? (
          <a href={linkUrl}>
            <Text field={linkName} />
          </a>
        ) : linkUrl && linkUrl.length && withViewOnMap ? (
          <div className="separated-location">
            <span>
              <Text field={linkName} />
            </span>
            <a href={linkUrl + "#/profile?location=" + mapInfo}>View on map</a>
          </div>
        ) : linkUrl && linkUrl.length ? (
          <a href={linkUrl + "#/profile?location=" + mapInfo}>
            <Text field={linkName} />
          </a>
        ) : (
          <Text field={linkName} />
        )}
      </div>
    );
  } else return null;
};
export default Details;

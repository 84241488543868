export const Action_Constants = {
    show: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
    hide: "{7F58C8DD-D7C0-4FB7-BB44-8EC6B5E1C3D9}",
    enable: "{5744A87E-E32C-42CC-862F-96842A0202BB}",
    disable: "{C698C993-549E-486A-A09C-BB8D830DA958}",
}

export const MatchType_Constants = {
    any: "{365C94DA-C1CD-4783-A91D-0D17A16C7117}",
    all: "{4E50C172-7EA6-4989-82C3-75F24F80EF72}",
}

export const Operator_Constants = {
    is_equal_To: "{1D38B217-A2EE-4E7B-B6ED-13E751462FEB}",
    is_not_equal_to: "{49F47E77-E8C5-46F9-BF39-78D6B0D40B48}",
    contains: "{BF8935A6-1976-43A0-ABA5-D0BC128A76EA}",
    does_not_contain: "{45AAB0FB-775B-40F5-B3B8-7CAE3ABBF643}",
    starts_with: "{FD10F291-3C2E-4AE7-8A67-2F8271CB3DF2}",
    does_not_start_with: "{6B92597D-F2E0-47D3-A40D-59AFB37EEDE5}",
    ends_with: "{D375ED5B-E156-4A2B-9F91-DFD5B03F0D78}",
    does_not_end_with: "{F3AC7A1A-3458-4385-BB65-860315313DB3}",
    is_greater_than: "{61FF63A0-375C-47BD-9986-1F81BD12BBBB}",
    is_greater_than_or_equal_to: "{062C6ED9-EA6E-4A88-AE54-C88E2147971D}",
    is_less_than: "{8FE41E53-AD87-4D24-B50F-EA0F6BDF739F}",
    is_less_than_or_equal_to: "{88AC1C6B-BAFE-40A7-BB75-E304C8EC29DD}",
}

export const FieldTypes_Constants = {
  RECAPTCHA: "{E383BDE2-BC88-4278-83EF-832A15C9E94A}"
}
import React, { Component } from "react";
import { Link } from "@sitecore-jss/sitecore-jss-react";
class UniversalBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBanner: true
    };
  }

  handleClick = () => {
    this.setState({
      showBanner: false
    });
  };

  render() {
    const {
      BannerLink,
      BannerBackgroundColor,
      BannerBackgroundImage,
      BannerFontColor,
      BannerIcon
    } = this.props.data;
    let isDefault = true;
    if (
      (BannerBackgroundColor && BannerBackgroundColor.value) ||
      (BannerBackgroundImage && BannerBackgroundImage.value)
    ) {
      isDefault = false;
    }
    if (
      BannerLink &&
      BannerLink.value &&
      BannerLink.value.href &&
      BannerLink.value.text
    )
      return (
        <div
          className={
            "universal-banner " +
            (this.state.showBanner ? "" : "hidden ") +
            (this.props.isNavShown ? "is-hidden" : "") +
            (isDefault ? "" : " banner__themed")
          }
          style={{
            backgroundColor:
              BannerBackgroundColor && BannerBackgroundColor.value,
            backgroundImage:
              BannerBackgroundImage &&
                BannerBackgroundImage.value &&
                BannerBackgroundImage.value.src
                ? `url(${BannerBackgroundImage.value.src})`
                : null,
            color: BannerFontColor && BannerFontColor.value
          }}
        >
          <div className="container">
            <div className="universal-banner__details">
              {BannerIcon && BannerIcon.value ? (
                <i className={`icon banner-icon icon-${BannerIcon.value}`} />
              ) : (
                  ""
                )}
              <u>
                <Link field={BannerLink} />
              </u>
              <i
                className="icon banner-icon-close icon-outlined-x"
                onClick={this.handleClick}
              />
            </div>
          </div>
        </div>
      );
    else return null;
  }
}

export default UniversalBanner;

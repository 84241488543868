import React from "react";
import { Link, Image } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import defaultImg from "../../../../assets/img/placeholders/gray.png";

const FeatureGenericContentAdCampaign = props => {
  const {
    LandingPage = null,
    BackgroundColor = null,
    WebBanner = null,
    MobileBanner = null
  } = props.fields || {};

  const bgStyle = {
    backgroundColor: BackgroundColor && BackgroundColor.value ? BackgroundColor.value : "transparent"
  }

  if (
    LandingPage &&
    WebBanner &&
    WebBanner.fields &&
    WebBanner.fields.Image &&
    WebBanner.fields.Image.value &&
    WebBanner.fields.Image.value.src &&
    MobileBanner
  ) {
    return (
      <BaseComponent margin="standard">
        <div className="container">
          <div className={`display-advertising-container-leaderboard 
          ${WebBanner.fields.Orientation && WebBanner.fields.Orientation.value === "Billboard"
              ? "billboard"
              : ""}`}
            style={bgStyle}>

            <div className="display-advertising-image-wrapper-leaderboard">
              <Link field={LandingPage}>
                {WebBanner.fields.Orientation &&
                  WebBanner.fields.Orientation.value ? (
                    <Image
                      media={{ ...WebBanner.fields.Image.value, src: defaultImg }}
                      data-src={WebBanner.fields.Image.value.src}
                      className={`display-advertising-image${WebBanner.fields.Orientation.value === "Super Leaderboard" ? "-super lazyload"
                        : WebBanner.fields.Orientation.value === "Billboard" ? "-billboard lazyload"
                          : "-leaderboard lazyload"}`}
                    />
                    ) : (
                      <Image
                      media={{ ...WebBanner.fields.Image.value, src: defaultImg }}
                      data-src={WebBanner.fields.Image.value.src}
                      className="display-advertising-image-leaderboard lazyload"
                    />
                    )}
              </Link>
            </div>
          </div>
        </div>
        {MobileBanner.fields &&
          MobileBanner.fields.Orientation &&
          MobileBanner.fields.Orientation.value && (
            <div>
              {MobileBanner.fields.Orientation.value === "Square" ? (
                <div className={"display-advertising-container-mrec"} style={bgStyle}>
                  <div className="display-advertising-image-wrapper-mrec">
                    <Link field={LandingPage}>
                      <Image
                        media={{ ...MobileBanner.fields.Image.value, src: defaultImg }}
                        data-src={MobileBanner.fields.Image.value.src}
                        className="display-advertising-image-mrec lazyload"
                      />
                    </Link>
                  </div>
                </div>
              ) : (
                <div className={"display-advertising-container-lmb"} style={bgStyle}>
                  <div className="display-advertising-image-wrapper-lmb">
                    <Link field={LandingPage}>
                      <Image
                        media={{ ...MobileBanner.fields.Image.value, src: defaultImg }}
                        data-src={MobileBanner.fields.Image.value.src}
                        className="display-advertising-image-lmb lazyload"
                      />
                    </Link>
                  </div>
                </div>
              )}
            </div>
          )}
      </BaseComponent>
    );
  } else return null;
};

export default FeatureGenericContentAdCampaign;

import React, { Component } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import CustomDropdown from "../../../Common/CustomDropdown";
import GetAncestorElement from "../../../Common/Helper/GetAncestorElement";

class FeatureMetricsPopularTimes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeDay: null
    }
  }

  componentDidMount() {
    this.triggerHandleMouserOver();
    window.addEventListener("resize", this.triggerHandleMouserOver);
  }

  triggerHandleMouserOver = () => {
    const activeColumnList = document.querySelectorAll(".popular-times__chart--column.is-active");
    if (activeColumnList) {
      for (let i = 0; i < activeColumnList.length; i++) {
        let activeColumn = activeColumnList[i];
        this.handleMouseover(null, activeColumn);
      }
    }
  }

  renderChartNavigation = (Hours, MediumLimit, HighLimit) => {
    const currentHours = new Date().getHours();
    let currentTime;
    if (currentHours < 12) {
      if (currentHours == 0) {
        currentTime = "12am";
      } else {
        currentTime = currentHours + "am";
      }
    } else {
      if (currentHours == 12) {
        currentTime = currentHours + "pm";
      } else {
        currentTime = (currentHours - 12) + "pm";
      }
    }
    return (
      <div className="popular-times__chart--container is-active">
        {
          this.renderGraph(Hours, MediumLimit, HighLimit, currentTime)
        }
      </div>
    )
  }

  renderChartNonNavigation = (Days, activeDay, MediumLimit, HighLimit) => {
    return Days.map((item, index) => {
      const {
        Day,
        Hours
      } = item.fields || {}
      return (
        <div key={Day.value}
          className={`popular-times__chart--container 
      ${(index == 0 && activeDay == null) ||
              (activeDay == Day.value) ? "is-active" : ""}
      `}>
          {
            this.renderGraph(Hours, MediumLimit, HighLimit)
          }
        </div>
      )
    })
  }

  renderGraph = (hours, mediumLimit, highLimit, currentTime) => {
    let highNum = 0;
    let graphLayout;
    hours.map((item, index) => {
      if (item.fields) {
        var {
          Occupancy = null,
        } = item.fields;
      } else {
        var {
          Occupancy = null,
        } = item
      }
      if (parseInt(Occupancy.value) > parseInt(highNum)) {
        highNum = parseInt(Occupancy.value);
      }
    })
    graphLayout = hours.map((item, index) => {
      if (item.fields) {
        var {
          Time = null,
          Occupancy = null,
          Description = null,
          IsActive = null
        } = item.fields;
      } else {
        var {
          Time = null,
          Occupancy = null,
          Description = null,
          IsActive = null
        } = item;
      }
      let newHeight = 0;
      let counter = Occupancy.value
      let busyClass = "is-little-busy";
      if (highNum > 0) {
        if (highLimit && highLimit.value && mediumLimit && mediumLimit.value) {
          if (parseInt(counter) >= parseInt(highLimit.value)) {
            busyClass = "is-very-busy";
            newHeight = ((counter * highNum) / (highNum * highNum)) * 100;
          }
          else if (parseInt(counter) >= parseInt(mediumLimit.value)) {
            busyClass = "is-busy";
            newHeight = ((counter * highNum) / (highNum * highNum)) * 75;
          } else {
            newHeight = ((counter * highNum) / (highNum * highNum)) * 50;
          }
        } else {
          if (parseInt(counter) >= 1500) {
            busyClass = "is-very-busy";
            newHeight = ((counter * highNum) / (highNum * highNum)) * 100;
          }
          else if (parseInt(counter) >= 1000) {
            busyClass = "is-busy";
            newHeight = ((counter * highNum) / (highNum * highNum)) * 75;
          } else {
            newHeight = ((counter * highNum) / (highNum * highNum)) * 50;
          }
        }
      }

      let activeTime = false;
      if (currentTime) {
        if ((Time && Time.value) && (Time.value.toLowerCase() == currentTime)) {
          activeTime = true;
        }
      } else {
        if (IsActive && IsActive.value) {
          activeTime = true;
        }
      }

      return (
        <div key={counter + index}
          className={`popular-times__chart--column 
                    ${newHeight == 0 ? "no-height" : ""}
                    ${activeTime ? "is-active" : ""}
                    `}
          onMouseOver={this.handleMouseover}
          onClick={this.handleMouseover}
        >
          <div className={`popular-times__chart--column-block ${busyClass}`}
            style={{ height: newHeight + "%" }}
            disabled={true}
          >
          </div>
          <div className="popular-times__chart--tooltip">
            <Text field={Description} />
          </div>
        </div>
      )
    })
    return graphLayout;
  }

  renderDropdown = () => {
    const { Days } = this.props.fields || {}
    let dataDaysDropdown = [];
    let currentDay;
    Days.map((item, index) => {
      const { Day } = item.fields;
      if (index == 0) {
        currentDay = Day.value
      }
      dataDaysDropdown.push(
        {
          "fields": {
            "Id": {
              "value": Day.value
            },
            "Name": {
              "value": Day.value
            }
          }
        }
      )
    });
    return (
      <CustomDropdown
        data={dataDaysDropdown}
        placeholder={"Select Day"}
        defaultValue={currentDay}
        onChange={(daysValue, element) =>
          this.handleDropdown({ daysValue })
        }
      />
    )
  }

  handleDropdown = (value) => {
    this.setState({
      activeDay: value.daysValue
    })
  };

  handleMouseover = (event, activeColumn) => {
    let element;
    let chartColumnElement;
    if (activeColumn) {
      chartColumnElement = activeColumn;
    } else {
      element = event.target;
      const chartColumnContainer = GetAncestorElement(element, "popular-times__chart--column");
      if (chartColumnContainer) {
        chartColumnElement = chartColumnContainer;
      } else {
        chartColumnElement = element;
      }
    }
    const columnOffsetLeft = chartColumnElement.offsetLeft;
    const chartContainer = GetAncestorElement(chartColumnElement, "popular-times__chart--container");
    const tooltip = chartColumnElement.getElementsByClassName("popular-times__chart--tooltip")[0];
    if (element && element.classList.value == "popular-times__chart--tooltip") {
      chartContainer.classList.remove("on-mouse-over");
    } else {
      if (!(/no-height/.test(chartColumnElement.classList.value)) && !activeColumn) {
        chartContainer.classList.add("on-mouse-over");
      }
    }
    if (tooltip) {
      let tooltipWidth = tooltip.clientWidth / 2;
      let chartContainerWidth = chartContainer.clientWidth;
      if (columnOffsetLeft <= chartContainerWidth / 2) {
        if (tooltipWidth <= columnOffsetLeft) {
          tooltip.style.left = "-" + tooltipWidth + "px";
        } else {
          tooltip.style.left = "-" + columnOffsetLeft + "px";
        }
        tooltip.style.right = "auto";
      } else {
        let columnOffsetRight = chartContainerWidth - columnOffsetLeft - chartColumnElement.clientWidth;
        if (columnOffsetRight > tooltipWidth) {
          tooltip.style.right = "-" + tooltipWidth + "px";
        } else {
          tooltip.style.right = "-" + columnOffsetRight + "px";
        }
        tooltip.style.left = "auto";
      }
    }
  }

  handleMouseOut = (event) => {
    const element = event.target;
    const chartWrapperContainer = GetAncestorElement(element, "popular-times__chart--wrapper-graph");
    let chartWrapperElement;
    if (chartWrapperContainer) {
      chartWrapperElement = chartWrapperContainer;
    } else {
      chartWrapperElement = element;
    }
    if (chartWrapperElement) {
      chartWrapperElement.getElementsByClassName("popular-times__chart--container")[0].classList.remove("on-mouse-over");
    }
  }

  render() {
    const { activeDay } = this.state;
    const {
      Title = null,
      Days = null,
      MediumLimit = null,
      HighLimit = null,
      PopularTimes = null
    } = this.props.fields || {}
    const {
      Hours = null,
      LeastBusy = null
    } = PopularTimes || {}
    const {
      isNavigation = null
    } = this.props || {}

    return (
      <div className="popular-times">
        {
          ((Days && Days.length) ||
            (PopularTimes && PopularTimes.Hours && PopularTimes.Hours.length)) &&
          <div className="popular-times__wrapper">
            {
              Title &&
              Title.value &&
              <div className="popular-times__title">
                <Text field={Title} />
              </div>
            }
            {!isNavigation &&
              <div className="popular-times__dropdown">
                {this.renderDropdown()}
              </div>
            }
            <div className="popular-times__chart">
              <div className="popular-times__chart--wrapper-graph"
                onMouseOut={this.handleMouseOut}
              >
                {
                  Days && Days.length
                    ? this.renderChartNonNavigation(Days, activeDay, MediumLimit, HighLimit)
                    : this.renderChartNavigation(Hours, MediumLimit, HighLimit)
                }
                <hr className="popular-times__chart--underline one" />
                <hr className="popular-times__chart--underline two" />
                <hr className="popular-times__chart--underline three" />
                <hr className="popular-times__chart--underline four" />
                <div className="popular-times__chart--wrapper-time">
                  <div className="popular-times__chart--time">
                    <div className="popular-times__chart--time-text">6am</div>
                  </div>
                  <div className="popular-times__chart--time">
                    <div className="popular-times__chart--time-text">7am</div>
                  </div>
                  <div className="popular-times__chart--time">
                    <div className="popular-times__chart--time-text">8am</div>
                  </div>
                  <div className="popular-times__chart--time is-shown">
                    <div className="popular-times__chart--time-text">9am</div>
                  </div>
                  <div className="popular-times__chart--time">
                    <div className="popular-times__chart--time-text">10am</div>
                  </div>
                  <div className="popular-times__chart--time">
                    <div className="popular-times__chart--time-text">11am</div>
                  </div>
                  <div className="popular-times__chart--time is-shown">
                    <div className="popular-times__chart--time-text">12pm</div>
                  </div>
                  <div className="popular-times__chart--time">
                    <div className="popular-times__chart--time-text">1pm</div>
                  </div>
                  <div className="popular-times__chart--time">
                    <div className="popular-times__chart--time-text">2pm</div>
                  </div>
                  <div className="popular-times__chart--time is-shown">
                    <div className="popular-times__chart--time-text">3pm</div>
                  </div>
                  <div className="popular-times__chart--time">
                    <div className="popular-times__chart--time-text">4pm</div>
                  </div>
                  <div className="popular-times__chart--time">
                    <div className="popular-times__chart--time-text">5pm</div>
                  </div>
                  <div className="popular-times__chart--time is-shown">
                    <div className="popular-times__chart--time-text">6pm</div>
                  </div>
                  <div className="popular-times__chart--time">
                    <div className="popular-times__chart--time-text">7pm</div>
                  </div>
                  <div className="popular-times__chart--time">
                    <div className="popular-times__chart--time-text">8pm</div>
                  </div>
                  <div className="popular-times__chart--time is-shown">
                    <div className="popular-times__chart--time-text">9pm</div>
                  </div>
                  <div className="popular-times__chart--time">
                    <div className="popular-times__chart--time-text">10m</div>
                  </div>
                  <div className="popular-times__chart--time">
                    <div className="popular-times__chart--time-text">11pm</div>
                  </div>
                </div>
              </div>
            </div>
            {((Days && Days.length) ||
              (LeastBusy && LeastBusy.value)) &&
              <div className="popular-times__description">

                {Days && Days.length ?
                  Days.map((item, index) => {
                    const {
                      Day,
                      LeastBusy
                    } = item.fields || {}
                    let leastBusyLayout;
                    if (LeastBusy && LeastBusy.value) {
                      leastBusyLayout =
                        <div>
                          <div className={`popular-times__description--busy 
                    ${(index == 0 && activeDay == null) ||
                              (activeDay == Day.value) ? "is-active" : ""}
                    `}>
                            <div className="popular-times__description--indicator is-little-busy"></div>
                            <div className="popular-times__description--text">{"Least busy:\u00A0"}</div>
                          </div>
                          <div key={Day.value + index}
                            className={`popular-times__description--time 
                    ${(index == 0 && activeDay == null) ||
                                (activeDay == Day.value) ? "is-active" : ""}
                    `}>
                            <Text field={LeastBusy} />
                          </div>
                        </div>
                    }
                    return leastBusyLayout;
                  })
                  : (LeastBusy && LeastBusy.value ?
                    <div>
                      <div className="popular-times__description--busy">
                        <div className="popular-times__description--indicator is-little-busy"></div>
                        <div className="popular-times__description--text">{"Least busy:\u00A0"}</div>
                      </div>
                      <div className="popular-times__description--time is-active">
                        <Text field={LeastBusy} />
                      </div>
                    </div>
                    : "")
                }
              </div>
            }
          </div>
        }
      </div>
    );
  }
}

export default FeatureMetricsPopularTimes;
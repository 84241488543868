import React, { Fragment } from "react";
import { Image, Text, Link, RichText } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import defaultImg from "../../../../assets/img/placeholders/gray.png";
import Details from "../../../Common/Details";

const FeatureCentreServicesCentreServicesListLeftImage = props => {
  const {
    Logo: logo = null,
    Title: title = null,
    Description: description = null,
    CentreHighlights: centreHighlights = null
  } = props.fields || {};
  if (props && props.rendering && props.rendering.dataSource) {
    return (
      <BaseComponent margin="standard">
        <div className="centre-services-main-container">
          <div className="centre-services-title-container">
            <Image
              media={{ ...logo.value, src: defaultImg }}
              data-src={logo.value.src}
              className="centre-services-logo lazyload"
            />
            <div className="centre-services-title">
              <Text field={title} />
            </div>
          </div>
          <div className="centre-services-desc-container">
            <div className="centre-services-desc">
              <Text field={description} />
            </div>
          </div>
          <Fragment>
            {centreHighlights &&
              centreHighlights.map((listItem, index) => {
                const {
                  Image: image = null,
                  Summary: summary = null,
                  Name: name = null,
                  MapLink: mapLink = null,
                  Location: location = null
                } = listItem.fields || {};

                return (
                  <div
                    key={`myId-${index}`}
                    className="centre-services-content"
                  >
                    <div className="centre-services-content-image-wrapper">
                      {image && image.value && image.value.src ? (
                        <Image
                          media={{ ...image.value, src: defaultImg }}
                          data-src={image.value.src}
                          className="centre-services-content-image lazyload"
                        />
                      ) : (
                        <div className="centre-services-content-no-image">
                          &nbsp;
                        </div>
                      )}
                    </div>
                    <div className="centre-services-content-desc-wrapper">
                      <div className="centre-services-content-title">
                        <Text field={name} />
                      </div>

                      <div className="centre-services-content-summary">
                        <RichText field={summary} />
                      </div>

                      {/* {
                        link && link.value && (link.value.text || link.value.href) 
                        ? (
                          <div className="centre-services-content-link">
                            <Link field={link}/>
                          </div>
                        )
                        : null
                      } */}

                      {mapLink &&
                      mapLink.value &&
                      (mapLink.value.text || mapLink.value.href) ? (
                        <div className="centre-services-content-link">
                          <Details
                            iconValue="location"
                            linkUrl={
                              mapLink && mapLink.value && mapLink.value.href
                            }
                            mapInfo={
                              location && location.value ? 
                              encodeURI(location.value)
                              : ""
                            }
                            linkName={
                              mapLink && mapLink.value && mapLink.value.text
                                ? { value: mapLink.value.text }
                                : ""
                            }
                            size="24"
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })}
          </Fragment>
        </div>
      </BaseComponent>
    );
  } else return null;
};

export default FeatureCentreServicesCentreServicesListLeftImage;

import React, { Component } from "react";
import axios from "axios";
import { Image, Text, Link, RichText } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import defaultImg from "../../../../assets/img/placeholders/gray.png";
import FeatureMapsCentreMap from "../../../Feature/Maps/CentreMap";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

class RetailerStorefrontRetailerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: null,
      price_level: null,
      categoryCount: 0,
    };
  }

  componentDidMount() {
    const {
      PlaceId = null,
      HasBooking = null,
      BrauzFormId = null
    } = this.props.fields || {};
    if (PlaceId) {
      this.getGoogleRating(PlaceId.value);
    }
    let centre = window.document.getElementsByName("ContextItem")[0].dataset
      .centre;
    let el = window.document.getElementsByClassName("retailer-details__map--parking__details--available");
    if (centre !== "robina" && el && el[0]) {
      el[0].classList.add("hidden");
    }

    if (document) {
      setTimeout(() => {
        const metaCentre = document.querySelector('meta[name="centrename"]');
        if (metaCentre) {
          const giftCardText =
            "Accepts " +
            metaCentre.getAttribute("content") +
            " Giftcards";
          const giftCardContainer = document
            .getElementsByClassName("retailer-details__wrapper")[0]
            .querySelectorAll(".js-gift-card");
          if (giftCardContainer) {
            for (var i = 0; i < giftCardContainer.length; ++i) {
              giftCardContainer[i].innerHTML = giftCardText.toLowerCase();
            }
          }
        }
      }, 1000);
      this.updateStorefrontUrl();
      this.categoryTagsDisplay();
    }

    if (window) {
      window.addEventListener("resize", this.categoryTagsDisplay);
    }

    if (HasBooking && HasBooking.value && BrauzFormId && BrauzFormId.value) {
      const brauzPackageURL = "https://d3aq2u4yw77ivo.cloudfront.net/retailer-appointment/public/1.0/bookAStylist.prod.js";
      this.loadScript(brauzPackageURL, () => {
        window.Brauz.initializeBrauzBookAStylist(BrauzFormId.value);
      });
    }
  }

  updateStorefrontUrl = () => {
    const title = document.title;
    let state = "";
    let url = "";
    let urlHref = window.location.href;
    if (urlHref.indexOf("profile") <= -1) {
      console.log("no-location");
      const { LocationMapTargetId: locationMapTargetId = null } =
        this.props.fields || {};
      state = locationMapTargetId.value;
      if (locationMapTargetId && urlHref.slice(-2) !== "#/") {
        url = urlHref + "#/profile?location=" + state;
        window.history.pushState({ mapId: state }, title, url);
      } else {
        url = urlHref + "profile?location=" + state;
        window.history.pushState({ mapId: state }, title, url);
      }
    } else {
      url = urlHref.replace("#/#/", "#/");
      window.history.pushState({ mapId: state }, title, url);
    }
  }

  categoryTagsDisplay = () => {
    if (window && window.outerWidth >= 992) {
      let categoryWrapper = document.querySelector(".retailer-details__category");

      if (categoryWrapper) {
        let hasShowAll = categoryWrapper.querySelector(".show-all-list");

        if (!hasShowAll) {
          let categoryLabel = categoryWrapper.querySelector(".retailer-details__category--label");
          let categoryList = categoryWrapper.querySelectorAll(".retailer-details__category--link__list");
          let categoryBtn = categoryWrapper.querySelector(".retailer-details__category--link__more");
          let totalWidth = 0;
          let hiddenListCount = 0;

          categoryBtn.classList.remove("is-hidden");
          Array.prototype.slice.call(categoryList).map((categoryItem, index) => {
            categoryItem.classList.remove("is-hidden");
          })

          let categoryWrapperWidth = categoryWrapper.clientWidth;
          let categoryLabelWidth = categoryLabel.clientWidth + 16;
          let categoryBtnWidth = categoryBtn.clientWidth + 15;

          Array.prototype.slice.call(categoryList).map((categoryItem, index) => {
            let categoryWidth = categoryItem.clientWidth + 8;
            totalWidth = categoryWidth + totalWidth;

            if (categoryWrapperWidth < (categoryLabelWidth + categoryBtnWidth + totalWidth)) {
              categoryItem.classList.add("is-hidden");
              hiddenListCount++;
            }
          })
          if (hiddenListCount > 0) {
            this.setState({
              categoryCount: hiddenListCount
            })
          } else {
            categoryBtn.classList.add("is-hidden");
          }
        }
      }
    }
  }
  loadScript(url = "", callback = () => { }) {
    if (url) {
      const script = document.createElement("script");
      script.setAttribute("type", "text/javascript");
      script.setAttribute("src", url);
      document.body.appendChild(script);
      script.onload = callback;
    }
  }

  handleClickBrauzBtn = (id) => {
    window.Brauz_open_book_a_stylist_dialog_with_selected_store(id);
  }

  getGoogleRating(placeId) {
    let url = `/api/sitecore/placeratingandprice/GetPlaceRatingAndPrice?place_id=${placeId}`;
    axios({
      url,
      method: "GET"
    })
      .then(response => {
        if (
          response.data &&
          response.data.result &&
          response.data.status == "OK"
        ) {
          this.setState({
            rating: response.data.result.Rating
              ? response.data.result.Rating
              : null,
            price_level: response.data.result.Price_level
              ? response.data.result.Price_level
              : null
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  renderRatings() {
    let ratingsLayout = "";
    let rating = [];
    let priceLevel = [];

    if (this.state.rating) {
      const ratingValue = parseInt(this.state.rating);

      for (let i = 1; i <= 5; i++) {
        if (i <= ratingValue) {
          rating.push(<div className="icon icon-star checked" key={i} />);
        } else {
          rating.push(<div className="icon icon-star" key={i} />);
        }
      }
    }

    if (this.state.price_level) {
      const priceLevelValue = parseInt(this.state.price_level);
      if (priceLevelValue <= 1) {
        priceLevel.push(
          <i className="icon icon-dollar" key={`priceLevelId`} />
        );
      } else if (priceLevelValue === 2 || priceLevelValue === 3) {
        for (let i = 0; i < 2; i++) {
          priceLevel.push(
            <i className="icon icon-dollar" key={`priceLevelId` + i} />
          );
        }
      } else if (priceLevelValue >= 4) {
        for (let i = 0; i < 3; i++) {
          priceLevel.push(
            <i className="icon icon-dollar" key={`priceLevelId` + i} />
          );
        }
      }
    }
    if (rating.length > 0 || priceLevel.length > 0) {
      ratingsLayout =
        <div className="retailer-details__info--rating">
          {priceLevel.length > 0 &&
            <div className="retailer-details__info--rating__dollar">
              <span>{priceLevel}</span>
            </div>
          }
          {rating.length > 0 &&
            <div className="retailer-details__info--rating__star">
              {rating}
            </div>
          }
        </div>
    }
    return ratingsLayout;
  }

  renderLogo = (Logo, retailerName) => {
    let logoLayout;

    if (Logo) {
      if (!Logo.value.src) {
        logoLayout =
          <div className="retailer-details__title">
            <Text field={retailerName} />
          </div>

      } else {
        logoLayout =
          <Image
            className="img-fluid lazyload"
            media={{
              ...Logo,
              src: defaultImg,
              alt: Logo && Logo.value && Logo.value.alt ? Logo.value.alt : ""
            }}
            data-src={Logo.value.src}
          />
      }
    } else {
      logoLayout =
        <div className="retailer-details__title">
          <Text field={retailerName} />
        </div>
    }

    return logoLayout;
  };

  renderOpeningHours = () => {
    const {
      OpeningToday = null,
      OpeningHours = null,
      HideOpeningHours = null
    } = this.props.fields || {};
    let openingHoursLayout;

    openingHoursLayout = (
      <div className="retailer-details__info--openinghours">
        {(HideOpeningHours == null || (HideOpeningHours && !HideOpeningHours.value)) && (
          <input
            type="checkbox"
            id={`openingHoursUpdate`}
            className="retailer-details__info--openinghours__toggle"
          />
        )}
        <label
          className={
            "retailer-details__info--openinghours__title " +
            (HideOpeningHours && HideOpeningHours.value ? "hide-openinghours" : "")
          }
          htmlFor={`openingHoursUpdate`}
        >
          <i className="icon icon-time" />
          <span>
            <Text field={OpeningToday} />
          </span>
        </label>

        {(HideOpeningHours == null || (HideOpeningHours && !HideOpeningHours.value)) && (
          <div className="retailer-details__info--openinghours__content">
            {OpeningHours &&
              OpeningHours.length > 0 &&
              OpeningHours.map((item, index) => {
                const {
                  Day = null,
                  Time = null,
                  SecondaryTime = null
                } = item.fields || {};
                return (
                  <div className="row" key={`rowID-${index}`}>
                    <div className="col-4 col-md-5 col-lg-4 col-xl-3 retailer-details__info--openinghours__content-col">
                      <Text field={Day} />
                    </div>
                    <div className="col-8 col-md-7 col-lg-8 col-xl-9 retailer-details__info--openinghours__content-col">
                      <Text field={Time} />
                      {SecondaryTime && SecondaryTime.value && (
                        <React.Fragment>
                          <br />
                          <Text field={SecondaryTime} />
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    );
    return openingHoursLayout;
  }

  renderActions = () => {
    let phoneLayout,
      bookingLayout,
      uberLayout,
      viewMenuLayout;

    let hasPhone = false,
      hasBookingUrl = false,
      hasUber = false,
      hasViewMenu = false,
      counter = 0;
    const {
      PhoneNumber = null,
      BookUberTitle = null,
      BookUberLink = null,
      ViewMenuURL = null,
      LocationMapTargetId = null,
      HasBooking = null,
      BookingIcon = null,
      BookingUrl = null,
      BrauzFormId = null,
      BookingLabel = null
    } = this.props.fields || {};

    if (PhoneNumber && PhoneNumber.value) {
      hasPhone = true;
      counter++;
    }
    if ((HasBooking && HasBooking.value && BrauzFormId && BrauzFormId.value) ||
      (BookingUrl && BookingUrl.value)) {
      hasBookingUrl = true;
      counter++;
    }
    if (BookUberTitle && BookUberTitle.value && BookUberLink && BookUberLink.value) {
      hasUber = true;
      counter++;
    }
    if (ViewMenuURL && ViewMenuURL.value && ViewMenuURL.value.href) {
      hasViewMenu = true;
      counter++;
    }
    if (PhoneNumber && PhoneNumber.value) {
      phoneLayout =
        <div className={`retailer-details__info--list__item has-action 
        ${hasBookingUrl || hasUber || hasViewMenu ? "is-half-width" : ""}`}
          key="phoneLayout">
          <i className="icon icon-phone-call" />
          <div className="text-block">
            <a href={"tel:" + PhoneNumber.value}>
              <Text field={PhoneNumber} />
            </a>
          </div>
        </div>
    }

    if ((HasBooking && HasBooking.value && BrauzFormId && BrauzFormId.value) ||
      (BookingUrl && BookingUrl.value)) {
      bookingLayout =
        <div className={`retailer-details__info--list__item has-action
        ${hasPhone || hasUber || hasViewMenu ? "is-half-width" : ""}`}
          key="bookingLayout">
          {BookingIcon && BookingIcon.value ? (
                  <i
                  className={`icon icon-${BookingIcon.value}`}/>
                ) : 
                <i className="icon icon-calendar-2"/>
              }
          <div className="text-block">
            {HasBooking.value ?
              <button onClick={() => this.handleClickBrauzBtn(LocationMapTargetId.value)}>
                <Text field={BookingLabel} />
              </button>
              : <a href={BookingUrl.value} target="_blank">
                <Text field={BookingLabel} />
              </a>
            }

          </div>
        </div>
    }

    if (BookUberTitle && BookUberTitle.value && BookUberLink && BookUberLink.value) {
      uberLayout =
        <div className={`retailer-details__info--list__item is-uber has-action
        ${(counter == 1 || (hasPhone && hasBookingUrl && counter == 3)) ? "" : "is-half-width"}`}
          key="uberLayout">
          <i className="icon icon-uber" />
          <div className="text-block">
            <a href={BookUberLink.value} target="_blank">
              <Text field={BookUberTitle} />
            </a>
          </div>
        </div>
    }

    if (ViewMenuURL && ViewMenuURL.value && ViewMenuURL.value.href) {
      viewMenuLayout =
        <div className={`retailer-details__info--list__item has-action
        ${(counter == 1 || counter == 3) ? "" : "is-half-width"}`}
          key="viewMenuLayout">
          <i className="icon icon-menu" />
          <div className="text-block">
            <a href={ViewMenuURL.value.href} target="_blank">
              {"View menu"}
            </a>
          </div>
        </div>
    }

    return ([phoneLayout, bookingLayout, uberLayout, viewMenuLayout]);
  }

  renderTags = () => {
    let tagsLayout;
    const {
      IsVegetarian = null,
      IsKidsFriendly = null,
      AfterPay = null,
      GiftCards = null,
      OrderAndCollect = null,
    } = this.props.fields || {};

    if ((AfterPay && AfterPay.value) ||
      (GiftCards && GiftCards.value) ||
      (IsKidsFriendly && IsKidsFriendly.value) ||
      (IsVegetarian && IsVegetarian.value) ||
      (OrderAndCollect && OrderAndCollect.value)) {
      tagsLayout =
        <div className="retailer-details__tags">

          {GiftCards && GiftCards.value &&
            <div className="retailer-details__tags--item">
              <i className="icon icon-gift-card-2" />
              <span className="js-gift-card">
                {"Giftcards"}
              </span>
            </div>
          }

          {AfterPay && AfterPay.value &&
            <div className="retailer-details__tags--item">
              <i className="icon icon-afterpay-2" />
              <span>
                {"Accepts AfterPay"}
              </span>
            </div>
          }

          {IsVegetarian && IsVegetarian.value &&
            <div className="retailer-details__tags--item">
              <i className="icon icon-leaf" />
              <span>
                {"Vegetarian"}
              </span>
            </div>
          }

          {IsKidsFriendly && IsKidsFriendly.value &&
            <div className="retailer-details__tags--item">
              <i className="icon icon-parent" />
              <span>
                {"Kids Friendly"}
              </span>
            </div>
          }

          {OrderAndCollect && OrderAndCollect.value &&
            <div className="retailer-details__tags--item">
              <i className="icon icon-order-and-collect" />
              <span>
                {"Accepts Order & Collect"}
              </span>
            </div>
          }
        </div>
    }

    return tagsLayout;
  }

  renderSocialLink = () => {
    const {
      RetailerWebsite = null,
      FacebookLink = null,
      InstagramLink = null,
    } = this.props.fields || {};

    let socialLayout = "";

    if ((RetailerWebsite && RetailerWebsite.value) ||
      (FacebookLink && FacebookLink.value) ||
      (InstagramLink && InstagramLink.value)) {
      socialLayout =
        <div className="retailer-details__social">
          {RetailerWebsite &&
            RetailerWebsite.value &&
            <a className="retailer-details__social--item"
              href={RetailerWebsite.value}
              target="_blank" >
              <i className="icon icon-globe" />
              <div className="text-block">
                {"Website"}
              </div>
            </a>
          }
          {FacebookLink &&
            FacebookLink.value &&
            <a className="retailer-details__social--item"
              href={FacebookLink.value}
              target="_blank" >
              <i className="icon icon-facebook-2" />
              <div className="text-block">
                {"Facebook"}
              </div>
            </a>
          }
          {InstagramLink &&
            InstagramLink.value &&
            <a className="retailer-details__social--item"
              href={InstagramLink.value}
              target="_blank" >
              <i className="icon icon-instagram-2" />
              <div className="text-block">
                {"Instagram"}
              </div>
            </a>
          }
        </div>
    }

    return socialLayout;
  }

  renderCategoryTags = () => {
    const {
      CategoryTags = null
    } = this.props.fields || {};

    const {
      categoryCount
    } = this.state;
    let categoryLayout;

    if (CategoryTags && CategoryTags.length > 0) {
      categoryLayout =
        <div className="retailer-details__category">
          <div className="retailer-details__category--label">
            {"Tags"}
          </div>
          <ul className="retailer-details__category--link">
            {CategoryTags.map((item, index) => {
              const {
                CategoryName = null,
                CategoryLink = null
              } = item.fields || {}

              let categoryItem = "";

              if (CategoryName && CategoryName.value && CategoryLink && CategoryLink.value) {
                categoryItem =
                  <li className="retailer-details__category--link__list" key={CategoryName.value + index}>
                    <a href={CategoryLink.value} target="">
                      <Text field={CategoryName} />
                    </a>
                  </li>
              }
              return categoryItem
            })
            }
            <li className={`retailer-details__category--link__more
            ${categoryCount == 0 ? "is-hidden" : ""}
            `}>
              <button onClick={this.categoryBtnOnClick}>
                {"+"}
                {categoryCount}
                {" more"}
              </button>
            </li>
          </ul>
        </div>
    }

    return categoryLayout;
  }

  categoryBtnOnClick = () => {
    let categoryLinkWrapper = document.querySelector(".retailer-details__category--link");
    if (categoryLinkWrapper) {
      categoryLinkWrapper.classList.add("show-all-list");
    }
  }
  render() {
    const {
      Logo = null,
      RetailerName = null,
      RetailerDescription = null,
      StoreLocation = null,
      StoreLocationLink = null,
      BestParkingLabel = null,
      BestParking = null,
      ParkingAvailable = null,
      ParkingPageLink = null,
      HeroImage = null,
      HeaderCarousel = null,
      LocationMapTargetId = null,
      MapDetails = null,
      MapVenue = null,
      IconColour = null,
      IconCharacters = null,
    } = this.props.fields || {};

    const parkColour = (IconColour && IconColour.value ? IconColour.value : "");
    const parkText = (IconCharacters && IconCharacters.value ? IconCharacters.value : "P");

    return (
      <BaseComponent margin="custom">
        <div className={`retailer-details__wrapper font-regular
        ${((HeroImage && HeroImage.value && HeroImage.value.src) || (HeaderCarousel &&
            HeaderCarousel.length > 0)) ? "" : "no-banner"}`}>
          <div className="retailer-details__banner">
            {HeaderCarousel && HeaderCarousel.length > 0 ?
              <CarouselProvider
                totalSlides={HeroImage && HeroImage.value && HeroImage.value.src ? HeaderCarousel.length + 1 : HeaderCarousel.length}
                naturalSlideWidth={1}
                naturalSlideHeight={1}
                visibleSlides={1}
                step={1}
              >
                <Slider className={"slider"}>
                  {HeroImage &&
                    HeroImage.value &&
                    HeroImage.value.src &&
                    <Slide>
                      <Image
                        className="lazyload"
                        media={{ ...HeroImage.value, src: defaultImg }}
                        data-src={HeroImage.value.src}
                      />
                    </Slide>
                  }
                  {HeaderCarousel.map((item, index) => {
                    const {
                      GalleryImage = null
                    } = item.fields;
                    let imageLayout;

                    if (GalleryImage && GalleryImage.value && GalleryImage.value.src) {
                      imageLayout =
                        <Slide index={index} key={"HeaderCarousel-Storefront" + index}>
                          <Image
                            className="lazyload"
                            media={{ ...GalleryImage.value, src: defaultImg }}
                            data-src={GalleryImage.value.src}
                          />
                        </Slide>
                    }
                    return imageLayout;
                  })}
                </Slider>
                <div className="retailer-details__button">
                  <ButtonBack className="retailer-details__button--prev">
                    <i className="icon icon-arrow-left" />
                  </ButtonBack>
                  <ButtonNext className="retailer-details__button--next">
                    <i className="icon icon-arrow-right" />
                  </ButtonNext>
                </div>
                <DotGroup />
              </CarouselProvider>
              : HeroImage && HeroImage.value && HeroImage.value.src ?
                <div className="retailer-details__banner--hero">
                  <Image
                    className="lazyload"
                    media={{ ...HeroImage.value, src: defaultImg }}
                    data-src={HeroImage.value.src}
                  />
                </div>
                : ""
            }
          </div>
          <div className="container">
            <div className="retailer-details__content">
              <div className="row">
                <div className="retailer-details__logo--wrapper col col-12 col-md-7 col-lg-8">
                  <div className="retailer-details__logo">
                    {this.renderLogo(Logo, RetailerName)}
                  </div>
                  <h1 className="retailer-details__name">
                    <Text field={RetailerName} />
                  </h1>
                  {RetailerDescription &&
                    RetailerDescription.value &&
                    <div className="retailer-details__description">
                      <RichText field={RetailerDescription} />
                    </div>
                  }
                  {this.renderSocialLink()}
                </div>
                <div className="retailer-details__info col col-12 col-md-5 col-lg-4">
                  <div className="retailer-details__info--list">
                    <div className="retailer-details__info--list__item">
                      {this.renderOpeningHours()}
                    </div>
                    {this.renderActions()}
                  </div>
                  {this.renderRatings()}
                  {this.renderSocialLink()}
                </div>
              </div>
              {((RetailerName && RetailerName.value && StoreLocation && StoreLocation.value)
                || (RetailerName && RetailerName.value && BestParking && BestParking.value)) &&
                <div className="retailer-details__map--info">
                  <div className="row">
                    <div className="col col-12 col-lg-8">
                      <div className="retailer-details__map--location">
                        <div className="retailer-details__map--location__logo">
                          {this.renderLogo(Logo, RetailerName)}
                          <i className="icon icon-location"></i>
                        </div>
                        {(RetailerName && RetailerName.value ||
                          StoreLocation && StoreLocation.value) &&
                          <div className="retailer-details__map--location__details">
                            {RetailerName && RetailerName.value &&
                              <span className="retailer-details__map--location__details--name">
                                <Text field={RetailerName} />
                                {": "}
                              </span>
                            }
                            {StoreLocation && StoreLocation.value &&
                              <span className="retailer-details__map--location__details--loc">
                                <Text field={StoreLocation} />
                              </span>
                            }
                          </div>
                        }
                      </div>
                      {BestParking &&
                        BestParking.value &&
                        <div className="retailer-details__map--parking">
                          <div className="retailer-details__map--parking__icon" style={{ borderColor: parkColour, backgroundColor: parkColour }}>
                            <div className="retailer-details__map--parking__icon-border">
                              <div className="retailer-details__map--parking__icon-text">{parkText}</div>
                            </div>
                          </div>
                          <div className="retailer-details__map--parking__details">

                            <span className="retailer-details__map--parking__details--best">
                              {BestParkingLabel && BestParkingLabel.value ?
                                <Text field={BestParkingLabel} />
                                : "Best parking:"
                              }
                              {" "}
                            </span>
                            <span className="retailer-details__map--parking__details--location">
                              <Text field={BestParking} />
                            </span>
                            {ParkingAvailable &&
                              ParkingAvailable.value &&
                              <span className="retailer-details__map--parking__details--available">
                                <Text field={ParkingAvailable} />
                                {" car spots available"}
                              </span>
                            }
                          </div>
                        </div>
                      }
                    </div>
                    <div className="col col-12 col-lg-4">
                      <div className="retailer-details__map--link-container">
                        {StoreLocationLink &&
                          StoreLocationLink.value &&
                          StoreLocationLink.value.href &&
                          <div className={`retailer-details__map--link is-centre-map ${ParkingPageLink && ParkingPageLink.value && ParkingPageLink.value.href ? "is-half-width" : ""}`}>
                            {(LocationMapTargetId && LocationMapTargetId.value) ?
                              <a href={StoreLocationLink.value.href +
                                "#/profile?location=" +
                                LocationMapTargetId.value}
                                target={StoreLocationLink.value.target} >
                                {StoreLocationLink.value.text}
                              </a> :
                              <Link field={StoreLocationLink} />
                            }
                          </div>
                        }

                        {ParkingPageLink &&
                          ParkingPageLink.value &&
                          ParkingPageLink.value.href &&
                          <div className={`retailer-details__map--link ${StoreLocationLink && StoreLocationLink.value && StoreLocationLink.value.href ? "is-half-width" : ""}`}>
                            <Link field={ParkingPageLink} />
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          {MapVenue && MapVenue.value &&
            <div className="retailer-details__map">
              <FeatureMapsCentreMap
                mapVenue={MapVenue.value}
                retailerStorefrontMap={true}
                MapDetails= {MapDetails}
              />
            </div>
          }

          <div className="container">
            <div className="retailer-details__map--link-container is-desktop-hidden">
              {StoreLocationLink &&
                StoreLocationLink.value &&
                StoreLocationLink.value.href &&
                <div className={`retailer-details__map--link is-centre-map ${ParkingPageLink && ParkingPageLink.value && ParkingPageLink.value.href ? "is-half-width" : ""}`}>
                  {(LocationMapTargetId && LocationMapTargetId.value) ?
                    <a href={StoreLocationLink.value.href +
                      "#/profile?location=" +
                      LocationMapTargetId.value}
                      target={StoreLocationLink.value.target} >
                      {StoreLocationLink.value.text}
                    </a> :
                    <Link field={StoreLocationLink} />
                  }
                </div>
              }

              {ParkingPageLink &&
                ParkingPageLink.value &&
                ParkingPageLink.value.href &&
                <div className={`retailer-details__map--link ${StoreLocationLink && StoreLocationLink.value && StoreLocationLink.value.href ? "is-half-width" : ""}`}>
                  <Link field={ParkingPageLink} />
                </div>
              }
            </div>
            {this.renderTags()}
            {this.renderCategoryTags()}
          </div>
        </div>
      </BaseComponent >
    );
  }
}

export default RetailerStorefrontRetailerDetails;

import React, { Component } from "react";
import defaultImg from "../../../assets/img/placeholders/transparent.png";
import { ReactSVG } from "react-svg";
import { Image } from "@sitecore-jss/sitecore-jss-react";

class HeaderImageFormat extends Component {
  constructor(props) {
    super(props);
  }

  validateImageFormat = (logo, uniqueKey, isSticky, simpleNav, logoLayout, isGeneric) => {
    const imageSource = logo.value.src;

    const splitLogoComponent = (sticky, src, simpleState) => {
      return (
        <div className={`logo-container ${isSticky ? 'remove-indent-sticky' : isGeneric ? 'remove-indent-generic' : !simpleNav ? 'remove-indent-non-sticky' : ''}`}>
          {['above', 'below'].map((pos) => <div className={`cdn-url-image-${pos}${sticky ? '-sticky' : isGeneric ? '-generic' : ''}`} style={{"background-image": `url('${src}')`}}></div>)}
        </div>
      )
    }

    if (imageSource) {
      let svgPattern = new RegExp("svg");
      let urlPattern = new RegExp("https|http|.com")
      let isSVGFormat = svgPattern.test(imageSource.toLowerCase());
      let isUrlFormat = urlPattern.test(imageSource.toLowerCase());
      let imageFormat = ""
      if (isSVGFormat && isUrlFormat) {
        // imageFormat =
        //   <img
        //     className="cdn-url-image"
        //     alt="centre logo"
        //     src={imageSource}
        //   />
        
        imageFormat = logoLayout === 'Split' && !simpleNav ? 
        splitLogoComponent(isSticky, imageSource, simpleNav, isGeneric) : <img className={`cdn-url-image ${ (logoLayout === 'Split' && simpleNav) || (logoLayout === 'Split' && isGeneric) ? 'remove-indent-generic' : ''}`} style={{ filter: (logoLayout === 'Special' && simpleNav) ? "contrast(5)"  : ((logoLayout === 'Split' || logoLayout === 'Default') && simpleNav ) && "brightness(0) contrast(10) invert(0)" }} alt="centre logo" src={imageSource} />

        // <div className="logo-wrapper">
        //   <img
        //     className="cdn-url-image"
        //     alt="qicgre logo"
        //     src={imageSource}
        //   />
        //   <svg className="cdn-url-image-svg">
        //     <filter id={`filter-image-cdn-${uniqueKey}`} colorInterpolationFilters="sRGB">
        //       <feColorMatrix
        //         type="matrix"
        //         values="">
        //       </feColorMatrix>
        //     </filter>
        //     <image
        //       filter={`url(#filter-image-cdn-${uniqueKey})`}
        //       preserveAspectRatio="xMidYMid meet"
        //       xlinkHref={imageSource}
        //       x="0"
        //       y="0"
        //       width="100%"
        //       height="100%">
        //     </image>
        //   </svg >
        // </div>
      }
      else if (isSVGFormat) {
        imageFormat =
          <ReactSVG src={imageSource} />
      }
      else {
        imageFormat =
          <Image
            media={{ ...logo.value, src: defaultImg }}
            data-src={imageSource}
            className="img-fluid lazyload"
          />
      }
      return imageFormat;
    }
  }

  render() {
    const { logo, uniqueKey, isSticky, simpleNav, logoLayout, isGeneric } = this.props;
    return (
      <a href="/" className="navbar-brand">
        {this.validateImageFormat(logo, uniqueKey, isSticky, simpleNav, logoLayout, isGeneric)}
      </a>
    );
  }
}

export default HeaderImageFormat;
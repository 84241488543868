import React, { Component } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { imageHelper } from "../../../helper/ImageHelper";
import {
  disableBodyScroll,
  enableBodyScroll
} from "../../../helper/ScrollHelper";

class Lightbox extends Component {
  constructor(props) {
    super(props);
    this.state = { isHidden: true, enableFade: false };
  }

  componentDidMount() {
    if (
      this.props.data &&
      this.props.data.fields &&
      this.props.data.fields.HideLightbox &&
      !this.props.data.fields.HideLightbox.value
    ) {
      setTimeout(() => {
        this.setState({ isHidden: false });
        this.disableBodyScroll();
      }, 3000);
    }
  }

  disableBodyScroll = () => {
    disableBodyScroll();
  };
  enableBodyScroll = () => {
    setTimeout(() => {
      enableBodyScroll();
      document.querySelector(".lightbox-main-wrapper").style.zIndex = "-1";
    }, 1000);
  };

  render() {
    const { isHidden, enableFade } = this.state;

    if (!isHidden) {
      const {
        Title: title = null,
        Thumbnail: thumbnail = null,
        ThumbnailAltText: thumbnailAlt = null,
        Button: button = null,
        ButtonUrl: buttonUrl = null,
        Description: details = null
      } = this.props.data.fields || {};
      return (
        <div
          className={`lightbox-main-wrapper ${
            enableFade ? "lb-closed" : "lb-opened"
          }`}
        >
          <div className="lightbox-blur" />
          <div className="lightbox-wrapper">
            <div className="lightbox-container">
              <div className="lightbox-info">
                <div className="lightbox-content">
                  <div className="title">
                    <Text field={title} />
                  </div>
                  <div className="details">
                    <Text field={details} />
                  </div>
                </div>
                {buttonUrl && buttonUrl.value && button && button.value && (
                  <a href={buttonUrl.value}>
                    <div className="btn primary-btn lightbox-button">
                      <Text field={button} />
                    </div>
                  </a>
                )}
              </div>
              <div className="lightbox-image">
                {imageHelper(thumbnail, thumbnailAlt)}
              </div>
            </div>
            <div
              className="close-button"
              onClick={() =>
                this.setState({ enableFade: true }, () =>
                  this.enableBodyScroll()
                )
              }
            >
              <i className="icon icon-x" />
            </div>
          </div>
        </div>
      );
    } else return null;
  }
}

export default Lightbox;

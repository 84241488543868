import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Details from "../../../Common/Details";

const FeatureRecipeRecipeQuickLinksMenu = (props) => {
  const {
    Occassion = [],
    Tags = []
  } = props.fields || {};
  return (
    <div className="recipe-quicklinks-menu font-regular">
      {((Occassion && Occassion.length > 0) ||
        (Tags || Tags.length > 0)) &&
        <div className="recipe-quicklinks-menu__wrapper">
          {
            Occassion &&
            Occassion.length > 0 &&
            <div className="recipe-quicklinks-menu__occassion">
              <div className="recipe-quicklinks-menu__occassion--title">Occasion</div>
              <div className="recipe-quicklinks-menu__occassion--list">
                {
                  Occassion.map((item, index) => {
                    const {
                      Name = null,
                      IconClass = null,
                      Link = null
                    } = item.fields || {}
                    return (
                      <Details
                        key={Name.value + index}
                        iconValue={IconClass && IconClass.value ? IconClass.value : "cupcake"}
                        linkUrl={Link && Link.value
                          ? Link.value
                          : ""}
                        linkName={Name}
                        size="24"
                        noLocation={true}
                      />
                    )
                  })
                }
              </div>
            </div>
          }
          {
            Tags &&
            Tags.length > 0 &&
            <div className="recipe-quicklinks-menu__tags">
              <div className="recipe-quicklinks-menu__tags--title">Tags</div>
              <div className="recipe-quicklinks-menu__tagsn--list">
                {
                  Tags.map((item, index) => {
                    const {
                      Name = null,
                      Link = null
                    } = item.fields || {}

                    return (
                      <div key={Name.value + index}
                        className="recipe-quicklinks-menu__tags--item">
                        {Link &&
                          Link.value ?
                          <a href={Link && Link.value ? Link.value : ""}>
                            <Text field={Name} />
                          </a>
                          : <Text field={Name} />
                        }
                      </div>
                    )
                  })
                }
              </div>
            </div>
          }
        </div>
      }
    </div>
  )
};

export default FeatureRecipeRecipeQuickLinksMenu;


import React, { Fragment } from "react";
import errorIcon from "../../../assets/icons/png/22px/error.png";



const SubscribeForm = ({ fields, fieldsStates = {}, fieldsLabels, setFields }) => {

    const validateFormat = (name, value) => {
        let emailRegEx = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
        let nameRegEx = new RegExp(/^[a-zÀ-ÿ ,.'-]+$/i);
        let numRegex = new RegExp(/^[0-9]+$/)
        let result = true

        if (value) {
            if (name === 'email') result = emailRegEx.test(String(value).toLowerCase())
            else if (name === 'postcode') {
                if (value.length > 4) result = true
                else result = value.length === 4 && numRegex.test(value)
            }
            else result = nameRegEx.test(String(value).toLowerCase())
        }
        return result
    };

    const validateFields = (name, value) => {
        let message = ''
        let error
        let fieldsArr = fields;
        if (!validateFormat(name, value)) {
            if (name === 'email') message = "Please enter a valid email address";
            else if (name === 'postcode') message = "Please enter a valid postcode";
            else if (name === 'fname') message = "Please enter a valid first name"
            else if (name === 'lname') message = "Please enter a valid last name"
            error = true;
        }

        for (let i = 0; i < fieldsArr.length; i++) {
            if (fieldsArr[i].name === name) {
                fieldsArr[i].error = error
                fieldsArr[i].message = message
            }
        }

        setFields({
            fields: fieldsArr
        })
    }

    const handleFormChange = e => {
        validateFields(e.target.name, e.target.value)
        setFields({
            [e.target.name]: e.target.value,
        });
    };
    const renderLabel = (name) => {
        const {
            FirstNameLabel,
            LastNameLabel,
            EmailLabel,
            PostcodeLabel
        } = fieldsLabels || {}

        let label = ""
        switch (name) {
            case 'fname':
                label = FirstNameLabel && FirstNameLabel.value ? FirstNameLabel.value : 'First Name'
                break;
            case 'lname':
                label = LastNameLabel && LastNameLabel.value ? LastNameLabel.value : 'Last Name'
                break;
            case 'email':
                label = EmailLabel && EmailLabel.value ? EmailLabel.value : 'Email'
                break;
            case 'postcode':
                label = PostcodeLabel && PostcodeLabel.value ? PostcodeLabel.value : 'Postcode'
                break;
            default:
                break;
        }
        return label
    }
    return (
        fields.map((field, index) => {
            const inputProps = {
                type: 'text',
                id: field.name,
                name: field.name,
                className:
                    "form-control form-control-lg" +
                    (fields[index].error ? " box-error" : ""),

                required: true,
                onChange: e => {
                    handleFormChange(e)
                },
                value: fieldsStates[field.name]
            }

            if (field.name === 'postcode') {
                inputProps.pattern = '\d'
                inputProps.maxLength = '4'
                inputProps.onKeyUp = (event) => {
                    if (/[^0-9]+/.test(event.currentTarget.value)) event.currentTarget.value = event.currentTarget.value.replace(/[^0-9]*/g, "")
                }
                inputProps.pattern = "[0-9]*"
            }
            return <div className="form-group" key={index}>
                <input
                    {...inputProps}
                />
                <label
                    className="form-control-placeholder"
                    htmlFor={field.name}
                >
                    {renderLabel(field.name)}
                </label>
                {fields[index].error && fields[index].message !== "" && (
                    <Fragment>
                        <img
                            src={errorIcon}
                            className="notify-indicator"
                            alt="notify-indicator"
                        />
                        <label
                            className={
                                (fields[index].message ? "text-message" : "") +
                                (fields[index].error ? " text-error" : "")
                            }
                        >
                            {fields[index].message}
                        </label>
                    </Fragment>
                )}

            </div>

        })
    )
}

export default SubscribeForm
import React, { Component } from "react";
import { FieldTypes } from '@sitecore-jss/sitecore-jss-react-forms';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";
import ReCAPTCHA from "react-google-recaptcha";
import TagManager from "react-gtm-module";
import { hideElement } from "../ConditionalRendering";
import getAncestorElement from "../../../../Common/Helper/GetAncestorElement";
import { FieldTypes_Constants }  from "../Constants";

export class ConditionalFieldWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            showDroplist: false,
            selectValue: null,
            startDate: null,
            verifiedRecaptcha: false,
            expiredRecaptcha: false,
            load: false,
            recaptchaLoaded: false,
            captchaValue: ''
        };
    }

    componentDidMount = () => {
        setTimeout(() => {
            this.setState({ load: true });
        }, 1500);
        if (document) {
            const { field } = this.props;
            if (field.model.fieldTypeItemId === FieldTypes.Button) {
                const formInputWrapper = document.getElementsByClassName("custom-form")[0].querySelectorAll(".form-group.form-single-line");
                const formTextareaWrapper = document.getElementsByClassName("custom-form")[0].querySelectorAll(".form-group.form-textarea");
                const formDateWrapper = document.getElementsByClassName("custom-form")[0].querySelectorAll(".form-group.form-date");
                const formSpan = document.getElementsByClassName("custom-form")[0].querySelectorAll("span");
                const formParagraph = document.getElementsByClassName("custom-form")[0].querySelectorAll("p");
                const formH1 = document.getElementsByClassName("custom-form")[0].querySelectorAll("h1");
                const formH2 = document.getElementsByClassName("custom-form")[0].querySelectorAll("h2");
                const formH3 = document.getElementsByClassName("custom-form")[0].querySelectorAll("h3");
                const formH4 = document.getElementsByClassName("custom-form")[0].querySelectorAll("h4");
                const formH5 = document.getElementsByClassName("custom-form")[0].querySelectorAll("h5");
                const formH6 = document.getElementsByClassName("custom-form")[0].querySelectorAll("h6");
                const formHyperlink = document.getElementsByClassName("custom-form")[0].querySelectorAll(".has-hyperlink");
                const checkboxlist = document.getElementsByClassName("custom-form")[0].querySelectorAll(".form-checkboxlist");
                const listboxWrapper = document.getElementsByClassName("custom-form")[0].querySelectorAll(".form-listbox");

                if (formInputWrapper) {
                    Array.prototype.slice.call(formInputWrapper).map((formGroups, index) => {
                        const inputElem = formGroups.querySelector("input");
                        if (inputElem.type === "email") {
                            inputElem.type = "text";
                        }

                        this.addEvent(inputElem, "focus", this.handleOnInputFocus);
                        this.addEvent(inputElem, "blur", this.handleOnInputBlur, true);
                        this.addEvent(inputElem, "keyup", this.handleOnInputChange);
                        this.addEvent(inputElem, "change", this.handleOnInputChange);
                    })
                }
                if (formDateWrapper) {
                    Array.prototype.slice.call(formDateWrapper).map((formGroups, index) => {
                        const inputElem = formGroups.querySelector("input");
                        this.addEvent(inputElem, "change", this.handleConditionOnChangeDate);
                    })
                }
                if (formTextareaWrapper) {
                    Array.prototype.slice.call(formTextareaWrapper).map((formGroups, index) => {
                        const textareaElem = formGroups.querySelector("textarea");

                        this.addEvent(textareaElem, "blur", this.handleOnInputBlur, true);
                        this.addEvent(textareaElem, "keyup", this.handleOnInputChange);
                        this.addEvent(textareaElem, "change", this.handleOnInputChange);
                    })
                }
                if (listboxWrapper) {
                    Array.prototype.slice.call(listboxWrapper).map((formGroups, index) => {
                        const selectElem = formGroups.querySelector("select")
                        this.addEvent(selectElem, "change", this.handleOnChangeListbox);
                    })
                }
                if (formHyperlink) {
                    Array.prototype.slice.call(formHyperlink).map((formLinks, index) => {
                        let formLabel = formLinks.querySelector(".hyperlink-container").querySelectorAll("label");
                        if (formLabel) {
                            this.formUpdateHyperlink(formLabel);

                            if (/form-checkbox-only/g.test(formLinks.className)) {
                                let checkboxElem = formLinks.querySelector("input");
                                this.addEvent(checkboxElem, "click", this.handleClickCheckbox);
                                let hyperlinkContainer = formLinks.querySelector(".hyperlink-container");
                                setTimeout(() => {
                                    let labelHeight = hyperlinkContainer.querySelector("label").clientHeight;
                                    let hyperlinkText = hyperlinkContainer.textContent;
                                    let formError = formLinks.querySelector(".form-control-validation");
                                    formLinks.querySelector("label").style.height = labelHeight + "px";
                                    if (formError) {
                                        formError.innerHTML = hyperlinkText + " is required.";
                                    }
                                }, 250)
                            }

                            if (/form-radiobutton/g.test(formLinks.className)) {
                                let radioInput = formLinks.querySelectorAll("input");
                                for (var i = 0; i < radioInput.length; i++) {
                                    this.addEvent(radioInput[i], "click", this.handleClickRadio);
                                }
                                if (/form-required/g.test(formLinks.className)) {
                                    let labelList = formLinks.querySelectorAll("label");
                                    let counter = (labelList.length - 1) / 2;
                                    labelList[counter].classList.add("hide-label");
                                }
                                setTimeout(() => {
                                    let labelHeight = formLinks.querySelector("label").clientHeight;
                                    let hyperlinkContainer = formLinks.querySelector(".hyperlink-container");
                                    formLinks.querySelector("label").style.height = labelHeight + "px";
                                    hyperlinkContainer.style.top = (labelHeight + 26) + "px";
                                }, 500)
                            }
                        }

                    })
                }
                if (checkboxlist) {
                    Array.prototype.slice.call(checkboxlist).map((checkboxlist, index) => {
                        let formLabel = checkboxlist.querySelector("label");
                        let hyperlinkContainer = checkboxlist.querySelector(".hyperlink-container");
                        let checkboxLabel;
                        if (hyperlinkContainer) {
                            checkboxLabel = hyperlinkContainer.querySelectorAll("label");
                        }

                        if (formLabel) {
                            if (formLabel.innerHTML.trim() == "") {
                                let formError = checkboxlist.querySelector(".form-control-validation");
                                checkboxlist.classList.add("no-title");
                                formLabel.classList.add("hide-label")
                                formError.innerHTML = "Field is required. Please select atleast one of the choices.";
                            } else {
                                if (hyperlinkContainer) {
                                    let formLabelHeight = formLabel.clientHeight + 24;
                                    hyperlinkContainer.style.top = formLabelHeight + "px";
                                }
                            }
                        }
                        if (checkboxLabel) {
                            Array.prototype.slice.call(checkboxLabel).map((checkboxLabel, index) => {
                                let checkboxLabelHeight = checkboxLabel.clientHeight;
                                checkboxlist.querySelectorAll("label")[index + 1].style.maxHeight = checkboxLabelHeight + "px";
                            })
                        }

                    })
                }
                if (formSpan) {
                    this.formUpdateHyperlink(formSpan);
                }
                if (formParagraph) {
                    this.formUpdateHyperlink(formParagraph);
                }
                if (formH1) {
                    this.formUpdateHyperlink(formH1);
                }
                if (formH2) {
                    this.formUpdateHyperlink(formH2);
                }
                if (formH3) {
                    this.formUpdateHyperlink(formH3);
                }
                if (formH4) {
                    this.formUpdateHyperlink(formH4);
                }
                if (formH5) {
                    this.formUpdateHyperlink(formH5);
                }
                if (formH6) {
                    this.formUpdateHyperlink(formH6);
                }
            }
            this.handleConditionalLogic(field);
        }
        window.addEventListener("resize", this.handleRadioStyle);
    }

    handleRadioStyle = () => {
        const formHyperlink = document.getElementsByClassName("custom-form")[0].querySelectorAll(".has-hyperlink");
        if (formHyperlink) {
            Array.prototype.slice.call(formHyperlink).map((formLinks, index) => {
                let formLabel = formLinks.querySelector(".hyperlink-container").querySelectorAll("label");
                if (formLabel) {
                    if (/form-radiobutton/g.test(formLinks.className)) {
                        let labelElem = formLinks.querySelector("label");
                        let hyperlinkContainer = formLinks.querySelector(".hyperlink-container");
                        let labelHeight;
                        labelElem.style.height = "auto";
                        labelHeight = labelElem.clientHeight;
                        labelElem.style.height = labelHeight + "px";
                        hyperlinkContainer.style.top = (labelHeight + 26) + "px";
                    }
                }

            })
        }
    }

    addEvent = (element, event, task, useCapture) => {
        // For all major browsers, except IE 8 and earlier
        if (element.addEventListener) {
            element.addEventListener(event, task, useCapture);
            // For IE 8 and earlier versions
        } else if (element.attachEvent) {
            element.attachEvent("on" + event, task);
        }
    }

    removeEvent = (element, event, task, useCapture) => {
        // For all major browsers, except IE 8 and earlier
        if (element.removeEventListener) {
            element.removeEventListener(event, task, useCapture);
        }
    }

    // Manual Trigger Event
    triggerEvent = (element, event) => {
        var evt;
        var evtResult;
        if (document.createEvent) {
            evt = document.createEvent("HTMLEvents");
            evt.initEvent(event, true, true);
            evt.eventName = event;
            evtResult = element.dispatchEvent(evt);
        } else {
            evt = document.createEventObject();
            evt.eventName = event;
            evt.eventType = event;
            evtResult = element.fireEvent("on" + evt.eventType, evt);
        }
    }

    handleConditionalLogic = (field) => {
        if (field) {
            let fieldConditions = [];
            if (field.model) {
                fieldConditions = field.model.conditionSettings.fieldConditions;
            }
            if (fieldConditions.length > 0) {
                let fieldKey = field.model.conditionSettings.fieldKey;
                let elemFieldKey = document.querySelector('[data-fieldkey = "' + fieldKey + '"]');
                if (elemFieldKey) {
                    if (/form-single-line/g.test(elemFieldKey.className)) {
                        let inputVal = elemFieldKey.querySelector("input").value;
                        hideElement(inputVal, fieldConditions);
                    }
                    else if (/form-date/g.test(elemFieldKey.className)) {
                        let inputVal = elemFieldKey.querySelector("input").value;
                        hideElement(inputVal, fieldConditions);
                    }
                    else if (/form-textarea/g.test(elemFieldKey.className)) {
                        let textareaVal = elemFieldKey.querySelector("textarea").value;
                        hideElement(textareaVal, fieldConditions);
                    }
                    else if (/form-dropdown/g.test(elemFieldKey.className)) {
                        let dropdownVal = elemFieldKey.querySelector(".dropdown-btn").value;
                        hideElement(dropdownVal, fieldConditions);
                    }
                    else if (/form-checkbox-only/g.test(elemFieldKey.className)) {
                        let checkboxElem = elemFieldKey.querySelector("input");
                        let checkboxVal = checkboxElem.checked;
                        this.addEvent(checkboxElem, "click", this.handleClickCheckbox);
                        hideElement(checkboxVal, fieldConditions);
                    }
                    else if (/form-radiobutton/g.test(elemFieldKey.className)) {
                        let radioInput = elemFieldKey.querySelectorAll("input");
                        var radioValue = "";
                        for (var i = 0; i < radioInput.length; i++) {
                            if (radioInput[i].checked) {
                                radioValue = radioInput[i].value;
                            }
                            this.addEvent(radioInput[i], "click", this.handleClickRadio);
                        }
                        hideElement(radioValue, fieldConditions);
                    }
                    else if (/form-listbox/g.test(elemFieldKey.className)) {
                        let listboxSelect = elemFieldKey.querySelector("select");
                        hideElement(listboxSelect.value, fieldConditions);
                    }
                }
            }
        }
    }

    formUpdateHyperlink = (element) => {
        Array.prototype.slice.call(element).map((formElement, index) => {
            let anchor = formElement.querySelector("a");
            if (!anchor) {
                let textContent = formElement.innerHTML;
                if (/\/a&gt;/g.test(textContent)) {
                    while (/\/a&gt;/g.test(textContent)) {
                        textContent = textContent.replace("&lt;a", "<a");
                        textContent = textContent.replace("&gt;", ">");
                        textContent = textContent.replace("&lt;/a&gt;", "</a>");
                        formElement.innerHTML = textContent;
                    }
                }
            }

        })
    }

    handleOnInputFocus = (e) => {
        const inputElem = e.target;
        inputElem.parentElement.classList.add("input-focus");
    }

    handleOnInputBlur = (e) => {
        const inputElem = e.target;
        if (inputElem.value) {
            inputElem.parentElement.classList.add("input-focus");
        } else {
            inputElem.parentElement.classList.remove("input-focus");
        }
    }

    handleOnInputChange = (event) => {
        let inputElem = event.target;
        let inputValue = inputElem.value;
        let requiredParentElem = getAncestorElement(inputElem, "form-required");
        let formElemParent = getAncestorElement(inputElem, "form-single-line");
        if (!formElemParent) {
            formElemParent = getAncestorElement(inputElem, "form-textarea")
        }
        if (requiredParentElem) {
            if (!(/is-hidden/g.test(requiredParentElem.className))) {
                if (inputValue) {
                    requiredParentElem.classList.remove("is-invalid");
                    requiredParentElem.classList.remove("is-to-validate");
                } else {
                    if (event.keyCode != 9 && event.keyCode != 16) {
                        requiredParentElem.classList.add("is-invalid");
                        requiredParentElem.classList.add("is-to-validate");
                    }
                }
            } else {
                requiredParentElem.classList.remove("is-invalid");
                requiredParentElem.classList.remove("is-to-validate");
            }
        }

        let dataCondition = formElemParent.getAttribute("data-fieldcondition");
        if (dataCondition) {
            let fieldCondition = JSON.parse(dataCondition);
            if (fieldCondition.length > 0) {
                hideElement(inputElem.value, fieldCondition);
            }
        }
    }

    handleToggle = (e) => {
        e.target.focus();
        this.setState({ showDroplist: !this.state.showDroplist });
    }

    handleBlur = (e) => {
        if (this.state.showDroplist) {
            setTimeout(() => {
                this.setState({ showDroplist: !this.state.showDroplist });
            }, 250)

        }
    }

    onChangeSelection = (event) => {
        let listElem = event.target;
        const formElemParent = getAncestorElement(listElem, "form-dropdown");

        if (listElem.tagName.toLowerCase() === 'i') {
            listElem = listElem.parentElement;
        }

        // Set custom select value
        this.setState({ selectValue: listElem.textContent });

        // Get dropdown siblings and remove active class
        var allSiblings = [],
            node = listElem.parentNode.firstChild;

        while (node) {
            if (node !== listElem && node.nodeType === Node.ELEMENT_NODE)
                allSiblings.push(node);
            node = node.nextElementSibling || node.nextSibling;
        }
        allSiblings.map((siblings, index) => {
            siblings.classList.remove("active");
        })

        // Add dropdown active class
        listElem.classList.add("active");

        // Adjust Select value
        const selectElement = formElemParent.querySelector("select");
        selectElement.value = listElem.getAttribute("value");
        this.triggerEvent(selectElement, "change");
        this.formValidate(formElemParent);
        let dataCondition = formElemParent.getAttribute("data-fieldcondition");
        if (dataCondition) {
            let fieldCondition = JSON.parse(dataCondition);
            if (fieldCondition.length > 0) {
                hideElement(listElem.textContent, fieldCondition);
            }
        }
    }

    handleOnChangeDate = (date, event) => {
        let parentElement = getAncestorElement(event.target, "form-date");
        const inputDate = parentElement.querySelector('input[type="date"]');

        if (!moment(date)._isValid) {
            inputDate.setAttribute("value", null);
            this.triggerEvent(inputDate, "change");
            this.setState({
                startDate: date
            });
            if (!(/is-hidden/g.test(parentElement.className))) {
                parentElement.classList.add("is-invalid");
                parentElement.classList.add("is-to-validate");
            }
            return false;
        }
        let dateMonth = ("0" + (date.getMonth() + 1)).slice(-2),
            dateDay = ("0" + date.getDate()).slice(-2),
            dateYear = date.getFullYear();
        const newDate = (dateYear) + "-" + (dateMonth) + "-" + (dateDay);
        this.setState({
            startDate: date
        });
        if (!(/is-hidden/g.test(parentElement.className))) {
            parentElement.classList.remove("is-invalid");
            parentElement.classList.remove("is-to-validate");
        } else {
            parentElement.classList.remove("is-invalid");
            parentElement.classList.remove("is-to-validate");
        }
        inputDate.setAttribute("value", newDate);
        this.triggerEvent(inputDate, "change");
    };

    handleConditionOnChangeDate = (event) => {
        let dateElem = event.target;
        const formElemParent = getAncestorElement(dateElem, "form-date");
        let dataCondition = formElemParent.getAttribute("data-fieldcondition");
        if (dataCondition) {
            let fieldCondition = JSON.parse(dataCondition);
            if (fieldCondition.length > 0) {
                hideElement(dateElem.value, fieldCondition);
            }
        }
    }

    handleOnClickCalendar = (e) => {
        const datePickerInput = e.target.nextElementSibling.querySelector("input");
        datePickerInput.click();
    }

    handleClickCheckbox = (event) => {
        let checkboxElem = event.target;
        let checboxValue = checkboxElem.checked;
        const formElemParent = getAncestorElement(checkboxElem, "form-checkbox-only");
        let dataCondition = formElemParent.getAttribute("data-fieldcondition");
        this.formCheckboxValidate(formElemParent);
        if (dataCondition) {
            let fieldCondition = JSON.parse(dataCondition);
            if (fieldCondition.length > 0) {
                hideElement(checboxValue, fieldCondition);
            }
        }
    }

    handleClickRadio = (event) => {
        let radioElem = event.target;
        const formElemParent = getAncestorElement(radioElem, "form-radiobutton");
        let dataCondition = formElemParent.getAttribute("data-fieldcondition");
        this.formCheckboxValidate(formElemParent);
        if (dataCondition) {
            let fieldCondition = JSON.parse(dataCondition);
            if (fieldCondition.length > 0) {
                if (radioElem.checked) {
                    hideElement(radioElem.value, fieldCondition);
                }
            }
        }
    }

    handleOnChangeListbox = (event) => {
        let selectElem = event.target;
        const formElemParent = getAncestorElement(selectElem, "form-listbox");
        let listboxOption = selectElem.querySelectorAll("option");
        this.formValidate(formElemParent);
        let dataCondition = formElemParent.getAttribute("data-fieldcondition");
        if (dataCondition) {
            let fieldCondition = JSON.parse(dataCondition);
            if (fieldCondition.length > 0) {
                let selectValue = [];
                for (var i = 0; i < listboxOption.length; i++) {
                    if (listboxOption[i].selected) {
                        selectValue.push(listboxOption[i].value);
                    }
                }
                hideElement(selectValue.toString(), fieldCondition, true);
            }
        }
    }

    recaptchaLoaded = () => {
        this.setState({ recaptchaLoaded: true });
    }

    recaptchaExpired = () => {
        let recaptchaWrapper = document.querySelector(".form-recaptcha");
        let inputCaptcha = recaptchaWrapper.querySelector('input[type="hidden"].fxt-captcha');
        inputCaptcha.value = '';
        this.setState({
            verifiedRecaptcha: false,
            expiredRecaptcha: true
        });
    }
    recaptchaVerifyResponse = (response) => {
        if (response) {
            let formWrapper = document.querySelector(".custom-form form");
            let inputCaptcha = formWrapper.querySelector('input[type="hidden"].fxt-captcha');
            inputCaptcha.value = response;
            this.setState({
                verifiedRecaptcha: true,
                expiredRecaptcha: false,
                captchaValue : response
            });
            let submitBtn = formWrapper.querySelector("form button.customform-submit-btn");
            submitBtn.removeAttribute("disabled");
        } else {
            this.setState({ verifiedRecaptcha: false });
        }
    }

    // Manual Trigger Form Validation
    formValidate = (formWrapper) => {
        if (formWrapper.length > 0) {
            Array.prototype.slice.call(formWrapper).map((formGroups, index) => {
                let element;
                const inputElem = formGroups.querySelector("input");
                const selectElem = formGroups.querySelector("select");
                const textAreaElem = formGroups.querySelector("textarea");

                if (selectElem) {
                    element = selectElem;
                    let parentElement = getAncestorElement(selectElem, "form-required");
                    if (parentElement) {
                        if (!(/is-hidden/g.test(parentElement.className))) {
                            if (selectElem.value) {
                                parentElement.classList.remove("is-invalid");
                                parentElement.classList.remove("is-to-validate");
                            } else {
                                parentElement.classList.add("is-invalid");
                                parentElement.classList.add("is-to-validate");
                            }
                        }
                    }
                } else if (inputElem) {
                    let dateParentWrapper = getAncestorElement(inputElem, "form-date");
                    if (dateParentWrapper) {
                        element = dateParentWrapper.querySelector(".react-datepicker-wrapper input");
                    } else {
                        element = inputElem;
                    }
                    let parentElement = getAncestorElement(inputElem, "form-required");
                    if (parentElement) {
                        if (!(/is-hidden/g.test(parentElement.className))) {
                            if (inputElem.value) {
                                parentElement.classList.remove("is-invalid");
                                parentElement.classList.remove("is-to-validate");
                            } else {
                                parentElement.classList.add("is-invalid");
                                parentElement.classList.add("is-to-validate");
                            }
                        }
                    }
                } else if (textAreaElem) {
                    element = textAreaElem;
                }

                if (!element.value) {
                    element.value = null;
                }
                this.triggerEvent(element, "change");
            })
        } else if (formWrapper && formWrapper.length !== 0) {
            const selectElem = formWrapper.querySelector("select");
            if (selectElem) {
                let parentElement = getAncestorElement(selectElem, "form-required");
                if (parentElement) {
                    if (!(/is-hidden/g.test(parentElement.className))) {
                        if (selectElem.value) {
                            parentElement.classList.remove("is-invalid");
                            parentElement.classList.remove("is-to-validate");
                        } else {
                            parentElement.classList.add("is-invalid");
                            parentElement.classList.add("is-to-validate");
                        }
                    }
                }
            }
        }
    }

    // Validate checkbox and radio
    formCheckboxValidate = (formWrapper) => {
        if (formWrapper.length > 0) {
            Array.prototype.slice.call(formWrapper).map((formGroups, index) => {
                let inputElem = formGroups.querySelectorAll("input");
                const parentWrapper = formGroups;
                parentWrapper.classList.add("no-check");
                if (inputElem.length > 1) {
                    Array.prototype.slice.call(inputElem).map((inputList, index) => {
                        if (inputList.checked) {
                            parentWrapper.classList.remove("no-check");
                        }
                    });
                    if (/form-required/g.test(formGroups.className)) {
                        if (!(/is-hidden/g.test(formGroups.className))) {
                            let hasCheck = false;
                            let isEmpty = false;
                            Array.prototype.slice.call(inputElem).map((inputList, index) => {
                                if (inputList.checked) {
                                    hasCheck = true;
                                    if (inputList.value === "" || inputList.value === "null") {
                                        isEmpty = true;
                                    }
                                }
                            });
                            if (hasCheck && !isEmpty) {
                                formGroups.classList.remove("is-invalid");
                                formGroups.classList.remove("is-to-validate");
                            } else {
                                formGroups.classList.add("is-invalid");
                                formGroups.classList.add("is-to-validate");
                            }
                        }
                    }
                } else {
                    inputElem = formGroups.querySelector("input");
                    if (inputElem.checked) {
                        parentWrapper.classList.remove("no-check");
                    }
                    if (/form-required/g.test(parentWrapper.className)) {
                        if (!(/is-hidden/g.test(parentWrapper.className))) {
                            if (inputElem.checked) {
                                parentWrapper.classList.remove("is-invalid");
                                parentWrapper.classList.remove("is-to-validate");
                            } else {
                                parentWrapper.classList.add("is-invalid");
                                parentWrapper.classList.add("is-to-validate");
                            }
                        }
                    }
                }
            })
        }
        else if (formWrapper) {
            let inputElem = formWrapper.querySelectorAll("input");
            if (/form-required/g.test(formWrapper.className)) {
                if (!(/is-hidden/g.test(formWrapper.className))) {
                    let hasCheck = false;
                    let isEmpty = false;
                    if (inputElem.length > 0) {
                        Array.prototype.slice.call(inputElem).map((inputList, index) => {
                            if (inputList.checked) {
                                hasCheck = true;
                                if (inputList.value === "" || inputList.value === "null") {
                                    isEmpty = true;
                                }
                            }

                        });
                    }
                    if (hasCheck && !isEmpty) {
                        formWrapper.classList.remove("is-invalid");
                        formWrapper.classList.remove("is-to-validate");
                    } else {
                        formWrapper.classList.add("is-invalid");
                        formWrapper.classList.add("is-to-validate");
                    }
                }
            }
        }

    }

    handleSubmit = (e) => {
        const customParentWrapper = getAncestorElement(e.target, "custom-form");
        const formElem = customParentWrapper.querySelector("form");
        const formInputWrapper = customParentWrapper.querySelectorAll(".form-group.form-single-line");
        const formDateWrapper = customParentWrapper.querySelectorAll(".form-group.form-date");
        const formCheckboxWrapper = customParentWrapper.querySelectorAll(".form-group.form-checkbox");
        const formRadioWrapper = customParentWrapper.querySelectorAll(".form-group.form-radiobutton");
        const formTextAreaWrapper = customParentWrapper.querySelectorAll(".form-group.form-textarea");
        const formDropdownWrapper = customParentWrapper.querySelectorAll(".form-group.form-dropdown");
        const formListboxWrapper = customParentWrapper.querySelectorAll(".form-group.form-listbox");
        const recaptchaWrapper = customParentWrapper.querySelector(".form-recaptcha");
        const submitBtn = formElem.querySelector("button.customform-submit-btn");

        if (submitBtn) {
            TagManager.dataLayer({
                dataLayer: {
                    customFormSubmitBtnId: submitBtn.id
                },
                dataLayerName: "dataLayer"
            });
        }

        if (formCheckboxWrapper.length > 0) {
            this.formCheckboxValidate(formCheckboxWrapper);
        }
        if (formRadioWrapper.length > 0) {
            this.formCheckboxValidate(formRadioWrapper);
        }

        const noTickWrapper = customParentWrapper.querySelectorAll(".form-group.is-Required.no-check");
        const formRequiredWrapper = customParentWrapper.querySelectorAll(".form-group.form-required");
        if (recaptchaWrapper || formRequiredWrapper.length > 0) {
            let isVerifiedRecaptcha;
            let invalidForms = customParentWrapper.querySelectorAll(".form-group.form-required.is-invalid");
            let toValidateForms = customParentWrapper.querySelectorAll(".form-group.form-required.is-to-validate");
            if (recaptchaWrapper) {
                isVerifiedRecaptcha = /\bverifiedRecaptcha\b/g.test(recaptchaWrapper.className);
                if(!isVerifiedRecaptcha){
                    recaptchaWrapper.classList.add("is-required");
                }
            }
            if ((recaptchaWrapper && isVerifiedRecaptcha && invalidForms.length === 0 && toValidateForms.length === 0) ||
                (noTickWrapper.length > 0 || invalidForms.length === 0 && toValidateForms.length === 0)) {
                this.triggerEvent(formElem, "submit");
            } else {
                if (formInputWrapper.length > 0) {
                    this.formValidate(formInputWrapper);
                }
                if (formDateWrapper.length > 0) {
                    this.formValidate(formDateWrapper);
                }
                if (formTextAreaWrapper.length > 0) {
                    this.formValidate(formTextAreaWrapper);
                }
                if (formDropdownWrapper.length > 0) {
                    this.formValidate(formDropdownWrapper);
                }
                if (formListboxWrapper.length > 0) {
                    this.formValidate(formListboxWrapper);
                }
            }
        } else {
            this.triggerEvent(formElem, "submit");
        }
        submitBtn.setAttribute("disabled", "");
      
        setTimeout(() => {
            if (!formElem.querySelector(".custom-form-button")) {
                customParentWrapper.scrollIntoView();

                TagManager.dataLayer({
                    dataLayer: {
                        event: "customFormSubmit"
                    },
                    dataLayerName: "dataLayer"
                });
            }
        }, 5000);
    }

    render() {
        const { field } = this.props;
        const { verifiedRecaptcha, expiredRecaptcha, load } = this.state;
        let formDisplay,
            fieldKey,
            fieldCondition,
            fieldConditionData;

        if (field.model.fieldTypeItemId !== FieldTypes.TextField) {
            fieldKey = field.model.conditionSettings.fieldKey;
            fieldCondition = field.model.conditionSettings.fieldConditions;
            fieldConditionData = JSON.stringify(fieldCondition);

            // Layout for single line, number, telephone, email, password
            if (field.model.fieldTypeItemId === FieldTypes.SingleLineText
                || field.model.fieldTypeItemId === FieldTypes.NumberField
                || field.model.fieldTypeItemId === FieldTypes.Telephone
                || field.model.fieldTypeItemId === FieldTypes.Email
                || field.model.fieldTypeItemId === FieldTypes.Password) {
                let isRequired = false;
                if (/required/g.test(field.model.cssClass)) {
                    isRequired = true;
                }
                formDisplay =
                    <div className={`form-group form-single-line ${isRequired ? "form-required is-to-validate" : ""}`}
                        data-fieldkey={fieldKey}
                        data-fieldcondition={fieldConditionData}>
                        {this.props.children}
                        {isRequired &&
                            <div className="form-control-validation-required">
                                <span>
                                    <i className="icon icon-danger">!</i>
                                    <label>
                                        {field.model.title ? field.model.title : field.model.name}{" is required"}
                                    </label>
                                </span>
                            </div>
                        }
                    </div>
            }
            // Layout for date
            else if (field.model.fieldTypeItemId === FieldTypes.DateField) {
                fieldKey = field.model.conditionSettings.fieldKey;
                fieldCondition = field.model.conditionSettings.fieldConditions;
                fieldConditionData = JSON.stringify(fieldCondition);

                const defaultMinDate = new Date("1500-01-01T00:00:00Z");
                const defaultMaxDate = (moment().add(5, 'years'))._d;
                const yearDropdownNum = moment()._d.getFullYear() - 1500;
                const minDate = field.model.min ? new Date(field.model.min) : defaultMinDate;
                const maxDate = field.model.max ? new Date(field.model.max) : defaultMaxDate;
                const initialDate = field.model.value ? new Date(field.model.value) : null;
                let isRequired = false;
                if (/required/g.test(field.model.cssClass)) {
                    isRequired = true;
                }
                formDisplay =
                    <div className={`form-group form-date ${isRequired ? "form-required is-to-validate" : ""}`}
                        data-fieldkey={fieldKey}
                        data-fieldcondition={fieldConditionData}>
                        {this.props.children}
                        {isRequired &&
                            <div className="form-control-validation-required">
                                <span>
                                    <label>
                                        {field.model.title ? field.model.title : field.model.name}{" is required"}
                                    </label>
                                </span>
                            </div>
                        }
                        <i className="icon icon-calendar" onClick={this.handleOnClickCalendar}></i>
                        <DatePicker
                            selected={this.state.startDate ? this.state.startDate : initialDate}
                            onChange={this.handleOnChangeDate}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/YYYY"
                            minDate={minDate}
                            maxDate={maxDate}
                            dateFormatCalendar="MMMM"
                            showMonthDropdown={true}
                            showYearDropdown={true}
                            useWeekdaysShort={true}
                            yearDropdownItemNumber={yearDropdownNum}
                        />
                    </div>
            }
            // Layout for checkbox
            else if (field.model.fieldTypeItemId === FieldTypes.Checkbox) {
                fieldKey = field.model.conditionSettings.fieldKey;
                fieldCondition = field.model.conditionSettings.fieldConditions;
                fieldConditionData = JSON.stringify(fieldCondition);

                let hyperlinkItems = <label htmlFor={field.valueField.id}>{field.model.title}</label>;
                let isRequired = false;
                if (/required/g.test(field.model.cssClass)) {
                    isRequired = true;
                }
                formDisplay =
                    <div className={"form-group form-checkbox form-checkbox-only has-hyperlink " +
                        (field.model.required ? "is-Required " : "") +
                        (isRequired ? "form-required is-to-validate " : "")
                    } data-fieldkey={fieldKey} data-fieldcondition={fieldConditionData}>
                        {this.props.children}
                        <div className="hyperlink-container">
                            {hyperlinkItems}
                        </div>
                    </div>
            }
            // Layout for checkbox list
            else if (field.model.fieldTypeItemId === FieldTypes.CheckboxList) {
                fieldKey = field.model.conditionSettings.fieldKey;
                fieldCondition = field.model.conditionSettings.fieldConditions;
                fieldConditionData = JSON.stringify(fieldCondition);

                let hyperlinkItems = field.model.items.map((itemList, index) => {
                    return (
                        <label htmlFor={field.valueField.id + index} key={itemList.text + index}>{itemList.text}</label>
                    )
                });
                let isRequired = false;
                if (/required/g.test(field.model.cssClass)) {
                    isRequired = true;
                }
                formDisplay =
                    <div className={"form-group form-checkbox form-checkboxlist has-hyperlink " +
                        (field.model.required ? "is-Required " : "") +
                        (isRequired ? "form-required is-to-validate " : "")
                    } data-fieldkey={fieldKey} data-fieldcondition={fieldConditionData}>
                        {this.props.children}
                        <div className="hyperlink-container">
                            {hyperlinkItems}
                        </div>
                    </div>
            }
            // Layout for radio button
            else if (field.model.fieldTypeItemId === FieldTypes.RadioButtonList) {
                fieldKey = field.model.conditionSettings.fieldKey;
                fieldCondition = field.model.conditionSettings.fieldConditions;
                fieldConditionData = JSON.stringify(fieldCondition);
                let hasNullValue = false

                let hyperlinkItems = field.model.items.map((itemList, index) => {
                    if (itemList && itemList.value === "null") {
                        hasNullValue = true
                    }
                    return (
                        <label htmlFor={field.valueField.id + index} key={itemList.text + index}>{itemList.text}</label>
                    )

                });

                let isRequired = false;
                if (/required/g.test(field.model.cssClass)) {
                    isRequired = true;
                }
                formDisplay =
                    <div className={"form-group form-radiobutton has-hyperlink " +
                        (field.model.required ? "is-Required " : "") +
                        (isRequired ? "form-required is-to-validate " : "") +
                        (hasNullValue ? "has-null-value" : "") +
                        (field.model.title ? "" : "no-label")
                    } data-fieldkey={fieldKey} data-fieldcondition={fieldConditionData}>
                        {this.props.children}
                        {isRequired &&
                            <div className="form-control-validation-required">
                                {field.model.title ? field.model.title : field.model.name}{" is required"}
                            </div>
                        }
                        <div className="hyperlink-container">
                            {hyperlinkItems}
                        </div>
                    </div>
            }
            // Layout for textarea
            else if (field.model.fieldTypeItemId === FieldTypes.MultipleLineText) {
                fieldKey = field.model.conditionSettings.fieldKey;
                let isRequired = false;
                if (/required/g.test(field.model.cssClass)) {
                    isRequired = true;
                }
                formDisplay =
                    <div className={`form-group form-textarea ${isRequired ? "form-required is-to-validate" : ""}`}
                        data-fieldkey={fieldKey}
                        data-fieldcondition={fieldConditionData}>
                        {this.props.children}
                        {isRequired &&
                            <div className="form-control-validation-required">
                                <span>
                                    <i className="icon icon-danger">!</i>
                                    <label>
                                        {field.model.title ? field.model.title : field.model.name}{" is required"}
                                    </label>
                                </span>
                            </div>
                        }
                    </div>
            }
            // Layout for dropdown list
            else if (field.model.fieldTypeItemId === FieldTypes.DropdownList) {
                fieldKey = field.model.conditionSettings.fieldKey;
                fieldCondition = field.model.conditionSettings.fieldConditions;
                fieldConditionData = JSON.stringify(fieldCondition);
                const { field: dropdownField = null } = this.props || {};
                let defaultDropdownValue = "";
                field.model.items &&
                    field.model.items.map((dropdownList, index) => {
                        if (dropdownList.selected === true) {
                            defaultDropdownValue = dropdownList.text;
                        }
                    })
                let isRequired = false;
                if (/required/g.test(field.model.cssClass)) {
                    isRequired = true;
                }
                formDisplay =
                    <div className={`form-group form-dropdown ${isRequired ? "form-required is-to-validate" : ""}`}
                        data-fieldkey={fieldKey}
                        data-fieldcondition={fieldConditionData}>
                        {this.props.children}
                        {isRequired &&
                            <div className="form-control-validation-required">
                                <span>
                                    <label>
                                        {field.model.title ? field.model.title : field.model.name}{" is required"}
                                    </label>
                                </span>
                            </div>
                        }
                        <div className="form-group custom-dropdown">
                            <div className="dropdown-container">
                                <div className={`dropdown-label ${this.state.showDroplist ? "active" : ""}`}
                                    onClick={this.handleToggle}
                                    onBlur={this.handleBlur}
                                    tabIndex="0"
                                >
                                    <div className={"dropdown-placeholder "
                                        + (defaultDropdownValue ? "has-default-value " : "")
                                        + (this.state.selectValue ? "has-default-value " : "")
                                    }>
                                        <div className="label">{dropdownField.model.title}</div>
                                        <input
                                            type="button"
                                            value={(this.state.selectValue ? this.state.selectValue : defaultDropdownValue)}
                                            className="dropdown-btn"
                                        />
                                    </div>
                                    <i className="icon icon-arrow-down"></i>
                                </div>
                                <ul className="dropdown-list" hidden={!this.state.showDroplist}>
                                    {field.model.showEmptyItem &&
                                        <li className="hidden-option"
                                            value={""}
                                            onClick={this.onChangeSelection} >
                                            <i className="icon icon-check"></i>{""}
                                        </li>
                                    }
                                    {field.model.items &&
                                        field.model.items.map((dropdownList, index) => {
                                            return (
                                                <li className={dropdownList.selected ? "active" : ""}
                                                    value={dropdownList.value}
                                                    key={dropdownList.value + index}
                                                    onClick={this.onChangeSelection} >
                                                    <i className="icon icon-check"></i>{dropdownList.text}
                                                </li>
                                            )
                                        })}
                                </ul>
                            </div>
                        </div>
                    </div>
            }
            //Layout for list box
            else if (field.model.fieldTypeItemId === FieldTypes.ListBox) {
                fieldKey = field.model.conditionSettings.fieldKey;
                fieldCondition = field.model.conditionSettings.fieldConditions;
                fieldConditionData = JSON.stringify(fieldCondition);
                let isRequired = false;
                if (/required/g.test(field.model.cssClass)) {
                    isRequired = true;
                }
                formDisplay =
                    <div className={`form-group form-listbox ${isRequired ? "form-required is-to-validate" : ""}`}
                        data-fieldkey={fieldKey}
                        data-fieldcondition={fieldConditionData}>
                        {this.props.children}
                        {isRequired &&
                            <div className="form-control-validation-required">
                                <span>
                                    <label>
                                        {field.model.title ? field.model.title : field.model.name}{" is required"}
                                    </label>
                                </span>
                            </div>
                        }
                        <div className="hint">
                            *Tip: Hold the <b>Ctrl</b> (Windows) or <b>Command</b> (Mac) key down while selecting multiple items.
                        </div>
                    </div>
            }
            // Layout for recaptcha
            else if (field.model.fieldTypeItemId === FieldTypes_Constants["RECAPTCHA"]) {
                if (load) {
                  fieldKey = field.model.conditionSettings.fieldKey;                
                  let isRequired = field.model.required;
                  let recaptchaId = field.valueField.id;
                  let recaptchaName = field.valueField.name;

                    formDisplay =
                        <div className={"form-group form-recaptcha"
                            + (isRequired ? " form-required" : "")
                            + (verifiedRecaptcha ? " verifiedRecaptcha" : " is-invalid")
                            + (expiredRecaptcha ? " is-required" : "")} 
                          data-fieldkey={fieldKey} data-fieldcondition="">
                            <input type="hidden" id={recaptchaId} name={recaptchaName} class="fxt-captcha" data-val-required="Please confirm you are not a robot." data-val="true" value={this.state.captchaValue} />
                            <ReCAPTCHA
                                size="normal"
                                sitekey={field.model.captchaPublicKey ? field.model.captchaPublicKey : "6LeqAfYUAAAAAGK1oZ1NzfVUFeC4kqJOe16XklGg"}
                                onChange={this.recaptchaVerifyResponse}
                                onExpired={this.recaptchaExpired}
                                asyncScriptOnLoad={this.recaptchaLoaded}
                            />
                            <div className="form-control-validation">
                                <span>
                                    <label>ReCaptcha is required</label>
                                </span>
                            </div>
                        </div >
                }
                else {
                    formDisplay = "";
                }

            }
            // Layout for button field
            else if (field.model.fieldTypeItemId === FieldTypes.Button) {
                fieldKey = field.model.conditionSettings.fieldKey;

                formDisplay =
                    <div className={"custom-form-button"} data-fieldkey={fieldKey}>
                        <button type="button"
                            className="customform-submit-btn"
                            value={field.model.title ? field.model.title : field.model.name}
                            name={field.buttonField.name}
                            id={field.buttonField.name}
                            onClick={this.handleSubmit} >
                            {field.model.title ? field.model.title : field.model.name}
                        </button>
                    </div >
            }
            // Default layout
            else {
                formDisplay =
                    <div className="form-group"
                        data-fieldkey={fieldKey}
                    >
                        {this.props.children}
                    </div>
            }
        } else {
            fieldKey = field.model.conditionSettings.fieldKey;

            formDisplay =
                <div className="form-element" data-fieldkey={fieldKey}>
                    {this.props.children}
                </div >
        }

        return (
            formDisplay
        )
    }

};

export const ErrorComponent = (props) => {
    return (
        <div className="invalid-form">
            {props.formErrors.map((error, index) => (
                <span key={`formError-${index}`}>
                    <i className="icon icon-danger">!</i>
                    <label>{error}</label>
                </span>
            ))}
        </div>
    )
};

export const FieldErrorComponent = (props) => (
    <div className="form-control-validation">
        {props.errors.map((error, index) => (
            <span key={`fieldFormError-${index}`}>
                <i className="icon icon-danger">!</i>
                <label>{error}</label>
            </span>
        ))}
    </div>
);

export const CustomFetchForm = (frmData, endpoint) => 
  fetch(endpoint, {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: frmData.toUrlEncodedFormData()
})
    .then(async response => {
        if (response.status != 200) {
            let indexUrl = endpoint.indexOf("/api");
            let formChecker = document.querySelector("form[action=" + "'" + endpoint + "'" + "]");
            let formChecker2 = document.querySelector("form[action=" + "'" + endpoint.substring(indexUrl) + "'" + "]");
            let formParentWrapper = formChecker || formChecker2;
            if (formParentWrapper) {
                let hasConditionalInvalidField = formParentWrapper.querySelector(".is-invalid");
                let hasDefaultInvalidField = formParentWrapper.querySelector(".form-group label.invalid");
                let submitBtn = formParentWrapper.querySelector("button.customform-submit-btn");
              
                if (hasConditionalInvalidField && hasDefaultInvalidField) {
                    let conditionalTopPosition = hasConditionalInvalidField.getBoundingClientRect().top,
                        defaultTopPosition = hasDefaultInvalidField.getBoundingClientRect().top;

                    if (conditionalTopPosition < defaultTopPosition) {
                        hasConditionalInvalidField.scrollIntoView()
                    } else {
                        hasDefaultInvalidField.scrollIntoView();
                    }
                } else if (hasConditionalInvalidField) {
                    hasConditionalInvalidField.scrollIntoView()
                } else if (hasDefaultInvalidField) {
                    hasDefaultInvalidField.scrollIntoView();
                }

                submitBtn.removeAttribute("disabled");
            }
        }
        let jsonResult = await response.json();
        if (jsonResult.success && jsonResult.newTabUrl) {
            window.open(jsonResult.newTabUrl, "_self");
        }
        return jsonResult;
    })
    .catch(error => {
        console.log(error);
        return { success: false, error: 'Something went wrong. Error was thrown when submit form' }
    })

// Update Field JSON
export const UpdateFieldModel = (fields) => {
    let newField = fields;
    let fieldArray = [];

    if (fields.fields[0].fields) {
        fieldArray = fields.fields[0].fields;
    }
    else if (fields.fields) {
        fieldArray = fields.fields;
    }

    if (fieldArray.length > 0) {
        fieldArray.map((fieldItem, index) => {
            if (fieldItem.model.fieldTypeItemId === FieldTypes.RadioButtonList) {
                if (/required/g.test(fieldItem.model.cssClass)) {
                    let hasAddedArray = false;
                    fieldItem.model.items.map((item, index) => {
                        const {
                            itemId = null
                        } = item || {};

                        if (itemId == (fieldItem.model.name)) {
                            hasAddedArray = true;
                        }
                    })
                    if (!hasAddedArray) {
                        let addedArray = {
                            "itemId": fieldItem.model.name,
                            "selected": false,
                            "text": "",
                            "value": ""
                        }
                        fieldItem.model.items.push(addedArray);
                    }
                }
            }
            else if (fieldItem.model.fieldTypeItemId === FieldTypes.ListBox) {
                if (/required/g.test(fieldItem.model.cssClass)) {
                    let hasAddedArray = false;
                    fieldItem.model.items.map((item, index) => {
                        const {
                            itemId = null
                        } = item || {};

                        if (itemId == (fieldItem.model.name)) {
                            hasAddedArray = true;
                        }
                    })
                    if (!hasAddedArray) {
                        let addedArray = {
                            "itemId": fieldItem.model.name,
                            "selected": false,
                            "text": "",
                            "value": ""
                        }
                        fieldItem.model.items.unshift(addedArray);
                    }
                }
            }
            else if (fieldItem.model.fieldTypeItemId === FieldTypes.DropdownList) {
                fieldItem.model.showEmptyItem = true;
            }
        });
    }

    return newField;
}
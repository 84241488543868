import React from "react";
import defaultImg from "../../../../assets/img/placeholders/gray.png";
import { Text, Image, Link } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import Details from "../../../Common/Details";
import ScrollAnimation from "react-animate-on-scroll";
import RiseAndFade from "../../../Common/Animations/RiseAndFade";
import { valueHelper } from "../../../../helper/Animations";

const FeaturePrecinctsCampaignFeaturedStoreList = props => {
  const { Title = null, Subtitle = null, StoreList = null } =
    props.fields || {};

  return (
    <div className="featuredStoreList font-regular">
      {StoreList && StoreList.length > 0 && (
        <BaseComponent margin="standard">
          <div className="featuredStoreList__header">
            <div className="container">
              <div className="featuredStoreList__header--container col-12 col-lg-11">
                <ScrollAnimation
                  animateIn="animate__animated animate__fadeIn"
                  animateOnce={true}
                >
                  {Title &&
                    Title.value &&
                    (Title.value.href || Title.value.text) && (
                      <div className="featuredStoreList__header--title">
                        {Title.value.href ? (
                          <Link field={Title} />
                        ) : (
                          Title.value.text
                        )}
                      </div>
                    )}
                  {Subtitle &&
                    Subtitle.value &&
                    (Subtitle.value.href || Subtitle.value.text) && (
                      <div className="featuredStoreList__header--subtitle">
                        {Subtitle.value.href ? (
                          <Link field={Subtitle} />
                        ) : (
                          Subtitle.value.text
                        )}
                      </div>
                    )}
                </ScrollAnimation>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="featuredStoreList__tile row">
              {StoreList.map((item, index) => {
                const {
                  ItemId = null,
                  OpeningTime = null,
                  Name = null,
                  StoreLocation = null,
                  StoreLocationLink = null,
                  Logo = null,
                  Thumbnail = null,
                  StoreLink = null
                } = item.fields || {};
                let imageLogoLayout;
                let imageMainLayout;

                imageLogoLayout = (
                  <div className="featuredStoreList__tile--image-logo">
                    {Logo && Logo.value && Logo.value.src ? (
                      <Image
                        media={{
                          ...Logo.value,
                          src: defaultImg
                        }}
                        data-src={Logo.value.src}
                        className="featuredStoreList__tile--image-logo-img lazyload"
                      />
                    ) : (
                      <img
                        alt="default"
                        src={defaultImg}
                        className="featuredStoreList__tile--image-logo-img"
                      />
                    )}
                  </div>
                );

                imageMainLayout = (
                  <div>
                    {Thumbnail && Thumbnail.value && Thumbnail.value.src ? (
                      <Image
                        media={{
                          ...Thumbnail.value,
                          src: defaultImg
                        }}
                        data-src={Thumbnail.value.src}
                        className="featuredStoreList__tile--image-img lazyload"
                      />
                    ) : (
                      <img
                        alt="default"
                        src={defaultImg}
                        className="featuredStoreList__tile--image-img"
                      />
                    )}
                    {imageLogoLayout}
                  </div>
                );

                return (
                  <div
                    key={`featuredStoreList-${index}`}
                    className="featuredStoreList__tile--column col-lg-4"
                  >
                    <RiseAndFade position={valueHelper(index, 3)}>
                      <div className="animation-range">
                        <div className="featuredStoreList__tile--image-wrapper">
                          {StoreLink && StoreLink.value ? (
                            <a href={StoreLink.value}>{imageMainLayout}</a>
                          ) : (
                            imageMainLayout
                          )}
                        </div>

                        <div className="featuredStoreList__tile--title">
                          {StoreLink && StoreLink.value ? (
                            <a href={StoreLink.value}>
                              <Text field={Name} />
                            </a>
                          ) : (
                            <Text field={Name} />
                          )}
                        </div>
                      </div>
                      <div className="featuredStoreList__tile--metadata">
                        {StoreLocation && StoreLocation.value && (
                          <Details
                            iconValue="location"
                            linkUrl={
                              StoreLocationLink && StoreLocationLink.value
                                ? StoreLocationLink.value
                                : ""
                            }
                            linkName={StoreLocation}
                            size="24"
                            mapInfo={ItemId && ItemId.value ? ItemId.value : ""}
                          />
                        )}
                        {OpeningTime && OpeningTime.value && (
                          <Details
                            iconValue="time"
                            linkName={OpeningTime}
                            size="24"
                          />
                        )}
                      </div>
                    </RiseAndFade>
                  </div>
                );
              })}
            </div>
          </div>
        </BaseComponent>
      )}
    </div>
  );
};

export default FeaturePrecinctsCampaignFeaturedStoreList;

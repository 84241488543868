import React, { Component } from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import ContentTileList from "../../../Common/ContentTileList";
import defaultImg from "../../../../assets/img/placeholders/gray.png";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import LazyLoading from "../../../Common/LazyLoading";
import { valueHelper } from "../../../../helper/Animations";
import Subnav from "../../../Common/Subnav";

class FeatureRecipeRecipeListingFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recipeListData: {
        pageId: "",
        dbName: "",
        pageSize: 1,
        filter_tag: [],
        filter_occasion: ""
      },
      recipeListing: [],
      currentPage: 1,
      totalCount: 0,
      showLoader: true,
      isScrolling: false,
      hasMoreItems: true
    };
  }

  componentDidMount() {
    let contextInfo = window.document.getElementsByName("ContextItem")[0];
    let pageId = contextInfo.value;
    let dbName = contextInfo.dataset.targetDb;
    let tagCategory = "";
    let occassionCategory = "";
    let tagCategoryQSName = "filter_tag";
    let occassionCategoryQSName = "filter_occasion";
    tagCategory = this.getUrlParameter(tagCategoryQSName);
    occassionCategory = this.getUrlParameter(occassionCategoryQSName);
    let tagCategoryList = [];
    if (tagCategory !== "") {
      tagCategoryList = tagCategory.split(",");
    }
    this.setState(
      {
        ...this.state,
        recipeListData: {
          pageId: pageId,
          dbName: dbName,
          pageSize: 12,
          filter_tag: tagCategoryList,
          filter_occasion: occassionCategory
        }
      },
      () => this.getRecipeList(1)
    );
  }

  getUrlParameter(querystringParam) {
    querystringParam = querystringParam
      .replace(/[\[]/, "\\[")
      .replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + querystringParam + "=([^&#]*)");
    var results =
      window && window.location && window.location.search
        ? regex.exec(window.location.search)
        : null;
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  getRecipeList = async (pageNumber, isInfiniteScroll) => {
    const {
      pageId,
      dbName,
      pageSize,
      filter_tag,
      filter_occasion
    } = this.state.recipeListData;

    const data = {
      pageId,
      dbName,
      pageNumber,
      pageSize,
      filter_tag: filter_tag.toString(),
      filter_occasion
    };

    if (isInfiniteScroll) {
      this.setState({ isScrolling: true });
    } else {
      this.setState({ showLoader: true });
    }

    const url = "/api/sitecore/recipe/GetRecipes";
    axios({
      url,
      method: "GET",
      params: data
    })
      .then(responseRaw => {
        let response = responseRaw.data ? responseRaw.data : null;
        if (response && response.Recipes) {
          const lastPage = Math.ceil(response.TotalCount.value / 12);
          const list =
            pageNumber > 1
              ? this.state.recipeListing.concat(response.Recipes)
              : response.Recipes;
          const totalCount =
            response.TotalCount && response.TotalCount.value
              ? response.TotalCount.value
              : 0;
          const page =
            response.Page && response.Page.value ? response.Page.value : 1;

          this.setState({
            ...this.state,
            recipeListing: list,
            totalCount: totalCount,
            currentPage: page,
            hasMoreItems: page == lastPage ? false : true
          });
        }
        this.setState({
          showLoader: false,
          isScrolling: false
        });
      })
      .catch(error => {
        // just handle error
        console.log(error);
        this.setState({
          showLoader: false,
          isScrolling: false
        });
      });
  };

  handleClickMeal = value => {
    const { filter_occasion } = this.state.recipeListData;

    if (filter_occasion == value) {
      return;
    }

    this.setState(
      {
        recipeListData: {
          ...this.state.recipeListData,
          filter_occasion: value
        },
        currentPage: 1
      },
      () => this.getRecipeList(1)
    );
  };

  handleClickTaste = event => {
    const element = event.target;
    const elementId = element.id;
    let tagList = this.state.recipeListData.filter_tag;
    if (/is-active/.test(element.classList.value)) {
      tagList = tagList.filter(function (arrValue) {
        return elementId.indexOf(arrValue) < 0;
      });
    } else {
      tagList.push(elementId);
    }
    this.setState(
      {
        recipeListData: {
          ...this.state.recipeListData,
          filter_tag: tagList
        },
        currentPage: 1
      },
      () => this.getRecipeList(1)
    );
    element.classList.toggle("is-active");
  };

  loadRecipeList() {
    this.getRecipeList(this.state.currentPage + 1, true);
  }

  renderMeals(Occasions) {
    const { filter_occasion } = this.state.recipeListData;
    return (
      <Subnav
        tabs={Occasions}
        hasAllTab
        allTabQuery=""
        defaultName="Name"
        defaultFilter="Id"
        selected={filter_occasion}
        updateFilter={filter => this.handleClickMeal(filter)}
      />
    );
  }

  renderTaste(Tags) {
    const { filter_tag } = this.state.recipeListData;
    return (
      <div className="recipe-listing__taste">
        <div className="recipe-listing__taste--label">Filter by:</div>
        <ul className="recipe-listing__taste--filter">
          {Tags.map((item, index) => {
            const { Name = null, Id = null } = item.fields;
            let isActive = "";
            if (filter_tag.length > 0) {
              filter_tag.forEach(function (item, index) {
                if (item == Id.value) {
                  isActive = "is-active";
                }
              });
            }
            return (
              <li
                key={`taste-${Id.value}-${index}`}
                className={`recipe-listing__taste--filter-list ${isActive}`}
                id={Id && Id.value ? Id.value : ""}
                onClick={this.handleClickTaste}
              >
                <Text field={Name} />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  renderRecipes(recipeListing) {
    const { NoResultsText } = this.props.fields || {};
    let recipeLayout;
    if (recipeListing && recipeListing.length > 0) {
      recipeLayout = (
        <div className="content-tile-list-wrapper">
          <div className="row">
            {recipeListing.map((item, index) => {
              const {
                RecipeTitle = null,
                Thumbnail = null,
                CookTime = null,
                Serves = null,
                Difficulty = null,
                RecipeLink = null
              } = item;

              return (
                <ContentTileList
                  index
                  name={RecipeTitle}
                  nameLink={RecipeLink}
                  thumbnail={Thumbnail}
                  cookTime={CookTime}
                  serves={Serves}
                  difficulty={Difficulty}
                  position={valueHelper(index, 4, 3)}
                />
              );
            })}
          </div>
        </div>
      );
    } else {
      recipeLayout = (
        <div className="recipe-listing__content--noresult">
          {NoResultsText && NoResultsText.value ? (
            <Text field={NoResultsText} />
          ) : (
            "No result. Please try again."
          )}
        </div>
      );
    }
    return recipeLayout;
  }

  render() {
    const { Occasions = [], Tags = [] } = this.props.fields || {};

    const { showLoader, isScrolling, recipeListing } = this.state;

    return (
      <div className="recipe-listing font-regular">
        <BaseComponent margin="standard">
          {Occasions && Occasions.length > 0 && this.renderMeals(Occasions)}

          <div className="container">
            {Tags && Tags.length > 0 && this.renderTaste(Tags)}
            <InfiniteScroll
              style={{ height: "auto", overflow: "auto" }}
              dataLength={recipeListing.length}
              next={this.loadRecipeList.bind(this)}
              hasMore={this.state.hasMoreItems}
            >
              <div className="recipe-listing__content--wrapper">
                {!showLoader && this.renderRecipes(recipeListing)}
                {(showLoader || isScrolling) && <LazyLoading />}
              </div>
            </InfiniteScroll>
          </div>
        </BaseComponent>
      </div>
    );
  }
}

export default FeatureRecipeRecipeListingFilter;

import React from "react";
import { Text, RichText, Image } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import Details from "../../../Common/Details";
import ScrollAnimation from "react-animate-on-scroll";
import defaultImg from "../../../../assets/img/placeholders/gray.png";
import Highlight from "../../../Common/Highlight";

const FeaturePromotionsStorefrontWhatsOn = props => {
  const { TitleLink = null, Title = null, PromotionList = [] } =
    props.fields || {};
  let storeFrontWhatsOnLayout = "";

  if (PromotionList && PromotionList.length > 0) {
    storeFrontWhatsOnLayout = (
      <BaseComponent margin="custom">
        <div className="container">
          <div className="storefront-whatson__wrapper font-regular">
            <ScrollAnimation
              animateIn="animate__animated animate__fadeIn"
              animateOnce={true}
            >
              <div className="storefront-whatson__header">
                {TitleLink && TitleLink.value ? (
                  <a href={TitleLink.value} target="">
                    <Text field={Title} />
                  </a>
                ) : (
                  <Text field={Title} />
                )}
              </div>
            </ScrollAnimation>

            <div className="storefront-whatson__content">
              {PromotionList.map((item, index) => {
                const {
                  PromotionName = null,
                  Summary = null,
                  Duration = null,
                  OfferHighlight = null,
                  Thumbnail = null,
                  PromotionLink = null,
                  IsRetailerContent = null,
                  IsLogoAsThumbnail = null,
                  Retailer = null
                } = item.fields || {};

                let imageLayout = (
                  <div
                    className={
                      IsLogoAsThumbnail && IsLogoAsThumbnail.value
                        ? "display_retailer_logo"
                        : ""
                    }
                  >
                    {Thumbnail &&
                      Thumbnail.value &&
                      !Thumbnail.value.src &&
                      IsRetailerContent &&
                      IsRetailerContent.value && (
                        <div className="display_retailer_name">
                          <Text field={Retailer} />
                        </div>
                      )}
                    <div className="include_logo_padding">
                      <Image
                        media={{ ...Thumbnail.value, src: defaultImg }}
                        data-src={Thumbnail.value.src}
                        className="lazyload"
                      />
                      {OfferHighlight && OfferHighlight.value && (
                        <Highlight text={OfferHighlight} />
                      )}
                    </div>
                  </div>
                );

                return (
                  <div
                    className="storefront-whatson__item"
                    key={"storefront-whatson-promo-list" + index}
                  >
                    <div
                      className={`storefront-whatson__animation ${
                        PromotionLink && PromotionLink.value
                          ? "animation-range"
                          : ""
                      }`}
                    >
                      <div className="storefront-whatson__image">
                        {PromotionLink && PromotionLink.value ? (
                          <a href={PromotionLink.value} target="">
                            {imageLayout}
                          </a>
                        ) : (
                          imageLayout
                        )}
                      </div>
                      {PromotionName && PromotionName.value && (
                        <div className="storefront-whatson__title">
                          {PromotionLink && PromotionLink.value ? (
                            <a href={PromotionLink.value} target="">
                              <span>
                                <Text field={PromotionName} />
                              </span>
                              <i className="icon icon-arrow-right" />
                            </a>
                          ) : (
                            <Text field={PromotionName} />
                          )}
                        </div>
                      )}
                    </div>

                    <div className="storefront-whatson__details">
                      {Summary && Summary.value && (
                        <div className="storefront-whatson__description">
                          <RichText field={Summary} />
                        </div>
                      )}
                      {Duration && Duration.value && (
                        <Details
                          iconValue="time"
                          linkName={Duration}
                          size="24"
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  }
  return storeFrontWhatsOnLayout;
};

export default FeaturePromotionsStorefrontWhatsOn;

import React, { Component } from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import { debounce } from "lodash";
import BaseComponent from "../../../Base/BaseComponent";
import GetAncestorElement from "../../../Common/Helper/GetAncestorElement";

class FeatureGenericContentConsumerFAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showClearButton: false,
      faqList: {}
    };
    this.searchTerm = React.createRef();
  }

  componentDidMount() {
    const {
      FAQGroups = []
    } = this.props.fields || {};
    if (FAQGroups && FAQGroups.length > 0) {
      this.setState({
        faqList: FAQGroups
      })
    }
  }

  handleClickOption = (event) => {
    const element = event.target;
    const faqOptionListContainer = GetAncestorElement(element, "faq__group--content__item");
    if (faqOptionListContainer) {
      faqOptionListContainer.classList.toggle("is-active");
    }
  }

  handleClickCollapse = (event) => {
    const element = event.target;
    const faqContainer = GetAncestorElement(element, "faq");
    const faqOptionList = faqContainer.querySelectorAll(".faq__group--content__item");
    if (faqOptionList) {
      for (let i = 0; i < faqOptionList.length; i++) {
        let faqOption = faqOptionList[i];
        faqOption.classList.remove("is-active");
      }
    }
  }

  handleClickExpand = (event) => {
    const element = event.target;
    const faqContainer = GetAncestorElement(element, "faq");
    const faqOptionList = faqContainer.querySelectorAll(".faq__group--content__item");
    if (faqOptionList) {
      for (let i = 0; i < faqOptionList.length; i++) {
        let faqOption = faqOptionList[i];
        faqOption.classList.add("is-active");
      }
    }
  }

  handleSearchFilter = event => {
    const {
      FAQGroups = {}
    } = this.props.fields || {};
    let searchFaqList = [];
    const searchTerm = this.searchTerm.current;
    if (searchTerm && searchTerm.value !== "") {
      let replaceSpecialChar = searchTerm.value.replace(/[-[\]{}()*+!<=:?.\/\\^$|#\s,]/g, '\\$&');
      const searchPattern = new RegExp(replaceSpecialChar.toLowerCase());
      FAQGroups.map((itemGroup, index) => {
        let groupOption = {}
        let hasSearch = false
        const {
          Name = null,
          FAQs = null,
        } = itemGroup.fields;

        groupOption = {
          "fields": {
            "Name": {
              "value": Name.value
            },
            "FAQs": [
            ]
          }
        }
        FAQs.map((itemOption, index) => {
          const {
            Question,
            Answer
          } = itemOption.fields
          let optionList;
          if (searchPattern.test(Question.value.toLowerCase())) {
            let regEx = new RegExp("(" + replaceSpecialChar + ")", "gi");
            let replaceMask = `<span class="faq__group--content__item--mask">$1</span>`;
            const replaceQuestion = Question.value.replace(regEx, replaceMask);
            optionList = {
              "fields": {
                "Question": {
                  "value": replaceQuestion
                },
                "Answer": {
                  "value": Answer.value
                }
              }
            }
            groupOption.fields.FAQs.push(optionList);
            hasSearch = true;
          }
        })
        if (hasSearch) {
          searchFaqList.push(groupOption);
        }
      })
      this.setState({
        ...this.state,
        showClearButton: true,
        faqList: searchFaqList
      });
    } else {
      this.setState({
        ...this.state,
        showClearButton: false,
        faqList: FAQGroups
      });
    }
  };

  handleClearButton = (faqGroup) => {
    this.searchTerm.current.value = "";
    this.setState({
      ...this.state,
      showClearButton: false,
      faqList: faqGroup
    });
  };

  renderFAQ = () => {
    const {
      faqList
    } = this.state;
    const {
      NoResultsText = null
    } = this.props.fields || {};
    let faqOptionLayout;
    if (faqList && faqList.length > 0) {

      faqOptionLayout = faqList.map((item, index) => {
        const {
          Name = null,
          FAQs = null,
        } = item.fields;

        return (
          <div className="faq__group" key={Name.value + index}>
            <div className="row">
              <div className="col-12 col-md-3">
                <div className="faq__group--label">
                  <Text field={Name} />
                </div>
              </div>
              <div className="col-12 col-md-9">
                <div className="faq__group--content">
                  {FAQs &&
                    FAQs.length > 0 &&
                    FAQs.map((item, index) => {
                      const {
                        Question = null,
                        Answer = null
                      } = item.fields;
                      let faqContentLayout;
                      if (Question && Question.value && Answer && Answer.value) {
                        faqContentLayout =
                          <div className="faq__group--content__item" key={Question.value + index}>
                            <button className="faq__group--content__item--btn"
                              onClick={this.handleClickOption}>
                              <RichText field={Question} />
                            </button>
                            <div className="faq__group--content__item--description">
                              <RichText field={Answer} />
                            </div>
                          </div>
                      }
                      return faqContentLayout;
                    })
                  }
                </div>
              </div>
            </div>
          </div >
        )
      })
    } else {
      faqOptionLayout =
        <div className="faq__group--noresult">
          {NoResultsText && NoResultsText.value ?
            <Text field={NoResultsText} /> :
            "No Result"
          }
        </div>
    }

    return faqOptionLayout;
  }
  render() {
    const {
      SearchPlaceholder = null,
      FAQGroups = [],
    } = this.props.fields || {};
    return (
      <div className="faq font-regular">
        {FAQGroups &&
          FAQGroups.length > 0 &&
          <BaseComponent margin="standard">
            <div className="container">
              <div className="faq__search">
                <input
                  className="faq__search--box"
                  type="search"
                  placeholder={SearchPlaceholder.value}
                  ref={this.searchTerm}
                  onChange={debounce(e => {
                    this.handleSearchFilter(e);
                  }, 1000)}
                />
                {
                  this.state.showClearButton &&
                  <button
                    className="faq__search--clear"
                    onClick={() => this.handleClearButton(FAQGroups)}>
                    Clear
                </button>
                }
              </div>
              <div className="faq__btn">
                <button className="faq__btn--button"
                  onClick={this.handleClickCollapse}>
                  <i className="icon icon-collapse" />
                  <span>Collapse all</span>
                </button>
                <button className="faq__btn--button"
                  onClick={this.handleClickExpand}>
                  <i className="icon icon-expand-2" />
                  <span>Expand all</span>
                </button>
              </div>
              <div className="faq__group--wrapper">
                {this.renderFAQ()}
              </div>
            </div>
          </BaseComponent>
        }
      </div>
    );
  }
}

export default FeatureGenericContentConsumerFAQ;

import React from "react";
import { Text, Link } from "@sitecore-jss/sitecore-jss-react";
import Newsletter from "../../Forms/Newsletter";

const FeatureGenericContentGlobalFooter = props => {
  const {
    CentreText: CentreText = null,
    VisitLinks: VisitLinks = null,
    CentreAddress: CentreAddress = null,
    ViewOnMap: ViewOnMap = null,
    SubscribeTitle: SubscribeTitle = null,
    EmailPlaceholder: EmailPlaceholder = null,
    CentreFormButton: CentreFormButton = null,
    CentreAgreement: CentreAgreement = null,
    SocialLinks: SocialLinks = null,
    FootnoteLinks: FootnoteLinks = null,
    EmailPreferenceModalTitle = null,
    EmailPreferenceModalDescription = null,
    EmailPreferenceModalButton = null,
    Interests = null,
    GenderGroupLabel = null,
    GenderOptions = null,
    MiscLogoUrl = null,
    MiscLogoLink = null,
    FirstNameLabel = null,
    LastNameLabel = null,
    EmailLabel = null,
    PostcodeLabel = null
  } = props.fields || {};

  let footerLayout = "";

  if (props.params.pageName !== "Centre Map") {
    footerLayout =
      <div className="globalfooter-wrapper">
        <div className="container">
          <div className="row">
            <div className="col col-12 col-xl-6 col-lg-5 ">
              <div className="heading">
                <Text field={CentreText} />
              </div>
              <div className="row">
                {/* ---------- CENTRE PAGES ---------- */}
                <div className="col col-12 col-md-6 centre-pages">
                  {VisitLinks &&
                    VisitLinks.length &&
                    VisitLinks.map((item, index) => {
                      let {
                        VisitLink: VisitLink = null,
                        LinkName: LinkName = null
                      } = item.fields || {};
                      let visitLinksLayout = null;
                      if (
                        VisitLink &&
                        VisitLink.value &&
                        VisitLink.value.href &&
                        LinkName &&
                        LinkName.value
                      ) {
                        visitLinksLayout = (
                          <div className="item" key={`myId-${index}`}>
                            <a {...VisitLink.value}>
                              <Text field={LinkName} />
                            </a>
                          </div>
                        );
                      }
                      return visitLinksLayout;
                    })}
                </div>
                {/* ---------- CENTRE INFO ---------- */}
                <div className="col col-12 col-xl-5 col-md-6 centre-location">
                  <div className="centre-address">
                    <Text field={CentreAddress} />
                  </div>
                  <div className="centre-map">
                    <i className="icon icon-location" />
                    <Link field={ViewOnMap} />
                  </div>

                  {/* ---------- MISC INFO---------- */}
                  {MiscLogoUrl && MiscLogoUrl.value &&
                    MiscLogoLink && MiscLogoLink.value && MiscLogoLink.value.href &&
                    <div className="misc-url">
                      <a
                        {...MiscLogoLink.value}
                        href={MiscLogoLink.value.href + MiscLogoLink.value.querystring}
                      >
                        <img src={MiscLogoUrl.value} alt={"Misc Logo Alt"} />
                        <span>{MiscLogoLink.value.text}</span>
                      </a>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="col">
              {/* ---------- SUBSCRIBE FORM ---------- */}

              {SubscribeTitle && SubscribeTitle.value && (
                <div className="footer-desc">
                  <Text field={SubscribeTitle} />
                </div>
              )}
              <Newsletter
                EmailPlaceholder={EmailPlaceholder}
                CentreFormButton={CentreFormButton}
                CentreAgreement={CentreAgreement}
                EmailPreferenceModalTitle={EmailPreferenceModalTitle}
                EmailPreferenceModalDescription={EmailPreferenceModalDescription}
                EmailPreferenceModalButton={EmailPreferenceModalButton}
                Interests={Interests}
                GenderGroupLabel={GenderGroupLabel}
                FirstNameLabel={FirstNameLabel}
                LastNameLabel={LastNameLabel}
                EmailLabel={EmailLabel}
                PostcodeLabel={PostcodeLabel}
                GenderOptions={GenderOptions}
              />
              {/* ---------- SOCIAL MEDIA LINKS ---------- */}
              {SocialLinks && SocialLinks.length && (
                <div className="centresocial">
                  <div className="row no-gutters justify-content-md-start">
                    {SocialLinks &&
                      SocialLinks.map((item, index) => {
                        let {
                          SocialLink: SocialLink = null,
                          Icon: Icon = null
                        } = item.fields;
                        let socialLinkLayout = null;
                        if (
                          Icon &&
                          Icon.value &&
                          SocialLink &&
                          SocialLink.value &&
                          SocialLink.value.href
                        ) {
                          socialLinkLayout = (
                            <div className="col col-auto" key={`myId-${index}`}>
                              <a {...SocialLink.value}>
                                <i className={`icon icon-${Icon.value}`} />
                              </a>
                            </div>
                          );
                        }
                        return socialLinkLayout;
                      })}
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* ---------- CENTRE LINKS ---------- */}
          {FootnoteLinks && FootnoteLinks.length && (
            <div className="centrelink">
              <div className="row no-gutters">
                {FootnoteLinks &&
                  FootnoteLinks.map((item, index) => {
                    let {
                      FootNoteLink: FootNoteLink = null,
                      LinkName: LinkName = null
                    } = item.fields || {};
                    let footnoteLinkLayout = null;
                    if (FootNoteLink.value.href && LinkName.value) {
                      footnoteLinkLayout = (
                        <div key={`myId-${index}`} className="col-auto">
                          <a {...FootNoteLink.value}>
                            <Text field={LinkName} />
                          </a>
                          <a {...FootNoteLink.value} className="no-underline">
                            <i className="icon icon-external ml-2" />
                          </a>
                        </div>
                      );
                    }
                    return footnoteLinkLayout;
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
  }

  return footerLayout;
};

export default FeatureGenericContentGlobalFooter;

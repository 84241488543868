import React from "react";
import FeaturePromotionsSimilarPromotions from "../SimilarPromotions";

const FeaturePromotionsFeaturedPromotions = props => {
  const {
    Title: Title = null,
    ShortDescription: Subtext = null,
    PromotionList: EventList = null
  } = props.fields || {};
  return (
    <FeaturePromotionsSimilarPromotions
      fields={{ Title, Subtext, EventList }}
    />
  );
};

export default FeaturePromotionsFeaturedPromotions;

import React, { Component } from "react";

class CustomDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDroplist: false,
            selectValue: null,
            relatedField: {
                id: null,
                selectedHasRelatedField: false,
                relatedFieldLabel: null,
                relatedFieldvalue: null
            },

        }
    }

    componentDidMount() {
        if (window) {
            window.addEventListener("scroll", this.handleScroll);
        }

    }

    componentDidUpdate() {
        const listScroller = document.querySelector('.email-preference__options') //modal scroller
        if (listScroller) {
            if (this.state.relatedField.selectedHasRelatedField) listScroller.scrollTop = listScroller.scrollHeight
        }
    }

    handleScroll = () => {
        this.setState({ showDroplist: false });
    }

    handleToggle = (e) => {
        e.target.focus();
        this.setState({ showDroplist: !this.state.showDroplist });
    }

    handleBlur = (e) => {
        if (this.state.showDroplist) {
            setTimeout(() => {
                this.setState({ showDroplist: !this.state.showDroplist });
            }, 250)
        }
    }

    handleContainerClick = (event) => {
        const elemClass = event.target.classList.value;
        const dropdownBtnPattern = new RegExp("dropdown-btn");
        const dropdownLabelPattern = new RegExp("dropdown-label");
        const iconPattern = new RegExp("icon");
        const dropdownBtnFormat = dropdownBtnPattern.test(elemClass);
        const dropdownLabelFormat = dropdownLabelPattern.test(elemClass);
        const iconPatternFormat = iconPattern.test(elemClass);

        if (dropdownBtnFormat) {
            event.target.click();
        }
        else if (dropdownLabelFormat) {
            event.target.querySelector("input").click();
        }
        else if (iconPatternFormat) {
            event.target.previousElementSibling.click();
        }
    }

    handleDropdownClick = (event, relatedField) => {
        const { onChange = null, relatedFieldsValues = {} } = this.props || {}
        const { id, HasRelatedField, RelatedFieldLabel, RelatedFieldCurrentValue, RelatedFieldRequired } = relatedField
        this.setState({
            selectValue: event.target.getAttribute("label"),
            relatedField: {
                selectedHasRelatedField: false
            }
        })
        if (HasRelatedField && HasRelatedField.value) this.setState({
            relatedField: {
                id: id && id.value,
                selectedHasRelatedField: HasRelatedField && HasRelatedField.value,
                relatedFieldLabel: RelatedFieldLabel && RelatedFieldLabel.value,
                relatedFieldvalue: RelatedFieldCurrentValue && RelatedFieldCurrentValue.value,
                relatedFieldRequired: RelatedFieldRequired && RelatedFieldRequired.value
            }
        })

        if (onChange) {
            if (relatedFieldsValues[event.target.id]) onChange(`${event.target.id}+${this.props.relatedFieldsValues[event.target.id]}`, event.target);
            else onChange(event.target.id, event.target, 'dropdown');
        }
    }

    validateInput = (value) => {
        let inputRegex = new RegExp(/^[a-zÀ-ÿ ,.'-]+$/i);
        if (value) return inputRegex.test(String(value).toLowerCase())
    };

    handleInputChange = (event, isRelatedFieldRequired = false) => {
        const { onChange = null, setRelatedFieldsValues } = this.props || {}
        const tempFieldsValues = { ...this.props.relatedFieldsValues[event.target.id] }
        tempFieldsValues[event.target.id.split('-')[0]] = event.target.value
        this.props.setRelatedFieldsValues(tempFieldsValues)

        if (isRelatedFieldRequired) {
            if (this.validateInput(event.target.value)) {
                // remove box-error if there's any
                event.target.classList.remove('box-error')
                if (onChange) onChange(`${event.target.id}+${event.target.value}`, event.target);
            } else event.target.classList.add('box-error')
        } else {
            if (onChange) onChange(`${event.target.id}+${event.target.value}`, event.target);
        }

    }

    render() {

        const {
            data = null,
            placeholder = null,
            defaultValue = null,
            relatedFieldsValues = null
        } = this.props || {};
        const {
            showDroplist,
            selectValue,
            relatedField,
        } = this.state;
        return (
            <div className="form-group form-dropdown">
                <div className="dropdown-container">
                    <div className={`dropdown-label ${showDroplist ? "active" : ""}`}
                        onClick={this.handleContainerClick}
                    >
                        <input
                            type="button"
                            value={(selectValue ? selectValue : defaultValue ? defaultValue : placeholder ? placeholder : "Select")}
                            className="dropdown-btn"
                            onClick={this.handleToggle}
                            onBlur={this.handleBlur}
                        />
                        <i className="icon icon-arrow-down"></i>
                    </div>
                    <ul className="dropdown-list" hidden={!showDroplist}>
                        {data &&
                            data.map((dropdownList, index) => {
                                const {
                                    Id: id = null,
                                    Name: name = null,
                                    Selected: selected = null,
                                    HasRelatedField = null,
                                    RelatedFieldLabel = null,
                                    RelatedFieldCurrentValue = null,
                                    RelatedFieldRequired = null,
                                } = dropdownList.fields || {};
                                return (
                                    <li className={(defaultValue == id.value && selectValue == null) || selectValue === name.value ? "active" : ""}
                                        id={id.value}
                                        key={`${name.value}-${name.id}-${index}-dropdown`}
                                        onClick={(e) => this.handleDropdownClick(e, { id, HasRelatedField, RelatedFieldLabel, RelatedFieldCurrentValue, RelatedFieldRequired })}
                                        label={name.value} >
                                        <i className="icon icon-check"></i>{name.value}
                                    </li>
                                )
                            })}
                    </ul>
                    {relatedField.selectedHasRelatedField &&
                        <div className="form-group related-field">
                            <input
                                id={relatedField.id + '-dropdown-input'}
                                type="text"
                                className="form-control form-control-lg related-field-input"
                                onChange={(e) => this.handleInputChange(e, relatedField.relatedFieldRequired)}
                                onBlur={(e) => this.handleInputChange(e, relatedField.relatedFieldRequired)}
                                required
                                value={relatedFieldsValues[relatedField.id]}
                            >
                            </input>
                            <label
                                className="form-control-placeholder"
                                htmlFor={relatedField.id + '-dropdown-input'}
                            >
                                {relatedField.relatedFieldLabel}
                            </label>
                        </div>

                    }
                </div>
            </div>
        )
    }
}

export default CustomDropdown;


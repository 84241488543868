import React from "react";
import { Link, Text, Image } from "@sitecore-jss/sitecore-jss-react";
import ScrollAnimation from "react-animate-on-scroll";
import BaseComponent from "../../../Base/BaseComponent";
import Details from "../../../Common/Details";
import defaultImg from "../../../../assets/img/placeholders/gray.png";
import RiseAndFade from "../../../Common/Animations/RiseAndFade";

const FeatureStorefrontRecommendation = props => {
  const {
    StoreDirectoryLandingPage: storeDirectoryLandingPage = "",
    StoreDirectoryCtaText: storeDirectoryCtaText = "",
    StoreDirectoryCtaAnchor: storeDirectoryCtaAnchor = "",
    Title: Title = "",
    RetailerList: retailerList = ""
  } = props.fields || {};

  const storeDirectoryLinkField = {
    href: storeDirectoryLandingPage ? storeDirectoryLandingPage.value : null,
    text: storeDirectoryCtaText ? storeDirectoryCtaText.value : null,
    anchor: storeDirectoryCtaAnchor ? storeDirectoryCtaAnchor.value : null,
    linktype: "internal",
    class: "",
    title: storeDirectoryCtaText ? storeDirectoryCtaText.value : null,
    querystring: ""
  };

  let recommendation;

  if (
    props.rendering.dataSource &&
    props.rendering.dataSource !== "" &&
    retailerList &&
    retailerList.length === 3
  ) {
    recommendation = (
      <BaseComponent margin="standard">
        <div className="recommendation-wrapper">
          <div className="container">
            <div className="recommendation-banner">
              <div className="recommendation-header">
                <ScrollAnimation
                  animateIn="animate__animated animate__fadeIn"
                  animateOnce={true}
                >
                  <div className="title">
                    <Link field={storeDirectoryLinkField} />
                    <i className="icon icon-arrow-right ml-2" />
                  </div>
                  <div className="subtitle">
                    <Text field={Title} />
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
          <div className="recommendation-tile-wrapper">
            <div className="container">
              <div className="row no-gutters">
                {retailerList &&
                  retailerList.map((item, index) => {
                    const {
                      HeroImage = null,
                      Logo = null,
                      RetailerName = null,
                      StoreLocation = null,
                      StoreLocationLink = null,
                      OpeningTime = null,
                      RetailerPageHref = null,
                      ItemId = null
                    } = item.fields;
                    let value = 0;
                    let guidRegex = new RegExp(/^{[a-z0-9-]}+$/i);
                    if (index == 1) {
                      value = 6;
                    } else if (index == 2) {
                      value = 8;
                    }
                    return (
                      <div key={`myId-${index}`} className="col-10 col-md-4">
                        <RiseAndFade position={value}>
                          <div className="animation-range">
                            <div className="recommendation-tile-wrapper-img-wrapper">
                              <a
                                href={
                                  RetailerPageHref && RetailerPageHref.value
                                    ? RetailerPageHref.value
                                    : null
                                }
                              >
                                {HeroImage &&
                                HeroImage.value &&
                                HeroImage.value.src ? (
                                  <Image
                                    media={{
                                      ...HeroImage.value,
                                      src: defaultImg
                                    }}
                                    data-src={HeroImage.value.src}
                                    className="recommendation-tile-wrapper-img lazyload"
                                  />
                                ) : (
                                  <div className="no-image" />
                                )}
                                <div className="recommendation-tile-wrapper-logo">
                                  {Logo && Logo.value && Logo.value.src ? (
                                    <Image
                                      media={{ ...Logo.value, src: defaultImg }}
                                      data-src={Logo.value.src}
                                      className="recommendation-tile-wrapper-img-thumb lazyload"
                                    />
                                  ) : (
                                    <div className="no-logo">
                                      <Text field={RetailerName} />
                                    </div>
                                  )}
                                </div>
                              </a>
                            </div>

                            <div className="details-title">
                              <a
                                href={
                                  RetailerPageHref && RetailerPageHref.value
                                    ? RetailerPageHref.value
                                    : null
                                }
                              >
                                <Text field={RetailerName} />
                              </a>
                            </div>
                          </div>
                          {StoreLocation && StoreLocation.value && (
                            <Details
                              iconValue="location"
                              linkUrl={
                                StoreLocationLink && StoreLocationLink.value
                              }
                              linkName={StoreLocation}
                              size="24"
                              mapInfo={
                                ItemId &&
                                ItemId.fields &&
                                ItemId.fields.Guid &&
                                ItemId.fields.Guid.value
                                  ? (guidRegex.test(ItemId.fields.Guid.value) ? 
                                    ItemId.fields.Guid.value : "{" + ItemId.fields.Guid.value + "}")
                                  : ""
                              }
                            />
                          )}
                          {OpeningTime && OpeningTime.value && (
                            <Details
                              iconValue="time"
                              linkName={OpeningTime}
                              size="24"
                            />
                          )}
                        </RiseAndFade>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  } else {
    recommendation = "";
  }

  return recommendation;
};

export default FeatureStorefrontRecommendation;

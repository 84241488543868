import React from "react";
import Details from "../../Details";
import RiseAndFade from "../../Animations/RiseAndFade";
import { Image, Text } from "@sitecore-jss/sitecore-jss-react";
import transparent from "../../../../assets/img/placeholders/transparent.png";

const FeaturedRecipeTiles = props => (
  <div className="featured-recipe-list-wrapper">
    <div className="container">
      <div className="featured-recipe-list">
        {props.data.map((item, index) => {
          const {
            Thumbnail,
            Type,
            Link: link,
            Title,
            CookTime,
            Servings,
            Difficulty
          } = item.fields || {};
          let value = 0;
          if (index == 1) {
            value = 6;
          } else if (index == 2) {
            value = 8;
          }
          return (
            <div className="tile" key={`featured-recipe-item-${index}`}>
              <RiseAndFade position={value}>
                <div className="animation-range">
                  <div className="photo">
                    <a href={link.value}>
                      {Thumbnail && Thumbnail.value ? (
                        <Image media={Thumbnail} className="lazyload" />
                      ) : (
                        <img src={transparent} />
                      )}
                    </a>
                  </div>
                  <div className="category-type">
                    <Text field={Type} />
                  </div>
                  <div className="title">
                    <a href={link.value}>
                      <Text field={Title} />
                    </a>
                  </div>
                </div>
                <div className="recipe-details-wrapper">
                  {CookTime && CookTime.value && (
                    <Details
                      iconValue="timer"
                      size="24"
                      linkName={{ value: CookTime.value }}
                    />
                  )}
                  {Servings && Servings.value && (
                    <Details
                      iconValue="users"
                      size="24"
                      linkName={{ value: Servings.value }}
                    />
                  )}
                  {Difficulty && Difficulty.value && (
                    <Details
                      iconValue="chef-2"
                      size="24"
                      linkName={{ value: Difficulty.value }}
                    />
                  )}
                </div>
              </RiseAndFade>
            </div>
          );
        })}
      </div>
    </div>
  </div>
);

export default FeaturedRecipeTiles;

import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const Highlight = props => {
  const { text } = props;
  if (text && text.value) {
    return (
      <div className="highlight-wrapper">
        <Text field={text} />
      </div>
    );
  } else return null;
};
export default Highlight;

import React from "react";
import { Image, Text } from "@sitecore-jss/sitecore-jss-react";
import Badge from "../../../Common/Badge";
import HeroVideo from "../../../Common/Video";
import defaultImg from "../../../../assets/img/placeholders/transparent.png";

const FeatureGenericContentHero = props => {
  const {
    StartDate: startDate = null,
    EndDate: endDate = null,
    HeroImage: heroImage = null,
    TargetUrl: targetUrl = null,
    HeroVideo: heroVideo = null,
    Title: title = null,
    PromotionType: promotionType = null,
  } = props.fields || {};

  const getBadge = () => {
    if (startDate && startDate.value) {
      return (
        <div className="genericcontent-hero-badge">
          <Badge
            type="hero"
            startDate={startDate.value}
            endDate={endDate && endDate.value ? endDate.value : ""}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const getTitle = () => {
    if (title && title.value) {
      return (
        <div className="row">
          <div className="col-12 col-lg-9 col-xl-8">
            <div className="genericcontent-herodescription-wrapper">
              {promotionType &&
                promotionType.value &&
                <div className="promotions-type">
                  {targetUrl && targetUrl.value ? (
                    <a href={targetUrl.value}>
                      <Text field={promotionType} />
                    </a>
                  ) : (
                      <Text field={promotionType} />
                    )}
                </div>
              }
              <div className="promotions-name">
                {targetUrl && targetUrl.value ? (
                  <a href={targetUrl.value}>
                    <Text field={title} />
                  </a>
                ) : (
                    <Text field={title} />
                  )}
              </div>
            </div>
          </div>
        </div>
      );
    } else return null;
  };

  if (heroVideo && heroVideo.value) {
    return (
      <div className={`genericcontent-hero ${promotionType && promotionType.value ? "has-promotion" : ""}`}>
        <div className="container">
          <div className="animation-range">
            <div className="genericcontent-hero-container">
              <div className="hero-wrapper">
                <HeroVideo data={heroVideo.value} 
                hasControls={promotionType && promotionType.value ? false : true}
                isMute={promotionType && promotionType.value ? true : false}
                isLoop={promotionType && promotionType.value ? true : false}
                isAutoplay={promotionType && promotionType.value ? true : false}
                />
              </div>
            </div>
            {getTitle()}
          </div>
        </div>
      </div>
    );
  } else if (heroImage && heroImage.value && heroImage.value.src) {
    return (
      <div className={`genericcontent-hero ${promotionType && promotionType.value ? "has-promotion" : ""}`}>
        <div className="container">
          <div className="animation-range">
            <div className="genericcontent-hero-container">
              <div className="hero-wrapper">
                {targetUrl && targetUrl.value ? (
                  <a href={targetUrl.value}>
                    <Image
                      media={{ ...heroImage.value, src: defaultImg }}
                      data-src={heroImage.value.src}
                      className="hero-img lazyload"
                    />
                  </a>
                ) : (
                    <Image
                      media={{ ...heroImage.value, src: defaultImg }}
                      data-src={heroImage.value.src}
                      className="hero-img lazyload"
                    />
                  )}
              </div>
              {getBadge()}
            </div>
            {getTitle()}
          </div>
        </div>
      </div>
    );
  } else {
    return <div className="genericcontent-hero-empty"></div>;
  }
};

export default FeatureGenericContentHero;

import React, { Component } from "react";
import { Text, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import SitecoreContextFactory from "../../../../lib/SitecoreContextFactory";
import Subnav from "../../../Common/Subnav";

class FeatureHeaderWhatsOnListHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: ""
    };
  }

  componentDidMount() {
    this.setState({ selectedCategory: this.getUrlParameter("filter_cat") });
  }

  updateMainFilter(filterName) {
    const sitecoreContext = this.props.sitecoreContext;
    SitecoreContextFactory.setSitecoreContext({
      ...sitecoreContext,
      whatsOnMainFilter: filterName,
      refresh: true
    });
    this.setState({ selectedCategory: filterName });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.sitecoreContext &&
      this.props.sitecoreContext.whatsOnMainFilter
    ) {
      if (
        prevProps.sitecoreContext.whatsOnMainFilter !==
        this.props.sitecoreContext.whatsOnMainFilter
      ) {
        this.setState({
          selectedCategory: this.props.sitecoreContext.whatsOnMainFilter
        });
      }
    }
  }

  getUrlParameter(querystringParam) {
    querystringParam = querystringParam
      .replace(/[\[]/, "\\[")
      .replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + querystringParam + "=([^&#]*)");
    var results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  render() {
    const { WhatsOnListHeadline, PromotionFilter } = this.props.fields;
    const { selectedCategory } = this.state;
    const whatsOnMainFilter = selectedCategory
      ? selectedCategory
      : this.props.sitecoreContext.whatsOnMainFilter
      ? this.props.sitecoreContext.whatsOnMainFilter
      : "all";
    return (
      <div>
        <div className="whatson-list-header-container">
          <div className="container">
            <div className="header-wrapper">
              <h1 className="header-title">
                {WhatsOnListHeadline.value && (
                  <Text field={WhatsOnListHeadline} />
                )}
              </h1>
            </div>
          </div>
        </div>
        {/* header sub nav */}
        <Subnav
          tabs={PromotionFilter}
          hasAllTab
          allTabQuery="all"
          defaultName="Name"
          defaultFilter="FilterValue"
          selected={whatsOnMainFilter}
          updateFilter={filter => this.updateMainFilter(filter)}
        />
      </div>
    );
  }
}

export default withSitecoreContext()(FeatureHeaderWhatsOnListHeader);

import React, { Component, Fragment } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import axios from "axios";
import TagManager from "react-gtm-module";
import InfiniteScroll from "react-infinite-scroll-component";
import { find, debounce } from "lodash";
import LazyLoading from "../../../Common/LazyLoading";
import { valueHelper } from "../../../../helper/Animations";
import ContentTileList from "../../../Common/ContentTileList";
import BaseComponent from "../../../Base/BaseComponent";

class FeatureStoreDirectoryDirectorySearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listing: [],
      categoriesList: props.fields.Categories,
      hasMoreItems: true,
      currentPage: 1,
      totalCount: 0,
      showClearButton: false,
      filterCat: "",
      category: "",
      level: "",
      showLoader: true,
      isScrolling: false,
      isSearchFilter: false,
      isAfterPay: false,
      isGiftCard: false,
      isOnlineBooking: false,
      isOrderAndCollect: false,
      giftcardText: "",
      noStores: true,
      hideFirstRow: false //hide input and dropdown fields, store count
    };
    this.searchTerm = React.createRef();
    this.selectedCategory = React.createRef();
  }

  componentDidMount() {
    console.log("props search");
    const {
      DisplayOnlineBookingFilter = null,
      BrauzFormId = null,
      Subcategory = null,
      HasSelectedStores = null
    } = this.props.fields || {};
    if (
      window.location.pathname &&
      window.location.pathname.toLowerCase().indexOf("/directory") === -1
    ) {
      this.setState({ hideFirstRow: true });
    }
    if (
      window.location.pathname &&
      window.location.pathname.toLowerCase().indexOf("/directory") === -1 &&
      (!HasSelectedStores || (HasSelectedStores && !HasSelectedStores.value)) &&
      (!Subcategory || (Subcategory && !Subcategory.value))
    ) {
    } else {
      let category = "";
      let categoryQSName = "filter_cat";
      category =
        (Subcategory && Subcategory.value) ||
        this.getUrlParameter(categoryQSName);
      let _this = this;
      if (category !== "") {
        this.selectedCategory.current.value = category;
        this.setState(
          {
            filterCat: category,
            category: category,
            isAfterPay: this.getUrlParameter("afterpay"),
            isGiftCard: this.getUrlParameter("giftcards"),
            isOnlineBooking: this.getUrlParameter("online_bookings"),
            isOrderAndCollect: this.getUrlParameter("orderandcollect")
          },
          () => {
            _this.getRetailerList(1, true);
          }
        );
      } else {
        this.selectedCategory.current.value = category;
        this.setState(
          {
            filterCat: category,
            category: category,
            isAfterPay: this.getUrlParameter("afterpay"),
            isGiftCard: this.getUrlParameter("giftcards"),
            isOnlineBooking: this.getUrlParameter("online_bookings"),
            isOrderAndCollect: this.getUrlParameter("orderandcollect"),
            level: 1
          },
          () => {
            _this.getRetailerList(1, true);
          }
        );
      }

      if (document) {
        setTimeout(() => {
          const metaCentre = document.querySelector('meta[name="centrename"]');
          if (metaCentre && metaCentre.getAttribute("content")) {
            this.setState({ giftcardText: metaCentre.getAttribute("content") });
          }
        }, 1000);
      }

      if (
        DisplayOnlineBookingFilter &&
        DisplayOnlineBookingFilter.value &&
        BrauzFormId &&
        BrauzFormId.value
      ) {
        const brauzPackageURL =
          "https://d3aq2u4yw77ivo.cloudfront.net/retailer-appointment/public/1.0/bookAStylist.prod.js";
        this.loadScript(brauzPackageURL, () => {
          window.Brauz.initializeBrauzBookAStylist(BrauzFormId.value);
        });
      }
    }
  }

  loadScript(url = "", callback = () => {}) {
    if (url) {
      const script = document.createElement("script");
      script.setAttribute("type", "text/javascript");
      script.setAttribute("src", url);
      document.body.appendChild(script);
      script.onload = callback;
    }
  }

  getRetailerList = async (pageNumber, isInfiniteScroll) => {
    let category = this.state.category;
    let isAfterPay = this.state.isAfterPay;
    let isGiftCard = this.state.isGiftCard;
    let isOnlineBooking = this.state.isOnlineBooking;
    let isOrderAndCollect = this.state.isOrderAndCollect;
    let keywords = "";
    let url = "";
    let pageId = "";
    let dbName = "";
    let data = "";

    // populate keywords
    if (
      this.searchTerm &&
      this.searchTerm.current &&
      this.searchTerm.current.value &&
      /\S/.test(this.searchTerm.current.value)
    ) {
      keywords = this.searchTerm.current.value.replace(/\s/g, ",");
      TagManager.dataLayer({
        dataLayer: {
          event: "performedSDS",
          searchTermSD: this.searchTerm.current.value
        },
        dataLayerName: "dataLayer"
      });
    } else {
      keywords = "";
    }
    isAfterPay = isAfterPay ? true : false;
    isGiftCard = isGiftCard ? true : false;
    isOnlineBooking = isOnlineBooking ? true : false;
    isOrderAndCollect = isOrderAndCollect ? true : false;

    let contextInfo = window.document.getElementsByName("ContextItem")[0];
    pageId = contextInfo.value;
    dbName = contextInfo.dataset.targetDb;
    url = `/api/sitecore/storedirectory/search`;
    data = {
      pageId: pageId,
      dbName: dbName,
      keyword: keywords,
      category: category,
      afterpay: isAfterPay,
      giftcard: isGiftCard,
      acceptbooking: isOnlineBooking,
      orderandcollect: isOrderAndCollect,
      pageNumber: pageNumber,
      pageSize: 12
    };

    if (isInfiniteScroll) {
      this.setState({
        isScrolling: true,
        isSearchFilter: false
      });
    } else {
      this.setState({
        showLoader: true,
        isSearchFilter: true
      });
    }

    await axios({
      url,
      method: "GET",
      params: data
    })
      .then(responseRaw => {
        let response = responseRaw.data ? responseRaw.data : null;
        if (response && response.ResultList) {
          const lastPage = Math.ceil(response.TotalCount.value / 12);
          const list =
            pageNumber > 1
              ? this.state.listing.concat(response.ResultList)
              : response.ResultList;
          this.setState({
            ...this.state,
            listing: list,
            totalCount: response.TotalCount.value,
            currentPage: response.Page.value,
            hasMoreItems: response.Page.value == lastPage ? false : true
          });
          if (list && list.length) {
            this.setState({ noStores: false });
          }
        }
        this.setState({
          showLoader: false,
          isScrolling: false
        });
      })
      .catch(error => {
        // just handle error
        console.log(error);
      });
  };

  loadRetailerList() {
    this.getRetailerList(this.state.currentPage + 1, true);
  }

  getUrlParameter(querystringParam) {
    querystringParam = querystringParam
      .replace(/[\[]/, "\\[")
      .replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + querystringParam + "=([^&#]*)");
    var results =
      window && window.location && window.location.search
        ? regex.exec(window.location.search)
        : null;
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  // Event handlers
  handleSearchFilter = event => {
    this.getRetailerList(1);
    if (this.searchTerm.current && this.searchTerm.current.value !== "") {
      this.setState({ ...this.state, showClearButton: true });
    } else {
      this.setState({ ...this.state, showClearButton: false });
    }
  };

  handleClearButton = () => {
    this.searchTerm.current.value = "";
    this.setState({ ...this.state, showClearButton: false });
    this.getRetailerList(1);
  };

  handleSearchButton = () => {
    this.getRetailerList(1);
  };

  handleAcceptAfterPay = () => {
    this.setState({ isAfterPay: !this.state.isAfterPay }, () =>
      this.getRetailerList(1)
    );
  };

  handleAcceptGiftCard = () => {
    this.setState({ isGiftCard: !this.state.isGiftCard }, () =>
      this.getRetailerList(1)
    );
  };

  handleAcceptOnlineBookings = () => {
    this.setState({ isOnlineBooking: !this.state.isOnlineBooking }, () =>
      this.getRetailerList(1)
    );
  };

  handleAcceptOrderAndCollect = () => {
    this.setState({ isOrderAndCollect: !this.state.isOrderAndCollect }, () =>
      this.getRetailerList(1)
    );
  };

  handleCategoryFilter = e => {
    let categoryType = e.target.value === "0" ? "all" : "category";
    const selectedIndex = e.target.options.selectedIndex;
    TagManager.dataLayer({
      dataLayer: {
        event: "performedSDCF",
        categoryFilterSD: e.target.options[selectedIndex].getAttribute(
          "data-key"
        )
      },
      dataLayerName: "dataLayer"
    });
    if (categoryType === "category") {
      window.location.replace(`/Directory?filter_cat=${e.target.value}`);
    } else {
      window.location.replace(`/Directory`);
    }
  };

  showRetailerList = () => {
    const { NoResultsText: noResultsText = null } = this.props.fields || {};

    let returnRetailer;
    if (this.state.listing === false) {
      if (
        noResultsText.value &&
        noResultsText.value !== null &&
        noResultsText.value !== ""
      ) {
        returnRetailer = (
          <div className="col no-results">{noResultsText.value}</div>
        );
      } else {
        returnRetailer = (
          <div className="col no-results">No result. Please try again.</div>
        );
      }
    } else if (this.state.listing.length > 0) {
      returnRetailer = this.state.listing.map((listItem, index) => {
        const {
          RetailerName = null,
          RetailerPageHref = null,
          HeroImage = null,
          Logo = null,
          StoreLocation = null,
          StoreLocationLink = null,
          OpeningTime = null,
          Rating = null,
          PriceLevel = null,
          ItemId = null,
          AcceptsOnlineBooking = null,
          BookingIcon = null,
          BookingLabel = null,
          BookingUrl = null,
          HasBooking = null
        } = listItem;

        return (
          <ContentTileList
            index
            name={RetailerName}
            nameLink={RetailerPageHref}
            thumbnail={HeroImage}
            logo={Logo}
            location={StoreLocation}
            locationLink={StoreLocationLink}
            itemId={ItemId}
            openTime={OpeningTime}
            rating={Rating}
            price={PriceLevel}
            acceptsOnlineBooking={AcceptsOnlineBooking}
            bookingIcon={BookingIcon}
            bookingLabel={BookingLabel}
            bookingUrl={BookingUrl}
            hasBooking={HasBooking}
            position={valueHelper(index, 4, 3)}
            retailerId={ItemId}
          />
        );
      });
    } else {
      returnRetailer = (
        <div className="col no-results">No result. Please try again.</div>
      );
    }
    return returnRetailer;
  };

  showStoreCount = () => {
    let returnStoreCount;
    let nounCheck = "stores";
    returnStoreCount = (
      <div className="col-auto directorysearch-storecount">
        {this.state.totalCount} {nounCheck}
      </div>
    );
    return returnStoreCount;
  };

  showSearchPlaceholderText = () => {
    let { SearchPlaceholderText: searchPlaceholderText = "Find something" } =
      this.props.fields || {};

    if (searchPlaceholderText) {
      if (
        searchPlaceholderText.value &&
        searchPlaceholderText.value !== null &&
        searchPlaceholderText.value !== ""
      )
        searchPlaceholderText = searchPlaceholderText.value;
    }
    return searchPlaceholderText;
  };

  showAfterpayCheckbox = () => {
    let showAfterpay = null;
    let { AfterPayCheckboxText: afterPayCheckboxText = null } =
      this.props.fields || {};
    if (afterPayCheckboxText) {
      if (
        afterPayCheckboxText.value &&
        afterPayCheckboxText.value !== null &&
        afterPayCheckboxText.value !== ""
      ) {
        afterPayCheckboxText = afterPayCheckboxText.value;
        showAfterpay = (
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="acceptsAfterpayCB"
              onChange={this.handleAcceptAfterPay}
              checked={this.state.isAfterPay}
            />
            <label className="custom-control-label" htmlFor="acceptsAfterpayCB">
              {afterPayCheckboxText}
            </label>
          </div>
        );
      }
    }
    return showAfterpay;
  };

  showGiftCardCheckbox = () => {
    let showGiftCard = null;
    let { DisplayGiftCardsCheckbox: DisplayGiftCardsCheckbox = null } =
      this.props.fields || {};
    if (DisplayGiftCardsCheckbox && DisplayGiftCardsCheckbox.value) {
      showGiftCard = (
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="acceptsGiftCardCB"
            onChange={this.handleAcceptGiftCard}
            checked={this.state.isGiftCard}
          />
          <label className="custom-control-label" htmlFor="acceptsGiftCardCB">
            <span className="js-accept-gift-card">
              Accepts {this.state.giftcardText} Gift Cards
            </span>
          </label>
        </div>
      );
    }

    return showGiftCard;
  };

  showOnlineBookingsCheckbox = () => {
    let showOnlineBookings = null;
    let { DisplayOnlineBookingFilter = null, OnlineBookingFilterLabel = null } =
      this.props.fields || {};
    if (DisplayOnlineBookingFilter && DisplayOnlineBookingFilter.value) {
      showOnlineBookings = (
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="acceptsOnlineBookingsCB"
            onChange={this.handleAcceptOnlineBookings}
            checked={this.state.isOnlineBooking}
          />
          <label
            className="custom-control-label"
            htmlFor="acceptsOnlineBookingsCB"
          >
            {OnlineBookingFilterLabel && OnlineBookingFilterLabel.value ? (
              <Text field={OnlineBookingFilterLabel} />
            ) : (
              "Accepts online bookings"
            )}
          </label>
          {/* <div className="custom-control-new">New</div> */}
        </div>
      );
    }

    return showOnlineBookings;
  };

  showOrderAndCollectCheckbox = () => {
    let showOrderAndCollect = null;
    let {
      DisplayOrderAndCollectCheckbox = null,
      OrderAndCollectCheckboxText = null
    } = this.props.fields || {};
    if (
      DisplayOrderAndCollectCheckbox &&
      DisplayOrderAndCollectCheckbox.value
    ) {
      showOrderAndCollect = (
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="acceptsOrderAndCollect"
            onChange={this.handleAcceptOrderAndCollect}
            checked={this.state.isOrderAndCollect}
          />
          <label
            className="custom-control-label"
            htmlFor="acceptsOrderAndCollect"
          >
            {OrderAndCollectCheckboxText &&
            OrderAndCollectCheckboxText.value ? (
              <Text field={OrderAndCollectCheckboxText} />
            ) : (
              "Accepts Order & Collect"
            )}
          </label>
          {/* <div className="custom-control-new">New</div> */}
        </div>
      );
    }
    return showOrderAndCollect;
  };

  showCategory() {
    let subCategoryMapping;
    let completeArray = [];
    let { filterCat: category, level } = this.state;
    let categoryType = "sub";
    if (this.state.categoriesList && this.state.categoriesList.length) {
      // check if category is main or sub
      this.state.categoriesList.map(listItem => {
        if (category && category === listItem.fields.Id.value) {
          categoryType = "main";
        }
      });

      this.state.categoriesList.map((listItem, mainIndex) => {
        if (
          category &&
          category !== listItem.fields.Id.value &&
          categoryType === "sub"
        ) {
          if (
            !find(listItem.fields.SubCategories, function(o) {
              return category === o.fields.Id.value;
            })
          ) {
            return;
          }
        }
        if (categoryType !== "sub" || level === 1) {
          if (mainIndex === 0) {
            completeArray.push(
              <option key="option-all" value={0} data-key="All">
                All
              </option>
            );
          }
          completeArray.push(
            <option
              key={`${listItem.fields.Name.value}-${mainIndex}`}
              className="directorysearch-mainCategory"
              value={listItem.fields.Id.value}
              data-key={listItem.fields.Name.value}
            >
              {listItem.fields.Name.value}
            </option>
          );
        }
        subCategoryMapping = listItem.fields.SubCategories;
        subCategoryMapping.map((item, subIndex) => {
          completeArray.push(
            <option
              key={`${listItem.fields.Name.value}-sub-${subIndex}`}
              value={item.fields.Id.value}
              data-key={
                listItem.fields.Name.value + " - " + item.fields.Name.value
              }
            >
              {item.fields.Name.value}
            </option>
          );
        });
      });
    }
    return completeArray;
  }
  render() {
    const { showLoader, isScrolling, hideFirstRow, noStores } = this.state;
    const {
      Subcategory = null,
      Title = null,
      HasSelectedStores = null
    } = this.props.fields;
    const hideComponent =
      hideFirstRow &&
      (!HasSelectedStores || (HasSelectedStores && !HasSelectedStores.value)) &&
      (!Subcategory || (Subcategory && !Subcategory.value));
    if (hideComponent || (hideFirstRow && noStores)) {
      return null;
    } else {
      return (
        <BaseComponent margin="standard">
          <div className="directorysearch-wrapper container">
            <form
              className={`directorysearch-form ${hideFirstRow ? "d-none" : ""}`}
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              <div className="no-gutters">
                {/* ---------- SEARCH BAR ---------- */}
                <div className="directorysearch-searchbar">
                  <input
                    className="form-control search-box"
                    type="search"
                    placeholder={this.showSearchPlaceholderText()}
                    ref={this.searchTerm}
                    onChange={debounce(e => {
                      this.handleSearchFilter(e);
                    }, 1000)}
                  />
                  {this.state.showClearButton && (
                    <button
                      className="clear-icon"
                      type="button"
                      onClick={this.handleClearButton}
                    >
                      <i className="icon icon-close" />
                    </button>
                  )}
                  {!this.state.showClearButton && (
                    <button
                      className="search-icon"
                      type="button"
                      onClick={this.handleSearchButton}
                    >
                      <i className="icon icon-outlined-search" />
                    </button>
                  )}
                </div>
                {/* ---------- CATEGORY LIST ---------- */}
                <Fragment>
                  <div className="directorysearch-category">
                    <label className="select">
                      <select
                        className="form-control"
                        ref={this.selectedCategory}
                        onChange={e => {
                          this.handleCategoryFilter(e);
                        }}
                        value={this.state.category}
                      >
                        {this.showCategory()}
                      </select>
                    </label>
                  </div>
                </Fragment>
                {/* ---------- STORE COUNT ---------- */}
                {!showLoader && this.showStoreCount()}
              </div>
            </form>
            {hideFirstRow ? (
              <Fragment>
                <hr className="subcategory-title" />
                <div>
                  <Text field={Title} />
                </div>
              </Fragment>
            ) : (
              <hr className="d-none d-md-block" />
            )}

            {/* ---------- CHECKBOX ----------
             *** ACCEPTS AFTERPAY
             *** ACCEPTS GIFT CARD
             *** ACCEPTS ONLINE BOOKINGS
             */}

            <div>
              {this.showAfterpayCheckbox()}
              {this.showGiftCardCheckbox()}
              {this.showOnlineBookingsCheckbox()}
              {this.showOrderAndCollectCheckbox()}
            </div>
            {/* ---------- RETAILER LIST PASS DATA ---------- */}
            <InfiniteScroll
              style={{ height: "auto", overflow: "hidden" }}
              dataLength={this.state.listing.length}
              next={this.loadRetailerList.bind(this)}
              hasMore={this.state.hasMoreItems}
              // scrollThreshold={hideFirstRow ? 0.5 : 0.8}
              scrollThreshold={0.5}
            >
              {!showLoader && (
                <div className="content-tile-list-wrapper store-directory">
                  <div className="row">{this.showRetailerList()}</div>
                </div>
              )}
              {(showLoader || isScrolling) && (
                <div className="directorylist-wrapper">
                  <LazyLoading />
                </div>
              )}
            </InfiniteScroll>
          </div>
        </BaseComponent>
      );
    }
  }
}

export default FeatureStoreDirectoryDirectorySearch;

import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";

const FeatureGenericContentTwoColumn7030 = props => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-9 col-xl-8">
          <Placeholder
            name="jss-two-column-70-30-left"
            rendering={props.rendering}
          />
        </div>
        <div className="col-12 col-lg-3 col-xl-4">
          <Placeholder
            name="jss-two-column-70-30-right"
            rendering={props.rendering}
          />
        </div>
      </div>
    </div>
  );
};

export default FeatureGenericContentTwoColumn7030;

import React from "react";
import { withSitecoreContext, RichText } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";

class FeatureGenericContentRichText extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (window) {
      const el = window.document.getElementById("_goback");
      if (el) {
        el.onclick = function () {
          window.history.back();
        };
      }
    }
  }

  render() {
    const props = this.props;
    return (
      <div>
        {((props.fields && props.fields.Details) ||
          (!props.fields && props.sitecoreContext.pageEditing)) &&
          <BaseComponent margin="standard">
            {props.fields && props.fields.Details && (
              <div className="richtext-wrapper">
                <RichText field={props.fields.Details} />
              </div>
            )}

            {!props.fields && props.sitecoreContext.pageEditing && (
              <div className="richtext-wrapper">
                <p>Rich Text component is not displayed due to following reasons: <br />
                  - Datasource is not set.
                </p>
              </div>
            )}
          </BaseComponent>
        }
      </div>
    );
  }
};

export default withSitecoreContext()(FeatureGenericContentRichText);

import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";

const FeatureLayoutInfoLayout = props => {
  return (
    <div>
      <div className="row">
        <Placeholder name="jss-info-header" rendering={props.rendering} />
      </div>
      <div className="row">
        <div className="col">
          <Placeholder name="jss-info-body" rendering={props.rendering} />
        </div>
        <div className="col">
          <Placeholder name="jss-info-sidepanel" rendering={props.rendering} />
        </div>
      </div>
    </div>
  );
};

export default FeatureLayoutInfoLayout;

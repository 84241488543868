import React from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";

const FeatureGenericContentTextBlock = props => {
  const {
    Title,
    Description,
    Image: image,
    ImagePosition,
    ImageLink,
    ImageAltText,
    BackgroundColor,
    Link: cta,
    CTAText,
    CTALink
  } = props.fields;

  if (Title && Title.value && Description && Description.value) {
    let blockImage = {
      src: "",
      alt: ""
    };
    if (ImageLink && ImageLink.value) {
      blockImage.src = ImageLink.value;
      blockImage.alt = ImageAltText.value;
    } else if (image && image.value && image.value.src) {
      blockImage.src = image.value.src;
      blockImage.alt = image.value.alt;
    } else return null;
    return (
      <BaseComponent className="itb-wrapper">
        <div className="container">
          <div
            className={`campaign-image-text-block-wrapper ${
              ImagePosition && ImagePosition.value
                ? ImagePosition.value
                : "right"
            }`}
          >
            <div className="animation-range">
              <div className="row no-gutters">
                <div
                  className={`col-12 col-md-5 details ${
                    BackgroundColor && BackgroundColor.value
                      ? "bg-pale-grey"
                      : ""
                  }`}
                >
                  <div className="content">
                    <div className="title">
                      {cta && cta.value && cta.value.href ? (
                        <a {...cta.value}>
                          <Text field={Title} />
                        </a>
                      ) : (
                        <span>
                          <Text field={Title} />
                        </span>
                      )}
                    </div>
                    <div className="description">
                      <RichText field={Description} />
                    </div>
                    {CTAText &&
                      CTAText.value &&
                      CTALink &&
                      CTALink.value &&
                      CTALink.value.href && (
                        <div className="cta-button">
                          <a {...CTALink.value}>
                            <div className="btn primary-btn">
                              {CTAText.value}
                            </div>
                          </a>
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-12 col-md-7 image-wrapper">
                  {cta && cta.value && cta.value.href ? (
                    <a {...cta.value}>
                      <img
                        {...blockImage}
                        className="campaign-image lazyload"
                      />
                    </a>
                  ) : (
                    <img {...blockImage} className="campaign-image lazyload" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  } else return null;
};

export default FeatureGenericContentTextBlock;

import React from "react";

const CommonBadge = props => {
  const { type, startDate, endDate, badgeText } = props;
  let badgeType;
  let badgeClass;
  let parentClass;
  let line1;
  let line2;
  const getDate = function(date) {
    let d = new Date(date);
    return d.getDate();
  };
  const getMonth = function(date) {
    const month = [];
    month[0] = "Jan";
    month[1] = "Feb";
    month[2] = "Mar";
    month[3] = "Apr";
    month[4] = "May";
    month[5] = "Jun";
    month[6] = "Jul";
    month[7] = "Aug";
    month[8] = "Sep";
    month[9] = "Oct";
    month[10] = "Nov";
    month[11] = "Dec";

    let d = new Date(date);
    return month[d.getMonth()];
  };

  if (type === "hero") {
    badgeType = "hero-content-tile";
  } else if (type === "list") {
    badgeType = "content-tile";
  } else {
    badgeType = "generic";
  }

  if (startDate) {
    if (endDate) {
      if (getMonth(startDate) === getMonth(endDate)) {
        if (getDate(startDate) === getDate(endDate)) {
          badgeClass = "single-day";
          line1 = getDate(startDate);
          line2 = getMonth(startDate);
        } else {
          badgeClass = "single-month";
          line1 = getDate(startDate) + "-" + getDate(endDate);
          line2 = getMonth(startDate);
        }
      } else {
        badgeClass = "multiple-months";
        line1 = getDate(startDate) + " " + getMonth(startDate);
        line2 = getDate(endDate) + " " + getMonth(endDate);
      }
    } else {
      badgeClass = "single-day";
      line1 = getDate(startDate);
      line2 = getMonth(startDate);
    }
  } else if (badgeText) {
    if (/\s/.test(badgeText)) {
      badgeClass = "badge-lines";
    } else {
      badgeClass = "badge-line";
    }
    line1 = badgeText;
  }

  if (!startDate && !badgeText) {
    parentClass = "hide-badge";
  } else {
    parentClass = `d-flex flex-column justify-content-center align-items-center ${badgeType} ${badgeClass}`;
  }

  return (
    <div className={parentClass}>
      <div className="badge-day">{line1}</div>
      <div className="badge-month"> {line2}</div>
    </div>
  );
};
export default CommonBadge;

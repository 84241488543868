import React from "react";

const Checkbox = props => {
    const {
        className = null,
        id = null,
        name = null,
        uniqueKey = null,
        handleCheckboxClick = null,
        checked = false
    } = props || {};
    return (
        <div className="form-group form-checkbox" key={uniqueKey}>
            <label>
                <input
                    type="checkbox"
                    className={`${className} ${checked ? "is-active" : ""}`}
                    id={id}
                    name={id}
                    value={name}
                    onClick={handleCheckboxClick}
                />
                {name}
            </label>
        </div>
    )
};

export default Checkbox;
import React, { Component } from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import ContentTileList from "../../../Common/ContentTileList";
import axios from "axios";
import TagManager from "react-gtm-module";
import InfiniteScroll from "react-infinite-scroll-component";
import BaseComponent from "../../../Base/BaseComponent";
import LazyLoading from "../../../Common/LazyLoading";
import SitecoreContextFactory from "../../../../lib/SitecoreContextFactory";
import _ from "lodash";
import { valueHelper } from "../../../../helper/Animations";

class FeaturePromotionsWhatsOnList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listing: [],
      hasMoreItems: true,
      currentPage: 1,
      totalCount: 0,
      searchResultCountText: "",
      showClearButton: false,
      showLoader: true,
      isScrolling: false,
      selectedCategory: ""
    };
    this.searchTerm = React.createRef();
  }

  componentDidMount() {
    this.setState(
      { selectedCategory: this.getUrlParameter("filter_cat") },
      () => this.getResultsList(1)
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.sitecoreContext &&
      this.props.sitecoreContext.whatsOnMainFilter
    ) {
      if (
        prevProps.sitecoreContext.whatsOnMainFilter !==
        this.props.sitecoreContext.whatsOnMainFilter
      ) {
        this.clearSearch();
        this.setState(
          {
            selectedCategory: this.props.sitecoreContext.whatsOnMainFilter
          },
          () => this.getResultsList(1)
        );
      }
    }
  }

  handleSearchFilter() {
    this.getResultsList(1);
    if (this.searchTerm.current && this.searchTerm.current.value !== "") {
      this.setState({ showClearButton: true });
    } else {
      this.setState({ showClearButton: false });
    }
  }

  handleClearButton = () => {
    this.searchTerm.current.value = "";
    this.setState({ showClearButton: false });
    this.getResultsList(1);
  };

  clearSearch() {
    this.searchTerm.current.value = "";
    this.setState({ showClearButton: false });
  }

  showResultsCount() {
    let returnStoreCount;
    if (this.state.searchResultCountText) {
      returnStoreCount = (
        <div className="col-auto whatsonsearch-resultscount">
          {this.state.searchResultCountText}
        </div>
      );
    } else {
      returnStoreCount = "";
    }
    return returnStoreCount;
  }

  textFieldToLowercase(textField) {
    let value = this.capitalizeWord(textField.value.toLowerCase());
    return { value };
  }

  capitalizeWord(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  categoryMainHandler = category => {
    let selectedCategory = this.textFieldToLowercase(category).value;
    if (selectedCategory === "Article") {
      selectedCategory = "News";
    }
    this.updateMainFilter(selectedCategory);
    // this.setState({ selectedCategory }, () => this.getResultsList(1));
  };

  updateMainFilter(filterName) {
    const sitecoreContext = this.props.sitecoreContext;
    SitecoreContextFactory.setSitecoreContext({
      ...sitecoreContext,
      whatsOnMainFilter: filterName,
      refresh: true
    });
  }

  composeResultsList() {
    const { listing } = this.state;
    const { NoSearchResultText: noSearchResultText = null } =
      this.props.fields || {};
    let list;

    if (listing.length < 1) {
      if (noSearchResultText && noSearchResultText.value) {
        list = <div className="col no-results">{noSearchResultText.value}</div>;
      } else {
        list = (
          <div className="col no-results">No result. Please try again.</div>
        );
      }
    } else {
      list = listing.map((listItem, index) => {
        const {
          PromotionType,
          Name,
          Location,
          PromotionDateText,
          LocationLink,
          Thumbnail,
          PromotionLink,
          AddToCalendarCta,
          IsRetailerContent,
          IsLogoAsThumbnail,
          Retailer,
          HidePublishDate,
          RetailerId
        } = listItem;


          return (
            <ContentTileList
              index
              category={PromotionType}
              name={Name}
              nameLink={PromotionLink}
              location={Location}
              locationLink={LocationLink}
              retailerId={RetailerId}
              openTime={(HidePublishDate && !HidePublishDate.value) && PromotionDateText}
              thumbnail={Thumbnail}
              addToCalendarLink={AddToCalendarCta}
              categoryMainHandler={this.categoryMainHandler}
              position={valueHelper(index, 4, 3)}
              retailerName={Retailer}
              isRetailerContent={IsRetailerContent}
              isLogoAsThumbnail={IsLogoAsThumbnail}
            />
          );
      });
    }
    return list;
  }

  getResultsList(pageNumber, isInfiniteScroll) {
    let keywords = "";
    let url = "";
    let promotionType = "";
    let pageId = "";
    let dbName = "";
    const { selectedCategory } = this.state;

    if (selectedCategory) {
      if (selectedCategory !== "all") {
        promotionType = selectedCategory;
      }
    }
    if (
      this.searchTerm.current.value &&
      /\S/.test(this.searchTerm.current.value)
    ) {
      keywords = this.searchTerm.current.value.replace(/\s/g, ",");
      TagManager.dataLayer({
        dataLayer: {
          event: "performedWOLS",
          searchTermWOL: this.searchTerm.current.value
        },
        dataLayerName: "dataLayer"
      });
    } else {
      keywords = "";
    }
    let contextInfo = window.document.getElementsByName("ContextItem")[0];
    pageId = contextInfo.value;
    dbName = contextInfo.dataset.targetDb;

    url = `/api/sitecore/promotions/search?pageId=${pageId}&dbName=${dbName}&keyword=${keywords}&promotionType=${promotionType}&pageNumber=${pageNumber}&pageSize=12`;

    if (isInfiniteScroll) {
      this.setState({ isScrolling: true });
    } else {
      this.setState({ showLoader: true });
    }
    axios({
      url,
      method: "GET"
    })
      .then(responseRaw => {
        let response = responseRaw.data ? responseRaw.data : null;
        if (response && response.ResultList) {
          const lastPage = Math.ceil(response.TotalCount.value / 12);
          const list =
            pageNumber > 1
              ? this.state.listing.concat(response.ResultList)
              : response.ResultList;
          this.setState({
            ...this.state,
            listing: list,
            totalCount: response.TotalCount.value,
            searchResultCountText:
              response.SearchResultCountText &&
              response.SearchResultCountText.value
                ? response.SearchResultCountText.value
                : "",
            currentPage: response.Page.value,
            hasMoreItems: response.Page.value == lastPage ? false : true
          });
        }
        this.setState({
          showLoader: false,
          isScrolling: false
        });
      })
      .catch(error => {
        // just handle error
      });
  }

  getUrlParameter(querystringParam) {
    querystringParam = querystringParam
      .replace(/[\[]/, "\\[")
      .replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + querystringParam + "=([^&#]*)");
    var results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  loadResultsList() {
    this.getResultsList(this.state.currentPage + 1, true);
  }

  render() {
    const { SearchHelpText: searchHelpText = null } = this.props.fields || {};
    const { showLoader, isScrolling } = this.state;
    return (
      <BaseComponent margin="standard">
        <div className="whatson-list-container">
          <div className="container">
            <div className="no-gutters whatsonsearch-row">
              {/* ---------- SEARCH BAR ---------- */}
              <div className="whatsonsearch-searchbar">
                <input
                  className="form-control"
                  type="text"
                  placeholder={
                    searchHelpText && searchHelpText.value
                      ? searchHelpText.value
                      : "Search"
                  }
                  ref={this.searchTerm}
                  onChange={_.debounce(e => {
                    this.handleSearchFilter(e);
                  }, 1000)}
                />
                {this.state.showClearButton && (
                  <button
                    className="clear-icon"
                    type="button"
                    onClick={this.handleClearButton}
                  >
                    <i className="icon icon-close" />
                  </button>
                )}
              </div>
              {/* ---------- RESULTS COUNT ---------- */}
              {this.showResultsCount()}
            </div>
            {/* <hr /> */}
            <InfiniteScroll
              className="whatsonsearch-wrapper"
              style={{ height: "auto", overflow: "hidden" }}
              dataLength={this.state.listing.length}
              next={this.loadResultsList.bind(this)}
              hasMore={this.state.hasMoreItems}
              // loader={<h4>Loading...</h4>}
            >
              {!showLoader && (
                <div className="content-tile-list-wrapper">
                  <div className="row">{this.composeResultsList()}</div>
                </div>
              )}
              {(showLoader || isScrolling) && <LazyLoading />}
            </InfiniteScroll>
          </div>
        </div>
      </BaseComponent>
    );
  }
}
export default withSitecoreContext()(FeaturePromotionsWhatsOnList);

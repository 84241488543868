import React from "react";
import errorIcon from "../../../../assets/icons/png/22px/error.png";
import confirmedIcon from "../../../../assets/icons/png/22px/confirmed.png";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";

const Details = ({ subscribeProps, state, handleDetailsUpdate, subscribe }) => {
    const { SubscribeButton, SubscribeTitle, SubscribeDescription, EmailPlaceholder } = subscribeProps
    const { isSpecial, message, error, email } = state

    const handleEmailChange = e => {
        let message = "";
        let error = false;

        handleDetailsUpdate({
            formData: {
                email: e.target.value
            },
            message: message,
            error: error
        })

    };

    return <React.Fragment>
        <div className="subscribe-header-wrapper">
            <Text field={SubscribeTitle} />
        </div>
        <div className="subscribe-description-wrapper">
            <RichText field={SubscribeDescription} />
        </div>
        <div className="row subscribe">
            <div
                className={`col-12 subscribe-email col-md-8 ${isSpecial ? "col-lg-7 col-xl-8" : ""
                    }`}
            >
                <div className="form-group">
                    <input
                        type="text"
                        id="subscribeEmail"
                        className={
                            "form-control form-control-lg" +
                            (message ? " box-message" : "") +
                            (error ? " box-error" : "")
                        }
                        required
                        onChange={e => {
                            handleEmailChange(e);
                        }}
                        value={email}
                    />
                    <label
                        className="form-control-placeholder"
                        htmlFor="subscribeEmail"
                    >
                        {EmailPlaceholder && EmailPlaceholder.value}
                    </label>
                    {error && (
                        <img
                            src={errorIcon}
                            className="notify-indicator"
                            alt="notify-indicator"
                        />
                    )}
                    {/* {success && (
                        <img
                            src={confirmedIcon}
                            className="notify-indicator"
                            alt="notify-indicator"
                        />
                    )} */}
                    {message !== "" && (
                        <label
                            className={
                                (message ? "text-message" : "") +
                                (error ? " text-error" : "")
                            }
                        >
                            {message}
                        </label>
                    )}
                </div>
            </div>
            <div className="col-12 col-md-4">
                <button
                    className="subscribe-button"
                    onClick={() => {
                        subscribe();
                    }}
                >
                    {SubscribeButton && SubscribeButton.value}
                </button>
            </div>
        </div>
    </React.Fragment>
}

export default Details;
import React from "react";
import BaseComponent from "../../../Base/BaseComponent";
import defaultImg from "../../../../assets/img/placeholders/gray.png";
import GetAncestorElement from "../../../Common/Helper/GetAncestorElement";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
class FeatureGenericContentImageGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFullscreen: false,
      imagePos: 0
    };
  }

  componentDidMount() {}

  handleKeyDown = event => {
    const enlargeGalleryContainer = document.querySelector(
      ".image-gallery__fullscreen.is-shown"
    );
    if (enlargeGalleryContainer) {
      const keyCode = event.keyCode;
      if (keyCode === 27) {
        const closeBtn = enlargeGalleryContainer.querySelector(
          ".image-gallery__button--close"
        );
        closeBtn.click();
      } else if (keyCode === 37 || keyCode === 39) {
        const { imagePos } = this.state;
        const { GalleryImages } = this.props.fields;
        let itemValue = 0;
        if (keyCode === 37) {
          if (imagePos === 1) {
            return;
          }
          itemValue = imagePos - 1;
        } else if (keyCode === 39) {
          if (imagePos === GalleryImages.length) {
            return;
          }
          itemValue = imagePos + 1;
        }
        const carouselDotSelector =
          ".carousel__dot:nth-child(" + itemValue + ")";
        const carouselDot = enlargeGalleryContainer.querySelector(
          carouselDotSelector
        );
        carouselDot.click();
        this.setState({
          imagePos: itemValue
        });
      }
    }
  };

  handleOnClickImage = event => {
    const element = event.target;
    const imageGalleryContainer = GetAncestorElement(element, "image-gallery");
    const itemValue = element.getAttribute("value");
    const carouselDotSelector = ".carousel__dot:nth-child(" + itemValue + ")";
    const carouselDot = imageGalleryContainer.querySelector(
      carouselDotSelector
    );
    const fullscreen = imageGalleryContainer.querySelector(
      ".image-gallery__fullscreen"
    );
    setTimeout(() => {
      fullscreen.classList.add("is-transition");
    }, 250);
    this.setState({
      showFullscreen: true,
      imagePos: parseInt(itemValue)
    });
    carouselDot.click();
    document.getElementsByTagName("body")[0].classList.add("modal-open");
    // For all major browsers, except IE 8 and earlier
    if (document.addEventListener) {
      document.addEventListener("keydown", this.handleKeyDown);

      // For IE 8 and earlier versions
    } else if (document.attachEvent) {
      document.attachEvent("onkeydown", this.handleKeyDown);
    }
  };

  handleOnClickClose = event => {
    const element = event.target;
    const imageGalleryContainer = GetAncestorElement(element, "image-gallery");
    const fullscreen = imageGalleryContainer.querySelector(
      ".image-gallery__fullscreen"
    );
    fullscreen.classList.remove("is-transition");
    this.setState({
      showFullscreen: false
    });
    document.getElementsByTagName("body")[0].classList.remove("modal-open");
  };

  render() {
    const { GalleryImages = null } = this.props.fields || {};
    let { showFullscreen } = this.state;
    return (
      <div className="image-gallery font-regular">
        {GalleryImages && GalleryImages.length ? (
          <BaseComponent margin="standard">
            <div className="container">
              <div className="image-gallery__wrapper">
                {GalleryImages.map((item, index) => {
                  const {
                    Alt = null,
                    Id = null,
                    Src = null,
                    Height = null,
                    Width = null
                  } = item.fields;
                  return (
                    <div key={Id.value + index} className="image-gallery__item">
                      <button
                        value={index + 1}
                        onClick={this.handleOnClickImage}
                      >
                        <img
                          value={index + 1}
                          alt={Alt.value}
                          src={defaultImg}
                          data-src={Src.value}
                          className="lazyload"
                        />
                      </button>
                    </div>
                  );
                })}
              </div>
              <div
                className={`image-gallery__fullscreen ${
                  showFullscreen ? "is-shown" : ""
                }`}
              >
                <CarouselProvider
                  totalSlides={GalleryImages.length}
                  naturalSlideWidth={1}
                  naturalSlideHeight={1}
                  visibleSlides={1}
                  step={1}
                >
                  <Slider className={"slider"}>
                    {GalleryImages.map((item, index) => {
                      const {
                        Alt = null,
                        Id = null,
                        Src = null,
                        Height = null,
                        Width = null
                      } = item.fields;
                      return (
                        <Slide index={index} key={Id.value + index}>
                          <img alt={Alt.value} id={Id.value} src={Src.value} />
                          <div className="image-gallery__caption">
                            {`${index + 1} of ${GalleryImages.length} - ${
                              Alt.value
                            }`}
                          </div>
                        </Slide>
                      );
                    })}
                  </Slider>
                  <ButtonBack className="image-gallery__button--prev">
                    <i className="icon icon-arrow-left" />
                  </ButtonBack>
                  <ButtonNext className="image-gallery__button--next">
                    <i className="icon icon-arrow-right" />
                  </ButtonNext>
                  <button
                    className="image-gallery__button--close"
                    onClick={this.handleOnClickClose}
                  >
                    <i className="icon icon-close" />
                  </button>
                  <DotGroup />
                </CarouselProvider>
              </div>
            </div>
          </BaseComponent>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default FeatureGenericContentImageGallery;

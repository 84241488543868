import React from "react";
import Details from "../../Details";
import RiseAndFade from "../../Animations/RiseAndFade";
import { Image, Text } from "@sitecore-jss/sitecore-jss-react";
import transparent from "../../../../assets/img/placeholders/transparent.png";

const FeaturedLifestyleTiles = props => (
  <div className="featured-lifestyle-list-wrapper">
    <div className="container">
      <div className="featured-lifestyle-list">
        {props.data.map((item, index) => {
          const {
            ThumbnailUrl,
            ThumbnailAltText,
            PageType,
            Link: link,
            Title
          } = item.fields || {};
          let value = 0;
          if (index == 1) {
            value = 6;
          } else if (index == 2) {
            value = 8;
          }
          return (
            <div className="tile" key={`featured-lifestyle-item-${index}`}>
              <RiseAndFade position={value}>
                <div className="animation-range">
                  <div className="photo">
                    <a href={link.value}>
                      {ThumbnailUrl && ThumbnailUrl.value ? (
                        <img src={ThumbnailUrl.value} alt={ThumbnailAltText.value} className="lazyload" />
                      ) : (
                        <img src={transparent} />
                      )}
                    </a>
                  </div>
                  <div className="category-type">
                    <Text field={PageType} />
                  </div>
                  <div className="title">
                    <a href={link.value}>
                      <Text field={Title} />
                    </a>
                  </div>
                </div>
              </RiseAndFade>
            </div>
          );
        })}
      </div>
    </div>
  </div>
);

export default FeaturedLifestyleTiles;

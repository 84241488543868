import React, { Component } from "react";
import { Image } from "@sitecore-jss/sitecore-jss-react";
import { Swipeable } from "react-swipeable";

const IMG_WIDTH = "320px";
const IMG_HEIGHT = "249px";
const RIGHT = "-1";
const LEFT = "+1";

const buttonStyles = {
  height: IMG_HEIGHT,
  color: "lightgrey",
  fontSize: "2em"
};

class FeatureStorefrontRetailerImageGallery extends Component {
  constructor(props) {
    super(props);
    this.galleryImages = props.fields.galleryImages;
    this.imageSet1 = "";
    this.imageSet2 = "";
    this.imageSet3 = "";
    this.imageSwipeable = "";
    this.swipeable = "";
    this.imageFiles = [];
    this.state = {
      imageIdx: 0,
      images: []
    };
  }

  componentWillMount() {
    let { galleryImages } = this;
    for (let key in galleryImages) {
      this.imageFiles.push(galleryImages[key].image.value.src);
    }
    this.setState({ images: this.imageFiles });
  }

  onSwiped(direction) {
    const change = direction === RIGHT ? RIGHT : LEFT;
    const adjustedIdx = this.state.imageIdx + Number(change);

    let newIdx;
    if (adjustedIdx >= this.state.images.length) {
      newIdx = 0;
    } else if (adjustedIdx < 0) {
      newIdx = this.state.images.length - 1;
    } else {
      newIdx = adjustedIdx;
    }
    this.setState({ imageIdx: newIdx });
  }

  startFunc = () => {
    let {
      galleryImages,
      imageSet1,
      imageSet2,
      imageSet3,
      imageFiles,
      swipeable
    } = this;
    const { imageIdx = 0 } = this.state;
    
    if (galleryImages && galleryImages.length >= 7) {
      imageSet2 = [];
      imageSet3 = [];
      imageFiles = [];
      for (let key in galleryImages) {
        let { src } = galleryImages[key].image.value;
        imageFiles.push(src);
        if (key == 0) {
          imageSet1 = (
            <Image
              key={`myId-${key}`}
              className={`gallery-image-1`}
              media={galleryImages[key].image}
            />
          );
        } else if (key < 4) {
          imageSet2.push(
            <Image
              key={`myId-${key}`}
              className={`gallery-thumbnail`}
              media={galleryImages[key].image}
            />
          );
        } else if (key < 7) {
          imageSet3.push(
            <Image
              key={`myId-${key}`}
              className={`gallery-thumbnail`}
              media={galleryImages[key].image}
            />
          );
        } else {
          break;
        }
      }

      const imageStyles = {
        width: IMG_WIDTH,
        height: IMG_HEIGHT,
        backgroundImage: `url(${imageFiles[imageIdx]})`
      };

      swipeable = (
        <div className="swipeContainer">
          <Swipeable
            className="swipe"
            trackMouse
            style={{ touchAction: "none" }}
            preventDefaultTouchmoveEvent
            onSwipedLeft={() => this.onSwiped(LEFT)}
            onSwipedRight={() => this.onSwiped(RIGHT)}
          >
            <div style={imageStyles}>
              <button
                onClick={() => this.onSwiped(RIGHT)}
                className="hollow float-left"
                style={buttonStyles}
              >
                ⇦
              </button>
              <button
                onClick={() => this.onSwiped(LEFT)}
                className="hollow float-right"
                style={buttonStyles}
              >
                ⇨
              </button>
            </div>
          </Swipeable>
        </div>
      );
      return (
        <div className="gallery-wrapper container-fluid ">
          <div className="gallery-banner" />
          <div className="gallery-container-swipeable">{swipeable}</div>
          <div className="gallery-container-normal">
            {imageSet1}
            <div className="gallery-image-sm">
              {imageSet2}
              <div className="w-100 d-none d-md-block" />
              {imageSet3}
            </div>
          </div>
        </div>
      );
    } else if (galleryImages && galleryImages.length >= 3) {
      imageSet2 = [];

      for (let key in galleryImages) {
        if (key < 3) {
          imageSet2.push(
            <Image
              key={`myId-${key}`}
              className={`gallery-image-3`}
              media={galleryImages[key].image}
            />
          );
        }
      }

      return (
        <div className="gallery-wrapper container-fluid">
          <div className="gallery-banner" />
          <div className="gallery-container-swipeable">{swipeable}</div>
          <div className="gallery-container-normal">{imageSet2}</div>
        </div>
      );
    } else {
      return "";
    }
  };
  //END

  render() {
    return <React.Fragment>{this.startFunc()}</React.Fragment>;
  }
}

export default FeatureStorefrontRetailerImageGallery;

import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
const FeaturePromotionsPromotionSchedules = props => {
  let {
    Title: Title = null,
    Schedules: Schedules = null,
    CalendarGoal: CalendarGoal = null,
    BookEventGoal: BookEventGoal = null
  } = props.fields;
  if (Title && Title.value && Schedules && Schedules.length) {
    return (
      <div className="promotions-schedules-wrapper container">
        <div className="title d-none d-md-block">
          <Text field={Title} />
        </div>
        {Schedules.map((scheduleItem, scheduleIndex) => {
          let {
            PromotionDate: PromotionDate = null,
            PromotionTime: PromotionTime = null,
            Price: Price = null,
            AddToCalendar: AddToCalendar = null,
            BookNow: BookNow = null,
            SoldOut: SoldOut = null
          } = scheduleItem.fields;
          let eventAvailable = false;
          if (SoldOut && SoldOut.value.toLowerCase() === "false") {
            eventAvailable = true;
          }
          const onClickCalendarButton = event => {
            event.preventDefault();
            let { href, target } = event.currentTarget;
            if (!target) target = "_self";
            let data = {
              GoalID: event.currentTarget.getAttribute("datagoalid")
            };
            fetch("/api/sitecore/analytics/TriggerGoalAjax", {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify(data)
            });

            setTimeout(() => {
              window.open(href, target);
            }, 200);
          };
          return (
            <div
              key={`scheduleRowId-${scheduleIndex}`}
              className={`row no-gutters align-items-center ${
                eventAvailable ? "eventAvailable" : "eventUnavailable"
              }`}
            >
              <div className="col-12 col-md">
                <div className="date">
                  <Text field={PromotionDate} />
                </div>
              </div>
              <div className="col-12 col-md">
                <div className="time">
                  <Text field={PromotionTime} />
                </div>
              </div>
              <div className="col-12 col-md-1">
                {Price && Price.value && (
                  <div className="price">
                    $
                    <Text field={Price} />
                  </div>
                )}
              </div>
              <div className="col-12 col-md align-self-right desktop">
                {eventAvailable &&
                  AddToCalendar &&
                  AddToCalendar.value &&
                  CalendarGoal &&
                  CalendarGoal.value(
                    <a
                      datagoalid={CalendarGoal.value}
                      href={AddToCalendar.value}
                      onClick={onClickCalendarButton}
                    >
                      <button className="btnAddToCalendar">
                        Add to Calendar
                      </button>
                    </a>
                  )}
              </div>
              <div className="col-12 col-md align-self-center book-sold desktop">
                {eventAvailable ? (
                  BookNow &&
                  BookNow.value &&
                  BookNow.value.href &&
                  BookNow.value.text &&
                  BookEventGoal &&
                  BookEventGoal.value(
                    <a
                      datagoalid={BookEventGoal.value}
                      href={BookNow.value.href}
                      onClick={onClickCalendarButton}
                    >
                      <button className="btnBookNow">
                        {BookNow.value.text}
                      </button>
                    </a>
                  )
                ) : (
                  <div className="soldOut">Sold Out</div>
                )}
              </div>
              <div className="col-12 col-md align-self-center book-sold mobile">
                {eventAvailable ? (
                  BookNow &&
                  BookNow.value &&
                  BookNow.value.href &&
                  BookNow.value.text &&
                  BookEventGoal &&
                  BookEventGoal.value(
                    <a
                      datagoalid={BookEventGoal.value}
                      href={BookNow.value.href}
                      onClick={onClickCalendarButton}
                    >
                      <button className="btnBookNow">
                        {BookNow.value.text}
                      </button>
                    </a>
                  )
                ) : (
                  <div className="soldOut">Sold Out</div>
                )}
              </div>
              <div className="col-12 col-md align-self-right mobile">
                {eventAvailable &&
                  AddToCalendar &&
                  AddToCalendar.value &&
                  CalendarGoal &&
                  CalendarGoal.value && (
                    <a
                      datagoalid={CalendarGoal.value}
                      href={AddToCalendar.value}
                      onClick={onClickCalendarButton}
                    >
                      <button className="btnAddToCalendar">
                        Add to Calendar
                      </button>
                    </a>
                  )}
              </div>
            </div>
          );
        })}
      </div>
    );
  } else return null;
};
export default FeaturePromotionsPromotionSchedules;

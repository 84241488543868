import React, { Component } from "react";
import { Link, Text } from "@sitecore-jss/sitecore-jss-react";
import Subnav from "../../../Common/Subnav";

class FeatureConsumerNavigationHeaderSubNav extends Component {
  render() {
    const { HeaderMenu: tabs = null } = this.props.fields || {};
    return (
      <Subnav
        tabs={tabs}
        defaultName="ServiceName"
        defaultFilter="Link"
        defaultActive="IsActive"
        className="with-margin"
      />
    );
  }
}

export default FeatureConsumerNavigationHeaderSubNav;

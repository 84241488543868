import React from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import EmailPreference from "../../../Common/EmailPreference";
import BaseComponent from "../../../Base/BaseComponent";

class UpdatePreferences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageId: "",
      dbName: "",
      success: false,
      pageLoaded: false,
    };
  }

  componentDidMount() {
    let contextInfo = window.document.getElementsByName("ContextItem")[0];
    let pageId = contextInfo.value;
    let dbName = contextInfo.dataset.targetDb;
    this.setState({
      ...this.state,
      pageId: pageId,
      dbName: dbName,
      pageLoaded: true
    });
  }

  componentDidUpdate() {
    if (this.state.success) {
      const updatePreferencesWrapper = document.body.querySelector('.update-preferences')
      setTimeout(() => {
        if (updatePreferencesWrapper) updatePreferencesWrapper.scrollIntoView()
      }, 250)
    }

  }

  handlePreferencesResponse = (value) => {
    this.setState({ ...value, pageLoaded: value.success ? false : true });
  }

  render() {
    const {
      SetPreferencesTitle = null,
      EmailPlaceholder = null,
      EmailAddress = null,
      SelectCategoriesTitle = null,
      SelectCategoriesDescription = null,
      SelectGenderDescription = null,
      PrivacyPolicyText = null,
      SubmitButtonLabel = null,
      Interests = null,
      GenderOptions = null,
      SuccessMessage = null
    } = this.props.fields || {};
    const {
      pageId,
      dbName,
      success,
      pageLoaded
    } = this.state || {};
    return (
      <BaseComponent margin="standard">
        <div className={`update-preferences ${success ? "sucess-update" : ""}`}>
          {
            pageLoaded &&
            <div>
              <div className="update-preferences__email-header"><Text field={SetPreferencesTitle} /></div>
              <div className="form-group form-single-line">
                <label>{EmailPlaceholder && EmailPlaceholder.value ? EmailPlaceholder.value : "Email"}</label>
                <input type="text" value={EmailAddress && EmailAddress.value ? EmailAddress.value : ""} readOnly />
              </div>
              <EmailPreference
                PageID={pageId}
                DBName={dbName}
                EmailAddress={EmailAddress && EmailAddress.value ? EmailAddress.value : ""}
                Title={SelectCategoriesTitle}
                Description={SelectCategoriesDescription}
                ButtonName={(SubmitButtonLabel && SubmitButtonLabel.value) ? SubmitButtonLabel.value : "Submit"}
                Interests={Interests}
                GenderDescription={SelectGenderDescription}
                GenderOptions={GenderOptions}
                RadioGroupName={"Update-Preferences-Gender"}
                HideDropdown={true}
                EmailPreferenceResponse={(success) =>
                  this.handlePreferencesResponse({ success })
                }
                Endpoint={"updatepreference"}
              />
              <div className="update-preferences__button-description"><RichText field={PrivacyPolicyText} /></div>
            </div>
          }
          {
            success &&
            <div className="update-preferences__message"><Text field={SuccessMessage} /></div>
          }
        </div>
      </BaseComponent>
    );
  }
}

export default UpdatePreferences;

import React from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import defaultImg from "../../../../assets/img/placeholders/gray.png";
import Details from "../../../Common/Details";

const FeatureConsumerArticleArticleDetails = props => {
  const {
    ContentCategory = null,
    Title = null,
    ShortDescription = null,
    PublishDate = null,
    RetailerLogo = null,
    StorefrontLink = null,
    HidePublishDate = null
  } = props.fields || {};

  let isRetailerArticle = false;
  if (RetailerLogo && RetailerLogo.value && RetailerLogo.value.src) {
    isRetailerArticle = true;
  }

  return (
    <BaseComponent>
      <div className="articledetails-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="articledetails-content">
                {ContentCategory && ContentCategory.value && (
                  <div className="articleevent">
                    <Text field={ContentCategory} />
                  </div>
                )}
                <h1 className="articletitle">
                  <Text field={Title} />
                </h1>
                <div className="articledescription">
                  <Text field={ShortDescription} />
                </div>
                {HidePublishDate && !HidePublishDate.value && (
                <div className="publisheddate">
                  <Details
                    iconValue="calendar"
                    linkName={PublishDate}
                    size="24"
                  />
                </div>
                )}
                {isRetailerArticle && (
                  <div className="article-logo-mobile">
                    <a href={StorefrontLink ? StorefrontLink.value : "#"}>
                      <Image
                        media={{ ...RetailerLogo.value, src: defaultImg }}
                        data-src={RetailerLogo.value.src}
                        className="lazyload"
                      />
                    </a>
                  </div>
                )}
              </div>
            </div>
            {isRetailerArticle && (
              <div className="col-12 col-md-4">
                <a href={StorefrontLink ? StorefrontLink.value : "#"}>
                  <Image
                    media={{ ...RetailerLogo.value, src: defaultImg }}
                    data-src={RetailerLogo.value.src}
                    className="article-logo lazyload"
                  />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </BaseComponent>
  );
};

export default FeatureConsumerArticleArticleDetails;

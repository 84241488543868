import React from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";

const NavRichTextList = ({ title, richTextList }) => {
  let content = "";
  if (richTextList.length > 0) {
    if (title.value) {
      content = (
        <React.Fragment>
          <div className="nav-richtext-title">
            <Text field={title} />
          </div>

          <div className="nav-richtext-content">
            {richTextList.map((item, index) => (
              <div key={`listID-${index}`} className="nav-richtext-row">
                <div className="nav-richtext-content-title">
                  <Text field={item.Name} />
                </div>
                <div className="nav-richtext-content-desc-normal">
                  <RichText field={item.Details} />
                </div>
              </div>
            ))}
          </div>
        </React.Fragment>
      );
    } else {
      content = (
        <React.Fragment>
          {richTextList.map((item, index) => (
            <div key={`listID-${index}`}>
              <div className="nav-richtext-title">
                <Text field={item.Name} />
              </div>
              <div className="nav-richtext-content">
                <div className="nav-richtext-row">
                  <div className="nav-richtext-content-desc-normal">
                    <RichText field={item.Details} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </React.Fragment>
      );
    }
  }

  return <div className="nav-richtext-wrapper">{content}</div>;
};

export default NavRichTextList;

import React, { Component } from "react";

class FeatureMapsCentreMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      control: "",
      directionToId: null,
      levelData: [],
      storeSelected: null,
      amenitiesSelected: null,
      newMapId: null
    };
  }



  componentDidMount() {
    const { retailerStorefrontMap } = this.props || {};
    const script = document.createElement("script");
    const venue = (typeof retailerStorefrontMap !== "undefined" && this.props) ? this.props.mapVenue : this.props.fields.MapDetails.fields.Venue.value;
    const MapDetails = (typeof retailerStorefrontMap !== "undefined" && this.props) ? this.props.MapDetails : this.props.fields.MapDetails;


    window.mappedin = {
      miKey: {
        id: "5b27ff074738e142bc3596a8",
        key: "cnLpUwpoMZNygC8AiDRB9K3xe5fISsE5CZ8SBhO0jUDRU1U6"
      },
      venue: venue ? venue : MapDetails.fields.Venue.value
    };

    script.src =
      "https://d1p5cqqchvbqmy.cloudfront.net/web2/release/mappedin-web.js";
    script.type = "module";
    script.async = true;
    document.body.appendChild(script);

    if (retailerStorefrontMap) {
      const script = document.createElement('script');
      script.src = script.url;
      script.onload = this.zoomOccurence;
      document.head.appendChild(script);
    }

    if (window) {
      const bannerContainer = document.getElementsByClassName("universal-banner");
      if (bannerContainer) {
        const centreMapContainer = document.getElementsByClassName("centremap-wrapper")[0];
        if (centreMapContainer) {
          centreMapContainer.classList.add("has-universal-banner");
        }
      }
    }
  }

  zoomOccurence = () => {
    setTimeout(this.zoomOut, 7000);
    setTimeout(this.zoomOut, 7500);
    setTimeout(this.zoomOut, 8000);
  };
  zoomOut = () => {
    if (
      document &&
      document.getElementsByClassName("sc-lkqHmb") &&
      document.getElementsByClassName("sc-lkqHmb").length
    ) {
      const a = document.getElementsByClassName("sc-lkqHmb")[1];
      if (a) {
        a.click();
      }
    }
  };


  render() {
    const { mapVenue, retailerStorefrontMap } = this.props || {};
    const { MapDetails } = ((typeof retailerStorefrontMap !== "undefined" && this.props) ? this.props : this.props.fields) || {};

    return (
      <div>
        {(MapDetails || mapVenue) && (
          <div className={`centremap-wrapper ${!retailerStorefrontMap ? "js-centremap" : ""}`}>
            <div id="mappedin-map" className="map-content">
              <main
                className="map"
                data-key="externalId"
                data-venue={mapVenue ? mapVenue : MapDetails.fields.Venue.value}
                id="mappedin-app"
              ></main>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default FeatureMapsCentreMap;

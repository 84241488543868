import React from "react";
import { Image, Text } from "@sitecore-jss/sitecore-jss-react";
import Details from "../../../../Common/Details";

const NavigationNavBestParking = ({ title, retailers, centre }) => {
  if (title && retailers && centre) {
    return (
      <div className="best-parking-wrapper">
        <div className="best-parking-title">
          {title && title.url && title.name ? (
            <React.Fragment>
              <a href={title.url}>
                <Text field={title.name} />
              </a>
              <i className="icon icon-arrow-right" />
            </React.Fragment>
          ) : title && title.name ? (
            <Text field={title.name} />
          ) : null}
        </div>

        {retailers &&
          retailers.length &&
          retailers.length > 0 &&
          retailers.map((item, index) => {
            const {
              RetailerUrl = null,
              Thumbnail = null,
              RetailerName = null,
              Carpark = null,
              AvailableParking = null,
              AvailableLabel = null
            } = item || {};
            return (
              <div
                key={`rtID-${index}`}
                className="best-parking-content-wrapper"
              >
                <a
                  href={
                    RetailerUrl && RetailerUrl.value ? RetailerUrl.value : null
                  }
                >
                  {Thumbnail && Thumbnail.value && Thumbnail.value.src ? (
                    <Image
                      media={{ ...Thumbnail.value }}
                      data-src={Thumbnail.value.src}
                      className="best-parking-content-image lazyload"
                    />
                  ) : (
                    <div className="no-image">
                      <Text field={RetailerName} />
                    </div>
                  )}
                </a>
                <div className="best-parking-content-row">
                  <div className="best-parking-content-row-title">
                    <a
                      href={
                        RetailerUrl && RetailerUrl.value
                          ? RetailerUrl.value
                          : null
                      }
                    >
                      <Text field={RetailerName} />
                    </a>
                  </div>
                  <div className="best-parking-content-row-desc">
                    {Carpark && (
                      <div className="best-parking-content-row-desc-col">
                        <Details
                          iconValue="location"
                          linkName={Carpark}
                          size="24"
                        />
                      </div>
                    )}
                    {centre === "robina" && (
                      <div className="best-parking-content-row-desc-col">
                        <span className="best-parking-content-row-desc-num">
                          <Text field={AvailableParking} />
                        </span>
                        <span className="best-parking-content-row-desc-text">
                          <Text field={AvailableLabel} />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  } else return null;
};

export default NavigationNavBestParking;

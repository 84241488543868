import React, { Component } from "react";

class BackToTop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBackToTop: false,
        };
    }
    componentDidMount() {
        if (window) {
            window.addEventListener('scroll', this.handleOnScroll);
        }
    }

    handleOnScroll = () => {
        if (document.documentElement.scrollTop > 20) {
            this.setState({ showBackToTop: true });
            const backToTopWrapper = document.querySelector(".back-to-top__wrapper")
            const footerContainer = document.querySelector(".globalfooter-wrapper  .container");
            let backToTopPos = 0;

            if (footerContainer) {
                backToTopPos = window.getComputedStyle(footerContainer).getPropertyValue("margin-left");
            } else {
                const centreMapContainer = document.querySelector(".js-centremap .map-content");
                const bodyContainer = document.querySelector(".container");

                if (centreMapContainer) {
                    backToTopPos = window.getComputedStyle(centreMapContainer).getPropertyValue("margin-left");
                    backToTopWrapper.classList.add("has-centre-map");
                } else if (bodyContainer) {
                    backToTopPos = window.getComputedStyle(bodyContainer).getPropertyValue("margin-left");
                }
            }
            backToTopWrapper.style.right = backToTopPos;
        }
        else {
            this.setState({ showBackToTop: false });
        }
    }

    handleOnClick = () => {
        // Check if using IE browser
        const ua = window.navigator.userAgent.toLowerCase();
        if ((/msie|trident/.test(ua))) {
            window.scroll(0, 0);
        } else {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }
    render() {
        const {
            showBackToTop
        } = this.state;
        return (
            <div>
                {showBackToTop &&
                    <div className="back-to-top__wrapper font-regular">
                        <div className="container">
                            <button onClick={this.handleOnClick} className="back-to-top__button">
                                <span className="font-markpro-medium">Back to top</span>
                                <i className="icon icon-arrow-up"></i>
                            </button>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default BackToTop;

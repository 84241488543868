import React from "react";
import BaseComponent from "../../../Base/BaseComponent";

const FeatureGenericContentHomeQuickLinks = props => {
  const { Links: links = null } = (props.quickLinks ? props.quickLinks : props.fields) || {};
  return (
    <div className="homequicklinks-container">
      {props.quickLinks &&
        <div className="homequicklinks-container-navigation">
          {links &&
            links.length !== undefined &&
            links.map((item, index) => {
              const {
                LinkIcon: LinkIcon = null,
                LinkTarget: LinkTarget = null
              } = item.fields;
              return (
                <span key={`liID-${index}`}>
                  <div className="item">
                    <a href={LinkTarget.value.href}
                      title={LinkTarget.value.title}
                      target={LinkTarget.value.target} >
                      <i className={`icon icon-${LinkIcon.value} main`} />
                      {LinkTarget.value.text}
                    </a>
                  </div>
                </span>
              );
            })}
        </div>
      }


      {props.fields &&
        <BaseComponent margin="standard">
          <div className="content-list">
            <ul>
              {links &&
                links.length !== undefined &&
                links.map((item, index) => {
                  const {
                    LinkIcon: LinkIcon = null,
                    LinkTarget: LinkTarget = null
                  } = item.fields;
                  return (
                    <li key={`liID-${index}`}>
                      <div className="item">
                        <a href={LinkTarget.value.href}
                          title={LinkTarget.value.title}
                          target={LinkTarget.value.target} >
                          <i className={`icon icon-${LinkIcon.value} main`} />
                          <span>{LinkTarget.value.text}</span>
                          <i className="icon icon-arrow-right" />
                        </a>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="clearfix"></div>
        </BaseComponent>
      }
    </div>
  );
};
export default FeatureGenericContentHomeQuickLinks;

import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const FeatureParkingParkingRates = props => {
  const {
    ParkRateTitle = null,
    MaxRate = null,
    HoursLabel = null,
    CasualRateLabel = null,
    CinemaRateLabel = null,
    SeniorRateLabel = null,
    DisplaySeniorRates = null,
    FreeAfterTime = null,
    ParkRates = null
  } = props.fields || {};

  const checkRate = rate => {
    return rate > 0 ? "" : "free";
  };

  const checkFree = rate => {
    return rate > 0 ? "$" + rate : "FREE";
  };

  if (ParkRateTitle && ParkRateTitle.value && ParkRates && ParkRates.length) {
    return (
      <div className="parking-parkingrates-container">
        <div className="content-wrapper">
          <div className="content-header-wrapper">
            <div className="header-main">
              <Text field={ParkRateTitle} />
            </div>
          </div>
          <div className="content-table-wrapper">
            <table className="table-content normal">
              <thead>
                <tr className="table-header">
                  <th className="header-item">
                    <Text field={HoursLabel} />
                  </th>
                  <th className="header-item">
                    <Text field={CasualRateLabel} />
                  </th>
                  <th className="header-item">
                    <Text field={CinemaRateLabel} />
                  </th>
                  {DisplaySeniorRates
                    && DisplaySeniorRates.value &&
                    <th className="header-item">
                      <Text field={SeniorRateLabel} />
                    </th>
                  }
                </tr>
              </thead>
              <tbody>
                {ParkRates &&
                  ParkRates.length !== undefined &&
                  ParkRates.map((item, index) => {
                    const {
                      HoursRange = null,
                      CasualRate = null,
                      CinemaRate = null,
                      SeniorRate = null
                    } = item.fields || {};
                    return (
                      <tr key={`trID-${index}`} className="table-item">
                        <td className="item">
                          <Text field={HoursRange} />
                        </td>

                        <td className="item">
                          <span className={checkRate(CasualRate.value)}>
                            <Text
                              field={{
                                value: checkFree(CasualRate.value)
                              }}
                            />
                          </span>
                        </td>

                        <td className="item">
                          <span className={checkRate(CinemaRate.value)}>
                            <Text
                              field={{
                                value: checkFree(CinemaRate.value)
                              }}
                            />
                          </span>
                        </td>
                        {DisplaySeniorRates &&
                          DisplaySeniorRates.value &&
                          <td className="item">
                            <span className={checkRate(SeniorRate.value)}>
                              <Text
                                field={{
                                  value: checkFree(SeniorRate.value)
                                }}
                              />
                            </span>
                          </td>
                        }
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <div className="table-content mobile">
              <div className="table-item-wrapper">
                {ParkRates &&
                  ParkRates.length !== undefined &&
                  ParkRates.map((item, index) => {
                    const {
                      HoursRange = null,
                      CasualRate = null,
                      CinemaRate = null,
                      SeniorRate = null
                    } = item.fields || {};
                    return (
                      <div key={`trmobileID-${index}`} className="table-item">
                        <div className="item header">
                          <Text field={HoursRange} />
                        </div>
                        <div className="item">
                          <div>
                            <Text field={CasualRateLabel} />
                          </div>
                          <div>
                            <span className={checkRate(CasualRate.value)}>
                              <Text
                                field={{
                                  value: checkFree(CasualRate.value)
                                }}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="item">
                          <div>
                            <Text field={CinemaRateLabel} />
                          </div>
                          <div>
                            <span className={checkRate(CinemaRate.value)}>
                              <Text
                                field={{
                                  value: checkFree(CinemaRate.value)
                                }}
                              />
                            </span>
                          </div>
                        </div>
                        {DisplaySeniorRates
                          && DisplaySeniorRates.value &&
                          <div className="item">
                            <div>
                              <Text field={SeniorRateLabel} />
                            </div>
                            <div>
                              <span className={checkRate(SeniorRate.value)}>
                                <Text
                                  field={{
                                    value: checkFree(SeniorRate.value)
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        }
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          {(MaxRate &&
            MaxRate.value &&
            FreeAfterTime &&
            FreeAfterTime.value) &&
            <div className="content-max-wrapper">
              <div className="max-text">
                <span>
                  <Text field={MaxRate} />
                </span>
                <span>
                  <Text field={FreeAfterTime} />
                </span>
              </div>
            </div>
          }
        </div>
      </div>
    );
  } else return null;
};
export default FeatureParkingParkingRates;

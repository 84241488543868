import React from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import Details from "../../../Common/Details";

const FeatureGenericContentHeroDescription = props => {
  let {
    PromotionCategoryTag: PromotionCategoryTag = null,
    PromotionName: PromotionName = null,
    PromotionSummary: PromotionSummary = null,
    PromotionLink: PromotionLink = null,
    Location: Location = null,
    LocationLink: LocationLink = null,
    ScheduleTime: ScheduleTime = null,
    HidePublishDate: HidePublishDate = null
  } = props.fields;

  return (
    <BaseComponent margin="standard">
      <div className="genericcontent-herodescription-wrapper">
        {/* <div className="promotions-category">
          <Text field={PromotionCategoryTag} />
        </div>
        <div className="promotions-name">
          {PromotionLink && PromotionLink.value ? (
            <a href={PromotionLink.value}>
              <Text field={PromotionName} />
            </a>
          ) : (
            <Text field={PromotionName} />
          )}
        </div> */}
        {PromotionSummary && PromotionSummary.value && (
          <div className="promotions-summary">
            <RichText field={PromotionSummary} />
          </div>
        )}
        {Location && Location.value && (
          <Details
            iconValue="location"
            linkUrl={LocationLink && LocationLink.value}
            linkName={Location}
            size="24"
          />
        )}
        {HidePublishDate && !HidePublishDate.value && ScheduleTime && ScheduleTime.value && (
          <Details iconValue="time" linkName={ScheduleTime} size="24" />
        )}
      </div>
    </BaseComponent>
  );
};
export default FeatureGenericContentHeroDescription;

export const disableBodyScroll = () => {
  const documentHeight = document.documentElement.scrollHeight;
  const windowHeight = window.innerHeight;

  if (documentHeight > windowHeight) {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    document.documentElement.classList.add("noscroll");
    document.documentElement.style.top = `-${scrollTop}px`;
  }
};

export const enableBodyScroll = () => {
  const scrollTop = parseInt(document.documentElement.style.top || '0px'); 
  document.documentElement.classList.remove("noscroll");
  window.scrollTo(0, -scrollTop); 
};

export const disableBodyScrolling = () => {
  if (document) {
    let bodyWrapper = document.querySelector("body");
    if (bodyWrapper) {
      bodyWrapper.classList.add("disable-scroll");
    }
  }
};

export const enableBodyScrolling = () => {
  if (document) {
    let bodyWrapper = document.querySelector("body");
    if (bodyWrapper) {
      bodyWrapper.classList.remove("disable-scroll");
    }
  }
};
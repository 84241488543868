import React, { Component } from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import Details from "../../../Common/Details";
import RiseAndFade from "../../../Common/Animations/RiseAndFade";
import defaultImg from "../../../../assets/img/placeholders/gray.png";

class FeatureStoreDirectoryDirectoryList extends Component {
  handleClickBrauzBtn = id => {
    window.Brauz_open_book_a_stylist_dialog_with_selected_store(id);
  };

  render() {
    let {
      RetailerPageHref = null,
      HeroImage = null,
      Logo = null,
      RetailerName = null,
      StoreLocationLink = null,
      StoreLocation = null,
      OpeningTime = null,
      Rating = null,
      PriceLevel = null,
      ItemId = null,
      AcceptsOnlineBooking = null,
      BookingLabel = null,
      BookingUrl = null,
      HasBooking = null
    } = this.props.info || {};

    const value = this.props.value;
    const noDefaultValue = this.props.noDefaultImage;

    let retailerLink;
    let defaultImage;
    let defaultLogo;
    let ratingSection;
    if (RetailerPageHref && RetailerPageHref.value) {
      retailerLink = RetailerPageHref.value;
    } else retailerLink = "/";
    defaultImage = () => {
      if (HeroImage && HeroImage.value && HeroImage.value.src) {
        return (
          <div>
            {noDefaultValue ? (
              <Image media={{ ...HeroImage.value }} className="tile-image" />
            ) : (
              <Image
                media={{ ...HeroImage.value, src: defaultImg }}
                data-src={HeroImage.value.src}
                className="tile-image lazyload"
              />
            )}
          </div>
        );
      } else {
        return <div className="no-image" />;
      }
    };

    defaultLogo = () => {
      if (Logo && Logo.value && Logo.value.src) {
        return (
          <div className="tile-logo-wrapper">
            {noDefaultValue ? (
              <Image media={{ ...Logo.value }} className="tile-logo" />
            ) : (
              <Image
                media={{ ...Logo.value, src: defaultImg }}
                data-src={Logo.value.src}
                className="tile-logo lazyload"
              />
            )}
          </div>
        );
      } else {
        return (
          <div className="no-logo">
            <Text field={RetailerName} />
          </div>
        );
      }
    };

    ratingSection = () => {
      let rating = [];
      let priceLevel = [];
      let ratingContainer = [];

      if (Rating && Rating.value) {
        const ratingValue = parseInt(Rating.value);

        for (let i = 1; i <= 5; i++) {
          if (i <= ratingValue) {
            rating.push(<div className="icon icon-star checked" key={i} />);
          } else {
            rating.push(<div className="icon icon-star" key={i} />);
          }
        }
      }

      if (PriceLevel && PriceLevel.value) {
        const priceLevelValue = parseInt(PriceLevel.value);
        if (priceLevelValue <= 1) {
          priceLevel.push(
            <i className="icon icon-dollar" key={`priceLevelId1`} />
          );
        } else if (priceLevelValue === 2 || priceLevelValue === 3) {
          for (let i = 0; i < 2; i++) {
            priceLevel.push(
              <i className="icon icon-dollar" key={`priceLevelId` + i} />
            );
          }
        } else if (priceLevelValue >= 4) {
          for (let i = 0; i < 3; i++) {
            priceLevel.push(
              <i className="icon icon-dollar" key={`priceLevelId` + i} />
            );
          }
        }
      } else {
        priceLevel = "";
      }

      ratingContainer =
        rating.length || priceLevel ? (
          <div className="directorylist-storerating">
            <div className="star">{rating}</div>
            <div className="dollar">
              <div className="pricelevel">
                <span>{priceLevel}</span>
              </div>
            </div>
          </div>
        ) : (
          ""
        );

      return ratingContainer;
    };

    return (
      <div className="directorylist-card">
        <RiseAndFade position={value}>
          <div className="animation-range">
            <div className="directorylist-tile">
              <a href={retailerLink}>
                {defaultImage()}
                {defaultLogo()}
              </a>
            </div>
            <div className="directorylist-storename">
              <a href={retailerLink}>
                <Text field={RetailerName} />
              </a>
            </div>
          </div>
          <div className="directorylist-details">
            {ratingSection()}
            {StoreLocation && StoreLocation.value && (
              <Details
                iconValue="location"
                linkUrl={StoreLocationLink && StoreLocationLink.value}
                linkName={StoreLocation}
                size="24"
                mapInfo={ItemId && ItemId.value ? ItemId.value : ""}
              />
            )}
            {OpeningTime && OpeningTime.value && (
              <Details iconValue="time" linkName={OpeningTime} size="24" />
            )}
            {AcceptsOnlineBooking && AcceptsOnlineBooking.value && (
              <div className="details-wrapper">
                <i
                  className="icon icon-calendar-2 mr-2"
                  style={{ fontSize: "24px" }}
                />
                <div className="text-block">
                  {HasBooking && HasBooking.value ? (
                    <button
                      onClick={() => this.handleClickBrauzBtn(ItemId.value)}
                    >
                      <Text field={BookingLabel} />
                    </button>
                  ) : BookingUrl && BookingUrl.value ? (
                    <a href={BookingUrl.value} target="_blank">
                      <Text field={BookingLabel} />
                    </a>
                  ) : (
                    <Text field={BookingLabel} />
                  )}
                </div>
              </div>
            )}
          </div>
        </RiseAndFade>
      </div>
    );
  }
}

export default FeatureStoreDirectoryDirectoryList;

import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
const FeatureOpeningHoursCentreOpeningHours = props => {
  let {
    schedules = null,
    comingUp = null
  } = props.fields || {};
  let {
    holidayGroups = null,
    comingUpText = null
  } = comingUp.fields || {};

  const renderOpeningHoursDesktop = (scheduleList, isOpeningHours) => {
    let openingHoursDesktop;
    openingHoursDesktop = scheduleList.map((item, index) => {
      const {
        day,
        time,
        holiday
      } = item.fields.Schedule.fields;
      return (
        <div
          key={`${day.value}-${time.value}-${index}`}
          className={`openinghours__schedule ${index == 0 && isOpeningHours ? "is-first" : ""}`}
        >
          <div className="col-12 col-md-5 openinghours__schedule--day">
            <span className="openinghours__schedule--day">
              <Text field={day} />
            </span>
          </div>
          <div className="col-12 col-md-3 openinghours__schedule--time">
            <span className="openinghours__schedule--time">
              <Text field={time} />
            </span>
          </div>
          <div className="col-12 col-md-4 openinghours__schedule--holiday">
            {
              holiday &&
              holiday.value &&
              <span className="">
                <Text field={holiday} />
              </span>
            }
          </div>
        </div>
      );
    })
    return (
      openingHoursDesktop
    )
  }

  const renderOpeningHoursMobile = (scheduleList, isOpeningHours) => {
    let openingHoursMobile;
    openingHoursMobile = scheduleList.map((item, index) => {
      const {
        day,
        time,
        holiday
      } = item.fields.Schedule.fields;
      let scheduleLayout;
      let btnLayout;
      if (index == 0 && isOpeningHours) {
        btnLayout =
          <input
            className="openinghours__schedule--checkbox"
            type="checkbox"
            id={`${day.value}-${time.value}-${index}`}
            key={`checkbox-${day.value}-${time.value}-${index}`}
          />
        scheduleLayout =
          <label
            className="openinghours__schedule--label"
            htmlFor={`${day.value}-${time.value}-${index}`}
            key={`label-${day.value}-${time.value}-${index}`}
          >
            <div className="openinghours__schedule is-first">
              <div className="col-11 openinghours__schedule--day">
                <div className="">
                  <Text field={day} />
                </div>
                <div className="openinghours__schedule--time">
                  <Text field={time} />
                </div>
              </div>
              <div className="col-1 openinghours__schedule--holiday">
                <i className="icon icon-arrow-down" />
              </div>
            </div>
          </label>
      }
      else {
        scheduleLayout =
          <div
            className="openinghours__schedule--list"
            key={`list-${day.value}-${time.value}-${index}`}
          >
            <div className="openinghours__schedule">
              <div className="col-7 openinghours__schedule--day">
                <div className="">
                  <Text field={day} />
                </div>
                <div className="openinghours__schedule--time">
                  <Text field={time} />
                </div>
              </div>
              <div className="col-5 openinghours__schedule--holiday">
                {
                  holiday &&
                  holiday.value &&
                  <span className="">
                    <Text field={holiday} />
                  </span>
                }
              </div>
            </div>
          </div>
      }
      return ([
        btnLayout,
        scheduleLayout
      ]);
    })
    return (
      openingHoursMobile
    )
  }

  const renderComingup = (isDesktop) => {
    let comingupLayout;
    if (holidayGroups.length > 0) {
      comingupLayout =
        <div className={`openinghours__comingup ${isDesktop ? "" : "openinghours__schedule--list"}`}>
          <div className="openinghours__comingup--title">
            <Text field={comingUpText} />
          </div>
          {
            holidayGroups.map((item, index) => {
              const {
                holidayGroupName,
                holidays,
              } = item.fields;
              let renderOpeningHours;
              if (isDesktop) {
                renderOpeningHours = renderOpeningHoursDesktop(holidays)
              } else {
                renderOpeningHours = renderOpeningHoursMobile(holidays)
              }
              return (
                <div key={holidayGroupName.value + index}>
                  <div className="openinghours__comingup--holidaygroup-title">
                    <Text field={holidayGroupName} />
                  </div>
                  {renderOpeningHours}
                </div>
              );
            })
          }
        </div>
    }
    return (comingupLayout)
  }
  return (
    <div className="openinghours__wrapper font-regular">
      <div className="openinghours__wrapper--desktop">
        {renderOpeningHoursDesktop(schedules, true)}
        {renderComingup(true)}
      </div>
      <div className="openinghours__wrapper--mobile">
        {renderOpeningHoursMobile(schedules, true)}
        {renderComingup()}
      </div>
    </div>
  );
};

export default FeatureOpeningHoursCentreOpeningHours;

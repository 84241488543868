import React from "react";
import { Text, Link, Image, RichText } from "@sitecore-jss/sitecore-jss-react";
import ScrollAnimation from "react-animate-on-scroll";
import BaseComponent from "../../../Base/BaseComponent";
import { get } from "lodash";
import defaultImg from "../../../../assets/img/placeholders/gray.png";
import RiseAndFade from "../../../Common/Animations/RiseAndFade";
import Details from "../../../Common/Details";

const FeatureCentreServicesFeaturedServicesHighlight = props => {
  const {
    PramParkingURL,
    CentreServiceHeading,
    ElevatorsAndLiftsURL,
    FeaturedServices: featuredServices = null
  } = props.fields || {};
  if (
    props &&
    props.rendering &&
    props.rendering.dataSource &&
    featuredServices &&
    featuredServices.length
  ) {
    return (
      <BaseComponent margin="standard">
        <div className="categoryservices-wrapper">
          <div className="categoryservices-banner">
            <div className="container">
              <div className="row no-gutters">
                <div className="categoryservices-content-wrapper col-11">
                  <div className="categoryservices-header-wrapper">
                    <ScrollAnimation
                      animateIn="animate__animated animate__fadeIn"
                      animateOnce={true}
                    >
                      <div className="categoryservices-header-title">
                        <Link field={CentreServiceHeading} />
                        <i className="icon icon-arrow-right" />
                      </div>

                      <div className="categoryservices-header-buttons">
                        {PramParkingURL && PramParkingURL.value.href &&
                          <Link
                            className="btn quaternary-btn"
                            field={PramParkingURL}
                          />

                        }
                        {ElevatorsAndLiftsURL && ElevatorsAndLiftsURL.value.href &&
                          <Link
                            className="btn quaternary-btn"
                            field={ElevatorsAndLiftsURL}
                          />

                        }
                      </div>
                    </ScrollAnimation>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="categoryservices-tiles-wrapper">
            <div className="container">
              <div className="categoryservices-tiles-content">
                <div className="row no-gutters">
                  {featuredServices &&
                    featuredServices.length &&
                    featuredServices.map((item, index) => {
                      const {
                        HeroImage: heroImage = null,
                        HeroImageUrl: heroImageUrl = null,
                        HeroImageAltText: heroImageAltText = null,
                        ServiceName: serviceName = null,
                        Description: description = null,
                        MapLink: mapLink = null,
                        Location: location = null
                      } = item.fields || {};

                      if (
                        heroImage &&
                        heroImage.value &&
                        heroImageUrl &&
                        heroImageUrl.value
                      ) {
                        heroImage.value.src = heroImageUrl.value;
                      }
                      let value = 0;
                      if (index == 1) {
                        value = 6;
                      }
                      return (
                        <div
                          className="col-12 col-md-6"
                          key={`catgeoryID` + index}
                        >
                          <RiseAndFade position={value}>
                            <div className="animation-range">
                              <div className="categoryservices-tiles-image-wrapper">
                                <Image
                                  media={{
                                    ...heroImage.value,
                                    src: defaultImg,
                                    alt:
                                      heroImageAltText && heroImageAltText.value
                                        ? heroImageAltText.value
                                        : ""
                                  }}
                                  data-src={heroImage.value.src}
                                  className="lazyload"
                                />
                              </div>
                              <div className="categoryservices-tiles-details-title">
                                <span>
                                  <Text field={serviceName} />
                                </span>
                              </div>
                            </div>
                            <div className="categoryservices-tiles-details">
                              <div className="categoryservices-tiles-details-description">
                                <RichText field={description} />
                              </div>
                              {get(mapLink, "value.href", null) ? (
                                <div className="categoryservices-tiles-details-item">
                                  <Details
                                    iconValue="location"
                                    linkUrl={
                                      mapLink &&
                                      mapLink.value &&
                                      mapLink.value.href
                                    }
                                    mapInfo={
                                      location && location.value ? 
                                      encodeURI(location.value)
                                      : ""
                                    }
                                    linkName={
                                      mapLink &&
                                        mapLink.value &&
                                        mapLink.value.text
                                        ? { value: mapLink.value.text }
                                        : ""
                                    }
                                    size="24"
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </RiseAndFade>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  } else return null;
};

export default FeatureCentreServicesFeaturedServicesHighlight;

import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Details from "../../../Common/Details";

const FeatureCraftCraftQuickLinksMenu = (props) => {
  const {
    Tags = []
  } = props.fields || {};
  return (
    <div className="craft-quicklinks-menu font-regular">
      {(Tags || Tags.length > 0) &&
        <div className="craft-quicklinks-menu__wrapper">
          {
            Tags &&
            Tags.length > 0 &&
            <div className="craft-quicklinks-menu__tags">
              <div className="craft-quicklinks-menu__tags--title">Tags</div>
              <div className="craft-quicklinks-menu__tagsn--list">
                {
                  Tags.map((item, index) => {
                    const {
                      Name = null,
                      Link = null
                    } = item.fields || {}

                    return (
                      <div key={Name.value + index}
                        className="craft-quicklinks-menu__tags--item">
                        {Link &&
                          Link.value ?
                          <a href={Link && Link.value ? Link.value : ""}>
                            <Text field={Name} />
                          </a>
                          : <Text field={Name} />
                        }
                      </div>
                    )
                  })
                }
              </div>
            </div>
          }
        </div>
      }
    </div>
  )
};

export default FeatureCraftCraftQuickLinksMenu;